const intialColumnsSampleDate = [
  {
    accessorKey: "date_receipt",
    header: "Data przyjęcia",
  },
  {
    accessorKey: "date_examination",
    header: "Data pobrania",
  },
];

export default intialColumnsSampleDate;
