import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import TableActionButton from "../../../../../components/Button/TableActionButton";
import api from "../../../../../config/api/apiRoutes";
import { requestTable } from "../../../../../services/actions/auth";

const attributeColumns = [
  {
    accessorKey: "id",
    header: "ID",
    size: 40,
  },
  {
    accessorKey: "attribute",
    header: "nazwa",
    size: 120,
  },

  {
    accessorFn: (row) => (
      <TableActionButton
        data={[
          {
            label: "Edytuj",
            icon: <EditIcon />,
            linkTo: `/attribute/${row.id}/edit`,
          },
          {
            label: "Usuń",
            icon: <DeleteIcon />,
            onClick: () => requestTable(api(row.id).ATTRIBUTE_DELETE, "delete"),
          },
        ]}
      />
    ),
    header: "Akcja",
    enableColumnFilter: false,
    enableSorting: false,
  },
];

export default attributeColumns;
