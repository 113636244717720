import React, { Component } from "react";
import { connect } from "react-redux"; // new import
import * as actions from "../../../services/actions/auth";
import axios from "axios";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Button,
  TextField,
  Typography,
  Container,
} from "@mui/material";
import api from "../../../config/api/apiRoutes";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { Redirect } from "react-router-dom";
import Select from "react-select";
import SelectStyles from "../../select/SelectStyles";

class ProcedureFormEquipmentAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      equipment: this.props.data,
      selectedEquipment: "",
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleaddEquipmentChange = (selectedEquipment) => {
    this.setState({ selectedEquipment });
  };

  addProcedureEquipment = (e) => {
    e.preventDefault();
    const formData = {
      procedure: this.props.id_procedure,
      equipment: this.state.selectedEquipment.value,
      creator: `${localStorage.getItem("user_id")}`,
    };

    this.props.onAuth(formData, this.props.id_procedure);
  };

  render() {
    if (this.props.adding === true) {
      this.props.props.history.push(`/procedure/${this.props.id_procedure}`);
    }

    return (
      <>
        {this.props.loading ? (
          <LoadingSpinner />
        ) : (
          <Card>
            <form onSubmit={this.addProcedureEquipment}>
              <Divider />
              <CardContent>
                <Grid container alignItems="center" justifyContent={"center"}>
                  <Grid item xs={12} xm={12} >
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      options={this.state.equipment}
                      placeholder="Wybierz sprzęt"
                      styles={SelectStyles}
                      menuPortalTarget={document.body}
                      onChange={this.handleaddEquipmentChange}
                      name="selectedEquipment"
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  p: 3,
                }}
              >
                <Button type="submit" variant="contained">
                  Dodaj sprzęt
                </Button>
              </Box>
            </form>
          </Card>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error,
    adding: state.adding,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (formData, id) =>
      dispatch(
        actions.requestApi(formData, api(id).PROCEDURE_EQUIPMENT_ADD, "patch")
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProcedureFormEquipmentAdd);
