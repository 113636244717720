import React, { Component } from "react";
import { connect } from "react-redux"; // new import
import * as actions from "../../../services/actions/auth";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import api from "../../../config/api/apiRoutes";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

class ParameterFormAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attribute: "",
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  addEquipmentForm = (e) => {
    e.preventDefault();
    const formData = {
      attribute: this.state.attribute,
      values_type: "KRÓTKI OPIS",
      sample_result: true,
      creator: `${localStorage.getItem("user_id")}`,
    };

    this.props.onAuth(formData);
  };

  render() {
    let errorMessage = null;
    if (this.props.error) {
      errorMessage = <p>{this.props.error.message}</p>;
    }

    if (this.props.adding == true) {
      this.props.props.history.push("/");
    }
    console.log(this.props);

    return (
      <>
        {this.props.loading ? (
          <LoadingSpinner />
        ) : (
          <Card>
            <Typography
              variant="h6"
              sx={{ position: "relative", padding: "16px 0 20px 16px" }}
            >
              <span
                style={{
                  content: "",
                  backgroundColor: "#111e6c",
                  position: "absolute",
                  left: "0",
                  top: "16px",
                  width: "4px",
                  height: "25px",
                }}
              ></span>
              Formularz dodania parametru
            </Typography>

            <Divider />
            <form onSubmit={this.addEquipmentForm}>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      type="text"
                      id="typeEmailX"
                      name="attribute"
                      label="Nazwa parametru"
                      variant="outlined"
                      value={this.state.attribute}
                      onChange={this.onChange}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  p: 3,
                }}
              >
                <Button type="submit" variant="contained" endIcon={<AddIcon />}>
                  Dodaj parametr
                </Button>
              </Box>
            </form>
          </Card>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error,
    adding: state.adding,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (formData) =>
      dispatch(actions.requestApi(formData, api().PARAMETER_ADD_POST, "post")),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ParameterFormAdd);
