import React, { Component } from "react";
import { connect } from "react-redux"; // new import
import * as actions from "../../../services/actions/auth";
import axios from "axios";
import api from "../../../config/api/apiRoutes";
import SelectStyles from "../../select/SelectStyles";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Button,
  TextField,
  Container,
  Typography,
  FormHelperText,
} from "@mui/material";
import Select from "react-select";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import EditIcon from "@mui/icons-material/Edit";

const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach(
    // if we have an error string set valid to false
    (val) => val.length > 0 && (valid = false)
  );
  return valid;
};

class SampleFormStatusEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getSampleStatus: this.props.data.status,
      addLocalization: "",
      id: "",
      errors: {
        addLocalization: "",
      },
    };
  }

  handleaddLocalizationChange = (addLocalization) => {
    this.setState({ addLocalization });
  };

  handledeleteLocalizationChange = (deleteLocalization) => {
    this.setState({ deleteLocalization });
  };

  editSampleStatusForm = (e) => {
    e.preventDefault();

    const { addLocalization } = this.state;

    const validate = () => {
      console.log(!this.state.addLocalization.label);
      let validationError = this.state.errors;
      if (!addLocalization.label) {
        validationError.addLocalization = "Wymagane pole";
      } else {
        validationError.addLocalization = "";
      }

      this.setState({ validationError, validationError });
      console.log("in validate", validationError);
      return validationError;
    };

    const formData = {
      sample: this.props.id_sample,
      status: addLocalization.label,
    };

    const validationError = validate();
    console.log(this.state.errors);
    if (validateForm(validationError)) {
      console.info("Valid Form");
      this.props.onAuth(formData, this.props.id_sample);
    } else {
      console.error("Invalid Form");
    }
  };

  render() {
    if (this.props.adding == true) {
      console.log(this.props);
      this.props.props.history.push(`/sample/${this.props.id_sample}`);
    }
    return (
      <>
        {this.props.loading ? (
          <LoadingSpinner />
        ) : (
          <Card>
            <Typography
              variant="h6"
              sx={{ position: "relative", padding: "16px 0 20px 16px" }}
            >
              <span
                style={{
                  content: "",
                  backgroundColor: "#111e6c",
                  position: "absolute",
                  left: "0",
                  top: "16px",
                  width: "4px",
                  height: "25px",
                }}
              ></span>
              Formularz edycji statusu próbki
            </Typography>

            <Divider />
            <form onSubmit={this.editSampleStatusForm}>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      type="text"
                      inputProps={{ readOnly: true }}
                      label="Obecny stetus"
                      disabled={true}
                      value={this.state.getSampleStatus}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <Select
                      options={[
                        { value: "NEW", label: "NOWA" },
                        {
                          value: "FOR_EXAMINATION",
                          label: "DO ZBADANIA",
                        },
                        { value: "EXAMINATED", label: "ZBADANA" },
                        { value: "ARCHIVED", label: "ZARCHIWIZOWANA" },
                      ]}
                      placeholder="Nadaj status"
                      onChange={this.handleaddLocalizationChange}
                      styles={SelectStyles}
                      name="color"
                      menuPortalTarget={document.body}
                    />
                    {this.state.errors.addLocalization.length > 0 && (
                      <FormHelperText error={true}>
                        {this.state.errors.addLocalization}
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  p: 3,
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  endIcon={<EditIcon />}
                >
                  Edytuj status
                </Button>
              </Box>
            </form>
          </Card>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error,
    adding: state.adding,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (formData, id) =>
      dispatch(actions.requestApi(formData, api(id).SAMPLE_STATUS_GET, "post")),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SampleFormStatusEdit);
