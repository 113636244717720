import React, { Component } from "react";
import { connect } from "react-redux"; // new import
import * as actions from "../../../services/actions/auth";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import api from "../../../config/api/apiRoutes";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Container,
  Typography,
  Grid,
  Button,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

class ReagentFormAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ReagentName: "",
      ReagentCas: "",
      ReagentLazyMass: "",
      ReagentShortName: "",
      ReagentAddInfo: "",
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  componentDidMount() {}

  addEquipmentForm = () => {
    const formData = {
      name: this.state.ReagentName,
      cas: this.state.ReagentCas,
      lazy_mass: this.state.ReagentLazyMass,
      short_name: this.state.ReagentShortName,
      additional_info: this.state.ReagentAddInfo,
      creator: `${localStorage.getItem("user_id")}`,
    };

    this.props.onAuth(formData);
  };

  render() {
    if (this.props.adding == true) {
      this.props.props.history.push("/");
    }
    return (
      <>
        {this.props.loading ? (
          <LoadingSpinner />
        ) : (
          <Card>
            <Typography
              variant="h6"
              sx={{ position: "relative", padding: "16px 0 20px 16px" }}
            >
              <span
                style={{
                  content: "",
                  backgroundColor: "#111e6c",
                  position: "absolute",
                  left: "0",
                  top: "16px",
                  width: "4px",
                  height: "25px",
                }}
              ></span>
              Formularz dodania odczynnika
            </Typography>

            <Divider />
            <form onSubmit={this.addEquipmentForm}>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="Nazwa odczynnika"
                      onChange={this.onChange}
                      value={this.state.ReagentName}
                      type="text"
                      name="ReagentName"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="CAS"
                      onChange={this.onChange}
                      value={this.state.ReagentCas}
                      type="text"
                      name="ReagentCas"
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="Masa molowa"
                      onChange={this.onChange}
                      value={this.state.ReagentLazyMass}
                      type="number"
                      name="ReagentLazyMass"
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="Nazwa skrócona"
                      onChange={this.onChange}
                      value={this.state.ReagentShortName}
                      type="text"
                      name="ReagentShortName"
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="Dodatkowe informacje"
                      onChange={this.onChange}
                      value={this.state.ReagentAddInfo}
                      type="text"
                      name="ReagentAddInfo"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  p: 2,
                }}
              >
                <Button type="submit" variant="contained" endIcon={<AddIcon />}>
                  Dodaj odczynnik
                </Button>
              </Box>
            </form>
          </Card>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error,
    adding: state.adding,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (formData) =>
      dispatch(actions.requestApi(formData, api().REAGENT_ADD_POST, "post")),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReagentFormAdd);
