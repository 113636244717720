import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import Input from "../../mui/inputs/Input";
import AddIcon from "@mui/icons-material/Add";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import api from "../../../config/api/apiRoutes";
import { connect } from "react-redux";
import * as actions from "../../../services/actions/auth";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";

function flattenArray(data) {
  const flattenedData = [];

  function flatten(item) {
    const { id, amount, unit, real_amount, real_unit } = item;
    flattenedData.push({
      id,
      amount,
      unit,
      real_amount,
      real_unit,
    });

    if (item.children && item.children.length > 0) {
      item.children.forEach((child) => {
        flatten(child);
      });
    }
  }

  data.forEach((item) => {
    flatten(item);
  });

  return flattenedData;
}

class SampleForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedSamples: [],
      sampleData: this.props.sampleTree,
      errors: {},
    };
  }

  toggleSample(e, sampleId) {
    e.preventDefault();
    const { expandedSamples } = this.state;
    const isExpanded = expandedSamples.includes(sampleId);

    if (isExpanded) {
      this.setState({
        expandedSamples: expandedSamples.filter((id) => id !== sampleId),
      });
    } else {
      this.setState({
        expandedSamples: [...expandedSamples, sampleId],
      });
    }
  }

  handleAmountChange(id, value) {
    const { sampleData } = this.state;
    const updatedSampleData = this.updateSampleData(sampleData, id, {
      amount: value,
    });

    this.setState({
      sampleData: updatedSampleData,
    });
  }

  handleRealAmountChange(id, value) {
    const { sampleData } = this.state;
    const updatedSampleData = this.updateSampleData(sampleData, id, {
      real_amount: value,
    });

    this.setState({
      sampleData: updatedSampleData,
    });
  }

  handleRealUnitChange(id, value) {
    const { sampleData } = this.state;
    const updatedSampleData = this.updateSampleData(sampleData, id, {
      real_unit: value,
    });

    this.setState({
      sampleData: updatedSampleData,
    });
  }

  handleUnitChange(id, value) {
    const { sampleData } = this.state;
    const updatedSampleData = this.updateSampleData(sampleData, id, {
      unit: value,
    });

    this.setState({
      sampleData: updatedSampleData,
    });
  }

  handleIdentifierChange(id, value) {
    const { sampleData } = this.state;
    const updatedSampleData = this.updateSampleData(sampleData, id, {
      identifier: value,
    });

    this.setState({
      sampleData: updatedSampleData,
    });
  }

  updateSampleData(sampleData, id, updatedFields) {
    return sampleData.map((sample) => {
      if (sample.id === id) {
        return {
          ...sample,
          ...updatedFields,
        };
      } else if (sample.children && sample.children.length > 0) {
        return {
          ...sample,
          children: this.updateSampleData(sample.children, id, updatedFields),
        };
      }
      return sample;
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const { sampleData } = this.state;

    const originalArray = [
      /* Twój pierwotny obiekt JSON */
    ];
    const flattenedArray = flattenArray(sampleData);
    console.log(flattenedArray);
    this.props.onAuth(flattenedArray);

    // Sprawdź, czy pola są puste
    const errors = {};
    sampleData.forEach((sample) => {
      if (!sample.amount) {
        errors[sample.id] = "Amount field is required";
      }
      if (!sample.unit) {
        errors[sample.id] = "Unit field is required";
      }
      if (!sample.identifier) {
        errors[sample.id] = "Identifier field is required";
      }
    });

    // Jeśli występują błędy, zaktualizuj stan i zakończ funkcję
    if (Object.keys(errors).length > 0) {
      this.setState({
        errors,
      });
      return;
    }

    // Wykonaj akcję z przekazanymi ostatecznymi danymi próbek
    console.log(sampleData);
  };

  renderSample(sample) {
    const { expandedSamples, errors } = this.state;
    const isExpanded = expandedSamples.includes(sample.id);

    return (
      <>
        <Grid item md={12} xs={12} align="center">
          <Typography variant="h6">
            Próbka:{" "}
            <b>
              <span style={{ color: "#fa5252" }}>
                {sample.identifier.substring(0, 7)}
              </span>
              <span style={{ color: "#82368C" }}>
                {sample.identifier.substring(7, 11)}
              </span>
              <span style={{ color: "#363A89" }}>
                {sample.identifier.substring(11)}
              </span>
            </b>
          </Typography>
        </Grid>
        <Input
          md={6}
          xs={12}
          inputLabel={"Ilość"}
          inputType="number"
          value={sample.amount}
          onChange={(e) => this.handleAmountChange(sample.id, e.target.value)}
        />

        <Input
          md={6}
          xs={12}
          inputLabel={"Jednostka"}
          type="number"
          value={sample.unit}
          onChange={(e) => this.handleUnitChange(sample.id, e.target.value)}
        />

        {!sample.is_root && (
          <>
            <Input
              md={6}
              xs={12}
              inputLabel={"Rzeczywista ilość"}
              inputType="number"
              value={sample.real_amount}
              onChange={(e) =>
                this.handleRealAmountChange(sample.id, e.target.value)
              }
            />
            <Input
              md={6}
              xs={12}
              inputLabel={"Rzeczywista Jednostka"}
              type="number"
              value={sample.real_unit}
              onChange={(e) =>
                this.handleRealUnitChange(sample.id, e.target.value)
              }
            />
          </>
        )}

        {sample.children && sample.children.length > 0 && (
          <>
            <Grid item md={12} xs={12} align="center">
              <Divider />
            </Grid>
          </>
        )}

        {sample.children && sample.children.length > 0 && (
          <>{sample.children.map((child) => this.renderSample(child))}</>
        )}
      </>
    );
  }

  render() {
    const { sampleData, errors } = this.state;

    // Sprawdź, czy występują błędy
    const hasErrors = Object.keys(errors).length > 0;

    return (
      <>
        {this.props.loading ? (
          <LoadingSpinner />
        ) : (
          <Card>
            <Typography
              variant="h6"
              sx={{ position: "relative", padding: "16px 0 20px 16px" }}
            >
              <span
                style={{
                  content: "",
                  backgroundColor: "#111e6c",
                  position: "absolute",
                  left: "0",
                  top: "16px",
                  width: "4px",
                  height: "25px",
                }}
              ></span>
              Formularz edycji ilości grupy próbek
            </Typography>

            <Divider />
            <form onSubmit={this.handleSubmit}>
              <CardContent>
                <Grid container spacing={3}>
                  {sampleData.map((sample) => this.renderSample(sample))}
                  {/* <button onClick={this.handleSubmit} disabled={hasErrors}>
                Submit
              </button> */}
                </Grid>
              </CardContent>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  p: 2,
                }}
              >
                <Button type="submit" variant="contained" endIcon={<AddIcon />}>
                  Zapisz
                </Button>
              </Box>
            </form>
          </Card>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error,
    adding: state.adding,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (formData) =>
      dispatch(actions.requestApi(formData, api().FAMILY_SAMPLE_EDIT, "put")),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SampleForm);
