import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../services/actions/auth";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import FormHelperText from "@mui/material/FormHelperText";
import {
  Box,
  Container,
  Button,
  Card,
  Divider,
  Typography,
  TextField,
  Alert,
} from "@mui/material";
import api from "../../../config/api/apiRoutes";

const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach(
    // if we have an error string set valid to false
    (val) => val.length > 0 && (valid = false)
  );
  return valid;
};

class PasswordMailForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      disabled: false, // nowy stan
      errors: {
        email: "",
      },
    };
  }
  onChange = (e) => {
    console.log(e);
    this.setState({ [e.target.name]: e.target.value });
    const { name, value } = e.target;
    let errors = this.state.errors;
    switch (name) {
      case "email":
        errors.email = validEmailRegex.test(value)
          ? ""
          : "Niepoprawny adres email";
        break;

      default:
        break;
    }

    this.setState({ errors, [name]: value });
  };

  // update function to call the action
  onSignupClick = (e) => {
    e.preventDefault();

    const userData = {
      email: this.state.email,
    };

    const validate = () => {
      let validationError = this.state.errors;
      if (!this.state.email) {
        validationError.email = "To pole jest wymagane";
      }

      this.setState({ validationError, validationError });
      console.log("in validate", validationError);
      return validationError;
    };
    const validationError = validate();
    console.log(this.state.errors);
    if (validateForm(validationError)) {
      console.info("Valid Form");
      this.props.onAuth(userData);
    } else {
      console.error("Invalid Form");
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.adding !== this.props.adding) {
      console.log("dziala");
      this.setState({ disabled: true });
    }
  }

  render() {
    const { errors } = this.state;

    if (this.props.adding == true) {
      //this.props.props.history.push("/logowanie");
    }

    console.log(this.state);
    return (
      <>
        {this.props.loading ? (
          <LoadingSpinner />
        ) : (
          <Box
            component="main"
            sx={{
              alignItems: "center",
              display: "flex",
              flexGrow: 1,
              minHeight: "100%",
            }}
          >
            <Container maxWidth="sm">
              <Card sx={{ p: 5 }}>
                <form onSubmit={this.onSignupClick}>
                  <Box sx={{ my: 3 }}>
                    <Typography color="textPrimary" variant="h4">
                      Zresetuj hasło
                    </Typography>
                    <Typography
                      color="textSecondary"
                      gutterBottom
                      variant="body2"
                    >
                      Wprowadź adres e-mail, aby zresetować hasło.
                    </Typography>
                  </Box>

                  {this.state.disabled && (
                    <Alert severity="success">Link został wysłany</Alert>
                  )}

                  <TextField
                    type="email"
                    name="email"
                    fullWidth
                    margin="normal"
                    placeholder="Wprowadź adres email"
                    value={this.state.email}
                    onChange={this.onChange}
                    label="Adres email"
                    variant="outlined"
                    disabled={this.state.disabled}
                    helperText={errors.email.length > 0 ? errors.email : ""}
                    error={errors.email.length ? true : false}
                  />

                  <Box sx={{ py: 2 }}>
                    <Button
                      color="primary"
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      disabled={this.state.disabled}
                    >
                      Zresetuj hasło
                    </Button>
                  </Box>
                </form>
              </Card>
            </Container>
          </Box>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error,
    adding: state.adding,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (formData) =>
      dispatch(actions.resetPassword(formData, api().RESET_MAIL_POST, "post")),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordMailForm);
