import { Link } from "react-router-dom";
import { Button } from "@mui/material";

const renderCustomTreeColumn = (row) => {
  //console.log(level);
  return (
    <b>
      <span style={{ color: "#fa5252" }}>{row.substring(0, 7)}</span>
      <span style={{ color: "#04a9f5" }}>{row.substring(7, 10)}</span>
      <span style={{ color: "#111e6c" }}>{row.substring(10, 13)}</span>
      <span style={{ color: "gray" }}>{row.substring(13)}</span>
    </b>
  );
};

const intialColumnsProcedureSamples = [
  {
    accessorKey: "identifier",
    header: "Identyfikator",
    accessorFn: (originalRow) => originalRow.identifier,
    Cell: ({ cell }) => renderCustomTreeColumn(cell.getValue()),
  },
  {
    accessorFn: (row) => (
      <Link to={`/sample/${row.sample}`}>
        <Button variant="contained" size="small">
          Zobacz
        </Button>
      </Link>
    ),
    header: "Szczegóły",
    enableColumnFilter: false,
  },
];

export default intialColumnsProcedureSamples;
