import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../services/actions/auth";
import { Redirect } from "react-router-dom";
import axios from "axios";
import api from "../../config/api/apiRoutes";
class DeleteEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      event_id: "",
      User: "",
      errors: {},
    };

    const queryParams = new URLSearchParams(this.props.location.search);
    this.state.event_id = queryParams.get("id");
  }

  componentDidMount() {

    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    axios
      .delete(api(this.state.event_id).CALENDAR_DELETE_EVENT)
      .then((response) => {
        if (response.status === 204) {
            this.props.onDeleteSuccess();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    return <Redirect to={`/calendar`} />;
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error,
    adding: state.adding,
  };
};

const mapDispatchToProps = (dispatch) => {
    return {
      onDeleteSuccess: () => dispatch(actions.deleteProcedureReagentSuccess()),
    };
  };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteEvent);
