const intialColumnsLocalizationDetails = [
  // {
  //   accessorKey: "id",
  //   header: "ID",
  //   size: 40,
  // },
  {
    accessorKey: "name",
    header: "nazwa",
    size: 120,
  },
  {
    accessorKey: "comment",
    header: "komentarz",
    size: 120,
  },
];

export default intialColumnsLocalizationDetails;
