const api = (id, id2) => {
  const env = process.env.NODE_ENV;
  const url = {
    local: "http://127.0.0.1:8000",
    prod: "",
    dev: "https://biobank-greycolab.pl:10443",
    test: "",
  };
  let apiURL = url.dev;

  const routes = {
    ACCOUNT_EDIT_PUT: `${apiURL}/users/update/`,
    CHECK_TOKEN: `${apiURL}/users/check/token/`,
    LICENSING_LIMITATIONS: `${apiURL}/licensing/transaction/limitations/`,
    PRODUCT_LIST: `${apiURL}/licensing/products/list/`,
    PRODUCT_DETAILS: `${apiURL}/licensing/products/detail/${id}/`,
    TRANSACTION_CREATE: `${apiURL}/licensing/transaction/create/`,
    COMPANY_REGISTER: `${apiURL}/licensing/company/register/`,
    REGISTER_USER: `${apiURL}/users/register/user/`,
    CONTACT_FORM_ADD: `${apiURL}/licensing/contact-form/add/`,
    BUTTONS_LIST: `${apiURL}/licensing/support-center/list/`,
    NEWS_LIST: `${apiURL}/licensing/news/list/`,
    LOGIN: `${apiURL}/users/login/`,
    LOGOUT: `${apiURL}/users/logout/`,
    LABORATORY_GET: `${apiURL}/users/register/laboratory/`,
    LABORATORY_POST: `${apiURL}/users/register/laboratory/`,
    DELIVERY_GET: `${apiURL}/procedures/reagent/delivery/list/`,
    DELIVERY_DELETE: `${apiURL}/procedures/reagent/delivery/delete/${id}/`,
    REAGENT_GET: `${apiURL}/procedures/reagent/list/`,
    EQUIPMENT_GET: `${apiURL}/procedures/equipment/list/`,
    EQUIPMENT_AVAILABLE_GET: `${apiURL}/procedures/equipment/procedure/${id}/list/available/`,
    EQUIPMENT_ADD_POST: `${apiURL}/procedures/equipment/new/`,
    EQUIPMENT_EDIT_PUT: `${apiURL}/procedures/equipment/detail/${id}/`,
    PATIENT_DETAIL_GET: `${apiURL}/analysis/patient/detail/${id}/`,
    PATIENT_SAMPLE_GET: `${apiURL}/analysis/patient/detail/${id}/samples/list/`,
    PATIENT_ADD_POST: `${apiURL}/analysis/patient/new/`,
    PATIENT_EDIT_PUT: `${apiURL}/analysis/patient/detail/${id}/`,
    PATIENT_DELETE: `${apiURL}/analysis/patient/detail/${id}/delete/`,
    PROCESS_GET: `${apiURL}/procedures/process/list/`,
    PROCESS_ADD_POST: `${apiURL}/procedures/process/new/`,
    PROCESS_DETAILS_GET: `${apiURL}/procedures/process/detail/${id}/`,
    PROCESS_EDIT_PUT: `${apiURL}/procedures/process/${id}/update/`,
    PROCESS_AVAILABLE_GET: `${apiURL}/procedures/process/procedure/${id}/list/available/`,
    SAMPLE_CHILDREN_GET: `${apiURL}/analysis/sample/${id}/children/`,
    SAMPLE_STATUS_GET: `${apiURL}/analysis/sample/${id}/status/`,
    SAMPLE_PROCEDURES_GET: `${apiURL}/analysis/sample/${id}/procedures/`,
    SAMPLE_PROCEDURES_REAGENTS_GET: `${apiURL}/analysis/sample/${id}/procedures/reagents/`,
    SAMPLE_PROCEDURES_REAGENTS_PUT: `${apiURL}/analysis/sample/new/reagents/`,
    SAMPLE_REAGENT_GET: `${apiURL}/analysis/sample/${id}/reagents/`,
    SAMPLE_STATUS_GET: `${apiURL}/analysis/sample/${id}/status/`,
    RESULTS_OPTIONS_GET: `${apiURL}/analysis/sample/result/options/`,
    SAMPLE_RESULTS_GET: `${apiURL}/analysis/sample/${id}/result/list/`,
    SAMPLE_RESULTS_ADD_POST: `${apiURL}/analysis/sample/${id}/result/new/`,
    SAMPLE_AMOUNT_GET: `${apiURL}/analysis/sample/${id}/amount/`,
    SAMPLE_GET: `${apiURL}/analysis/sample/${id}/`,
    SAMPLE_EDIT_PIT: `${apiURL}/analysis/sample/${id}/`,
    SAMPLE_PROCEDURES_PUT: `${apiURL}/analysis/sample/${id}/procedures/update/`,
    SAMPLE_LIST_GET: `${apiURL}/analysis/samples/list/`,
    SAMPLE_LIST_ARCHIVES_GET: `${apiURL}/analysis/samples/archives/list/`,
    SAMPLE_LIST_ALL_GET: `${apiURL}/analysis/samples/list/all/`,
    SAMPLE_LOCALIZATION_TREE_GET: `${apiURL}/analysis/sample/${id}/localization/tree/`,
    SAMPLE_LOCALIZATION_DETAIL_GET: `${apiURL}/analysis/sample/${id}/localization/detail/`,
    SAMPLE_ADD_POST: `${apiURL}/analysis/samples/new/`,
    SAMPLE_DELETE: `${apiURL}/analysis/sample/${id}/delete/`,
    LOCALIZATION_GET: `${apiURL}/localization/list/`,
    LOCALIZATION_DETAILS_GET: `${apiURL}/localization/detail/${id}/`,
    LOCALIZATION_DETAILS_GET: `${apiURL}/localization/detail/${id}/`,
    LOCALIZATION_PARENT_GET: `${apiURL}/localization/detail/${id}/parent/`,
    LOCALIZATION_EDIT_PUT: `${apiURL}/localization/detail/${id}/update/`,
    LOCALIZATION_CHILDREN_GET: `${apiURL}/localization/detail/${id}/children/`,
    LOCALIZATION_SAMPLES_GET: `${apiURL}/localization/detail/${id}/samples/`,
    LOCALIZATION_LIST_ID_GET: `${apiURL}/localization/new/options/`,
    LOCALIZATION_DETAILS_GET_2: `${apiURL}/localization/detail/${id}/`,
    LOCALIZATION_LIST_GET: `${apiURL}/localization/list/all/`,
    LOCALIZATION_ADD_POST: `${apiURL}/localization/new/`,
    PATIENT_LIST_GET: `${apiURL}/analysis/patient/list/`,
    REAGENT_DELIVERY_GET: `${apiURL}/procedures/reagent/detail/${id}/delivery/`,
    REAGENT_PROCEDURE_GET: `${apiURL}/procedures/reagent/detail/${id}/procedures/`,
    REAGENT_DELIVERY_SUM_GET: `${apiURL}/procedures/reagent/detail/${id}/delivery/sum/`,
    REAGENT_DELIVERY_ADD_POST: `${apiURL}/procedures/reagent/delivery/new/`,
    REAGENT_DELIVERY_EDIT_PUT: `${apiURL}/procedures/reagent/delivery/update/${id}/`,
    REAGENT_ADD_POST: `${apiURL}/procedures/reagent/new/`,
    REAGENT_EDIT_PUT: `${apiURL}/procedures/reagent/${id}/update/`,
    REAGENT_PROCEDURE_ADD: `${apiURL}/procedures/reagent/procedure/add/`,
    REAGENT_DETAIL_GET: `${apiURL}/procedures/reagent/detail/${id}/`,
    REAGENT_AVAILABLE_GET: `${apiURL}/procedures/reagent/procedure/${id}/list/available/`,
    REAGENT_DELIVERY_SAMPLES_GET: `${apiURL}/procedures/reagent/detail/${id}/samples/`,
    REAGENT_DELETE: `${apiURL}/procedures/procedure/${id}/reagent/${id2}/delete/`,
    REAGENT_DELETE_2: `${apiURL}/procedures/reagent/${id}/delete/`,
    EQUIPMENT_DETAILS_GET: `${apiURL}/procedures/equipment/detail/${id}/`,
    EQUIPMENT_DELETE: `${apiURL}/procedures/equipment/detail/${id}/delete/`,
    SAMPLE_LOCALIZATION_POST: `${apiURL}/analysis/sample/${id}/localization/`,
    SAMPLE_LOCALIZATION_PUT: `${apiURL}/analysis/sample/${id}/localization/update/`,
    SAMPLE_DATE_GET: `${apiURL}/events/sample/${id}/dates/`,
    SAMPLE_DATE_ADD_POST: `${apiURL}/events/samples/`,
    SAMPLE_HIERARCHY_LIST_GET: `${apiURL}/analysis/samples/list/hierarchy/`,
    SAMPLE_IDENTIFIER_GET: `${apiURL}/analysis/sample/${id}/identifier/`,
    DELIVERY_DETAIL_GET: `${apiURL}/procedures/reagent/delivery/details/${id}/`,
    DISEASE_ADD_POST: `${apiURL}/analysis/disease/new/`,
    DISEASE_GET: `${apiURL}/analysis/disease/detail/${id}/`,
    DISEASE_LIST_GET: `${apiURL}/analysis/disease/list/`,
    DISEASE_UPDATE: `${apiURL}/analysis/disease/detail/${id}/update/`,
    DASHBOARD_GET: `${apiURL}/users/dashboard/`,
    DASHBOARD_USER_GET: `${apiURL}/users/user/dashboard/`,
    DASHBOARD_FILTER_PUT: `${apiURL}/users/update/dashboard/`,
    VALUES_OPTIONS_POST: `${apiURL}/analysis/options/values/`,
    ATTRIBUTES_ADD_POST: `${apiURL}/analysis/options/attributes/new/`,
    ATTRIBUTES_LIST_GET: `${apiURL}/analysis/options/attributes/list/`,
    ATTRIBUTE_DELETE: `${apiURL}/analysis/options/attributes/detail/${id}/delete/`,
    ATTRIBUTE_DETAILS: `${apiURL}/analysis/options/attributes/detail/${id}/`,
    ATTRIBUTE_EDIT: `${apiURL}/analysis/options/attributes/detail/${id}/update/`,
    PARAMETER_ADD_POST: `${apiURL}/analysis/options/attributes/new/sample/`,
    PROCEDURE_DETAILS_GET: `${apiURL}/procedures/procedure/detail/${id}/`,
    PROCEDURE_SAMPLE_GET: `${apiURL}/procedures/procedure/detail/${id}/samples`,
    PROCEDURE_CHANGE_PUT: `${apiURL}/procedures/procedure/detail/${id}/update/`,
    PROCEDURE_LIST_GET: `${apiURL}/procedures/procedure/list/`,
    PROCEDURE_AVAILABLE_GET: `${apiURL}/procedures/procedure/reagent/${id}/list/available/`,
    PROCEDURE_ADD_POST: `${apiURL}/procedures/procedure/new/`,
    PROCEDURE_REAGENT_PUT: `${apiURL}/procedures/procedure-reagent/update/`,
    PROCEDURE_EQUIPMENT_ADD: `${apiURL}/procedures/procedure/equipment/add/`,
    PROCEDURE_EQUIPMENT_DELETE: `${apiURL}/procedures/procedure/${id}/equipment/${id2}/delete/`,
    PROCEDURE_REAGENT_ADD: `${apiURL}/procedures/procedure/reagent/add/`,
    PROCEDURE_PROCESS_ADD: `${apiURL}/procedures/procedure/process/add/`,
    PROCEDURE_DELETE: `${apiURL}/procedures/procedure/detail/${id}/delete/`,
    PROCESS_EQUIPMENT_DELETE: `${apiURL}/procedures/procedure/${id}/process/${id2}/delete/`,
    PROCESS_DELETE: `${apiURL}/procedures/process/${id}/delete/`,
    RESET_MAIL_POST: `${apiURL}/users/password-mail/`,
    RESET_PASSWORD_POST: `${apiURL}/users/password-reset/${id}/${id2}/`,
    BARCODE_ADD_POST: `${apiURL}/analysis/sample/${id}/barcode/new/`,
    BARCODE_SAMPLE_DETAIL_GET: `${apiURL}/analysis/sample/${id}/sample/detail/barcode/`,
    BARCODE_PATIENT_DETAIL_GET: `${apiURL}/analysis/sample/${id}/sample/detail/barcode/`,
    ANALYSIS_ATTRIBUTES: `${apiURL}/analysis/attributes/`,
    ANALYSIS_VALUES_POST: `${apiURL}/analysis/values/`,
    SWAGGER_VERSION_GET: `${apiURL}/api/?format=openapi`,
    CALENDAR_LIST_GET: `${apiURL}/events/list/`,
    CALENDAR_ADD_EVENT_POST: `${apiURL}/events/new/`,
    CALENDAR_DELETE_EVENT: `${apiURL}/events/detail/${id}/delete/`,
    USERS_LIST_GET: `${apiURL}/events/list/users/`,
    USERS_NOTIFICATION_GET: `${apiURL}/users/email2see/`,
    USERS_NOTIFICATION_UPDATE_PUT: `${apiURL}/users/update/email2see/`,
    USERS_EVENTS_GET: `${apiURL}/timeline/signals/user/${id}/`,
    CHANGE_PASSWORD: `${apiURL}/users/reset_password/`,
    TIMELINE_GET: `${apiURL}/timeline/signal/${id}/${id2}/`,
    TIMELINE_DETAILS_GET: `${apiURL}/timeline/changes/${id}/`,
    USER_IMAGE_GET: `${apiURL}/users/image/`,
    USER_IMAGE_EDIT_POST: `${apiURL}/users/update/image/`,
    PROCEDURE_REAGENT_DELETE: `${apiURL}/procedures/procedure/${id}/reagent/${id2}/different/delete/`,
    CALENDAR_EVENT_EDIT_PUT: `${apiURL}/events/detail/${id}/update/`,
    REPORT_SAMPLE_GET: `${apiURL}/report/samples/`,
    REPORT_EVENTS_GET: `${apiURL}/report/events/`,
    REPORT_PATIENT_GET: `${apiURL}/report/patient/`,
    REPORT_PROCEDURE_GET: `${apiURL}/report/procedures/`,
    REPORT_REAGENT_DELIVERY_GET: `${apiURL}/report/reagents/delivery/`,
    FLOW_EDGE_CREATE_POST: `${apiURL}/diagram/edges/create/`,
    FLOW_NODE_CREATE_POST: `${apiURL}/diagram/nodes/create/`,
    FLOW_NODE_LIST: `${apiURL}/diagram/nodes/list/${id}/`,
    FLOW_EDGE_LIST: `${apiURL}/diagram/edges/list/${id}/`,
    NEW_PATIENT_IDENTIFIER: `${apiURL}/analysis/patient/new/identifier/`,
    NEW_SAMPLE_PARENT_IDENTIFIER: `${apiURL}/analysis/patient/new/identifier/child/${id}/${id2}/`,
    NEW_SAMPLE_PATIENT_IDENTIFIER: `${apiURL}/analysis/patient/new/identifier/root/${id}/`,
    GET_PATIENT_SAMPLE: `${apiURL}/analysis/patient/detail/${id}/samples/list/`,
    VERIFY_OTP: `${apiURL}/users/otp/verify/`,
    VALIDATE_OTP: `${apiURL}/users/otp/validate/`,
    COMPANY_VAT: `${apiURL}/licensing/company/check/${id}/`,
    FAMILY_SAMPLE: `${apiURL}/analysis/sample/${id}/family/`,
    FAMILY_SAMPLE_EDIT: `${apiURL}/analysis/sample/family/update/`,
    PATIENT_FILE_LIST: `${apiURL}/files/list/patient/${id}/`,
    PATIENT_FILE_UPLOAD: `${apiURL}/files/add/patient/${id}/`,
    PATIENT_FILE_DETAILS: `${apiURL}/files/details/${id}/`,
    PATIENT_FILE_DELETE: `${apiURL}/files/delete/patient/${id}/`,
  };

  return routes;
};

export default api;
