import React from "react";
import * as XLSX from "xlsx";

export const ExportToXlsx = (selectedRow, visibleLeafColumns) => {
  console.log(selectedRow)
  console.log(visibleLeafColumns)

  let getSelectedRows = selectedRow;
  let visibleColumns = visibleLeafColumns;
  let getAllSelectedRows = getSelectedRows.rows;

  const newVisibleColumns = visibleColumns.filter(
    (item, index) => index !== 0 && item.id !== "Szczegóły"
  );

  const getidVisibleColumns = newVisibleColumns.map((item) => item.id);

  console.log(getidVisibleColumns);

  const getAllSelectedRowsData = getAllSelectedRows.map(
    (item) => item.original
  );

  const transformedVisibleColumns = getidVisibleColumns.map((attribute) => {
    if (attribute === "attribute_attribute.attribute") {
      return "attribute_attribute";
    }
    return attribute;
  });

  console.log(transformedVisibleColumns)

  console.log(getAllSelectedRowsData);

  const transformedData = getAllSelectedRowsData.map((row) => {
    let newRow = { ...row };
    const extraValues = newRow.extra_values;
    const result = newRow.result;

    if (extraValues) {
      extraValues.forEach((extraValue) => {
        const attribute =
          extraValue.attribute_attribute.attribute.toLowerCase();
        const value = extraValue.short_text;
        newRow[attribute] = value;
      });

      delete newRow.extra_values;
    }

    if (newRow.attribute_attribute) {
      newRow.attribute_attribute = newRow.attribute_attribute.attribute;
    }

    if (result) {
      const resultMap = {};
      result.forEach((resultValue) => {
        const attribute = resultValue.attribute_name;
        const value = resultValue.short_text;
        if (resultMap[attribute] === undefined) {
          resultMap[attribute] = [];
        }
        if (value !== null) {
          resultMap[attribute].push(value);
        }
      });

      Object.entries(resultMap).forEach(([attribute, values]) => {
        newRow[attribute.toLowerCase()] = values.reduce((acc, val, index) => {
          if (index === 0) {
            return val;
          }
          return `${acc}, ${val}`;
        }, "");
      });

      delete newRow.result;
    }

    return newRow;
  });

  console.log(transformedData);

  const filteredItems = transformedData.map((item) => {
    let filteredItem = {};
    transformedVisibleColumns.forEach((property) => {
      if (item.hasOwnProperty(property.toLowerCase())) {
        filteredItem[property] = item[property];
      }
    });
    return filteredItem;
  });

  console.log(filteredItems);

  const ws = XLSX.utils.json_to_sheet(filteredItems);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
  XLSX.writeFile(wb, "sheetjs.xlsx");
};
