import { Alert, Box, Button, Divider, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import ReactCodeInput from "react-code-input";
import DeleteIcon from "@mui/icons-material/Delete";

const CheckPin = ({ request, refreshData }) => {
  const [isPinCodeValid, setIsPinCodeValid] = useState(true);
  const [pinCode, setPinCode] = useState("");
  const [btnIsPressed, setBtnIsPressed] = useState(false);
  const [isPin, setIsPin] = useState(false);
  const handlePinChange = (pinCode) => {
    setPinCode(pinCode);
    setBtnIsPressed(false);
  };

  const CORRECT_PIN_CODE = localStorage.getItem("user_pin");

  const checkPinCode = (e) => {
    e.preventDefault();

    const isPinCodeValid = pinCode === CORRECT_PIN_CODE;

    setBtnIsPressed(true);
    setIsPinCodeValid(isPinCodeValid);
    if (isPinCodeValid) {
      setIsPin(false);
      request();
      localStorage.setItem("is_pin", false);
    }
    if (!isPinCodeValid) setPinCode("");
  };
  return (
    <>
      <Grid
        container
        spacing={2}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item lg={12} md={12} xs={12}>
          <Typography variant="h6" gutterBottom align="center">
            Akcja wymaga autoryzacji
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} xs={12}>
          <Typography variant="subtitle1" gutterBottom align="center">
            Wprowadź PIN do poniższego formularza
          </Typography>
        </Grid>
        <Grid item md={12} xs={12}>
          <ReactCodeInput
            id="pinCode"
            type="password"
            isValid={isPinCodeValid}
            fields={4}
            onChange={handlePinChange}
            value={pinCode}
          />
        </Grid>
        <Grid item md={6} xs={6}>
          {!isPinCodeValid ? (
            <Alert severity="error">Niepoprawny kod PIN!</Alert>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "end", mt: 2 }}>
        <Button
          type="submit"
          variant="contained"
          onClick={checkPinCode}
          endIcon={<DeleteIcon />}
        >
          Usuń
        </Button>
      </Box>
    </>
  );
};

export default CheckPin;
