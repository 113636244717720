const intialColumnsPatientAttribute = [
  {
    accessorKey: "attribute_attribute.attribute",
    header: "Atrybut",
    size: 120,
  },
  {
    accessorKey: "short_text",
    header: "Wartość",
    size: 120,
  },
];

export default intialColumnsPatientAttribute;
