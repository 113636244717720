const customRenderColumn = (row) => {
  return (
    <div>
      <b>
        <span style={{ color: "#fa5252" }}>{row.substring(0, 7)}</span>
        <span style={{ color: "gray" }}>{row.substring(7)}</span>
      </b>
    </div>
  );
};

const intialColumnsPatientDetails = [
  // {
  //   accessorKey: "id",
  //   header: "ID",
  // },
  {
    accessorKey: "identifier",
    accessorFn: (originalRow) => originalRow.identifier,
    Cell: ({ cell }) => customRenderColumn(cell.getValue()),
    header: "Identyfikator",
    size: 120,
  },
  {
    accessorKey: "pesel",
    header: "PESEL",
  },
  {
    accessorKey: "main_disease_name",
    header: "Głowna choroba",
  },
  {
    accessorKey: "comment",
    header: "Komentarz",
  },
];

export default intialColumnsPatientDetails;
