import React, { Fragment } from "react";
import Navbar from "./Navbar/Navbar";
import { DashboardLayout } from "../layout/Dashboard/Dashboard";
import * as actions from "../services/actions/auth";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

class MainLayout extends React.Component {
  render() {
    return (
      <>
        {this.props.isAuthenticated ? (
          <DashboardLayout {...this.props}>
            {this.props.children}
          </DashboardLayout>
        ) : (
          <>{this.props.children}</>
        )}
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    logoutApi: () => dispatch(actions.logoutApi()),
  };
};

export default withRouter(connect(null, mapDispatchToProps)(MainLayout));
