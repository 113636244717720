import React, { Component } from "react";
import BaseRouter from "./Routes";
import * as actions from "./services/actions/auth";
import { connect } from "react-redux";
import "rsuite/dist/rsuite.min.css";
import { theme } from "./assets/theme/theme";
import MainLayout from "./layout/MainLayout";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import api from "./config/api/apiRoutes";
import axios from "axios";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      testGet: "KO",
      testPost: "KO",
    };
  }
  componentDidMount() {
    this.props.autoCheckToken();
    this.props.onTryAutoSignup();

    console.log(this.props);
  }

  render() {
    console.log(this.props);

    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <MainLayout {...this.props}>
          <BaseRouter {...this.props} />
        </MainLayout>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state) => {
  console.log(state);
  return {
    isAuthenticated: state.token !== null,
    loading: state.loading_page,
    token_auth: state.token_auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
    autoCheckToken: () => dispatch(actions.checkToken()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
