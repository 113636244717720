import React, { useState, useEffect } from "react";
import { Box, Container, Typography } from "@mui/material";
import axios from "axios";
import api from "../../../config/api/apiRoutes";
import BasicTable from "../../../components/Table/BasicTable";
import { Link } from "react-router-dom";
import { Divider } from "rsuite";
import HomeIcon from "@mui/icons-material/Home";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import attributeColumns from "./config/columns/attributeColumns";

function AttributeList(props) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    const getData = async () => {
      try {
        const getList = await axios.get(api().ATTRIBUTES_LIST_GET);
        const getDataList = getList.data;

        setData(getDataList);
        setIsLoading(false);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      }
    };

    getData();
  }, []);

  return (
    <Container maxWidth={false}>
      <Typography variant="h5" style={{ fontWeight: "400" }}>
        Lista atrybutow
      </Typography>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" to="/" style={{ color: "#888" }}>
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Dashboard
        </Link>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="#111"
          style={{ fontWeight: "600" }}
        >
          Lista atrybutów
        </Typography>
      </Breadcrumbs>
      <Divider />
      <Box sx={{ mt: 3, mb: 5 }}>
        <BasicTable
          data={data}
          isLoading={isLoading}
          intialColumns={attributeColumns}
          headerTable={"Lista wszystkich atrybutów"}
          secondaryAction="/attributes-add"
          isSecondary={true}
        />
      </Box>
    </Container>
  );
}

export default AttributeList;
