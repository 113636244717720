/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import { Avatar, Box, Typography } from "@mui/material";

// Material Kit 2 React components

function DefaultReviewCard({ color, image, name, date, review, rating }) {
  const descriptionStyles = {
    fontSize: "1rem",
    fontWeight: "300",
    lineHeight: "1.6",
    display: "block",
    opacity: "1",
    textTransform: "none",
    verticalAlign: "unset",
    textDecoration: "none",
    color: "rgb(123, 128, 154)",
    letterSpacing: "-0.125px",
  };

  const titleStyles = {
    fontSize: "1rem",
    fontWeight: "700",
    lineHeight: "1.6",
    display: "block",
    opacity: "1",
    textTransform: "none",
    verticalAlign: "unset",
    textDecoration: "none",
    letterSpacing: "-0.125px",
    textAlign: "center",
  };

  return (
    <Box
      variant={color === "transparent" ? "contained" : "gradient"}
      bgColor={color}
      sx={{ background: "rgb(248, 249, 250)", borderRadius: "0.75rem" }}
      borderRadius="xl"
      shadow={color === "transparent" ? "none" : "md"}
      p={3}
    >
      <Box lineHeight={1}>
        <Avatar
          src={image}
          alt={name}
          variant="rounded"
          size="lg"
          shadow="md"
          sx={{
            mb: 1,
            width: "unset !important",
            height: "unset !important",
          }}
        />
        <Typography
          display="block"
          fontWeight="bold"
          color={"dark"}
          mb={0.5}
          sx={titleStyles}
        >
          {name}
        </Typography>
      </Box>
      <Typography variant="body2" my={4} sx={descriptionStyles}>
        &quot;{review}&quot;
      </Typography>
    </Box>
  );
}

export default DefaultReviewCard;
