import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../services/actions/auth";
import { Redirect } from "react-router-dom";
import axios from "axios";
import api from "../../config/api/apiRoutes";
class ReagentsProcedureFormDelete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      procedure_id: "",
      equipment_id: "",
      EquipmentCreator: "",
      errors: {},
    };

    const queryParams = new URLSearchParams(this.props.location.search);
    this.state.procedure_id = queryParams.get("procedure_id");
    this.state.equipment_id = queryParams.get("equipment_id");
  }

  componentDidMount() {

    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    axios
      .delete(api(this.state.procedure_id, this.state.equipment_id).PROCEDURE_EQUIPMENT_DELETE)
      .then((response) => {
        if (response.status === 204) {
            this.props.onDeleteSuccess();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  deleteReagentsProcedure = () => {
    const formData = {
      procedure: this.state.procedure_id,
      equipment: this.state.equipment_id,
      creator: this.state.EquipmentCreator,
    };

    this.props.onAuth(formData);
  };

  render() {
    return <Redirect to={`/procedure/${this.state.procedure_id}`} />;
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error,
    adding: state.adding,
  };
};

const mapDispatchToProps = (dispatch) => {
    return {
      onDeleteSuccess: () => dispatch(actions.deleteProcedureReagentSuccess()),
    };
  };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReagentsProcedureFormDelete);
