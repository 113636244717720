import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../services/actions/auth";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import FormHelperText from "@mui/material/FormHelperText";
import {
  Box,
  Container,
  Button,
  Card,
  Divider,
  Typography,
  TextField,
  Grid,
} from "@mui/material";
import { Link } from "react-router-dom";
import Input from "@mui/material/Input";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import { toast } from "react-toastify";
import Logo from "../../Logo";

const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach(
    // if we have an error string set valid to false
    (val) => val.length > 0 && (valid = false)
  );
  return valid;
};

class RegisterForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      password2: "",
      role: "",
      pin: "",
      vat: "",
      name: "",
      surname: "",
      contact: "",
      comment: "",
      file: "",
      license_key: "",
      showPin: false,
      showPassword: false,

      errors: {
        email: "",
        password: "",
        password2: "",
        role: "",
        pin: "",
        vat: "",
        name: "",
        surname: "",
        contact: "",
        comment: "",
        license_key: "",
      },
    };
  }
  onChange = (e) => {
    console.log(e);
    this.setState({ [e.target.name]: e.target.value });
    const { name, value } = e.target;
    let errors = this.state.errors;
    switch (name) {
      case "email":
        errors.email = validEmailRegex.test(value)
          ? ""
          : "Niepoprawny adres email";
        break;
      case "password":
        errors.password =
          value.length < 8
            ? "Password must be at least 8 characters long!"
            : "";
        break;
      case "password2":
        errors.password2 =
          value != this.state.password ? "Hasła się nie zgadzają" : "";
        break;
      case "vat":
        errors.vat = value.length < 1 ? "NIP nie może być mniejszy" : "";
        break;
      case "role":
        errors.role = value.length < 1 ? "Wybierz role" : "";
        break;
      case "pin":
        errors.pin = value.length < 4 ? "Pin nie może byc mniejszy" : "";
        break;
      default:
        break;
    }

    this.setState({ errors, [name]: value });
  };

  // update function to call the action
  onSignupClick = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("email", this.state.email);
    formData.append("password", this.state.password);
    formData.append("password2", this.state.password2);
    formData.append("role", this.state.role);
    formData.append("pin", this.state.pin);
    formData.append("laboratory_vat", this.state.vat);
    formData.append("additional_info.name", this.state.name);
    formData.append("additional_info.surname", this.state.surname);
    formData.append("additional_info.contact", this.state.contact);
    formData.append("additional_info.additional_info", this.state.comment);
    formData.append("image.image", this.state.file);
    formData.append("license_key", this.state.license_key)

    const validate = () => {
      let validationError = this.state.errors;
      if (!this.state.email) {
        validationError.email = "To pole jest wymagane";
      }
      if (!this.state.password) {
        validationError.password = "To pole jest wymagane";
      }
      if (!this.state.password2) {
        validationError.password2 = "To pole jest wymagane";
      }
      if (!this.state.role) {
        validationError.role = "To pole jest wymagane";
      }
      if (this.state.role == "Wybierz") {
        validationError.role = "To pole jest wymagane";
      }
      if (!this.state.pin) {
        validationError.pin = "To pole jest wymagane";
      }
      if (!this.state.vat) {
        validationError.vat = "To pole jest wymagane";
      }

      this.setState({ validationError, validationError });
      console.log("in validate", validationError);
      return validationError;
    };
    const validationError = validate();
    console.log(this.state.errors);
    if (validateForm(validationError)) {
      console.info("Valid Form");
      this.props.onAuth(formData);
    } else {
      console.error("Invalid Form");
    }
  };

  handleClickShowPassword = (e) => {
    console.log(e);
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  handleClickShowPin = (e) => {
    console.log(e);
    this.setState((prevState) => ({
      showPin: !prevState.showPin,
    }));
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    const allowedExtensions = /(\.jpg|\.png)$/i;
    if (!allowedExtensions.exec(selectedFile.name)) {
      toast.error(
        "Nieprawidłowy format pliku. Dozwolone rozszerzenia to: .jpg, .png."
      );
      event.target.value = null;
      return;
    }
    this.setState({ file: selectedFile });
  };

  render() {
    const { errors } = this.state;

    if (this.props.adding == true) {
      localStorage.setItem('email', this.state.email);

      this.props.props.history.push("/otp-verify");
    }

    console.log(this.state);
    return (
      <>
        {this.props.loading ? (
          <LoadingSpinner />
        ) : (
          <Box
            component="main"
            sx={{
              alignItems: "center",
              display: "flex",
              flexGrow: 1,
              minHeight: "100%",
            }}
          >
            <Container maxWidth="sm">
              <Card sx={{ p: 5 }}>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item sx={{ mb: 3 }}>
                    <Logo />
                  </Grid>
                </Grid>
                <form onSubmit={this.onSignupClick}>
                  <Box sx={{ my: 3 }}>
                    <Typography color="textPrimary" variant="h4">
                      Rejestracja
                    </Typography>
                    <Typography
                      color="textSecondary"
                      gutterBottom
                      variant="body2"
                    >
                      Zarejestruj się, aby uzyskać dostęp do platformy.
                    </Typography>
                  </Box>

                  <Grid container spacing={3}>
                    <Grid item md={12} xs={12}>
                        <TextField
                          type="text"
                          name="license_key"
                          fullWidth
                          placeholder="Wprowadź klucz licencyjny"
                          value={this.state.license_key}
                          onChange={this.onChange}
                          label="Nr licencji"
                          variant="outlined"
                          helperText={errors.license_key.length > 0 ? errors.license_key : ""}
                          error={errors.license_key.length ? true : false}
                        />
                      </Grid>
                    <Grid item md={12} xs={12}>
                      <TextField
                        type="number"
                        name="vat"
                        fullWidth
                        placeholder="Wprowadź NIP"
                        value={this.state.vat}
                        onChange={this.onChange}
                        label="NIP Laboratorium"
                        variant="outlined"
                        helperText={errors.vat.length > 0 ? errors.vat : ""}
                        error={errors.vat.length ? true : false}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        type="text"
                        name="name"
                        fullWidth
                        placeholder="Wprowadź imię"
                        value={this.state.name}
                        onChange={this.onChange}
                        label="Imię"
                        variant="outlined"
                        helperText={errors.name.length > 0 ? errors.name : ""}
                        error={errors.name.length ? true : false}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        type="text"
                        name="surname"
                        fullWidth
                        placeholder="Wprowadź nazwisko"
                        value={this.state.surname}
                        onChange={this.onChange}
                        label="Nazwisko"
                        variant="outlined"
                        helperText={
                          errors.surname.length > 0 ? errors.surname : ""
                        }
                        error={errors.surname.length ? true : false}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        type="number"
                        name="contact"
                        fullWidth
                        placeholder="Wprowadź nr. tel"
                        value={this.state.contact}
                        onChange={this.onChange}
                        label="Nr. tel"
                        variant="outlined"
                        helperText={
                          errors.contact.length > 0 ? errors.contact : ""
                        }
                        error={errors.contact.length ? true : false}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        type="text"
                        name="comment"
                        fullWidth
                        placeholder="Wprowadź dodatkowe informacje"
                        value={this.state.comment}
                        onChange={this.onChange}
                        label="Dodatkowe informacje"
                        variant="outlined"
                        helperText={
                          errors.comment.length > 0 ? errors.comment : ""
                        }
                        error={errors.comment.length ? true : false}
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <TextField
                        type="email"
                        name="email"
                        fullWidth
                        placeholder="Wprowadź adres email"
                        value={this.state.email}
                        onChange={this.onChange}
                        label="Adres email"
                        variant="outlined"
                        helperText={errors.email.length > 0 ? errors.email : ""}
                        error={errors.email.length ? true : false}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormControl sx={{ width: "100%" }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">
                          Hasło
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          type={this.state.showPassword ? "text" : "password"}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={this.handleClickShowPassword}
                                onMouseDown={this.handleMouseDownPassword}
                                edge="end"
                              >
                                {this.state.showPin ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Hasło"
                          name="password"
                          error={errors.password.length ? true : false}
                          value={this.state.password}
                          onChange={this.onChange}
                        />
                        {errors.password.length > 0 && (
                          <FormHelperText error={true}>
                            {errors.password}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        type="password"
                        name="password2"
                        fullWidth
                        placeholder="Powtórz Hasło"
                        value={this.state.password2}
                        onChange={this.onChange}
                        label="Powtórz Hasło"
                        variant="outlined"
                        helperText={
                          errors.password2.length > 0 ? errors.password2 : ""
                        }
                        error={errors.password2.length ? true : false}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Wybierz role
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={this.state.role}
                          label="Wybierz role"
                          name="role"
                          error={errors.role.length ? true : false}
                          onChange={this.onChange}
                        >
                          <MenuItem value="LABORANT">Laborant</MenuItem>
                          <MenuItem value="KIEROWNIK">Kierownik</MenuItem>
                        </Select>
                        {errors.role.length > 0 && (
                          <FormHelperText error={true}>
                            {errors.role}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormControl sx={{ width: "100%" }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">
                          Pin
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          type={this.state.showPin ? "text" : "password"}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={this.handleClickShowPin}
                                onMouseDown={this.handleMouseDownPassword}
                                name="showPin"
                                edge="end"
                              >
                                {this.state.showPin ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Pin"
                          name="pin"
                          error={errors.pin.length ? true : false}
                          value={this.state.pin}
                          onChange={this.onChange}
                        />
                        {errors.pin.length > 0 && (
                          <FormHelperText error={true}>
                            {errors.pin}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <InputLabel htmlFor="file-input">Wybierz zdjęcie profilowe</InputLabel>
                      <Input
                        id="file-input"
                        type="file"
                        onChange={this.handleFileChange}
                        style={{ display: "none" }}
                      />
                      {this.state.file && (
                        <p>Wybrany plik: {this.state.file.name}</p>
                      )}
                    </Grid>
                  </Grid>

                  <Divider margin="normal" />
                  <Box sx={{ py: 2 }}>
                    <Button
                      color="primary"
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Zarejestruj
                    </Button>
                  </Box>
                </form>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item xs={12} sx={{ mt: 4 }}>
                    <Divider />
                  </Grid>

                  <Grid item xs={12}>
                    <Grid
                      item
                      container
                      direction="column"
                      alignItems="center"
                      xs={12}
                    >
                      <Typography
                        component={Link}
                        to="/logowanie"
                        variant="subtitle1"
                        sx={{ textDecoration: "none" }}
                      >
                        Masz już konto?
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Container>
          </Box>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error,
    adding: state.adding,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (userData) => dispatch(actions.authSignup(userData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm);
