import React, { useState, useEffect } from "react";
import axios from "axios";
import api from "../../../config/api/apiRoutes";
import { Box, Container, Grid, Typography } from "@mui/material";
import SampleFormAdd from "../../../components/Sample/form/SampleFormAdd";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { Divider } from "rsuite";
import HomeIcon from "@mui/icons-material/Home";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";

function SampleAdd(props) {
  const [sampleData, setSampleData] = useState([]);
  const [patientData, setPatientData] = useState([]);
  const [procedureData, setProcedureData] = useState([]);
  const [attributesData, setAttributesData] = useState([]);
  const [localizationData, setLocalizationData] = useState([]);
  const [result, setResult] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    const getData = async () => {
      try {
        const sampleOptions = [];
        const patientOptions = [];
        const procedureOptions = [];
        const attributesOptions = [];
        const localizationOptions = [];
        const resultsOptions = [];

        const getSamples = await axios.get(api().SAMPLE_LIST_ALL_GET);
        const getDataSample = getSamples.data;
        const getPatient = await axios.get(api().PATIENT_LIST_GET);
        const getDataPatient = getPatient.data;
        const getProcedure = await axios.get(api().PROCEDURE_LIST_GET);
        const getDataProcedure = getProcedure.data;
        const getAttributes = await axios.get(api().ATTRIBUTES_LIST_GET);
        const getDataAttributes = getAttributes.data;
        const getLocalization = await axios.get(api().LOCALIZATION_LIST_ID_GET);
        const getLocalizationData = getLocalization.data;
        const getResults = await axios.get(api().RESULTS_OPTIONS_GET);
        const getDataResults = getResults.data;

        getDataSample.map((sample, index) => {
          return sampleOptions.push({
            value: sample.id,
            label: sample.identifier,
          });
        });

        getDataPatient.map((patient, index) => {
          patientOptions.push({
            value: patient.id,
            label: `PESEL: ${patient.pesel}, IDENTYFIKATOR: ${patient.identifier}`,
          });
          return null;
        });

        getDataProcedure.map((patients, index) => {
          return procedureOptions.push({
            value: patients.id,
            label: patients.name,
          });
        });

        getDataAttributes.map((laboratory, index) => {
          return attributesOptions.push({
            value: laboratory.id,
            label: laboratory.attribute,
            type: laboratory.value_type,
          });
        });

        console.log(getLocalizationData);

        getLocalizationData.map((item, index) => {
          return localizationOptions.push({
            value: item.id,
            label: item.name,
          });
        });

        getDataResults.map((item, index) => {
          return resultsOptions.push({
            value: item.id,
            label: item.attribute,
          });
        });

        console.log(sampleOptions);
        setSampleData(sampleOptions);
        setPatientData(patientOptions);
        setProcedureData(procedureOptions);
        setAttributesData(attributesOptions);
        setLocalizationData(localizationOptions);
        setResult(resultsOptions);

        setIsLoading(false);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      }
    };

    getData();
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }
  return (
    <Container maxWidth={false}>
      <Typography variant="h5" style={{ fontWeight: "400" }}>
        Dodaj próbkę
      </Typography>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" to="/" style={{ color: "#888" }}>
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Dashboard
        </Link>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="#111"
          style={{ fontWeight: "600" }}
        >
          Dodaj próbkę
        </Typography>
      </Breadcrumbs>
      <Divider />
      <Box sx={{ mt: 3, mb: 5 }}>
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} xs={12}>
            <SampleFormAdd
              props={props}
              sampleData={sampleData}
              patientData={patientData}
              procedureData={procedureData}
              attributesData={attributesData}
              localizationData={localizationData}
              resultData={result}
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default SampleAdd;
