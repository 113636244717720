import TableActionButton from "../../Button/TableActionButton";
import VisibilityIcon from "@mui/icons-material/Visibility";

const intialColumnsSampleProcedures = [
  // {
  //   accessorKey: "id",
  //   header: "ID procedury",
  //   size: 40,
  // },
  {
    accessorKey: "name",
    header: "Nazwa",
    size: 60,
  },

  {
    accessorFn: (row) => (
      <TableActionButton
        data={[
          {
            label: "Szczegóły",
            icon: <VisibilityIcon />,
            linkTo: `/procedure/${row.id}/`,
          },
        ]}
      />
    ),
    header: "Akcja",
    enableColumnFilter: false,
    enableSorting: false,
  },
];

export default intialColumnsSampleProcedures;
