import React from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import * as BiIcons from "react-icons/bi";
import LabelIcon from "@mui/icons-material/Label";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupIcon from "@mui/icons-material/Group";
import MedicationIcon from "@mui/icons-material/Medication";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import PlaceIcon from "@mui/icons-material/Place";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ScienceIcon from "@mui/icons-material/Science";
import DescriptionIcon from "@mui/icons-material/Description";
export const SidebarData = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: <DashboardIcon />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    title: "Choroba",
    icon: <MedicationIcon />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: "Dodaj chorobę",
        path: "/disease-add",
        icon: <IoIcons.IoIosAddCircle />,
      },
      {
        title: "Lista chorób",
        path: "/disease",
        icon: <IoIcons.IoIosPaper />,
      },
    ],
  },
  {
    title: "Pacjent",
    icon: <GroupIcon />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: "Lista pacjentów",
        path: "/patient",
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav",
      },
      {
        title: "Dodaj pacjenta",
        path: "/patient-add",
        icon: <IoIcons.IoIosAddCircle />,
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "Wyposażenie",
    icon: <FaIcons.FaCartPlus />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: "Lista wyposażenia",
        path: "/equipment",
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav",
      },
      {
        title: "Dodaj wyposażenie",
        path: "/new-equipment",
        icon: <IoIcons.IoIosAddCircle />,
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "Procesy",
    icon: <FaIcons.FaEnvelopeOpenText />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: "Lista procesów",
        path: "/process",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Dodaj proces",
        path: "/process-add",
        icon: <IoIcons.IoIosAddCircle />,
      },
    ],
  },
  {
    title: "Próbka",
    icon: <VaccinesIcon />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: "Lista próbek",
        path: "/sample",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Lista hierarchi próbek",
        path: "/sample-hierarchy",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Dodaj próbke",
        path: "/sample-add",
        icon: <IoIcons.IoIosAddCircle />,
      },
    ],
  },
  {
    title: "Procedura",
    icon: <LibraryBooksIcon />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: "Lista procedur",
        path: "/procedure",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Dodaj procedure",
        path: "/procedure-add",
        icon: <IoIcons.IoIosAddCircle />,
      },
    ],
  },
  {
    title: "Odczynnik",
    icon: <IoIcons.IoMdBeaker />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: "Lista odczynników",
        path: "/reagent",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Dodaj odczynnik",
        path: "/reagent-add",
        icon: <IoIcons.IoIosAddCircle />,
      },
      {
        title: "Lista dostaw",
        path: "/delivery",
        icon: <IoIcons.IoIosPaper />,
        cName: "sub-nav",
      },
      {
        title: "Dodaj dostawe",
        path: "/delivery-add",
        icon: <IoIcons.IoIosAddCircle />,
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "Lokalizacja",
    icon: <PlaceIcon />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: "Lista lokalizacji",
        path: "/localization",
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Dodaj lokalizacje",
        path: "/localization-add",
        icon: <IoIcons.IoIosAddCircle />,
      },
    ],
  },

  {
    title: "Atrybuty",
    path: "/attribute",
    icon: <LabelIcon />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    title: "Dodaj parametr wyniku",
    path: "/result-parameter-add",
    icon: <IoIcons.IoIosAddCircle />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },

  {
    title: "Archiwum",
    icon: <BiIcons.BiArchive />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: "Lista próbek",
        path: "/sample/archives",
        icon: <IoIcons.IoIosPaper />,
      },
    ],
  },
  {
    title: "Laboratorium",
    icon: <ScienceIcon />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: "Lista pracowników",
        path: "/users",
        icon: <GroupIcon />,
      },
      {
        title: "Raport",
        path: "/report",
        icon: <DescriptionIcon />,
      },
    ],
  },
  {
    title: "Kalendarz",
    path: "/calendar",
    icon: <CalendarTodayIcon />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
];
