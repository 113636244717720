import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Box, Button, Card, Container, Grid } from "@mui/material";
import api from "../../../config/api/apiRoutes";
import { Typography } from "@mui/material";
import BasicTable from "../../../components/Table/BasicTable";
import { useParams } from "react-router-dom";
import intialColumnsPatientAttribute from "../../../components/Patient/table/intialColumnsPatientAttribute";
import CoreTable from "../../../components/Table/CoreTable";
import intialColumnsPatientDetails from "../../../components/Patient/table/intialColumnsPatientDetails";
import HomeIcon from "@mui/icons-material/Home";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import TableActionButton from "../../../components/Button/TableActionButton";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import TimelineIcon from "@mui/icons-material/Timeline";
import TreeTableExportTreeSample from "../../../components/Table/TreeTableExportTreeSample";
import intialColumnsSampleListPatient from "../../../components/Sample/table/intialColumnsSampleListPatient";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import FolderIcon from "@mui/icons-material/Folder";
import Select from "react-select";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function PatientDetails(props) {
  const [patientSamples, setPatientSamples] = useState([]);
  const [patientDetails, setPatientDetails] = useState([]);
  const [patientExtraValues, setPatientExtraValues] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [value, setValue] = React.useState(0);

  let { id } = useParams();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const dataTableAction = [
    {
      label: "Timeline",
      icon: <TimelineIcon />,
      linkTo: `/patient/${id}/timeline`,
    },
    {
      label: "Edytuj pacjenta",
      icon: <EditIcon />,
      linkTo: `/patient/${id}/edit`,
    },
    {
      label: "Wygeneruj etykietę",
      icon: <QrCode2Icon />,
      linkTo: `/patient/${id}/barcode`,
    },
  ];

  if (localStorage.getItem("vault") === "true") {
    dataTableAction.push({
      label: "Lista plików pacjenta",
      icon: <FolderIcon />,
      linkTo: `/patient/${id}/files`,
    });
  }

  useEffect(() => {
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    const getData = async () => {
      try {
        const getPatient = await axios.get(api(id).PATIENT_DETAIL_GET);
        const getDataPatient = getPatient.data;
        const getPatientExtraValues = getDataPatient.extra_values;
        const getSample = await axios.get(api(id).PATIENT_SAMPLE_GET);
        const getDataSample = getSample.data;

        setPatientDetails(getDataPatient);
        setPatientExtraValues(getPatientExtraValues);
        setPatientSamples(getDataSample);
        setIsLoading(false);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      }
    };

    getData();
  }, []);

  return (
    <>
      <Container maxWidth={false}>
        <Typography variant="h5" style={{ fontWeight: "400" }}>
          Szczegóły pacjenta
        </Typography>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" to="/" style={{ color: "#888" }}>
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
            Dashboard
          </Link>
          <Link underline="hover" to="/patient" style={{ color: "#888" }}>
            Lista pacjentów
          </Link>
          <Typography
            sx={{ display: "flex", alignItems: "center" }}
            color="#111"
            style={{ fontWeight: "600" }}
          >
            Szczegóły pacjenta
          </Typography>
        </Breadcrumbs>
        <Box sx={{ width: "100%", mt: 2 }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Główne informacje" {...a11yProps(0)} />
              <Tab label="Próbki" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-start"
              spacing={2}
            >
              <TableActionButton data={dataTableAction} />
            </Stack>
            <Grid container spacing={6} sx={{ mt: 1 }}>
              <Grid item xs={12} lg={12}>
                <CoreTable
                  data={[patientDetails]}
                  isLoading={isLoading}
                  intialColumns={intialColumnsPatientDetails}
                  headerTable={"Główne informacje pacjenta"}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                {!isLoading && (
                  <>
                    Dodatkowe choroby:
                    <Select
                      isMulti
                      value={patientDetails?.additional_disease_name.map(
                        (item) => ({ value: item, label: item })
                      )}
                      placeholder="Brak dodatkowych chorób"
                      isDisabled={true}
                    />
                  </>
                )}
              </Grid>
              <Grid item xs={12} lg={12}>
                <BasicTable
                  data={patientExtraValues}
                  isLoading={isLoading}
                  intialColumns={intialColumnsPatientAttribute}
                  headerTable={"Dodatkowe informacje pacjenta"}
                />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-start"
              spacing={2}
            >
              <TableActionButton
                data={[
                  {
                    label: "Dodaj próbkę",
                    icon: <AddIcon />,
                    linkTo: `/sample-add/?patient_id=${id}`,
                  },
                ]}
              />
            </Stack>
            <Grid container spacing={6} sx={{ mt: 1 }}>
              <Grid item xs={12} lg={12}>
                <TreeTableExportTreeSample
                  data={patientSamples}
                  isLoading={isLoading}
                  intialColumns={intialColumnsSampleListPatient}
                  headerTable={"Lista hierarchi próbek"}
                />
              </Grid>
            </Grid>
          </TabPanel>
        </Box>
      </Container>
    </>
  );
}

export default PatientDetails;
