/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import { Box, Stack, Typography } from "@mui/material";
import ImageSlider from "./ImageSlider";
import { FaCheckCircle } from 'react-icons/fa';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import StorageIcon from '@mui/icons-material/Storage';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';

function SupportLab() {
  const titleStyles = {
    fontSize: "1.5rem",
    fontWeight: "700",
    lineHeight: "1.375",
    display: "flex",
    opacity: "1",
    textTransform: "none",
    textAlign: "center",
    textDecoration: "none",
    color: "rgb(255, 255, 255)",
    letterSpacing: "-0.125px",
  };

  const subTitleStyles = {
    fontSize: "1.25rem",
    fontWeight: "700",
    lineHeight: "1.375",
    display: "block",
    opacity: "1",
    textTransform: "none",
    textAlign: "center",
    verticalAlign: "unset",
    textDecoration: "none",
    color: "rgb(255, 255, 255)",
    letterSpacing: "-0.125px",
  };

  const textStyles = {
    color: "white !important",
    fontSize: "1.25rem",
    fontWeight: "400",
    lineHeight: "1.625",
    textTransform: "none",
    verticalAlign: "unset",
    textDecoration: "none",
    letterSpacing: "-0.125px",
    fontFamily: 'Segoe UI',
    marginBottom: "2.5rem",
    textAlign: "justify",
  };

  const bgImage =
    "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/desktop.jpg";

  return (
    
    <Box
      display="flex"
      alignItems="center"
      my={2}
      py={6}
      sx={{
        backgroundColor: "#2B537D",
      }}
    >
      <Container maxWidth={"xl"}>
        <Grid container>

          <Box display="flex" alignItems="center">
            <Typography
              variant="h2"
              color="white"
              fontWeight="bold"
              sx={titleStyles}
              style={{marginBottom: "3.5rem"}}
              text
            >
              Jak pomaga nasze oprogramowanie w prowadzeniu biobanku?
            </Typography>
        </Box>
            <Typography mb={2} sx={textStyles}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={1} container alignItems="center" justifyContent="center">
                  <Box display="flex" alignItems="right">
                    <AppRegistrationIcon style={{ fontSize: '36px' }} />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={11}>
                  Oprogramowanie do zarządzania biobankiem to niezastąpione narzędzie dla instytucji zajmujących się przechowywaniem próbek biologicznych. 
                  Umożliwia efektywne zarządzanie danymi, co obejmuje m.in. rejestrację przyjętych próbek, przypisywanie im unikalnych identyfikatorów, a także zapisywanie kluczowych informacji o pacjentach, dawcach, datach pobrania i rodzaju próbek. 
                  Dzięki temu, wszystkie dane są zawsze na wyciągnięcie ręki, umożliwiając precyzyjne monitorowanie i śledzenie przechowywanych próbek.
                </Grid>
              </Grid>
            </Typography>
            <Typography mb={2} sx={textStyles}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={1} container alignItems="center" justifyContent="center">
                  <Box display="flex" alignItems="right">
                    <StorageIcon style={{ fontSize: '36px' }} />
                  </Box>
                </Grid>
                  <Grid item xs={12} sm={11}>
                    Zarządzanie danymi biologicznymi jest kluczowym elementem pracy biobanku. 
                    To oprogramowanie umożliwia gromadzenie i przechowywanie szerokiego zakresu danych związanych z próbkami, takich jak wyniki badań laboratoryjnych, dane genetyczne czy informacje kliniczne pacjentów, dawców. 
                    Pozwala to na stworzenie bogatej, zorganizowanej i bezpiecznej bazy danych, która może służyć do wielu celów, od badań naukowych po diagnostykę kliniczną.
                </Grid>
              </Grid>
            </Typography>
            <Typography mb={2} sx={textStyles}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={1} container alignItems="center" justifyContent="center">
                  <Box display="flex" alignItems="right">
                    <TravelExploreIcon style={{ fontSize: '36px' }} />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={11}>
                  Oprogramowanie biobankowe ma też za zadanie monitorowanie ważności próbek, przeprowadzanych procedur oraz lokalizacji. 
                  Pozwala na śledzenie historii manipulacji i transportu próbek, co jest kluczowe dla zapewnienia ich jakości i wiarygodności. 
                  Dodatkowo, wspiera przestrzeganie odpowiednich standardów jakościowych, co jest niezbędne w dzisiejszym świecie, gdzie wymogi dotyczące jakości i bezpieczeństwa są coraz bardziej restrykcyjne.
                </Grid>
              </Grid>
            </Typography>
          </Grid>
      </Container>
    </Box>
  );
}

export default SupportLab;
