import JsBarcode from "jsbarcode";
import React from "react";
import ReactDOM from "react-dom";
import {
  PDFViewer,
  Image,
  Page,
  Text,
  View,
  Document,
  PDFDownloadLink,
  Font,
} from "@react-pdf/renderer";

import {
  Box,
  Grid,
  Divider,
  Typography,
  CardContent,
  Card,
  TextField,
} from "@mui/material";

import { Button } from "@mui/material";
const generateBarcodeImage = (data, height) => {
  const canvas = document.createElement("canvas");
  console.log(!height);
  if (height) {
    JsBarcode(canvas, data.text, { ...data, height });
  } else {
    JsBarcode(canvas, data.text, data);
  }
  return canvas.toDataURL();
};
//document.querySelector("body").appendChild(canvas);

Font.register({
  family: "Roboto",
  src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf",
});

// Create style with font-family
const styles = {
  page: {
    fontFamily: "Roboto",
  },
};

const MyDocument = ({
  data,
  width,
  height,
  center,
  barcodeOptions,
  fontSize,
  attributes,
  displayBarcode,
  barcodeSampleId,
  pageWidth,
  pageHeight,
  labelOrientation
}) => (
  <Document>
    <Page
      size={{ width: pageWidth, height: pageHeight }}
      style={{
        ...styles.page,
        fontSize: `${fontSize > 48 ? 48 : fontSize}px`,
        textAlign: center ? "center" : "left",
      }}
      orientation={labelOrientation}
    >
      <View>
        <Image
          src={generateBarcodeImage(data, height)}
          style={{
            width: width ? `${width}%` : "0",
            display: displayBarcode ? "flex" : "none",
            margin: center ? "0 auto" : "0",
          }}
        />
        {barcodeOptions &&
          barcodeOptions.map((option, index) => (
            <Text key={index}>
              {option.label}:{" "}
              {option.value === true || option.value === false
                ? option.value.toString()
                : option.value}
            </Text>
          ))}
      </View>
      <View>
        {attributes[0].attributeLabel &&
          attributes.map((option, index) => (
            <Text key={index}>
              {option.attributeLabel}: {option.attributeValue}
            </Text>
          ))}
      </View>
    </Page>
  </Document>
);

const BarcodePatientGenerator = ({
  data,
  width,
  height,
  center,
  barcodeOptions,
  fontSize,
  attributes,
  displayBarcode,
  barcodeSampleId,
  pageSize,
  labelDisplay,
  labelOrientation,
}) => {
  console.log(pageSize);
  let parsedPageSize;
  console.log(parsedPageSize);

  if (Array.isArray(pageSize)) {
    parsedPageSize = pageSize;
  } else {
    parsedPageSize = JSON.parse(pageSize);
  }

  let pageWidth = parsedPageSize[0];
  let pageHeight = parsedPageSize[1];

  return (
    <>
      {labelDisplay && (
        <CardContent>
          <PDFViewer style={{ width: "100%", height: "600px" }}>
            <MyDocument
              data={data}
              width={width}
              height={height}
              center={center}
              barcodeOptions={barcodeOptions}
              fontSize={fontSize}
              attributes={attributes}
              displayBarcode={displayBarcode}
              barcodeSampleId={barcodeSampleId}
              pageWidth={pageWidth}
              pageHeight={pageHeight}
              labelOrientation={labelOrientation}
            />
          </PDFViewer>
        </CardContent>
      )}
      <PDFDownloadLink
        document={
          <MyDocument
            data={data}
            width={width}
            height={height}
            center={center}
            barcodeOptions={barcodeOptions}
            fontSize={fontSize}
            attributes={attributes}
            displayBarcode={displayBarcode}
            barcodeSampleId={barcodeSampleId}
            pageWidth={pageWidth}
            pageHeight={pageHeight}
            labelOrientation={labelOrientation}
          />
        }
        fileName="etykieta.pdf"
      >
        {({ blob, url, loading, error }) =>
          loading ? null : (
            <>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  p: 2,
                }}
              >
                <Button variant="contained" size="large">
                  Pobierz etykietę
                </Button>
              </Box>
            </>
          )
        }
      </PDFDownloadLink>
    </>
  );
};

export default BarcodePatientGenerator;
