import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import TableActionButton from "../../Button/TableActionButton";

const renderTreeColumn = (row, level) => {
  const padding = level * 15 || "0px";
  console.log(row);
  return <div style={{ paddingLeft: padding }}>{row}</div>;
};

const renderCustomColumn = (row, level) => {
  const padding = level * 15 || "0px";
  console.log(row);

  const sampleItems = row.map((item, index) => {
    return <div key={index}>{item.sample}</div>;
  });

  return <div style={{ paddingLeft: padding }}>{sampleItems}</div>;
};

const intialColumnsLocalizationList = [
  {
    accessorKey: "name",
    header: "Nazwa",
    accessorFn: (originalRow) => originalRow.name,
    Cell: ({ cell }) =>
      renderTreeColumn(cell.getValue(), cell.row.original.level),
  },
  {
    accessorKey: "samples.sample",
    header: "Próbki",
    accessorFn: (originalRow) => originalRow.samples,
    Cell: ({ cell }) =>
      renderCustomColumn(cell.getValue(), cell.row.original.level),
  },
  {
    accessorFn: (row) => (
      <TableActionButton
        data={[
          {
            label: "Szczegóły",
            icon: <VisibilityIcon />,
            linkTo: `/localization/${row.id}`,
          },
          {
            label: "Edytuj",
            icon: <EditIcon />,
            linkTo: `/localization/${row.id}/edit`,
          },
        ]}
      />
    ),

    header: "Akcja",
    enableColumnFilter: false,
    enableSorting: false,
  },
];

export default intialColumnsLocalizationList;
