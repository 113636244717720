import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import { Grid, Stack, FormHelperText } from '@mui/material';

export default function MultiLineInput({
    inputLabel,
    value,
    name,
    handleBlur,
    onChange,
    categoryOptions,
    touched,
    errors,
    inputId,
    inputType,
    md,
    xs,
    inputProps
}) {
    return (
        <Grid item md={md} xs={xs}>
            <Stack spacing={1}>
                <InputLabel htmlFor={inputId}>{inputLabel}</InputLabel>
                <OutlinedInput
                    id={inputId}
                    type={inputType}
                    fullWidth
                    value={value}
                    name={name}
                    onBlur={handleBlur}
                    onChange={onChange}
                    inputProps={inputProps}
                    multiline
                    rows={4}
                />
                {touched && errors && (
                    <FormHelperText error id="standard-weight-helper-text-email-login">
                        {errors}
                    </FormHelperText>
                )}
            </Stack>
        </Grid>
    );
}
