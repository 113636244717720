import React, { Component } from "react";
import { connect } from "react-redux"; // new import
import * as actions from "../../services/actions/auth";
import axios from "axios";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import api from "../../config/api/apiRoutes";
import EditIcon from '@mui/icons-material/Edit';

class EquipmentFormEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ReagentName: props.data.name,
      ReagentShortName: props.data.short_name,
      ReagentCas: props.data.cas,
      ReagentLazyMass: props.data.lazy_mass,
      ReagentAdditionalInfo: props.data.additional_info,
      ReagentCreator: props.data.creator,
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChangeRadio = (event) => {
    console.log(event.target.value);
    this.setState({ EquipmentBig: event.target.value });
  };

  editEquipmentForm = (e) => {
    e.preventDefault();
    const formData = {
      name: this.state.ReagentName,
      short_name: this.state.ReagentShortName,
      cas: this.state.ReagentCas,
      lazy_mass: this.state.ReagentLazyMass,
      additional_info: this.state.ReagentAdditionalInfo,
      creator: this.state.EquipmentCreator,
    };

    let id = this.props.id_reagent;
    this.props.onAuth(formData, id);
  };

  render() {
    const { errors } = this.state;

    if (this.props.adding == true) {
      window.location.reload(true);
      let toSliceId = window.location.pathname;
      let id = toSliceId.slice(14);
    }

    return (
      <>
        {this.props.loading ? (
          <LoadingSpinner />
        ) : (
          <Card>
            <Typography
              variant="h6"
              sx={{ position: "relative", padding: "16px 0 20px 16px" }}
            >
              <span
                style={{
                  content: "",
                  backgroundColor: "#111e6c",
                  position: "absolute",
                  left: "0",
                  top: "16px",
                  width: "4px",
                  height: "25px",
                }}
              ></span>
              Formularz edycji odczynnika
            </Typography>
            <Divider />
            <form onSubmit={this.editEquipmentForm}>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      type="text"
                      label="Nazwa odczynnika"
                      name="ReagentName"
                      value={this.state.ReagentName}
                      onChange={this.onChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      type="text"
                      label="Nazwa skrócona"
                      name="ReagentShortName"
                      value={this.state.ReagentShortName}
                      onChange={this.onChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      type="text"
                      label="Nr CAS"
                      name="ReagentCas"
                      value={this.state.ReagentCas}
                      onChange={this.onChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      type="text"
                      label="Masa molowa"
                      name="ReagentLazyMass"
                      value={this.state.ReagentLazyMass}
                      onChange={this.onChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      type="text"
                      label="Dodatkowa informacja"
                      name="ReagentAdditionalInfo"
                      value={this.state.ReagentAdditionalInfo}
                      onChange={this.onChange}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  p: 3,
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  endIcon={<EditIcon />}
                >
                  Edytuj sprzęt
                </Button>
              </Box>
            </form>
          </Card>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error,
    adding: state.adding,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (formData, id) =>
      dispatch(actions.requestApi(formData, api(id).REAGENT_EDIT_PUT, "put")),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentFormEdit);
