import React, { useState, useEffect } from "react";
import axios from "axios";
import api from "../../../config/api/apiRoutes";
import ProcessFormEdit from "../../../components/Process/ProcessFormEdit";
import { useParams } from "react-router-dom";
import { Box, Container, Grid, Typography } from "@mui/material";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { Link } from "react-router-dom";
import { Divider } from "rsuite";
import HomeIcon from "@mui/icons-material/Home";
import Breadcrumbs from "@mui/material/Breadcrumbs";

function ProcessEdit(props) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  let { id } = useParams();
  console.log(id);

  useEffect(() => {
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    axios
      .get(api(id).PROCESS_DETAILS_GET)
      .then((response) => {
        setData(response.data);
        setIsLoading(false);

        console.log(response.data);
      })
      .catch((error) => {});
  }, []);
  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Container maxWidth={false}>
      <Typography variant="h5" style={{ fontWeight: "400" }}>
        Edytuj proces {id}
      </Typography>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" to="/" style={{ color: "#888" }}>
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Dashboard
        </Link>
        <Link underline="hover" to="/process" style={{ color: "#888" }}>
          Lista procesów
        </Link>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="#111"
          style={{ fontWeight: "600" }}
        >
          Edytuj proces
        </Typography>
      </Breadcrumbs>
      <Divider />
      <Grid container spacing={3}>
        <Grid item lg={12} md={12} xs={12}>
          <ProcessFormEdit data={data} id_process={id} props={props} />
        </Grid>
      </Grid>
    </Container>
  );
}

export default ProcessEdit;
