import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import { Grid, Stack, TextField } from "@mui/material";

export default function Input({
  inputLabel,
  value,
  name,
  handleBlur,
  onChange,
  inputId,
  inputType,
  md,
  xs,
  inputProps,
  disabled,
}) {
  return (
    <Grid item md={md} xs={xs}>
      <TextField
        fullWidth
        label={inputLabel}
        id={inputId}
        type={inputType}
        value={value}
        name={name}
        onBlur={handleBlur}
        onChange={onChange}
        inputProps={inputProps}
        disabled={disabled}
        variant="outlined"

      />
    </Grid>
  );
}
