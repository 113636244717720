import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FileHeader } from './FileHeader';

export function SingleFileUploadWithProgress({ file, onDelete, onUpload }) {
    return (
        <Grid item>
            <FileHeader file={file} onDelete={onDelete} />
        </Grid>
    );
}
