import React, { Component } from "react";
import { connect } from "react-redux"; // new import
import * as actions from "../../services/actions/auth";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import api from "../../config/api/apiRoutes";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';

const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach(
    // if we have an error string set valid to false
    (val) => val.length > 0 && (valid = false)
  );
  return valid;
};

class ProcessFormEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ProcessName: this.props.data.name,
      ProcessComment: this.props.data.additional_info,

      errors: {
        ProcessName: "",
        ProcessComment: "",
      },
    };
  }

  onChange = (e) => {
    e.persist();
    this.setState({ [e.target.name]: e.target.value });

    const { name, value } = e.target;
    let errors = this.state.errors;
    console.log(value.length);
    switch (name) {
      case "ProcessName":
        errors.ProcessName = value.length >= 1 ? "" : "Min 1 znak";
        break;
      default:
        break;
    }

    this.setState({ errors, [name]: value });
    console.log(value.length);
  };

  addEquipmentForm = (e) => {
    e.preventDefault();
    let id_process = this.props.id_process;
    const formData = {
      name: this.state.ProcessName,
      additional_info: this.state.ProcessComment,
      creator: `${localStorage.getItem("user_id")}`,
    };

    const validate = () => {
      let validationError = this.state.errors;
      if (!this.state.ProcessName) {
        validationError.ProcessName = "Wymagane pole";
      }

      this.setState({ validationError, validationError });
      console.log("in validate", validationError);
      return validationError;
    };

    const validationError = validate();
    console.log(this.state.errors);
    if (validateForm(validationError)) {
      console.info("Valid Form");
      this.props.onAuth(formData, id_process);
    } else {
      console.error("Invalid Form");
    }
  };

  render() {
    const { errors } = this.state;

    let errorMessage = null;
    if (this.props.error) {
      errorMessage = <p>{this.props.error.message}</p>;
    }

    if (this.props.adding == true) {
      this.props.props.history.push("/process");
    }
    console.log(this.props);

    return (
      <>
        {this.props.loading ? (
          <LoadingSpinner />
        ) : (
          <Card>
            <Typography
              variant="h6"
              sx={{ position: "relative", padding: "16px 0 20px 16px" }}
            >
              <span
                style={{
                  content: "",
                  backgroundColor: "#111e6c",
                  position: "absolute",
                  left: "0",
                  top: "16px",
                  width: "4px",
                  height: "25px",
                }}
              ></span>
              Formularz edycji procesu
            </Typography>

            <Divider />
            <form onSubmit={this.addEquipmentForm}>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      type="text"
                      label="Nazwa procesu"
                      error={errors.ProcessName.length ? true : false}
                      name="ProcessName"
                      value={this.state.ProcessName}
                      onChange={this.onChange}
                      helperText={
                        errors.ProcessName.length > 0 ? errors.ProcessName : ""
                      }
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      type="text"
                      label="Dodatkowe informacje"
                      error={errors.ProcessComment.length ? true : false}
                      name="ProcessComment"
                      value={this.state.ProcessComment}
                      onChange={this.onChange}
                      helperText={
                        errors.ProcessComment.length > 0
                          ? errors.ProcessComment
                          : ""
                      }
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  p: 2,
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  endIcon={<EditIcon />}
                >
                  Edytuj proces
                </Button>
              </Box>
            </form>
          </Card>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error,
    adding: state.adding,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (formData, id_process) =>
      dispatch(
        actions.requestApi(formData, api(id_process).PROCESS_EDIT_PUT, "put")
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProcessFormEdit);
