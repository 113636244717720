import BootstrapDialogTitle from "../../Popup/BootstrapDialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { Grid } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Select from "react-select";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import SelectStyles from "../../select/SelectStyles";
import AddIcon from "@mui/icons-material/Add";

export default function CalendarAddEventPopup(props) {
  return (
    <BootstrapDialog
      onClose={props.handleClosePopover}
      aria-labelledby="customized-dialog-title"
      open={props.showPopover}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={props.handleClosePopover}
      >
        Dodaj nowe wydarzenie
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <Grid container spacing={3} sx={{ mt: 1 }}>
          <Grid item md={12} xs={12}>
            <TextField
              fullWidth
              type="text"
              label="Nazwa wydarzenia"
              name="EventName"
              value={props.EventName}
              onChange={props.onChange}
              variant="outlined"
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <TextField
              fullWidth
              type="text"
              label="Dodatkowe informacje"
              name="EventInfo"
              value={props.EventInfo}
              onChange={props.onChange}
              variant="outlined"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                label="Wprowadz godzine wydarzenia"
                value={props.EventCalendarSheduledTime}
                onChange={(newValue) => {
                  props.calendarTimeChange(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} sx={{ width: "100%" }} />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item md={6} xs={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                fullWidth
                label="Wybierz date przypomnienia"
                value={props.EventCalendarReminder}
                onChange={props.calendarChange}
                renderInput={(params) => (
                  <TextField {...params} sx={{ width: "100%" }} />
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item md={6} xs={12}>
            <FormLabel id="demo-error-radios">Dodać pracowników?</FormLabel>

            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={props.handleChangeRadio}
            >
              <FormControlLabel value={true} control={<Radio />} label="Tak" />
              <FormControlLabel value={false} control={<Radio />} label="Nie" />
            </RadioGroup>
          </Grid>

          {props.EventParticipantsFlag == true ? (
            <Grid md={6} item xs={12}>
              <Select
                isMulti
                className="basic-multi-select"
                options={props.PatientList}
                placeholder="Przypisz pracownika"
                menuPortalTarget={document.body}
                styles={SelectStyles}
                onChange={(event) => {
                  props.handlePatient(event);
                  //setSamplePatient(event);
                }}
                value={props.PatientChoose}
                noOptionsMessage={() => "name not found"}
              />
            </Grid>
          ) : (
            <></>
          )}

          <Grid item md={12} xs={12}>
            <FormLabel id="demo-error-radios">
              Przypisać pracownika automatycznie?
            </FormLabel>

            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={props.handleChangePatient}
            >
              <FormControlLabel value={true} control={<Radio />} label="Tak" />
              <FormControlLabel value={false} control={<Radio />} label="Nie" />
            </RadioGroup>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={props.addEvent}
          endIcon={<AddIcon />}
        >
          Dodaj Wydarzenie
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
