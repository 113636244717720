import React, { useState, useEffect } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import AuthWrapper from "../../components/AuthWrapper";
import OtpVerifyForm from "../../components/otp/OtpVerifyForm";
import OtpValidateForm from "../../components/otp/OtpValidateForm";


function OtpValidate(props) {



  return (
    <AuthWrapper>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} xs={12}>
              <OtpValidateForm  {...props} />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </AuthWrapper>
  );
}

export default OtpValidate;
