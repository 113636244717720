import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import PasswordResetForm from "../../components/passwordReset/form/PasswordResetForm";
import { useParams } from "react-router-dom";

function PasswordReset(props) {
  const { uidb64, token } = useParams();

  console.log(uidb64, token);

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="lg">
          <Typography
            sx={{ mb: 3 }}
            variant="h4"
            style={{ textAlign: "center" }}
          ></Typography>
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} xs={12}>
              <PasswordResetForm props={props} uidb64={uidb64} token={token}/>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default PasswordReset;
