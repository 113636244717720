import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import { createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import reducer from "./services/reducers/auth";
import "bootstrap/dist/css/bootstrap.min.css";
import "mdb-ui-kit/css/mdb.min.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter } from "react-router-dom";
import { createBrowserHistory } from 'history';

const composeEnhances = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducer, composeEnhances(applyMiddleware(thunk)));

const history = createBrowserHistory();

const app = (
  <Provider store={store}>
    <BrowserRouter history={history}>
      <App />
      <ToastContainer position="bottom-right" />
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));
