import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import TableActionButton from "../../Button/TableActionButton";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import api from "../../../config/api/apiRoutes";
import { requestTable } from "../../../services/actions/auth";

const customRenderColumn = (row) => {
  return (
    <div>
      <b>
        <span style={{ color: "#fa5252" }}>{row.substring(0, 7)}</span>
        <span style={{ color: "gray" }}>{row.substring(7)}</span>
      </b>
    </div>
  );
};

const intialColumnsPatientList = [
  {
    accessorKey: "nr",
    header: "#",
  },
  {
    accessorKey: "id",
    header: "ID",
    size: 40,
  },
  {
    accessorKey: "identifier",
    accessorFn: (originalRow) => originalRow.identifier,
    Cell: ({ cell }) => customRenderColumn(cell.getValue()),
    header: "Identyfikator",
    size: 120,
  },
  {
    accessorKey: "pesel",
    header: "pesel / Nr pacjenta",
    size: 120,
  },
  {
    accessorKey: "comment",
    header: "Komentarz",
    size: 120,
  },
  {
    accessorFn: (row) => (
      <TableActionButton
        data={[
          {
            label: "Szczegóły",
            icon: <VisibilityIcon />,
            linkTo: `/patient/${row.id}`,
          },
          {
            label: "Edytuj",
            icon: <EditIcon />,
            linkTo: `/patient/${row.id}/edit`,
          },
          {
            label: "Usuń",
            icon: <DeleteIcon />,
            onClick: () => requestTable(api(row.id).PATIENT_DELETE, "delete"),
          },
        ]}
      />
    ),
    header: "Akcja",
    enableColumnFilter: false,
    enableSorting: false,
  },
];

export default intialColumnsPatientList;
