import React from "react";
import {
  Box,
  Button,
  Divider,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  useMediaQuery,
  Container,
  Card,
  Checkbox,
  Link,
  FormControlLabel,
} from "@mui/material";
import * as actions from "../../../services/actions/auth";
import { connect } from "react-redux"; // new import
import * as Yup from "yup";
import { Formik, setFieldValue, Field } from "formik";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import api from "../../../config/api/apiRoutes";
import InputOutlined from "../../ui-component/input/InputOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import DependSelect from "../../ui-component/select/depend-select";
import SelectStyles from "../../select/SelectStyles";
import Creatable from "react-select/creatable";
class ShopProcessingForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleButton: false,
      visibleCompany: false,
      foundCompany: null,
      vat: "",
      company: "",
      showPopover: false,
      formData: "",
      productId: this.props.productId,
      productAmount: this.props.productAmount,
      productName: this.props.productName,
    };
  }

  handleButtonClick = async () => {
    console.log(this.formik.values);
    const companyData = await this.getCompany(this.formik.values.companyVat);
    if (companyData) {
      this.setState({ visibleButton: true });
    } else {
      this.setState({ visibleButton: true });
    }
  };

  handleShowButton = () => {
    this.setState((prevState) => ({
      visibleCompany: !prevState.visibleCompany,
    }));
  };

  handleClose = () => {
    this.setState({ showPopover: false });
  };

  completeForm = async (form) => {
    console.log(form.mailLimitation);

    const transformedArray = form.mailLimitation.map((item) => item.value);

    console.log(transformedArray);

    const formDataLimitation = {
      domain_limitation: form.domainLimitation,
      mailbox_limitation: transformedArray,
      product_id: this.state.productId,
      email: form.email,
    };

    if (this.state.foundCompany) {
      const orderId = await this.getOrderId(formDataLimitation);
      if (orderId) {
        console.log(orderId);
        const formDataPayment = {
          amount: this.state.productAmount,
          order_id: orderId,
          payment_method: form.paymentMethod,
          payment_method_code: form.paymentMethodCode,
          products_name: [this.state.productName],
          customer_data: {
            first_name: form.firstName,
            last_name: form.lastName,
            company: form.companyName,
            email: form.email,
          },
          vat: form.companyVat,
        };

        this.props.onAuth(formDataPayment, api().TRANSACTION_CREATE);
      }
    }

    if (this.state.foundCompany === false) {
      const formDataCompany = {
        name: form.companyName,
        vat: form.companyVat,
        address: form.companyAddress,
        post_code: form.companyZip,
        city: form.companyCity,
      };

      const companyRegister = await this.props.onAuth(
        formDataCompany,
        api().COMPANY_REGISTER
      );
      console.log(companyRegister);
      const orderId = await this.getOrderId(formDataLimitation);
      if (orderId) {
        console.log(orderId);
        const formDataPayment = {
          amount: this.state.productAmount,
          order_id: orderId,
          payment_method: form.paymentMethod,
          payment_method_code: form.paymentMethodCode,
          products_name: [this.state.productName],
          customer_data: {
            first_name: form.firstName,
            last_name: form.lastName,
            company: form.companyName,
            email: form.email,
          },
          vat: form.companyVat,
        };

        setTimeout(() => {
          this.props.onAuth(formDataPayment, api().TRANSACTION_CREATE);
        }, 2000);
      }
    }
  };

  requestSend = () => {
    console.log("request?");
    this.props.onAuth(this.state.formData, api().USER_COMPANY_ASSIGN);
  };

  requestSendNotCompany = () => {
    console.log("request?");
    this.props.onAuth(this.state.formData, api().COMPANY_CREATE);
  };

  getCompany = (vat) => {
    return axios
      .get(api(vat).COMPANY_VAT)
      .then((response) => {
        let data = response.data;
        this.setState({
          vat: vat,
          company: data,
          foundCompany: true,
          visibleCompany: true,
        });
        console.log(data);
        return data;
      })
      .catch((error) => {
        console.error(error);
        if (error.response && error.response.status === 404) {
          this.setState({
            vat: vat,
            company: "",
            foundCompany: false,
            visibleCompany: false,
            visibleCompany: true,
            requiredCompany: false,
          });

          return false;
        } else {
          throw error;
        }
      });
  };

  getOrderId = (formData) => {
    return axios
      .post(api().LICENSING_LIMITATIONS, formData)
      .then((response) => {
        let data = response.data;
        let orderId = data.order_id;
        return orderId;
      })
      .catch((error) => {
        console.error(error);
        if (error.response && error.response.status === 404) {
          return false;
        } else {
          throw error;
        }
      });
  };

  componentDidUpdate(prevProps, prevState) {
    console.log("componentDidUpdate");
    console.log(this.state.company.id);
    const { values, setValues, validateForm } = this.formik;

    if (prevState.vat != this.state.vat) {
      if (this.state.foundCompany == false) {
        console.log("powinno byc puste");
        setValues({
          ...values,
          companyName: "",
          companyAddress: "",
          companyCity: "",
          companyZip: "",
        });
      }
      console.log("tataa");
      console.log(this.state.company.name);
      setValues({
        ...values,
        companyName: this.state.company.name ? this.state.company.name : "",
        companyAddress: this.state.company.address
          ? this.state.company.address
          : "",
        companyCity: this.state.company.city ? this.state.company.city : "",
        companyZip: this.state.company.post_code
          ? this.state.company.post_code
          : "",
      });

      if (prevState.foundCompany !== this.state.foundCompany) {
        //validateForm();
      }
    }
  }

  render() {
    const { fields } = this.state;

    const { adding, request_data } = this.props;

    if (request_data?.url) {
      console.log(request_data);
      localStorage.setItem("productInit", false);

      window.location.href = request_data.url;
    }

    return (
      <>
        <Formik
          innerRef={(formik) => (this.formik = formik)}
          initialValues={{
            companyVat: "1234567891",
            companyName: "",
            companyAddress: "",
            companyCity: "",
            companyZip: "",
            findCompany: false,
            domainLimitation: "testowadomena",
            mailLimitation: [],
            email: "mlencewicz@chimide.pl",
            firstName: "Jan",
            lastName: "Kowalski",
            paymentMethod: "",
            paymentMethodCode: "",
            rules: false,
          }}
          validationSchema={Yup.object({
            companyVat: Yup.string().max(10).required("Pole wymagane"),
            findCompany: Yup.boolean().oneOf([true], "Wyszukaj firmę"),
            companyName: Yup.string().required("Pole wymagane"),
            companyAddress: Yup.string().required("Pole wymagane"),
            companyCity: Yup.string().required("Pole wymagane"),
            companyZip: Yup.string().required("Pole wymagane"),
            domainLimitation: Yup.string(),
            mailLimitation: Yup.array(),
            email: Yup.string().required("Pole wymagane"),
            firstName: Yup.string().required("Pole wymagane"),
            lastName: Yup.string().required("Pole wymagane"),
            paymentMethod: Yup.string().required("Pole wymagane"),
            paymentMethodCode: Yup.string().required("Pole wymagane"),
            rules: Yup.boolean().oneOf(
              [true],
              "Zaakceptuj regulamin oraz politykę prywatności."
            ),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              setStatus({ success: true });
              this.completeForm(values);
              setSubmitting(false);
              console.log("ta");
            } catch (err) {
              console.error(err);
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue,
            setValues,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Box
                component="main"
                sx={{
                  alignItems: "center",
                  display: "flex",
                  flexGrow: 1,
                  minHeight: "100%",
                }}
              >
                <Card sx={{ p: 5 }}>
                  <Grid container spacing={2}>
                    <InputOutlined
                      md={12}
                      xs={12}
                      inputLabel={"NIP"}
                      value={values.companyVat}
                      name={"companyVat"}
                      handleBlur={handleBlur}
                      onChange={handleChange}
                      touched={touched.companyVat}
                      errors={errors.companyVat}
                      inputId={"company-vat"}
                      inputType={"number"}
                      inputProps={{ autoFocus: true }}
                    />
                    <Grid
                      item
                      md={12}
                      xs={12}
                      direction="column"
                      align="left"
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button
                        onClick={() => {
                          this.handleButtonClick({ nip: values.companyVat });

                          setValues((values) => ({
                            ...values,
                            findCompany: true,
                          }));
                        }}
                        disabled={!values.companyVat}
                        fullWidth
                        color="primary"
                        endIcon={<SearchIcon />}
                        style={{ padding: "9px 8px" }}
                      >
                        Wyszukaj firmę
                      </Button>
                    </Grid>
                    <Grid
                      item
                      container
                      justify="center"
                      style={{ margin: "0 auto", width: "fit-content" }}
                    >
                      <FormHelperText
                        error
                        id="standard-weight-helper-text--register"
                      >
                        {errors.findCompany}
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      {this.state.visibleButton && (
                        <Button
                          onClick={this.handleShowButton}
                          fullWidth
                          color="primary"
                          endIcon={
                            this.state.visibleCompany ? (
                              <ExpandLessIcon />
                            ) : (
                              <ExpandMoreIcon />
                            )
                          }
                        >
                          {this.state.visibleCompany
                            ? "Ukryj dane firmy"
                            : "Wyświetl dane firmy"}
                        </Button>
                      )}
                    </Grid>
                    {this.state.visibleCompany && (
                      <>
                        <InputOutlined
                          md={12}
                          xs={12}
                          inputLabel={"Nazwa firmy"}
                          value={values.companyName}
                          name={"companyName"}
                          handleBlur={handleBlur}
                          onChange={handleChange}
                          touched={touched.companyName}
                          errors={errors.companyName}
                          disabled={this.state.foundCompany ? true : false}
                          inputId={"company-name"}
                          inputType={"text"}
                        />

                        <InputOutlined
                          md={6}
                          xs={12}
                          inputLabel={"Miasto"}
                          value={values.companyCity}
                          name={"companyCity"}
                          handleBlur={handleBlur}
                          onChange={handleChange}
                          touched={touched.companyCity}
                          errors={errors.companyCity}
                          disabled={this.state.foundCompany ? true : false}
                          inputId={"company-city"}
                          inputType={"text"}
                        />

                        <InputOutlined
                          md={6}
                          xs={12}
                          inputLabel={"Kod pocztowy"}
                          value={values.companyZip}
                          name={"companyZip"}
                          handleBlur={handleBlur}
                          onChange={handleChange}
                          touched={touched.companyZip}
                          errors={errors.companyZip}
                          disabled={this.state.foundCompany ? true : false}
                          inputId={"company-zip"}
                          inputType={"text"}
                        />

                        <InputOutlined
                          md={12}
                          xs={12}
                          inputLabel={"Adres"}
                          value={values.companyAddress}
                          name={"companyAddress"}
                          handleBlur={handleBlur}
                          onChange={handleChange}
                          touched={touched.companyAddress}
                          errors={errors.companyAddress}
                          disabled={this.state.foundCompany ? true : false}
                          inputId={"company-adress"}
                          inputType={"text"}
                        />
                      </>
                    )}
                    <InputOutlined
                      md={6}
                      xs={12}
                      inputLabel={"Imie"}
                      value={values.firstName}
                      name={"firstName"}
                      handleBlur={handleBlur}
                      onChange={handleChange}
                      touched={touched.firstName}
                      errors={errors.firstName}
                      inputId={"first-name"}
                      inputType={"text"}
                    />
                    <InputOutlined
                      md={6}
                      xs={12}
                      inputLabel={"Nazwisko"}
                      value={values.lastName}
                      name={"lastName"}
                      handleBlur={handleBlur}
                      onChange={handleChange}
                      touched={touched.lastName}
                      errors={errors.lastName}
                      inputId={"last-name"}
                      inputType={"text"}
                    />
                    <InputOutlined
                      md={12}
                      xs={12}
                      inputLabel={"Email"}
                      value={values.email}
                      name={"email"}
                      handleBlur={handleBlur}
                      onChange={handleChange}
                      touched={touched.email}
                      errors={errors.email}
                      inputId={"email"}
                      inputType={"text"}
                    />
                    <InputOutlined
                      md={12}
                      xs={12}
                      inputLabel={"Ograniczenia domenowe"}
                      value={values.domainLimitation}
                      name={"domainLimitation"}
                      handleBlur={handleBlur}
                      onChange={handleChange}
                      touched={touched.domainLimitation}
                      errors={errors.domainLimitation}
                      inputId={"domain-limitation"}
                      inputType={"text"}
                    />

                    <Grid item md={12} xs={12}>
                      <Creatable
                        isMulti
                        name="mailLimitation"
                        placeholder="Ograniczenia email"
                        styles={SelectStyles}
                        onChange={(value) =>
                          setFieldValue("mailLimitation", value)
                        }
                        menuPortalTarget={document.body}
                      />
                      {touched.mailLimitation && errors.mailLimitation && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-email-login"
                        >
                          {errors.mailLimitation}
                        </FormHelperText>
                      )}
                    </Grid>
                    <DependSelect
                      md={6}
                      xs={6}
                      value1={values.paymentMethod}
                      value2={values.paymentMethodCode}
                      onChange={handleChange}
                      name1={"paymentMethod"}
                      name2={"paymentMethodCode"}
                      touched1={touched.paymentMethod}
                      errors1={errors.paymentMethod}
                      touched2={touched.paymentMethodCode}
                      errors2={errors.paymentMethodCode}
                    />
                  </Grid>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={values.rules}
                        onChange={handleChange}
                        name={"rules"}
                      />
                    }
                    label={
                      <Typography variant="body1" my={3}>
                        Przejdź do{" "}
                        <Link href="/terms" target="_blank" rel="noopener">
                          regulaminu
                        </Link>{" "}
                        i{" "}
                        <Link
                          href="/privacy-policy"
                          target="_blank"
                          rel="noopener"
                        >
                          polityki prywatności
                        </Link>
                        .
                      </Typography>
                    }
                  />
                  {touched.rules && errors.rules && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-email-login"
                    >
                      {errors.rules}
                    </FormHelperText>
                  )}
                  <Box sx={{ py: 2 }}>
                    <Button
                      disableElevation
                      disabled={isSubmitting}
                      id="submit-login"
                      color="primary"
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Zapłać
                    </Button>
                  </Box>
                </Card>
              </Box>
            </form>
          )}
        </Formik>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  console.log(state);
  return {
    auth: state.auth,
    loading: state.loading,
    error: state.error,
    adding: state.adding,
    request_data: state.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (formData, requestUrl) =>
      dispatch(actions.requestWithoutTokenApi(formData, requestUrl, "post")),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopProcessingForm);
