import React, { useState, useEffect } from "react";
import axios from "axios";
import api from "../../../config/api/apiRoutes";
import { useParams } from "react-router-dom";
import { Box, Container, Grid, Typography } from "@mui/material";
import { Divider } from "rsuite";
import HomeIcon from "@mui/icons-material/Home";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import ProcedureEditForm from "../../../components/Procedure/Form/ProcedureEditForm";

function ProcedureEdit(props) {
  const [procedureDetails, setProcedureDetails] = useState([]);
  const [procedureReagent, setProcedureReagent] = useState([]);
  const [reagentOptions, setReagentOptions] = useState([]);
  const [processOptions, setProcessOptions] = useState([]);
  const [equipmentOptions, setEquipmentOptions] = useState([]);
  const [procedureProcess, setProcedureProcess] = useState([]);
  const [procedureEquipment, setProcedureEquipment] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  let { id } = useParams();

  useEffect(() => {
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    const getData = async () => {
      try {
        const procedureAttributes = [];
        const reagentOptions = [];
        const processOptions = [];
        const equipmentOptions = [];
        const procedureProcess = [];
        const procedureEquipment = [];

        const getProcedureDetails = await axios.get(
          api(id).PROCEDURE_DETAILS_GET
        );
        const getDataProcedureDetails = getProcedureDetails.data;
        const getDataProcedureReagent = getDataProcedureDetails.reagents;
        const getDataProcedureProcess = getDataProcedureDetails.process;
        const getDataProcedureEquipment = getDataProcedureDetails.equipment;

        const getReagentList = await axios.get(api().REAGENT_GET);
        const getDataReagentList = getReagentList.data;

        const getProcessList = await axios.get(api().PROCESS_GET);
        const getDataProcessList = getProcessList.data;

        const getEquipmentList = await axios.get(api().EQUIPMENT_GET);
        const getDataEquipmentList = getEquipmentList.data;

        console.log(getDataEquipmentList);

        if (getDataProcedureReagent != null) {
          getDataProcedureReagent.map((item, index) => {
            return procedureAttributes.push({
              reagentName: item.name,
              reagentValue: item.reagent_value,
              reagentId: item.id,
              add: true,
            });
          });
        }

        getDataReagentList.map((item, index) => {
          return reagentOptions.push({
            value: item.id,
            label: item.name,
          });
        });

        getDataProcessList.map((item, index) => {
          return processOptions.push({
            value: item.id,
            label: item.name,
          });
        });

        getDataEquipmentList.map((item, index) => {
          return equipmentOptions.push({
            value: item.id,
            label: item.name,
          });
        });

        getDataProcedureProcess.map((item, index) => {
          return procedureProcess.push({
            value: item.id,
            label: item.name,
          });
        });

        getDataProcedureEquipment.map((item, index) => {
            return procedureEquipment.push({
              value: item.id,
              label: item.name,
            });
          });
  

        setProcedureDetails(getDataProcedureDetails);
        setProcedureReagent(procedureAttributes);
        setReagentOptions(reagentOptions);
        setProcessOptions(processOptions);
        setEquipmentOptions(equipmentOptions);
        setProcedureProcess(procedureProcess);
        setProcedureEquipment(procedureEquipment);

        setIsLoading(false);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      }
    };

    getData();
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Container maxWidth={false}>
      <Typography variant="h5" style={{ fontWeight: "400" }}>
        Edytuj procedure {id}
      </Typography>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" to="/" style={{ color: "#888" }}>
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Dashboard
        </Link>
        <Link underline="hover" to="/procedure" style={{ color: "#888" }}>
          Lista procedur
        </Link>
        <Link
          underline="hover"
          to={`/procedure/${id}`}
          style={{ color: "#888" }}
        >
          Szczegóły procedury {id}
        </Link>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="#111"
          style={{ fontWeight: "600" }}
        >
          Edytuj procedure
        </Typography>
      </Breadcrumbs>
      <Divider />
      <Grid container spacing={3} sx={{ mb: 5 }}>
        <Grid item lg={12} md={12} xs={12}>
          <ProcedureEditForm
            data={procedureDetails}
            procedureReagent={procedureReagent}
            reagentOptions={reagentOptions}
            processOptions={processOptions}
            equipmentOptions={equipmentOptions}
            procedureProcess={procedureProcess}
            procedureEquipment={procedureEquipment}

            id_procedure={id}
            props={props}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default ProcedureEdit;
