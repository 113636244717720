import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import api from "../../config/api/apiRoutes";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import CalendarEditEventPopup from "./popup/CalendarEditEventPopup";
import CalendarAddEventPopup from "./popup/CalendarAddEventPopup";

export default class CalendarForm extends React.Component {
  calendarComponentRef = React.createRef();

  state = {
    calendarWeekends: true,
    open: false,
    data: [],
    popoverID: "",
    popoverTitle: "",
    popoverDescription: "",
    popoverCreator: [],
    popoverDateStart: "",
    popoverDateReminder: "",
    popoverDateReminderFormat: null,
    popoverDateStartFormat: null,
    popoverUsers: "",
    showPopover: false,
    userList: [],
    EventName: "",
    EventInfo: "",
    EventCalendarScheduled: "",
    EventCalendarScheduledDateTime: "",
    EventCalendarReminder: null,
    EventCalendarReminderFormatted: "",
    EventParticipantsFlag: null,
    EventParticipantsAssign: null,
    EventCalendarSheduledTime: null,
    PatientList: [],
    PatientChoose: [],
  };

  componentDidMount() {
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    axios
      .get(api().CALENDAR_LIST_GET)
      .then((response) => {
        console.log(response.data);
        const data = response.data;

        this.setState({ data });
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });

    axios
      .get(api().USERS_LIST_GET)
      .then((response) => {
        let result = response.data;
        console.log(result);
        this.setState({ userList: result });

        result.map((laboratory, index) => {
          return this.state.PatientList.push({
            value: laboratory.id,
            label: laboratory.email,
          });
        });
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  }

  addEvent = (arg) => {
    console.log(this.state);

    const EventParticipantsID = this.state.PatientChoose.map(
      (item) => item.value
    );

    console.log(EventParticipantsID);

    const formData = {
      name: this.state.EventName,
      scheduled_datetime: this.state.EventCalendarScheduledDateTime,
      reminder_date: this.state.EventCalendarReminderFormatted,
      ...(this.state.EventInfo && { additional_info: this.state.EventInfo }),
      creator: `${localStorage.getItem("user_id")}`,
      flag: this.state.EventParticipantsFlag,
      ...(this.state.PatientChoose && { participants: EventParticipantsID }),
      assign_participants: this.state.EventParticipantsAssign,
    };

    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    axios
      .post(api().CALENDAR_ADD_EVENT_POST, formData)
      .then((response) => {
        console.log(response);
        toast.success("Dodano !");
        window.location.reload(false);
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  };

  editEvent = (arg) => {
    const formData = {
      name: this.state.popoverTitle,
      scheduled_datetime: this.state.popoverDateStartFormat,
      reminder_date: this.state.popoverDateReminderFormat,
      ...(this.state.popoverDescription && {
        additional_info: this.state.popoverDescription,
      }),
      creator: `${localStorage.getItem("user_id")}`,
    };

    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    axios
      .put(api(this.state.popoverID).CALENDAR_EVENT_EDIT_PUT, formData)
      .then((response) => {
        console.log(response);
        toast.success("Dodano!");
        window.location.reload(false);
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleClosePopover = () => {
    this.setState({ showPopover: false });
  };

  handleEventDelete = () => {
    const currentUrl = window.location.href;
    const urlObject = new URL(currentUrl);
    const domain = urlObject.hostname;
    window.location.href = `/event/delete?id=${this.state.popoverID}`;
  };

  handlePatient = (e, input) => {
    this.setState({ PatientChoose: e });
  };

  handleChangeRadio = (event) => {
    this.setState({ EventParticipantsFlag: JSON.parse(event.target.value) });
    console.log(JSON.parse(event.target.value));
  };

  handleChangePatient = (event) => {
    this.setState({ EventParticipantsAssign: JSON.parse(event.target.value) });
    console.log(JSON.parse(event.target.value));
  };

  calendarChange = (newValue) => {
    this.setState({ EventCalendarReminder: newValue });
    const date = newValue.$d;
    const assignReminderDate = async () => {
      const utcDate = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      );
      const isoString = utcDate.toISOString();

      this.setState({ EventCalendarReminderFormatted: isoString });
    };
    assignReminderDate();
  };

  calendarPopupChange = (newValue) => {
    this.setState({ popoverDateReminder: newValue });

    const assignReminderDate = async () => {
      const getDate = newValue.$d;
      const convertedDate = new Date(
        new Date(getDate) - new Date().getTimezoneOffset() * 60000
      ).toISOString();

      this.setState({ popoverDateReminderFormat: convertedDate });
    };
    assignReminderDate();
  };

  calendarTimeChange = (newValue) => {
    this.setState({ EventCalendarSheduledTime: newValue });

    const assignScheduldedDate = async () => {
      const getDate = newValue.$d;
      const dateTime = new Date(getDate);
      const timeString = dateTime.toLocaleTimeString([], {
        timeStyle: "short",
      });

      const dateString = this.state.EventCalendarScheduled;
      const [hour, minute] = timeString.split(":");

      const date = new Date(dateString);
      date.setUTCHours(hour);
      date.setUTCMinutes(minute);
      date.setUTCSeconds(0);
      date.setUTCMilliseconds(0);

      const updatedDateString = date.toISOString();

      this.setState({ EventCalendarScheduledDateTime: updatedDateString });
    };
    assignScheduldedDate();
  };

  calendarPopupTimeChange = (newValue) => {
    this.setState({ popoverDateStart: newValue });
    const assignScheduldedDate = async () => {
      const getDate = newValue.$d;
      const convertedDate = new Date(
        new Date(getDate) - new Date().getTimezoneOffset() * 60000
      ).toISOString();
      this.setState({ popoverDateStartFormat: convertedDate });
    };
    assignScheduldedDate();
  };

  onChange = (e) => {
    console.log(this.state);
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const newArray = this.state.data.map(
      ({
        id,
        name,
        scheduled_datetime,
        reminder_date,
        additional_info,
        users,
        creator,
      }) => {
        const convertedDate = new Date(scheduled_datetime)
          .toISOString()
          .replace("T", " ")
          .slice(0, -5);

        const convertedUsers = users.map((user) => {
          return {
            value: user.id,
            label: user.email,
          };
        });

        return {
          id,
          title: name,
          start: convertedDate,
          eventClasses: "custom-event-class",
          reminder_date: reminder_date,
          description: additional_info,
          users: convertedUsers,
          creator,
        };
      }
    );

    return (
      <>
        <FullCalendar
          defaultView="dayGridMonth"
          header={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
          }}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          ref={this.calendarComponentRef}
          weekends={this.state.calendarWeekends}
          events={newArray}
          eventClick={this.handleEventClick}
          dateClick={this.handleDateClick}
        />
        <CalendarAddEventPopup
          handleClosePopover={this.handleClose}
          showPopover={this.state.open}
          EventName={this.state.EventName}
          onChange={this.onChange}
          EventInfo={this.state.EventInfo}
          EventCalendarSheduledTime={this.state.EventCalendarSheduledTime}
          calendarTimeChange={this.calendarTimeChange}
          EventCalendarReminder={this.state.EventCalendarReminder}
          calendarChange={this.calendarChange}
          handleChangeRadio={this.handleChangeRadio}
          EventParticipantsFlag={this.state.EventParticipantsFlag}
          PatientList={this.state.PatientList}
          handlePatient={this.handlePatient}
          PatientChoose={this.state.PatientChoose}
          handleChangePatient={this.handleChangePatient}
          addEvent={this.addEvent}
        />

        <CalendarEditEventPopup
          handleClosePopover={this.handleClosePopover}
          showPopover={this.state.showPopover}
          popoverTitle={this.state.popoverTitle}
          popoverDateStart={this.state.popoverDateStart}
          popoverDateReminder={this.state.popoverDateReminder}
          popoverDescription={this.state.popoverDescription}
          popoverCreator={this.state.popoverCreator}
          onChange={this.onChange}
          editEvent={this.editEvent}
          handleEventDelete={this.handleEventDelete}
          calendarPopupTimeChange={this.calendarPopupTimeChange}
          calendarPopupChange={this.calendarPopupChange}
          popoverUsers={this.state.popoverUsers}
        />
      </>
    );
  }

  handleDateClick = (arg) => {
    const date = arg.date;
    const datex = new Date(
      new Date(date) - new Date().getTimezoneOffset() * 60000
    );
    const isoStringx = datex.toISOString();
    this.setState({ EventCalendarScheduled: isoStringx });
    this.handleClickOpen();
  };

  handleEventClick = (arg) => {
    const filteredArr = this.state.userList.filter(
      (item) => item.id === arg.event.extendedProps.creator
    );

    const date = moment(arg.event.start);
    const formattedDate = date.format("YYYY-MM-DD HH:mm:ss");

    const dateReminder = moment(arg.event.extendedProps.reminder_date);
    const formattedDateReminder = dateReminder.format("YYYY-MM-DD HH:mm:ss");

    this.setState({
      showPopover: true,
      popoverID: arg.event.id,
      popoverTitle: arg.event.title,
      popoverDescription: arg.event.extendedProps.description,
      popoverCreator: filteredArr,
      popoverDateStart: formattedDate,
      popoverDateReminder: formattedDateReminder,
      popoverUsers: arg.event.extendedProps.users,
    });

    const assignScheduldedDate = async () => {
      const date = moment(arg.event.start);
      const convertedDate = new Date(
        new Date(date) - new Date().getTimezoneOffset() * 60000
      ).toISOString();
      this.setState({ popoverDateStartFormat: convertedDate });
    };
    assignScheduldedDate();

    const assignScheduldedDate1 = async () => {
      const dateReminder = moment(arg.event.extendedProps.reminder_date);
      const convertedDate = new Date(
        new Date(dateReminder) - new Date().getTimezoneOffset() * 60000
      ).toISOString();
      this.setState({ popoverDateReminderFormat: convertedDate });
    };
    assignScheduldedDate1();
  };
}
