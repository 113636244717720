import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Box, Button, Typography } from "@mui/material";

function Download() {
  return (
    <Box component="section">
      <Box
        bgcolor="dark"
        position="relative"
        borderRadius="xl"
        sx={{ overflow: "hidden", mb: 2,  backgroundColor: "#0C3A6A"}}
      >
        <Container sx={{ position: "relative", zIndex: 2, py: 6 }}>
          <Grid
            container
            item
            xs={12}
            md={7}
            justifyContent="center"
            mx="auto"
            textAlign="center"
          >
            <Typography variant="h3" color="#fff" fontWeight='100'>
              Witaj w naszym systemie do biobankowania!
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="large"
              sx={{ mb: 2, mt: 2 }}
            >
              Kontakt
            </Button>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}

export default Download;
