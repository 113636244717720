import React, { useState, useEffect } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import LoginForm from "../../components/Login/Form/LoginForm";
import axios from "axios";
import api from "../../config/api/apiRoutes";
import Tour from "reactour";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import tourLoginConfig from "../../components/Login/tour/tourLoginConfig";
import Fab from "@mui/material/Fab";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import AuthWrapper from "../../components/AuthWrapper";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Footer from "../../components/footer";
import footerRoutes from "../../config/footer-routes";
import { margin } from "polished";


function Login(props) {
  const [data, setData] = useState([]);
  const [isTourOpen, setIsTourOpen] = useState(false);

  const disableBody = (target) => disableBodyScroll(target);
  const enableBody = (target) => enableBodyScroll(target);

  const closeTour = () => {
    setIsTourOpen(false);
  };

  const openTour = () => {
    setIsTourOpen(true);
  };

  const history = useHistory();
  console.log(props);
  if (props.token_auth) {
    history.push("/dashboard");
  }

  const accentColor = "#111e6c";

  useEffect(() => {
    const getData = async () => {
      try {
        const arr_reagent = [];

        const getSwagger = await axios.get(api().SWAGGER_VERSION_GET);
        const getDataSwagger = getSwagger.data.info;

        setData(getDataSwagger);
        console.log(data);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      }
    };

    getData();
  }, []);
  const swaggerVersion = data.version;
  const fabStyle = {
    position: "absolute",
    bottom: 16,
    right: 16,
  };
  return (
    
      <AuthWrapper>
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
          
          <Container maxWidth="lg">
            <Typography
              sx={{ mb: 3, mt: 10}}
              variant="h4"
              style={{ textAlign: "center" }}
            >
              APLIKACJA DO BIOBANKOWANIA v.{swaggerVersion}
            </Typography>
            <Grid container spacing={3}>
              <Grid item lg={12} md={12} xs={12}>
                <LoginForm />
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box sx={{ pt: 6, px: 1 }}>
          <Footer content={footerRoutes} />
        </Box>
        
      </AuthWrapper>
  );
}
const mapStateToProps = (state) => {
  return {
    token_auth: state.token_auth,
  };
};

export default connect(mapStateToProps, null)(Login);
