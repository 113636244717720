import React, { useEffect, useState } from "react";
import { Container } from "@mui/material";
import { Box } from "@mui/material";
import axios from "axios";
import api from "../../../config/api/apiRoutes";
import intialColumnsDeliveryList from "../../../components/Delivery/Table/intialColumnsDeliveryList";
import BasicTable from "../../../components/Table/BasicTable";
import { Divider } from "rsuite";
import HomeIcon from "@mui/icons-material/Home";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";

function DeliveryList(props) {
  const [deliveryList, setDeliveryList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    const getData = async () => {
      try {
        const getListDelivery = await axios.get(api().DELIVERY_GET);
        const getDataListDelivery = getListDelivery.data;

        setDeliveryList(getDataListDelivery);
        setIsLoading(false);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      }
    };

    getData();
  }, []);
  return (
    <Container maxWidth={false}>
      <Typography variant="h5" style={{ fontWeight: "400" }}>
        Lista dostaw
      </Typography>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" to="/" style={{ color: "#888" }}>
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Dashboard
        </Link>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="#111"
          style={{ fontWeight: "600" }}
        >
          Lista dostaw
        </Typography>
      </Breadcrumbs>
      <Divider />
      <Box sx={{ mt: 3, mb: 5 }}>
        <BasicTable
          data={deliveryList}
          isLoading={isLoading}
          intialColumns={intialColumnsDeliveryList}
          headerTable={"Lista wszystkich dostaw"}
        />
      </Box>
    </Container>
  );
}

export default DeliveryList;
