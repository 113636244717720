import React, { Component } from "react";
import { connect } from "react-redux"; // new import
import * as actions from "../../../services/actions/auth";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Button,
  Typography,
} from "@mui/material";
import api from "../../../config/api/apiRoutes";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import { toast } from "react-toastify";

class AccountImageForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: "",
    };
  }

  handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    const allowedExtensions = /(\.jpg|\.png)$/i;
    if (!allowedExtensions.exec(selectedFile.name)) {
      toast.error(
        "Nieprawidłowy format pliku. Dozwolone rozszerzenia to: .jpg, .png."
      );
      event.target.value = null;
      return;
    }
    this.setState({ file: selectedFile });
  };

  formAction = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image.image", this.state.file);
    this.props.onAuth(formData);
  };

  render() {
    console.log(this.props);

    return (
      <>
        {this.props.loading ? (
          <LoadingSpinner />
        ) : (
          <Card>
            <Typography
              variant="h6"
              sx={{ position: "relative", padding: "16px 0 20px 16px" }}
            >
              <span
                style={{
                  content: "",
                  backgroundColor: "#111e6c",
                  position: "absolute",
                  left: "0",
                  top: "16px",
                  width: "4px",
                  height: "25px",
                }}
              ></span>
              Zmień zdjęcie
            </Typography>

            <Divider />
            <form onSubmit={this.formAction}>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <InputLabel htmlFor="file-input">Wybierz plik</InputLabel>
                    <Input
                      id="file-input"
                      type="file"
                      onChange={this.handleFileChange}
                      style={{ display: "none" }}
                    />
                    {this.state.file && (
                      <p>Wybrany plik: {this.state.file.name}</p>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  p: 3,
                }}
              >
                <Button type="submit" variant="contained">
                  Zmień dane
                </Button>
              </Box>
            </form>
          </Card>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error,
    adding: state.adding,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (formData) =>
      dispatch(
        actions.uploadImage(formData, api().USER_IMAGE_EDIT_POST, "post")
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountImageForm);
