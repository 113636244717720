import React from "react";

import {
  Select,
  FormControl,
  FormHelperText,
  MenuItem,
  InputLabel,
  Grid,
} from "@mui/material";

export default function DependSelect({
  md,
  xs,
  value1,
  value2,
  onChange,
  name1,
  name2,
  touched1,
  errors1,
  touched2,
  errors2,
}) {
  const [age, setAge] = React.useState("");
  const [secondAge, setSecondAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const handleChange2 = (event) => {
    setSecondAge(event.target.value);
  };
  const ageMap = {
    pbl: [
      { value: "mtransfer", label: "mTransfer - mBank" },
      { value: "bzwbk", label: "Przelew24 - Santander Bank Polska" },
      { value: "pekao24", label: "Pekao24Przelew - Bank Pekao" },
      { value: "inteligo", label: "Płacę z Inteligo" },
      { value: "ipko", label: "Płać z iPKO" },
      { value: "getin", label: "Płacę z VeloBank" },
      { value: "noble", label: "Płacę z Noble Bank" },
      { value: "creditagricole", label: "Credit Agricole e-przelew" },
      { value: "alior", label: "Płacę z Alior Bankiem" },
      { value: "millennium", label: "Millennium - płatności internetowe" },
      { value: "citi", label: "Przelew z Citi Handlowego" },
      { value: "bos", label: "Płać z BOŚ" },
      { value: "bnpparibas", label: "Płacę z Pl@net" },
      { value: "pocztowy", label: "Pocztowy24" },
      { value: "plusbank", label: "Płacę z Plus Bank" },
      { value: "bs", label: "Bank Spółdzielczy" },
      { value: "bspb", label: "Bank Spółdzielczy w Brodnicy" },
      { value: "nest", label: "nestPrzelew" },
      { value: "blik", label: "Płatność BLIK." },
      { value: "ing", label: "Płać z ING." },
      { value: "imoje_paylater", label: "Imoje płacę później" },
      { value: "wt", label: "Przelew tradycyjny" },
    ],
    blik: [{ value: "blik", label: "Płatność BLIK." }],
    ing: [
      { value: "imoje_paylater", label: "Imoje płacę później" },
      { value: "ing", label: "Płać z ING." },
    ],
  };
  console.log(age);
  return (
    <>
      <Grid item md={md} xs={xs}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Wybór płatności</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={value1}
            name={name1}
            onChange={onChange}
          >
            <MenuItem value={"pbl"}>Płatność online</MenuItem>
            <MenuItem value={"blik"}>Płatność blik</MenuItem>
            <MenuItem value={"ing"}>Płatność dla klientów ING Bank</MenuItem>
          </Select>
          {touched1 && errors1 && (
            <FormHelperText error id="standard-weight-helper-text-email-login">
              {errors1}
            </FormHelperText>
          )}
        </FormControl>
      </Grid>
      <Grid item md={md} xs={xs}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Wybór płatności</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={value2}
            name={name2}
            onChange={onChange}
          >
            {ageMap[value1]
              ? ageMap[value1].map(function (item) {
                  return <MenuItem value={item.value}>{item.label}</MenuItem>;
                })
              : ""}
          </Select>
          {touched2 && errors2 && (
            <FormHelperText error id="standard-weight-helper-text-email-login">
              {errors2}
            </FormHelperText>
          )}
        </FormControl>
      </Grid>
    </>
  );
}
