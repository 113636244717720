import React, { Component } from "react";
import { connect } from "react-redux"; // new import
import * as actions from "../../services/actions/auth";
import axios from "axios";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Button,
  TextField,
  Typography,
  Container,
} from "@mui/material";
import api from "../../config/api/apiRoutes";
import { Redirect } from "react-router-dom";
import Select from "react-select";
import SelectStyles from "../../components/select/SelectStyles";

class ProcedureReagentFormAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      reagents: [],
      selectedReagent: "",
      procedureReagentValue: 0,
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleaddLocalizationChange = (selectedProcedure) => {
    this.setState({ selectedProcedure });
  };

  componentDidMount() {
    let toSliceId = window.location.pathname;
    let id = toSliceId.slice(23);
    this.state.id = id;
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    axios
      .get(api(id).REAGENT_AVAILABLE_GET)
      .then((response) => {
        const result = response.data;
        result.map((laboratory, index) => {
          return this.state.procedures.push({
            value: laboratory.id,
            label: laboratory.name,
          });
        });
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  }

  addProcedureReagent = () => {
    const formData = {
      reagent: this.state.selectedReagent.value,
      procedure: this.state.id,
      reagent_value: this.state.procedureReagentValue,
      creator: this.state.EquipmentCreator,
    };

    let toSliceId = window.location.pathname;
    this.props.onAuth(formData, this.state.id);
  };

  render() {
    const { errors } = this.state;

    if (this.props.adding === true) {
      return <Redirect to={`/procedure/${this.state.id}`} />;
    }

    return (
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="lg">
          <Typography sx={{ mb: 3 }} variant="h5">
            Dodaj odczynnik do procedury i przypisz wykorzystywaną ilosć
            odczynnika
          </Typography>
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} xs={12}>
              {this.props.loading ? (
                <LoadingSpinner />
              ) : (
                <Card>
                  <Divider />
                  <CardContent>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent={"center"}
                    >
                      <Grid item xs={4} xm={4} sx={{ margin: 5 }}>
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          options={this.state.reagents}
                          placeholder="Wybierz odczynnik"
                          styles={SelectStyles}
                          menuPortalTarget={document.body}
                          onChange={this.handleaddLocalizationChange}
                          name="selectedReagent"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Ilość odczynnika"
                          name="procedureReagentValue"
                          value={this.state.procedureReagentValue}
                          onChange={this.onChange}
                          variant="outlined"
                          inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Divider />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      p: 3,
                    }}
                  >
                    <Button
                      onClick={this.addReagentsProcedure}
                      variant="contained"
                    >
                      Dodaj odczynnik
                    </Button>
                  </Box>
                </Card>
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error,
    adding: state.adding,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (formData, id) =>
      dispatch(
        actions.requestApi(formData, api().REAGENT_PROCEDURE_ADD, "post")
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProcedureReagentFormAdd);
