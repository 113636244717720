import React, { Component } from "react";
import { connect } from "react-redux"; // new import
import * as actions from "../../../services/actions/auth";
import axios from "axios";
import Select from "react-select";
import api from "../../../config/api/apiRoutes";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Button,
  TextField,
  Radio,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  FormHelperText,
  Typography,
} from "@mui/material";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import { Redirect } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";

const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach(
    // if we have an error string set valid to false
    (val) => val.length > 0 && (valid = false)
  );
  return valid;
};

class LocatizationAddForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getDisease: [],
      LocalizationName: "",
      LocalizationComment: "",
      PatientAdditionalDisease: "",
      PatientComment: "",
      PatientExtraValues: "",
      radio_root: "",
      sampleParentId: "",
      errors: {
        LocalizationName: "",
        radio_root: "",
      },
    };
  }

  onChange = (e) => {
    e.persist();
    this.setState({ [e.target.name]: e.target.value });

    const { name, value } = e.target;
    let errors = this.state.errors;
    console.log(value.length);
    switch (name) {
      case "LocalizationName":
        errors.LocalizationName = value.length >= 1 ? "" : "Min 1 znak";
        break;

      default:
        break;
    }

    this.setState({ errors, [name]: value });
  };

  onChangeSelect = (e) => {
    console.log(e);
    this.setState({ sampleParentId: e });
    console.log(this.state);
  };

  componentDidMount() {
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    axios
      .get(api().LOCALIZATION_LIST_ID_GET)
      .then((response) => {
        console.log(response.data);
        const getDisease = [];
        response.data.map((user) => {
          return getDisease.push({ value: user.id, label: user.name });
        });
        this.setState({ getDisease });
        console.log(this.state);
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  }

  handleChangeRadio = (event) => {
    this.setState({ radio_root: event.target.value });
    console.log(event.target.value);

    const value = event.target.value;
    const name = "radio_root";
    console.log(value.length);
    let errors = this.state.errors;
    switch (name) {
      case "radio_root":
        errors.radio_root = value.length > 1 ? "" : "Wybierz opcje";
        break;
      default:
        break;
    }

    this.setState({ errors, [name]: value });
  };

  addEquipmentForm = (e) => {
    e.preventDefault();
    var isTrueSet = this.state.radio_root === "true";
    let parentId = [];

    if (this.state.radio_root == "false") {
      parentId.push(this.state.sampleParentId.value);
    } else {
      parentId.push("null");
    }

    const formData = {
      name: this.state.LocalizationName,
      is_root: isTrueSet,
      comment: this.state.LocalizationComment,
      parent: Number(parentId),
      creator: `${localStorage.getItem("user_id")}`,
    };

    const validate = () => {
      let validationError = this.state.errors;
      if (!this.state.LocalizationName) {
        validationError.LocalizationName = "Wymagane pole";
      }

      if (!this.state.radio_root) {
        validationError.radio_root = "Wymagane pole";
      }

      this.setState({ validationError, validationError });
      console.log("in validate", validationError);
      return validationError;
    };

    const validationError = validate();
    console.log(this.state.errors);
    if (validateForm(validationError)) {
      console.info("Valid Form");
      this.props.onAuth(formData);
    } else {
      console.error("Invalid Form");
    }
  };

  render() {
    if (this.props.adding === true) {
      return <Redirect to="/localization" />;
    }
    const { errors } = this.state;

    let errorMessage = null;
    if (this.props.error) {
      errorMessage = <p>{this.props.error.message}</p>;
    }

    return (
      <>
        {this.props.loading ? (
          <LoadingSpinner />
        ) : (
          <Card>
            <Typography
              variant="h6"
              sx={{ position: "relative", padding: "16px 0 20px 16px" }}
            >
              <span
                style={{
                  content: "",
                  backgroundColor: "#111e6c",
                  position: "absolute",
                  left: "0",
                  top: "16px",
                  width: "4px",
                  height: "25px",
                }}
              ></span>
              Formularz dodania lokalizacji
            </Typography>

            <Divider />
            <form onSubmit={this.addEquipmentForm}>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      type="text"
                      value={this.state.LocalizationName}
                      name="LocalizationName"
                      label="Nazwa"
                      onChange={this.onChange}
                      variant="outlined"
                      error={errors.LocalizationName.length ? true : false}
                      helperText={
                        errors.LocalizationName.length > 0
                          ? errors.LocalizationName
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      type="text"
                      label="Dodatkowe info"
                      onChange={this.onChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <FormLabel id="demo-error-radios">
                      Lokalizacja nadrzędna?
                    </FormLabel>

                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      onChange={this.handleChangeRadio}
                    >
                      <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label="Tak"
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label="Nie"
                      />
                    </RadioGroup>
                    {errors.radio_root.length > 0 && (
                      <FormHelperText error={true}>
                        {errors.radio_root}
                      </FormHelperText>
                    )}
                  </Grid>
                  {this.state.radio_root === "false" && (
                    <Grid item md={12} xs={12} align="start">
                      <Select
                        placeholder="Nazwa lokalizacji nadrzędnej"
                        options={this.state.getDisease}
                        value={this.state.reagent}
                        onChange={this.onChangeSelect}
                        noOptionsMessage={() => "name not found"}
                        menuPortalTarget={document.body}
                      ></Select>
                    </Grid>
                  )}
                </Grid>
              </CardContent>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  p: 3,
                }}
              >
                <Button type="submit" variant="contained" endIcon={<AddIcon />}>
                  Dodaj lokalizację
                </Button>
              </Box>
            </form>
          </Card>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error,
    adding: state.adding,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (formData) =>
      dispatch(
        actions.requestApi(formData, api().LOCALIZATION_ADD_POST, "post")
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocatizationAddForm);
