import React from "react";
import { Loader, Placeholder } from 'rsuite';


export default function LoadingSpinner() {
  return (
    <div>
      <Placeholder.Paragraph rows={8} />
      <Loader backdrop content="Ładowanie..." vertical speed="slow" size="md" />
    </div>
  );
}
