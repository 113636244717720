import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import PasswordResetForm from "../../components/passwordReset/form/PasswordMailForm";
function PasswordMail(props) {
  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="lg">
          <Typography
            sx={{ mb: 3 }}
            variant="h4"
            style={{ textAlign: "center" }}
          ></Typography>
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} xs={12}>
              <PasswordResetForm props={props} />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default PasswordMail;
