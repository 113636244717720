import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  Divider,
  Grid,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  MenuItem,
  OutlinedInput,
  SvgIcon,
  Typography,
  Container,
  CardContent,
  Alert,
  Fab,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import FileCard from "../../components/ui-component/cards/file-card";
import axios from "axios";
import api from "../../config/api/apiRoutes";
import SearchIcon from "@mui/icons-material/Search";
import { useTheme, styled } from "@mui/material/styles";
import { shouldForwardProp } from "@mui/system";
import MainCard from "../../components/ui-component/cards/main-card";
import { IconShare } from "@tabler/icons-react";
import { toast } from "react-toastify";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import FileViewer from "react-file-viewer";
import {
  IconTrash,
  IconSortAscendingLetters,
  IconSortDescendingLetters,
  IconSortAscending2,
  IconSortDescending2,
  IconArrowNarrowUp,
  IconArrowNarrowDown,
  IconUserCircle,
  IconWorld,
} from "@tabler/icons-react";
import BasicTooltip from "../../components/ui-component/tooltip/basic-tooltip";
import Select from "../../components/ui-component/select/Select";
import ViewFile from "../../components/ui-component/popup/view-file";
import { useParams } from "react-router-dom";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FileAdd from "../../components/form/upload/FileAdd";
import Popup from "../../components/ui-component/popup/popup";
import { connect } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import ReactCodeInput from "react-code-input";
import CheckPin from "../../components/form/check-pin";
import AddIcon from "@mui/icons-material/Add";

const fabStyle = {
  position: "absolute",
  bottom: 16,
  right: 16,
};

const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(
  ({ theme }) => ({
    paddingLeft: 16,
    paddingRight: 16,
    "& input": {
      background: "transparent !important",
      paddingLeft: "4px !important",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginLeft: 4,
      background: "#fff",
    },
  })
);

const PatientFiles = (props) => {
  const [open, setOpen] = React.useState(false);
  const [openFileViewer, setOpenFileViewer] = React.useState(false);
  const [openDeletePopup, setOpenDeletePopup] = React.useState(false);
  const [fileUserList, setFileList] = useState([]);
  const [fileShareList, setFileShareList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedShareUser, setSelectedShareUser] = useState([]);
  const [fileUrl, setFileUrl] = useState("");
  const [fileExtension, setFileExtension] = useState("");
  const [fileName, setFileName] = useState("");
  const [documentsPerPage, setDocumentsPerPage] = useState(18);
  const [iconColor, setIconColor] = useState("#364152");
  const [sortType, setSortType] = useState("date-asc");
  const [singleShareIdentifier, setSingleShareIdentifier] = useState("");
  const [showTypeFiles, setShowTypeFiles] = useState([]);
  const [isPinCodeValid, setIsPinCodeValid] = useState(true);
  const [pinCode, setPinCode] = useState("");
  const [btnIsPressed, setBtnIsPressed] = useState(false);
  const [isPin, setIsPin] = useState(false);
  const [fileIdentifier, setFileIdentifier] = useState("");

  let { id } = useParams();

  const filteredDocuments = showTypeFiles.filter((document) =>
    document.file.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handlePinChange = (pinCode) => {
    setPinCode(pinCode);
    setBtnIsPressed(false);
  };

  const CORRECT_PIN_CODE = localStorage.getItem("user_pin");

  const checkPinCode = (e) => {
    e.preventDefault();

    const isPinCodeValid = pinCode === CORRECT_PIN_CODE;

    setBtnIsPressed(true);
    setIsPinCodeValid(isPinCodeValid);
    if (isPinCodeValid) {
      setIsPin(false);
      localStorage.setItem("is_pin", false);
    }
    if (!isPinCodeValid) setPinCode("");
  };

  const sortedDocuments = useMemo(() => {
    const sortedList = [...filteredDocuments];

    switch (sortType) {
      case "rozszerzenie":
        sortedList.sort((a, b) => a.extension.localeCompare(b.extension));
        break;
      case "date-asc":
        sortedList.sort((a, b) => a.date_created.localeCompare(b.date_created));
        break;
      case "name-asc":
        sortedList.sort((a, b) => a.file.localeCompare(b.file));
        break;
      default:
        break;
    }

    switch (sortType) {
      case "extension-asc":
        sortedList.sort((a, b) => a.extension.localeCompare(b.extension));
        break;
      case "extension-desc":
        sortedList.sort((a, b) => b.extension.localeCompare(a.extension));
        break;
      case "date-asc":
        sortedList.sort((a, b) => a.date_created.localeCompare(b.date_created));
        break;
      case "name-asc":
        sortedList.sort((a, b) => a.file.localeCompare(b.file));
        break;
      case "name-asc":
        sortedList.sort((a, b) => b.file.localeCompare(a.file));
        break;
      case "date-desc":
        sortedList.sort((a, b) => b.date_created.localeCompare(a.date_created));
        break;
      default:
        break;
    }

    return sortedList;
  }, [filteredDocuments, sortType]);

  const indexOfLastDocument = currentPage * documentsPerPage;
  const indexOfFirstDocument = indexOfLastDocument - documentsPerPage;
  const currentDocuments = sortedDocuments.slice(
    indexOfFirstDocument,
    indexOfLastDocument
  );

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const request2 = () => {
    setOpen(false);
  };

  const handleCheckboxChange = (event, fileId) => {
    if (event.target.checked) {
      setSelectedFiles((prevSelectedFiles) => [...prevSelectedFiles, fileId]);
    } else {
      setSelectedFiles((prevSelectedFiles) =>
        prevSelectedFiles.filter((file) => file !== fileId)
      );
    }
    if (selectedFiles.length >= 1) {
      setIconColor("#364152");
    } else {
      setIconColor(theme.palette.secondary.dark);
    }
  };

  const handleSortChange = (event) => {
    setSortType(event.target.value);
  };

  const handleUploadClick = () => {
    setOpen(true);
  };

  const handleDeleteClick = (fileIdentifier) => {
    console.log("taaa");
    console.log(fileIdentifier);
    setFileIdentifier(fileIdentifier);
    setOpenDeletePopup(true);
  };

  const handleCloseDeletePopup = () => {
    setOpenDeletePopup(false);
  };

  const selectChange = (event) => {
    setSelectedShareUser(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedShareUser([]);
  };

  const handleCloseFileViewer = () => {
    setOpenFileViewer(false);
    setSelectedShareUser([]);
  };

  const downloadFile = (identifier, fileName) => {
    setIsLoading(true);

    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    axios
      .get(api(identifier).PATIENT_FILE_DETAILS, {
        responseType: "blob",
      })
      .then((response) => {
        setIsLoading(false);
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Błąd pobierania pliku:", error);
      });
  };

  const viewFile = (identifier, fileName, fileExtension) => {
    setIsLoading(true);
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    axios
      .get(api(identifier).PATIENT_FILE_DETAILS, {
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));

        console.log(url);
        setFileExtension(fileExtension);
        setFileName(fileName);
        setFileUrl(url);
        setIsLoading(false);
        setOpenFileViewer(true);
      })
      .catch((error) => {
        console.error("Błąd pobierania pliku:", error);
        setIsLoading(false);
      });
  };

  const deleteFile = () => {
    setIsLoading(true);

    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    axios
      .delete(api(fileIdentifier).PATIENT_FILE_DELETE)
      .then((response) => {
        if (response.status === 204) {
          setIsLoading(false);
          handleRefreshData();
          handleCloseDeletePopup();
          toast.success("Suckes!");
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        toast.error("Błąd!");
      });
  };

  const theme = useTheme();

  const getData = async () => {
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    try {
      const getFiles = await axios.get(api(id).PATIENT_FILE_LIST);
      const getFilesData = getFiles.data;

      console.log(getFilesData);

      const filesDataFormatted = getFilesData.map((item) => {
        const date = new Date(item.date_created);
        const formattedDate = `${("0" + date.getDate()).slice(-2)}.${(
          "0" +
          (date.getMonth() + 1)
        ).slice(-2)}.${date.getFullYear()} ${("0" + date.getHours()).slice(
          -2
        )}:${("0" + date.getMinutes()).slice(-2)}`;

        return {
          ...item,
          date_created: formattedDate,
        };
      });

      console.log(getFilesData);

      setFileList(filesDataFormatted);
      setShowTypeFiles(filesDataFormatted);

      setIsLoading(false);
    } catch (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleRefreshData = () => {
    setIsLoading(true);
    getData();
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  console.log(props);

  return (
    <>
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} xs={12}>
            <Card>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography
                    variant="h6"
                    sx={{ position: "relative", padding: "16px 0 20px 16px" }}
                  >
                    <span
                      style={{
                        content: "",
                        backgroundColor: "#111e6c",
                        position: "absolute",
                        left: "0",
                        top: "16px",
                        width: "4px",
                        height: "25px",
                      }}
                    ></span>
                    Lista plików pacjenta
                  </Typography>
                </Grid>
                <Grid item></Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    endIcon={<FileUploadIcon color="primary" />}
                    style={{ marginTop: "12px", marginRight: "6px" }}
                    onClick={handleUploadClick}
                  >
                    Dodaj plik
                  </Button>
                </Grid>
              </Grid>
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item lg={8} md={8} xs={12}>
                    <OutlineInputStyle
                      id="input-search-header"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      placeholder="Wyszukaj plik"
                      startAdornment={
                        <InputAdornment position="start">
                          <SearchIcon
                            stroke={1.5}
                            size="1rem"
                            color={theme.palette.grey[500]}
                          />
                        </InputAdornment>
                      }
                      aria-describedby="search-helper-text"
                      inputProps={{ "aria-label": "weight" }}
                    />
                  </Grid>
                  <Grid item lg={4} md={4} xs={12}>
                    <Select
                      name="sortowanie"
                      dataArray={[
                        {
                          value: "extension-asc",
                          label: (
                            <>
                              <Box sx={{ display: "flex", gap: 1 }}>
                                <SvgIcon color="primary">
                                  <IconArrowNarrowUp />
                                </SvgIcon>
                                Rozszerzenie rosnąco
                              </Box>
                            </>
                          ),
                        },
                        {
                          value: "extension-desc",
                          label: (
                            <>
                              <Box sx={{ display: "flex", gap: 1 }}>
                                <SvgIcon color="primary">
                                  <IconArrowNarrowDown />
                                </SvgIcon>
                                Rozszerzenie malejąco
                              </Box>
                            </>
                          ),
                        },
                        {
                          value: "date-asc",
                          label: (
                            <Box sx={{ display: "flex", gap: 1 }}>
                              <SvgIcon color="primary">
                                <IconSortAscending2 />
                              </SvgIcon>
                              Data dodania rosnąco
                            </Box>
                          ),
                        },
                        {
                          value: "date-desc",
                          label: (
                            <Box sx={{ display: "flex", gap: 1 }}>
                              <SvgIcon color="primary">
                                <IconSortDescending2 />
                              </SvgIcon>
                              Data dodania malejąco
                            </Box>
                          ),
                        },
                        {
                          value: "name-asc",
                          label: (
                            <Box sx={{ display: "flex", gap: 1 }}>
                              <SvgIcon color="primary">
                                <IconSortAscendingLetters />
                              </SvgIcon>
                              Nazwa pliku rosnąco
                            </Box>
                          ),
                        },
                        {
                          value: "name-desc",
                          label: (
                            <Box sx={{ display: "flex", gap: 1 }}>
                              <SvgIcon color="primary">
                                <IconSortDescendingLetters />
                              </SvgIcon>
                              Nazwa pliku malejąco
                            </Box>
                          ),
                        },
                      ]}
                      value={sortType}
                      onChange={handleSortChange}
                      valueKey={"value"}
                      nameKey={"label"}
                      style={{}}
                      stackSpacing={0}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Grid container spacing={3} mt={2}>
          {currentDocuments.map((document) => (
            <Grid item xs={12} sm={6} md={2} key={document.id}>
              <FileCard
                fileName={document.file}
                fileExtension={document.extension}
                fileId={document.id}
                fileDate={document.date_created}
                fileIdentifier={document.identifier}
                fileSharedUsers={document.shared_users}
                onCheckboxChange={handleCheckboxChange}
                onDownloadClick={downloadFile}
                onViewClick={viewFile}
                onDeleteClick={handleDeleteClick}
                isChecked={selectedFiles.includes(document.identifier)}
              />
            </Grid>
          ))}
        </Grid>

        <ViewFile
          showPopover={openFileViewer}
          handleClosePopover={handleCloseFileViewer}
          title={fileName}
          content={
            <>
              {fileUrl && (
                <>
                  {fileExtension == "webp" || fileExtension == "avif" ? (
                    <img src={fileUrl} alt={fileName} />
                  ) : (
                    <FileViewer
                      fileType={fileExtension}
                      filePath={fileUrl}
                      errorComponent={() => (
                        <div>Nie można wyświetlić pliku.</div>
                      )}
                    />
                  )}
                </>
              )}
            </>
          }
        />
        <Grid container justifyContent="space-between" sx={{ mt: 4, mb: 2 }}>
          <Grid item></Grid>
          <Grid item pt={1}>
            <Pagination
              count={Math.ceil(filteredDocuments.length / documentsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
              siblingCount={1}
              boundaryCount={1}
              size="large"
              shape="rounded"
              color="primary"
              style={{ marginTop: "20px" }}
            />
          </Grid>
          <Select
            inputLabel={"Ilość na stronie"}
            value={documentsPerPage}
            dataArray={[
              { value: 18, label: "18" },
              { value: 36, label: "36" },
              { value: 54, label: "54" },
            ]}
            onChange={(event) => setDocumentsPerPage(event.target.value)}
            valueKey={"value"}
            nameKey={"label"}
            style={{ background: "white" }}
          />
        </Grid>
      </Container>
      <Popup
        showPopover={open}
        handleClosePopover={handleClose}
        request1={handleClose}
        request2={handleClose}
        fullWidth={true}
        maxWidth={"md"}
        title={"Dodaj plik"}
        content={
          <FileAdd
            patient_id={id}
            setLoading={setIsLoading}
            refreshData={handleRefreshData}
            closePopup={handleClose}
          />
        }
        iconButton1={IconArrowNarrowDown}
        iconButton2={IconArrowNarrowDown}
        closeText={"Anuluj"}
        displayDialogActions={"none"}
        confirmText={"Dodaj plik"}
      />
      <Popup
        showPopover={openDeletePopup}
        handleClosePopover={handleCloseDeletePopup}
        request1={handleCloseDeletePopup}
        request2={handleCloseDeletePopup}
        fullWidth={true}
        maxWidth={"sm"}
        title={"Usuń plik"}
        content={
          <>
            <CheckPin request={deleteFile} refreshData={handleRefreshData} />
          </>
        }
        iconButton1={CancelIcon}
        iconButton2={DeleteIcon}
        displayDialogActions={"none"}
        closeText={"Anuluj"}
        confirmText={"Usuń"}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error,
    adding: state.adding,
  };
};

export default connect(mapStateToProps, null)(PatientFiles);
