import TableActionButton from "../../Button/TableActionButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const intialColumnsReagentProcedure = [
  {
    accessorKey: "id",
    header: "ID",
  },
  {
    accessorKey: "procedure_name",
    header: "Nazwa",
  },
  {
    accessorKey: "reagent_value",
    header: "Wykorzystywana ilość",
  },
  {
    accessorFn: (row) => (
      <TableActionButton
        data={[
          {
            label: "Szczegóły",
            icon: <VisibilityIcon />,
            linkTo: `/procedure/${row.procedure}`,
          },
          {
            label: "Edytuj",
            icon: <EditIcon />,
            linkTo: `/procedure-reagent/update?procedureName=${row.procedure_name}&procedureReagentId=${row.id}&ReagentId=${row.reagent}`,
          },
          {
            label: "Usuń",
            icon: <DeleteIcon />,
            linkTo: `/procedure-reagent/delete?procedureReagentId=${row.id}&ReagentId=${row.reagent}`,
          },
        ]}
      />
    ),
    header: "Akcja",
    enableColumnFilter: false,
    enableSorting: false,
  },
];

export default intialColumnsReagentProcedure;
