import * as React from "react";
import { useState } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import {
  CardActionArea,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTheme } from "@mui/material/styles";
import GetAppTwoToneIcon from "@mui/icons-material/GetAppOutlined";
import FileCopyTwoToneIcon from "@mui/icons-material/FileCopyOutlined";
import PictureAsPdfTwoToneIcon from "@mui/icons-material/PictureAsPdfOutlined";
import ArchiveTwoToneIcon from "@mui/icons-material/ArchiveOutlined";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import {
  IconPdf,
  IconJpg,
  IconPng,
  IconPhoto,
  IconFileText,
  IconFile,
  IconTrash,
  IconTrashXFilled,
  IconZip,
} from "@tabler/icons-react";
import Checkbox from "@mui/material/Checkbox";
import DeleteIcon from "@mui/icons-material/Delete";
import ShareIcon from "@mui/icons-material/Share";

const textStyles = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};

export default function FileCard({
  fileName,
  fileExtension,
  fileId,
  onCheckboxChange,
  fileDate,
  onDownloadClick,
  fileIdentifier,
  onViewClick,
  isChecked,
  onSingleShare,
  fileSharedUsers,
  onDeleteClick,
}) {
  const theme = useTheme();

  const [expanded, setExpanded] = React.useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const extensionIcons = {
    pdf: <IconPdf fontSize="large" />,
    zip: <IconZip fontSize="large" />,
    jpg: <IconJpg fontSize="large" />,
    png: <IconPng fontSize="large" />,
    webp: <IconPhoto fontSize="large" />,
    docx: <IconFileText fontSize="large" />,
    xlsx: <IconFileText fontSize="large" />,
    // Add other file extensions and corresponding icons
  };

  const defaultIcon = <IconFile fontSize="large" />;
  const fileIcon = extensionIcons[fileExtension.toLowerCase()] || defaultIcon;

  const handleCheckboxClick = (event) => {
    onCheckboxChange(event, fileIdentifier);
  };

  return (
    <Card>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Checkbox
            sx={{ top: 0, right: 0 }}
            color="primary"
            onChange={handleCheckboxClick}
            checked={isChecked}
          />
        </Grid>
        <Grid item></Grid>
      </Grid>

      <CardActionArea
        onClick={() => onViewClick(fileIdentifier, fileName, fileExtension)}
      >
        <CardContent
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#111e6c",
          }}
        >
          {fileIcon}
        </CardContent>
      </CardActionArea>
      <CardHeader
        action={
          <>
            <Avatar
              variant="rounded"
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.mediumAvatar,
                transition: "all .2s ease-in-out",
                background: "#111e6c",
                color: "white",
                '&[aria-controls="menu-list-grow"],&:hover': {
                  background: "#0080ff",
                  color: "white",
                },
              }}
              aria-controls="menu-earning-card"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon fontSize="inherit" />
            </Avatar>
            <Menu
              id="menu-earning-card"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              variant="selectedMenu"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem
                onClick={() => onDownloadClick(fileIdentifier, fileName)}
              >
                <GetAppTwoToneIcon sx={{ mr: 1.75 }} color="primary" /> Pobierz
              </MenuItem>
              <MenuItem
                onClick={() =>
                  onViewClick(fileIdentifier, fileName, fileExtension)
                }
              >
                <FileCopyTwoToneIcon sx={{ mr: 1.75 }} color="primary" />{" "}
                Wyświetl
              </MenuItem>
              <Divider />
              <MenuItem onClick={() => onDeleteClick(fileIdentifier)}>
                <DeleteIcon sx={{ mr: 1.75 }} style={{ color: "red" }} /> Usuń
              </MenuItem>
            </Menu>
          </>
        }
        title={<LongText text={fileName} maxLength={15} />}
        subheader={fileDate}
      />
    </Card>
  );
}

const LongText = ({ text, maxLength }) => {
  if (text.length <= maxLength) {
    return (
      <Typography sx={{ fontWeight: "bold", fontSize: "13px" }}>
        {text}
      </Typography>
    );
  }

  const truncatedText = `${text.slice(0, maxLength)}...`;

  return (
    <Typography
      noWrap
      title={text}
      sx={{ fontWeight: "bold", fontSize: "13px" }}
    >
      {truncatedText}
    </Typography>
  );
};
