import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import TableActionButton from "../../Button/TableActionButton";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { requestTable } from "../../../services/actions/auth";
import api from "../../../config/api/apiRoutes";

const intialColumnsProcedureList = [
  {
    accessorKey: "id",
    header: "ID",
    size: 40,
  },
  {
    accessorKey: "name",
    header: "Nazwa procedury",
    size: 120,
  },
  {
    accessorFn: (row) => (
      <TableActionButton
        data={[
          {
            label: "Szczegóły",
            icon: <VisibilityIcon />,
            linkTo: `procedure/${row.id}`,
          },
          {
            label: "Edytuj",
            icon: <EditIcon />,
            linkTo: `procedure/${row.id}/edit`,
          },
          {
            label: "Usuń",
            icon: <DeleteIcon />,
            onClick: () => requestTable(api(row.id).PROCEDURE_DELETE, "delete"),
          },
        ]}
      />
    ),
    header: "Akcja",
    enableColumnFilter: false,
    enableSorting: false,
  },
];

export default intialColumnsProcedureList;
