import VisibilityIcon from "@mui/icons-material/Visibility";
import TableActionButton from "../../Button/TableActionButton";

const intialColumnsSampleTree = [
  {
    accessorKey: "name",
    header: "Nazwa",
  },
  {
    accessorFn: (row) => (
      <TableActionButton
        data={[
          {
            label: "Szczegóły",
            icon: <VisibilityIcon />,
            linkTo: `/localization/${row.id}`,
          },
        ]}
      />
    ),
    header: "Akcja",
    enableColumnFilter: false,
    enableSorting: false,
  },
];

export default intialColumnsSampleTree;
