import { Link } from "react-router-dom";
import { Button } from "@mui/material";

const intialColumnsDiseaseList = [
  {
    accessorKey: "name_list",
    header: "Nazwa",
  },
  {
    accessorKey: "icd",
    header: "ICD",
  },
  {
    accessorFn: (row) => (
      <Link to={`/disease/${row.icd}`}>
        <Button variant="contained" size="small">
          Edytuj
        </Button>
      </Link>
    ),
    header: "Edytuj",
    enableColumnFilter: false,
  },
];

export default intialColumnsDiseaseList;
