import React, { Component } from "react";
import { connect } from "react-redux"; // new import
import PropTypes from "prop-types"; // new import
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Grid, List, ListItem, ListItemText } from "@material-ui/core";
import {
  Container,
  Button,
  Row,
  Col,
  Form,
  FormControl,
} from "react-bootstrap";
import api from "../../config/api/apiRoutes";
import DeliveryTableDetails from "../../components/Delivery/Table/DeliveryTableDetails";
class DeliveryDetails extends Component {
  constructor(props, id) {
    super(props);
    this.state = {
      getDataEquipment: [],
      getSumDelivery:"",
    };
  }

  componentWillMount() {
    let toSliceId = window.location.pathname;
    let id = toSliceId.slice(10);
    this.state.id = id;
    console.log(this.state.id);
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    axios
      .get(api(id).REAGENT_DELIVERY_GET)
      .then((response) => {
        console.log(response.data);
        const getDataEquipment = response.data;
        console.log(getDataEquipment)
        this.setState({ getDataEquipment });
        console.log(this.state)
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });

      axios
      .get(api(id).REAGENT_DELIVERY_SUM_GET)
      .then((response) => {
        console.log(response.data);
        const getSumDelivery = response.data;
        console.log(getSumDelivery)
        this.setState({ getSumDelivery });
        console.log(this.state)
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  }




  render() {

    console.log(this.state)
    return (
      <div>


        <h1>Dostawa</h1>


        <DeliveryTableDetails/>

      </div>
    );
  }
}

export default DeliveryDetails;
