import React, { useEffect, useState } from "react";
import MaterialReactTable from "material-react-table";
import { Box, Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ExportToCsv } from "export-to-csv";
import axios from "axios";
import { Link } from "react-router-dom";
import api from "../../../config/api/apiRoutes";

const intialColumns = [
  {
    accessorKey: "id",
    header: "ID",
    size: 40,
  },
  {
    accessorKey: "reagent_name",
    header: "nazwa odczynnika",
    size: 120,
  },
  {
    accessorKey: "unit",
    header: "jednostka",
    size: 120,
  },
  {
    accessorKey: "date_created",
    header: "data dostawy",
    size: 120,
  },
  {
    accessorKey: "amount",
    header: "ilość dostawy",
    size: 120,
  },
  {
    accessorKey: "additional_info",
    header: "Dodatkowe informacje",
    size: 120,
  },
  {
    accessorFn: (row) => (
      <Link to={`/delivery/edit/${row.id}`}>
        <Button variant="contained" size="small">Edytuj</Button>
      </Link>
    ),
    header: "Edytuj",
    enableColumnFilter: false,
    size: 120,
  },


];

const csvOptions = {
  fieldSeparator: ",",
  quoteStrings: '"',
  decimalSeparator: ".",
  showLabels: true,
  useBom: true,
  useKeysAsHeaders: false,
  headers: intialColumns.map((c) => c.header),
};

const csvExporter = new ExportToCsv(csvOptions);

const DeliveryTableDetails = () => {
  const [columns, setColumns] = useState(intialColumns);
  const [name, setName] = useState("");



  const handleExportRows = (rows) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  const handleExportData = () => {
    csvExporter.generateCsv(data);
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    axios
      .get(api().DELIVERY_GET)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {});
  }, []);
  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={data}
        enableRowSelection
        positionToolbarAlertBanner="bottom"
        renderTopToolbarCustomActions={({ table }) => (
          <Box
            sx={{ display: "flex", gap: "1rem", p: "0.5rem", flexWrap: "wrap" }}
          >
            <Button
              color="primary"
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              onClick={handleExportData}
              startIcon={<FileDownloadIcon />}
              variant="contained"
            >
              Export All Data
            </Button>
            <Button
              disabled={table.getPrePaginationRowModel().rows.length === 0}
              //export all rows, including from the next page, (still respects filtering and sorting)
              onClick={() =>
                handleExportRows(table.getPrePaginationRowModel().rows)
              }
              startIcon={<FileDownloadIcon />}
              variant="contained"
            >
              Export All Rows
            </Button>
            <Button
              disabled={table.getRowModel().rows.length === 0}
              //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
              onClick={() => handleExportRows(table.getRowModel().rows)}
              startIcon={<FileDownloadIcon />}
              variant="contained"
            >
              Export Page Rows
            </Button>
            <Button
              disabled={
                !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
              }
              //only export selected rows
              onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
              startIcon={<FileDownloadIcon />}
              variant="contained"
            >
              Export Selected Rows
            </Button>
          </Box>
        )}
      />
    </>
  );
};

export default DeliveryTableDetails;
