import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import DefaultInfoCard from "../mui/cards/DefaultInfoCard";
import CenteredBlogCard from "../mui/cards/CenteredBlogCard";
import demo from "../../assets/images/check-demo.PNG";
import bgImage from "../../assets/images/testing-background.png";
import PaymentsIcon from "@mui/icons-material/Payments";
import AssessmentIcon from "@mui/icons-material/Assessment";
function Testing() {
  return (
    <Box
      component="section"
      py={12}
      sx={{
        backgroundImage: ` url(${bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start" spacing={4}>
              <Grid item xs={12} md={6}>
                <Box mb={5}>
                  <DefaultInfoCard
                    icon={<AssessmentIcon />}
                    title="Dodatkowe funkcje"
                    description="Potrzebujesz w aplikacji nowej funkcji, która Ci pomoże w codziennej pracy ? Z chęcią Ci pomożemy!"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box mb={5}>
                  <DefaultInfoCard
                    icon={<PaymentsIcon />}
                    title="Elastyczne finansowanie"
                    description="Oferujemy model subskrybcyjny, wdrożeniowy. Przy przedłużaniu licencji, oferujemy rabaty."
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon={<AssessmentIcon />}
                    title="Dostęp zdalny"
                    description="Jeżeli posiadasz u nas konto, wystarczy mieć dostęp do internetu, aby korzystać z naszej apliakcji."
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon={<AssessmentIcon />}
                    title="Model wdrożeniowy"
                    description="Jeżeli chcesz, aby aplikacja działał na własnej infrastrukturze z dodatkowymi opcjami, dokanamy wdrożenia."
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={demo}
              title="Skorzystaj z możliwości przetestowania systemu"
              description="W ramach testowania będziesz mieć możliwość eksploracji wszystkich funkcji naszego systemu biobankowego."
              action={{
                type: "internal",
                route: "pages/company/about-us",
                color: "info",
                label: "Zobacz więcej",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Testing;
