import React, { Component } from "react";
import { connect } from "react-redux"; // new import
import * as actions from "../../../services/actions/auth";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import api from "../../../config/api/apiRoutes";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Button,
  TextField,
  Alert,
  Typography,
} from "@mui/material";
import Select from "react-select";
import SelectStyles from "../../select/SelectStyles";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import FormHelperText from "@mui/material/FormHelperText";
import EditIcon from '@mui/icons-material/Edit';

const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach(
    // if we have an error string set valid to false
    (val) => val.length > 0 && (valid = false)
  );
  return valid;
};

class ProcedureEditForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      procedureName: this.props.data.name,
      reagentOptions: this.props.reagentOptions,
      processOptions: this.props.processOptions,
      equipmentOptions: this.props.equipmentOptions,
      procedureEquipment: this.props.procedureEquipment,
      procedureProcess: this.props.procedureProcess,
      procedureComment: this.props.data.additional_info,
      reagents_procedure: [
        {
          reagentId: "",
          reagentValue: "",
          reagentName: "",
          add: false,
        },
      ],
      validationError: {
        procedureName: "",
        procedureReagentName: "",
        procedureReagentValue: "",
        reagents_procedure: [],
      },
      errors: {
        procedureName: "",
        procedureReagentName: "",
        procedureReagentValue: "",
        reagents_procedure: [],
      },
    };
  }

  componentDidMount() {
    console.log(this.props);
    const procedureReagent = this.props.procedureReagent;
    this.setState({
      reagents_procedure: procedureReagent,
    });
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });

    // const { name, value } = e.target;
    // let errors = this.state.errors;
    // console.log(Number(value), Number(this.props.data.amount));
    // switch (name) {
    //   case "sampleAmount":
    //     errors.sampleAmount =
    //       Number(value) > Number(this.props.data.amount)
    //         ? "Wartość nie może być wieksza"
    //         : "";
    //     break;
    //   default:
    //     break;
    // }

    // this.setState({ errors, [name]: value });
    console.log(this.state);
  };

  addFields = () => {
    let object = {
      reagentId: "",
      reagentName: "",
      reagentValue: "",
      add: false,
    };

    this.setState({
      reagents_procedure: [...this.state.reagents_procedure, object],
    });
  };

  removeFields = (index, form) => {
    let data = this.state.reagents_procedure;
    data.splice(index, 1);
    this.setState(data);
  };

  removeAndAddFields = (index) => {
    this.removeFields(index);
    this.addFields(index);
  };

  handleChangeSelect = (selectedOption, name) => {
    this.setState({ [name]: selectedOption });
    console.log(this.state);
  };

  handleFormChange = (event, index) => {
    let data = [...this.state.reagents_procedure];
    data[index][event.target.name] = event.target.value;
    this.setState(data);
    console.log(this.state);
  };

  handleFormChangeSelect = (event, index) => {
    let data = [...this.state.reagents_procedure];
    console.log(event);

    data[index]["reagentId"] = event.value;
    data[index]["reagentName"] = event.label;
    this.setState(data);
  };

  formAction = (e) => {
    let id_procedure = this.props.id_procedure;

    const transformedProcess = this.state.procedureProcess.map(
      (item) => item.value
    );

    const transformedEquipment = this.state.procedureEquipment.map(
      (item) => item.value
    );

    const transformReagents = this.state.reagents_procedure.map((item) => ({
      reagent: item.reagentId,
      reagent_value: item.reagentValue,
    }));

    console.log(transformedEquipment);

    const formData = {
      name: this.state.procedureName,
      process: transformedProcess,
      equipment: transformedEquipment,
      additional_info: this.state.procedureComment,
      creator: `${localStorage.getItem("user_id")}`,
      reagents_procedure: transformReagents,
    };

    console.log(formData);

    const validate = () => {
      let validationError = { ...this.state.errors };

      let hasErrors = false;
      transformReagents.forEach((reagent, index) => {
        if (!reagent.reagent || !reagent.reagent_value) {
          validationError[index] = "Wszystkie pola muszą być wypełnione";
          hasErrors = true;
        } else {
          validationError[index] = "";
        }
      });

      console.log(hasErrors);

      this.setState({ validationError });
      console.log("in validate", validationError);
      return validationError;
    };

    const validationError = validate();
    console.log(this.state.errors);
    if (validateForm(validationError)) {
      console.info("Valid Form");
      this.props.onAuth(formData, id_procedure);
    } else {
      console.error("Invalid Form");
    }
  };

  completeForm = async (e) => {
    e.preventDefault();
    //await this.addAttribute();
    this.formAction();
  };

  render() {
    const { errors } = this.state;

    let errorMessage = null;
    let id_procedure = this.props.id_procedure;

    if (this.props.error) {
      errorMessage = <p>{this.props.error.message}</p>;
    }

    if (this.props.adding == true) {
      this.props.props.history.push(`/procedure/${id_procedure}`);
    }
    console.log(this.props);

    return (
      <>
        {this.props.loading ? (
          <LoadingSpinner />
        ) : (
          <Card>
            <Typography
              variant="h6"
              sx={{ position: "relative", padding: "16px 0 20px 16px" }}
            >
              <span
                style={{
                  content: "",
                  backgroundColor: "#111e6c",
                  position: "absolute",
                  left: "0",
                  top: "16px",
                  width: "4px",
                  height: "25px",
                }}
              ></span>
              Formularz edycji procedury
            </Typography>

            <Divider />
            <form onSubmit={this.completeForm}>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      type="text"
                      label="Nazwa procedury"
                      error={
                        this.state.errors.procedureName.length ? true : false
                      }
                      name="procedureName"
                      value={this.state.procedureName}
                      onChange={this.handleChange}
                      helperText={
                        this.state.errors.procedureName.length > 0
                          ? this.state.errors.procedureName
                          : ""
                      }
                      variant="outlined"
                    />
                  </Grid>

                  {this.state.reagents_procedure.map((form, index) => {
                    return (
                      <>
                        <Grid item md={6} xs={12}>
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            options={this.state.reagentOptions}
                            placeholder="Wybierz odczynnik"
                            styles={SelectStyles}
                            onChange={(event) =>
                              this.handleFormChangeSelect(event, index)
                            }
                            value={{ label: form.reagentName }}
                            name="color"
                          />
                          {this.state.validationError[index] &&
                            this.state.validationError[index].length > 0 && (
                              <FormHelperText error={true}>
                                {this.state.validationError[index]}
                              </FormHelperText>
                            )}
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            type="number"
                            label="Wprowadź wartość"
                            name="reagentValue"
                            onChange={(event) =>
                              this.handleFormChange(event, index)
                            }
                            value={form.reagentValue}
                            variant="outlined"
                          />
                        </Grid>

                        <Grid item md={12} xs={12} align="center">
                          <Button
                            onClick={() => this.removeFields(index, form)}
                            startIcon={
                              <RemoveCircleRoundedIcon
                                style={{ color: "red" }}
                              />
                            }
                          >
                            Usuń
                          </Button>
                        </Grid>
                      </>
                    );
                  })}

                  <Grid item md={12} xs={12} align="center" sx={{ mb: 3 }}>
                    <Button
                      onClick={() => this.addFields()}
                      startIcon={
                        <AddCircleRoundedIcon style={{ color: "green" }} />
                      }
                    >
                      Dodaj nowy atrybut
                    </Button>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <Select
                      placeholder="Wybierz proces"
                      isMulti
                      onChange={(selectedOption) =>
                        this.handleChangeSelect(
                          selectedOption,
                          "procedureProcess"
                        )
                      }
                      value={this.state.procedureProcess}
                      name="procedureProcess"
                      options={this.state.processOptions}
                      styles={SelectStyles}
                      noOptionsMessage={() => "name not found"}
                    ></Select>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Select
                      name="procedureEquipment"
                      placeholder="Wybierz wyposażenie"
                      isMulti
                      onChange={(selectedOption) =>
                        this.handleChangeSelect(
                          selectedOption,
                          "procedureEquipment"
                        )
                      }
                      value={this.state.procedureEquipment}
                      options={this.state.equipmentOptions}
                      styles={SelectStyles}
                      noOptionsMessage={() => "name not found"}
                    ></Select>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      type="text"
                      label="Dodatkowa informacja"
                      name="procedureComment"
                      value={this.state.procedureComment}
                      onChange={this.handleChange}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  p: 2,
                }}
              >
                <Button type="submit" variant="contained" endIcon={<EditIcon />}>
                  Edytuj procedure
                </Button>
              </Box>
            </form>
          </Card>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error,
    adding: state.adding,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (formData, id_procedure) =>
      dispatch(
        actions.requestApi(
          formData,
          api(id_procedure).PROCEDURE_CHANGE_PUT,
          "put"
        )
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProcedureEditForm);
