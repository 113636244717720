import { Card, Box } from "@mui/material";

function ImageCard({ image, title, description, action }) {
  return (
      <Box
        component="img"
        src={image}
        alt={title}
        borderRadius="lg"
        width="100%"
        position="relative"
        zIndex={1}
      />
  );
}

export default ImageCard;
