import React, { useState, useEffect } from "react";
import axios from "axios";
import api from "../../../config/api/apiRoutes";
import { useParams } from "react-router-dom";
import {
  Box,
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
} from "@mui/material";
import { Divider } from "rsuite";
import HomeIcon from "@mui/icons-material/Home";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import ReactFlow from "reactflow";
import "reactflow/dist/style.css";
import DnDFlow from "../../../components/Flow/Flow";

function SampleFlowChart(props) {
  const [sampleNodes, setSampleNodes] = useState(true);
  const [sampleEdges, setSampleEdges] = useState(true);
  const [sampleIdentifier, setSampleIdentifier] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  let { id } = useParams();

  useEffect(() => {
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    const getData = async () => {
      try {
        const getSampleNodes = await axios.get(api(id).FLOW_NODE_LIST);
        const getDataSampleNodes = getSampleNodes.data;

        console.log(getDataSampleNodes);

        const getSampleEdges = await axios.get(api(id).FLOW_EDGE_LIST);
        const getDataSampleEdges = getSampleEdges.data;

        const convertedNodes = getDataSampleNodes.map((item) => ({
          ...item,
          id_node: item.id,
          id: item.checksum,
        }));

        const convertedEdges = getDataSampleEdges.map((item) => ({
          ...item,
          source: item.source.toString(),
          target: item.target.toString(),

        }));

        console.log(convertedEdges)

        setSampleNodes(convertedNodes);
        setSampleEdges(convertedEdges);

        const getSampleIdentifier = await axios.get(api(id).SAMPLE_IDENTIFIER_GET);
        const getDataSampleIdentifier = getSampleIdentifier.data;
        setSampleIdentifier(getDataSampleIdentifier.identifier);


        setIsLoading(false);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      }
    };

    getData();
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Container maxWidth={false}>
      <Typography variant="h5" style={{ fontWeight: "400" }}>
        Flow próbki {sampleIdentifier}
      </Typography>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" to="/" style={{ color: "#888" }}>
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Dashboard
        </Link>
        <Link underline="hover" to="/sample" style={{ color: "#888" }}>
          Lista próbek
        </Link>
        <Link underline="hover" to={`/sample/${id}`} style={{ color: "#888" }}>
          Szczegóły próbki
        </Link>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="#111"
          style={{ fontWeight: "600" }}
        >
          Flow próbki
        </Typography>
      </Breadcrumbs>
      <Divider />
      <Grid container spacing={6} sx={{ mb: 5 }}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent p={6}>
              <div style={{ height: "68vh" }}>
                <DnDFlow sampleNodes={sampleNodes} sampleEdges={sampleEdges} />
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

export default SampleFlowChart;
