import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../services/actions/auth";
import { Link } from "react-router-dom";
import { COLORS } from "../../../assets/color/Colors";
import { withRouter } from "react-router";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Alert from "@mui/material/Alert";
import api from "../../../config/api/apiRoutes";
import axios from "axios";
import {
  Box,
  Container,
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  Grid,
  TextField,
  Tooltip,
  Paper,
} from "@mui/material";
import Logo from "../../Logo";
const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach(
    // if we have an error string set valid to false
    (val) => val.length > 0 && (valid = false)
  );
  return valid;
};

const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      swagger_version: [],
      errors: {
        email: "",
        password: "",
        password2: "",
        phone: "",
      },
    };
  }

  componentDidMount() {}

  onChange = (e) => {
    e.persist();
    this.setState({ [e.target.name]: e.target.value });
    const { name, value } = e.target;
    let errors = this.state.errors;
    switch (name) {
      case "email":
        errors.email = validEmailRegex.test(value)
          ? ""
          : "Niepoprawny adres email";
        break;
      case "password":
        errors.password =
          value.length < 1
            ? "Password must be at least 8 characters long!"
            : "";
        break;
      default:
        break;
    }

    this.setState({ errors, [name]: value });
  };

  onLoginClick = (e) => {
    e.preventDefault();

    const userData = {
      email: this.state.email,
      password: this.state.password,
    };

    const validate = () => {
      let validationError = this.state.errors;
      if (!this.state.email) {
        validationError.email = "Wymagane pole";
      }
      if (!this.state.password) {
        validationError.password = "Wymagane pole";
      }

      this.setState({ validationError, validationError });
      console.log("in validate", validationError);
      return validationError;
    };

    const validationError = validate();
    console.log(this.state.errors);
    if (validateForm(validationError)) {
      console.info("Valid Form");
      this.props.onAuth(userData, this.props);
    } else {
      console.error("Invalid Form");
    }
  };

  render() {
    const { errors } = this.state;
    let errorMessage = null;
    if (this.props.error) {
      errorMessage = <p>{this.props.error.message}</p>;
    }

    return (
      <>
        {this.props.loading ? (
          <LoadingSpinner />
        ) : (
          <Box
            component="main"
            sx={{
              alignItems: "center",
              display: "flex",
              flexGrow: 1,
              minHeight: "100%",
            }}
          >
            <Container maxWidth="sm">
              <Card sx={{ p: 5 }}>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item sx={{ mb: 3 }}>
                    <Logo />
                  </Grid>
                </Grid>

                {errorMessage ? (
                  <Alert severity="error">Błędny adres email lub hasło.</Alert>
                ) : (
                  ""
                )}
                <form onSubmit={this.onLoginClick}>
                  <Box sx={{ my: 3 }}>
                    <Typography color="textPrimary" variant="h4">
                      Zaloguj się
                    </Typography>
                    <Typography
                      color="textSecondary"
                      gutterBottom
                      variant="body2"
                    >
                      Zaloguj się, aby uzyskać dostęp do platformy.
                    </Typography>
                  </Box>

                  <TextField
                    type="email"
                    id="email-input"
                    name="email"
                    fullWidth
                    margin="normal"
                    className={
                      errors.email.length
                        ? "form-control form-control-lg is-invalid"
                        : "form-control form-control-lg"
                    }
                    placeholder="Wprowadź adres email"
                    value={this.state.email}
                    onChange={this.onChange}
                    label="Adres email"
                    variant="outlined"
                    helperText={errors.email.length > 0 ? errors.email : ""}
                    error={errors.email.length ? true : false}
                  />
                  <TextField
                    type="password"
                    name="password"
                    margin="normal"
                    id="password-input"
                    className={
                      errors.email.length
                        ? "form-control form-control-lg is-invalid"
                        : "form-control form-control-lg"
                    }
                    placeholder="Wprowadź adres hasło"
                    value={this.state.password}
                    onChange={this.onChange}
                    label="Hasło"
                    helperText={
                      errors.password.length > 0 ? errors.password : ""
                    }
                    error={errors.password.length ? true : false}
                  />

                  <Box sx={{ py: 2 }}>
                    <Button
                      id="submit-login"
                      color="primary"
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Zaloguj
                    </Button>
                  </Box>
                  <Typography color="textSecondary" variant="body2">
                    Zapomniałeś hasło?{" "}
                    <Link
                      to="/reset-password"
                      variant="subtitle2"
                      underline="hover"
                      sx={{
                        cursor: "pointer",
                      }}
                    >
                      Zresetuj hasło
                    </Link>
                  </Typography>
                </form>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item xs={12} sx={{ mt: 4 }}>
                    <Divider />
                  </Grid>

                  <Grid item xs={12}>
                    <Grid
                      item
                      container
                      direction="column"
                      alignItems="center"
                      xs={12}
                    >
                      <Typography
                        component={Link}
                        to="/rejestracja"
                        variant="subtitle1"
                        sx={{ textDecoration: "none" }}
                      >
                        Nie masz konta?
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Container>
          </Box>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error,
    login_auth: state.login_auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (userData, props) => dispatch(actions.authLogin(userData, props)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LoginForm));
