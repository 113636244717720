import React, { Component } from "react";
import { connect } from "react-redux"; // new import
import * as actions from "../../../services/actions/auth";
import axios from "axios";
import api from "../../../config/api/apiRoutes";
import { FormHelperText } from "@material-ui/core";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import Select from "react-select";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import SelectStyles from "../../select/SelectStyles";
import EditIcon from '@mui/icons-material/Edit';

class SampleFormProcedureEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionsCurrentProcedure: this.props.data,
      optionsListProcedure: this.props.dataProcedure,
      selectProcedure: this.props.data,
    };
  }

  handleProcedureChange = (input) => (e, index) => {
    this.setState({ [input]: e });
  };

  editSampleProceduresForm = (e) => {
    e.preventDefault();
    const procedureId = [];

    this.state.selectProcedure.map((item, index) => {
      procedureId.push(item.value);
    });

    const formData = {
      procedures: procedureId,
    };

    console.log(procedureId);

    this.props.onAuth(formData, this.props.id_sample);
  };

  render() {
    if (this.props.adding == true) {
      this.props.props.history.push(`/sample/${this.props.id_sample}`);
    }
    console.log(this.state);
    return (
      <>
        {this.props.loading ? (
          <LoadingSpinner />
        ) : (
          <Card>
            <Typography
              variant="h6"
              sx={{ position: "relative", padding: "16px 0 20px 16px" }}
            >
              <span
                style={{
                  content: "",
                  backgroundColor: "#111e6c",
                  position: "absolute",
                  left: "0",
                  top: "16px",
                  width: "4px",
                  height: "25px",
                }}
              ></span>
              Formularz edycji procedury próbki
            </Typography>

            <Divider />
            <form onSubmit={this.editSampleProceduresForm}>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <Select
                      styles={SelectStyles}
                      isMulti
                      classNamePrefix="select"
                      menuPortalTarget={document.body}
                      value={this.state.selectProcedure}
                      options={this.state.optionsListProcedure}
                      placeholder="Wybierz procedure"
                      name="selectProcedure"
                      onChange={this.handleProcedureChange("selectProcedure")}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  p: 2,
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  endIcon={<EditIcon />}
                >
                  Edytuj procedure dla próbki
                </Button>
              </Box>
            </form>
          </Card>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error,
    login_auth: state.login_auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (formData, id) =>
      dispatch(
        actions.requestApi(formData, api(id).SAMPLE_PROCEDURES_PUT, "post")
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SampleFormProcedureEdit);
