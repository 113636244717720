import React, { Component } from "react";
import { connect } from "react-redux"; // new import
import * as actions from "../../../services/actions/auth";
import axios from "axios";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import api from "../../../config/api/apiRoutes";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach(
    // if we have an error string set valid to false
    (val) => val.length > 0 && (valid = false)
  );
  return valid;
};

class DiseaseFormAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getDataDisease: [],
      DiseaseICD: "",
      DiseaseName: "",
      errors: {
        DiseaseICD: "",
        DiseaseName: "",
      },
    };
  }

  onChange = (e) => {
    e.persist();
    this.setState({ [e.target.name]: e.target.value });

    const { name, value } = e.target;
    let errors = this.state.errors;
    switch (name) {
      case "DiseaseICD":
        errors.DiseaseICD =
          value.length < 10 ? "" : "Numer ICD powinien mieć max 10 znaków";
        break;
      case "DiseaseName":
        errors.DiseaseName = value.length < 3 ? "Min 3 znaki" : "";
        break;
      default:
        break;
    }

    this.setState({ errors, [name]: value });
  };

  addDiseaseForm = (e) => {
    e.preventDefault();

    const formData = {
      icd: this.state.DiseaseICD,
      name: this.state.DiseaseName,
    };

    const validate = () => {
      let validationError = this.state.errors;
      if (!this.state.DiseaseICD) {
        validationError.DiseaseICD = "Wymagane pole";
      }
      if (!this.state.DiseaseName) {
        validationError.DiseaseName = "Wymagane pole";
      }

      this.setState({ validationError, validationError });
      console.log("in validate", validationError);
      return validationError;
    };

    const validationError = validate();
    console.log(this.state.errors);
    if (validateForm(validationError)) {
      console.info("Valid Form");
      this.props.onAuth(formData);
    } else {
      console.error("Invalid Form");
    }
  };

  render() {
    const { errors } = this.state;
    let errorMessage = null;
    if (this.props.error) {
      errorMessage = <p>{this.props.error.message}</p>;
    }

    if (this.props.adding == true) {
      this.props.props.history.push("/disease");
    }

    return (
      <>
        {this.props.loading ? (
          <LoadingSpinner />
        ) : (
          <Card>
            <Typography
              variant="h6"
              sx={{ position: "relative", padding: "16px 0 20px 16px" }}
            >
              <span
                style={{
                  content: "",
                  backgroundColor: "#111e6c",
                  position: "absolute",
                  left: "0",
                  top: "16px",
                  width: "4px",
                  height: "25px",
                }}
              ></span>
              Dodaj nową chorobę
            </Typography>

            <Divider />
            <form onSubmit={this.addDiseaseForm}>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      type="text"
                      label="Nazwa choroby"
                      error={errors.DiseaseName.length ? true : false}
                      name="DiseaseName"
                      value={this.state.DiseaseName}
                      onChange={this.onChange}
                      helperText={
                        errors.DiseaseName.length > 0 ? errors.DiseaseName : ""
                      }
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      type="text"
                      label="Numer ICD"
                      error={errors.DiseaseICD.length ? true : false}
                      name="DiseaseICD"
                      value={this.state.DiseaseICD}
                      onChange={this.onChange}
                      helperText={
                        errors.DiseaseICD.length > 0 ? errors.DiseaseICD : ""
                      }
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  p: 2,
                }}
              >
                <Button type="submit" variant="contained" color="primary" endIcon={<AddIcon />}>
                  Dodaj chorobę
                </Button>
              </Box>
            </form>
          </Card>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error,
    adding: state.adding,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (formData) =>
      dispatch(actions.requestApi(formData, api().DISEASE_ADD_POST, "post")),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DiseaseFormAdd);
