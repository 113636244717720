import React, { Component } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { connect } from "react-redux"; // new import
import ReagentSamplesTableList from "../../components/Reagents/ReagentsSamplesTableDetails";
import { Container } from "@mui/material";
import { Box, Typography } from "@mui/material";
import api from "../../config/api/apiRoutes";

export class ReagentSamplesList extends Component {
  constructor(props, id) {
    super(props);
    this.state = {
      role: localStorage.getItem("role"),
      currentPage: 1,
      postsPerPage: 6,
      filteredData: [],
      searchingWord: [],
      samplesList: [],
    };
  }

  componentDidMount() {
    let toSliceId = window.location.pathname;
    let id = toSliceId.slice(26);
    this.state.id = id;
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    axios
      .get(api(id).REAGENT_DELIVERY_SAMPLES_GET)
      .then((response) => {
        const samplesList = response.data;
        this.setState({ samplesList });
        console.log(response);
      })
      .catch((error) => {
        toast.error("Błąd");
      });
  }

  render() {
    return (
      <Container maxWidth={false}>
        <Typography sx={{ mt: 4 }} variant="h4" style={{ marginTop: "20px" }}>
          Próbki dla danego odczynnika
        </Typography>
        <Box sx={{ minWidth: 1050 }}>
          <ReagentSamplesTableList />
        </Box>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, null)(ReagentSamplesList);
