import React, { memo } from "react";
import { Handle, Position } from "reactflow";
import { FiCloud } from "react-icons/fi";

export default memo(({ data }) => {
  console.log(data);
  return (
    <>
      <div className="wrapper gradient">
        <div className="inner">
          <div className="body">
            <div>
              <div className="title">{data.title}</div>

              {Array.isArray(data.content) &&
                data.content.map((content, index) => (
                  <div key={index} className="subline">
                    {content}
                  </div>
                ))}
            </div>
          </div>
          <Handle type="target" position={Position.Top} />
          <Handle type="source" position={Position.Bottom} />
        </div>
      </div>
    </>
  );
});
