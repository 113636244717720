import React, { useState, useEffect } from "react";
import axios from "axios";
import api from "../../../config/api/apiRoutes";
import LocalizationEditForm from "../../../components/Localization/Form/LocalizationEditForm";
import { useParams } from "react-router-dom";
import { Box, Container, Grid, Typography } from "@mui/material";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { Divider } from "rsuite";
import HomeIcon from "@mui/icons-material/Home";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";

function LocalizationEdit(props) {
  const [LocalizationDetails, setLocalizationDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  let { id } = useParams();
  console.log(id);

  useEffect(() => {
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    const getData = async () => {
      try {
        const getLocalizationDetails = await axios.get(
          api(id).LOCALIZATION_DETAILS_GET
        );
        const getDataLocalizationDetails = getLocalizationDetails.data;

        console.log(getDataLocalizationDetails);

        setLocalizationDetails(getDataLocalizationDetails);
        setIsLoading(false);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      }
    };

    getData();
  }, []);
  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Container maxWidth={false}>
      <Typography variant="h5" style={{ fontWeight: "400" }}>
        Edytuj lokalizacje
      </Typography>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" to="/" style={{ color: "#888" }}>
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Dashboard
        </Link>
        <Link underline="hover" to="/localization" style={{ color: "#888" }}>
          Lista lokalizacji
        </Link>
        <Link
          underline="hover"
          to={`/localization/${id}`}
          style={{ color: "#888" }}
        >
          Szczegóły lokalizacji
        </Link>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="#111"
          style={{ fontWeight: "600" }}
        >
          Edytuj lokalizacje
        </Typography>
      </Breadcrumbs>
      <Divider />
      <Grid container spacing={3}>
        <Grid item lg={12} md={12} xs={12}>
          <LocalizationEditForm
            data={LocalizationDetails}
            id_process={id}
            props={props}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default LocalizationEdit;
