import TableActionButton from "../../Button/TableActionButton";
import VisibilityIcon from "@mui/icons-material/Visibility";

const intialColumnsUsersList = [
  // {
  //   accessorKey: "id",
  //   header: "ID",
  // },
  {
    accessorKey: "email",
    header: "email",
  },
  {
    accessorKey: "role",
    header: "rola",
  },

  {
    accessorFn: (row) => (
      <TableActionButton
        data={[
          {
            label: "Szczegóły",
            icon: <VisibilityIcon />,
            linkTo: `/users/${row.id}/`,
          },
        ]}
      />
    ),
    header: "Akcja",
    enableColumnFilter: false,
    enableSorting: false,
  },
];

export default intialColumnsUsersList;
