import React, { Component } from "react";
import { connect } from "react-redux"; // new import
import * as actions from "../../services/actions/auth";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Button,
  TextField,
  Typography,
  Container,
} from "@mui/material";
import api from "../../config/api/apiRoutes";
import { Redirect } from "react-router-dom";
import Select from "react-select";
import SelectStyles from "../select/SelectStyles";
import AddIcon from "@mui/icons-material/Add";

class ReagentsProcedureFormAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.id_reagent,
      procedures: props.data,
      selectedProcedure: "",
      procedureReagentValue: 0,
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleaddLocalizationChange = (selectedProcedure) => {
    this.setState({ selectedProcedure });
  };

  addReagentsProcedure = () => {
    const formData = {
      procedure: this.state.selectedProcedure.value,
      reagent: this.state.id,
      reagent_value: this.state.procedureReagentValue,
      creator: this.state.EquipmentCreator,
    };

    let toSliceId = window.location.pathname;
    this.props.onAuth(formData, this.state.id);
  };

  render() {
    const { errors } = this.state;

    if (this.props.adding === true) {
      return <Redirect to={`/reagent/${this.state.id}`} />;
    }

    return (
      <Grid container spacing={3}>
        <Grid item lg={12} md={12} xs={12}>
          {this.props.loading ? (
            <LoadingSpinner />
          ) : (
            <Card>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      options={this.state.procedures}
                      placeholder="Wybierz procedurę"
                      styles={SelectStyles}
                      menuPortalTarget={document.body}
                      onChange={this.handleaddLocalizationChange}
                      name="selectedProcedure"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Ilość odczynnika"
                      name="procedureReagentValue"
                      value={this.state.procedureReagentValue}
                      onChange={this.onChange}
                      variant="outlined"
                      inputProps={{
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                      }}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  p: 3,
                }}
              >
                <Button
                  onClick={this.addReagentsProcedure}
                  variant="contained"
                  endIcon={<AddIcon />}
                >
                  Dodaj procedurę
                </Button>
              </Box>
            </Card>
          )}
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error,
    adding: state.adding,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (formData, id) =>
      dispatch(
        actions.requestApi(formData, api().REAGENT_PROCEDURE_ADD, "post")
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReagentsProcedureFormAdd);
