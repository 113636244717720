import React, { useState } from "react";
import { InputGroup } from "react-bootstrap";

export let ExtraValues = {
  list: [],
};

export function MyForm() {
  const [numFields, setNumFields] = useState(1);
  const [fields, setFields] = useState([{ label: "", value: "" }]);

  const handleAddField = () => {
    setNumFields(numFields + 1);
    setFields([...fields, { label: "", value: "" }]);
  };

  const handleRemoveField = () => {
    if (numFields > 1) {
      setNumFields(numFields - 1);
      setFields(fields.slice(0, -1));
    }
  };

  const handleChange = (event, index) => {
    const { name, value } = event.target;
    const updatedFields = [...fields];
    updatedFields[index][name] = value;
    setFields(updatedFields);
    ExtraValues.list = fields;
  };
  const handleSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <form onSubmit={handleSubmit}>
      {Array(numFields)
        .fill()
        .map((_, i) => (
          <div key={i}>
            <InputGroup className="mb-3">
              <input
                type="text"
                name="label"
                class="form-control form-control-lg"
                placeholder={`Etykieta ${i + 1}`}
                value={fields[i].label}
                onChange={(event) => handleChange(event, i)}
              />
              <input
                type="text"
                name="value"
                class="form-control form-control-lg"
                placeholder={`Wartość ${i + 1}`}
                value={fields[i].value}
                onChange={(event) => handleChange(event, i)}
              />
              <button
                onClick={handleAddField}
                class="btn btn-outline-primary btn-lg"
                size="lg"
              >
                Dodaj pole
              </button>
              <button
                type="button"
                onClick={handleRemoveField}
                class="btn btn-outline-danger btn-lg"
              >
                Usuń pole
              </button>
            </InputGroup>
          </div>
        ))}
    </form>
  );
}
