import { Route, Switch, Redirect } from "react-router-dom";
import Login from "./pages/login/Login";
import Register from "./pages/register/Register";
import PatientList from "./pages/patient/PatientList/PatientList";
import EquipmentList from "./pages/equipment/EquipmentList/EquipmentList";
import EquipmentDetails from "./pages/equipment/EquipmentDetails/EquipmentDetails";
import EquipmentEdit from "./pages/equipment/EquipmentEdit/EquipmentEdit";
import EquipmentAdd from "./pages/equipment/EquipmentAdd/EquipmentAdd";
import PatientAdd from "./pages/patient/PatientAdd/PatientAdd";
import SampleDetails from "./pages/sample/SampleDetails/SampleDetails";
import DeliveryList from "./pages/delivery/DeliveryList/DeliveryList";
import DeliveryDetails from "./pages/delivery/DeliveryDetails";
import DeliveryAdd from "./pages/delivery/DeliveryAdd/DeliveryAdd";
import ProcessList from "./pages/process/ProcessList/ProcessList";
import ProcessAdd from "./pages/process/ProcessAdd/ProcessAdd";
import SampleList from "./pages/sample/SampleList/SampleList";
import ReagentList from "./pages/reagent/ReagentList/ReagentList";
import ReagentAdd from "./pages/reagent/ReagentAdd/ReagentAdd";
import ReagentDetails from "./pages/reagent/ReagentDetails/ReagentDetails";
import ReagentEdit from "./pages/reagent/ReagentEdit/ReagentEdit";
import ProcedureList from "./pages/procedure/ProcedureList/ProcedureList";
import ProcedureDetails from "./pages/procedure/ProcedureDetails/ProcedureDetails";
import SampleProcedureEdit from "./pages/sample/SampleProcedureEdit/SampleProcedureEdit";
import SampleReagentEdit from "./pages/sample/SampleReagentEdit/SampleReagentEdit";
import SampleLocalizationEdit from "./pages/sample/SampleLocalizationEdit/SampleLocalizationEdit";
import LocalizationList from "./pages/localization/LocalizationList/LocalizationList";
import LocalizationDetails from "./pages/localization/LocalizationDetails/LocalizationDetails";
import LocatizationAdd from "./pages/localization/LocalizationAdd/LocalizationAdd";
import DiseaseAdd from "./pages/diseases/DiseaseAdd/DiseaseAdd";
import DiseaseList from "./pages/diseases/DiseaseList/DiseaseList";
import DiseaseEdit from "./pages/diseases/DiseaseEdit/DiseaseEdit";
import BarcodeAdd from "./pages/barcode/BarcodeAdd";
import TestExportTable from "./components/Patient/table/PatientListTable";
import Error from "./pages/error/Error";
import LoadingSpinner from "./components/LoadingSpinner/LoadingSpinner";
import ReagentSamplesList from "./pages/reagent/ReagentSamplesList";
import AddAttributes from "./pages/attributes/AddAttributes/AddAttributes";
import SampleStatusEdit from "./pages/sample/SampleStatus/SampleStatus";
import Calendar from "./pages/calendar/Calendar";
import PatientEdit from "./pages/patient/PatientEdit/PatientEdit";
import ProcedureAdd from "./pages/procedure/ProcedureAdd/ProcedureAdd";
import ProcessEdit from "./pages/process/ProcessEdit/ProcessEdit";
import ResultAdd from "./pages/sample/Result/ResultAdd";
import ReagentsProcedureEdit from "./components/Reagents/ReagentsProcedureEdit";
import ReagentsProcedureDelete from "./components/Reagents/ReagentsProcedureDelete";
import ReagentProcedureAdd from "./pages/reagent/ReagentProcedureAdd/ReagentProcedureAdd";
import ProcedureEquipmentAdd from "./pages/procedure/ProcedureEquipmentAdd/ProcedureEquipmentAdd";
import ProcedureEquipmentDelete from "./components/Procedure/ProcedureEquipmentDelete";
import ProcedureProcessAdd from "./pages/procedure/ProcedureProcessAdd/ProcedureProcessAdd";
import ProcedureProcessDelete from "./components/Procedure/ProcedureProcessDelete";
import ProcedureReagentAdd from "./pages/procedure/ProcedureReagentAdd";
import SampleAdd from "./pages/sample/SampleAdd/SampleAdd";
import AddParameter from "./pages/attributes/addParameter/AddParameter";
import DeliveryEdit from "./pages/delivery/DeliveryEdit/DeliveryEdit";
import AccountDetailChange from "./pages/account/AccountDetailChange/AccountDetailChange";
import PatientTimeline from "./pages/patient/PatientTimeline/PatientTimeline";
import ProcessTimeline from "./pages/process/ProcessTimeline/ProcessTimeline";
import EquipmentTimeline from "./pages/equipment/EquipmentTimeline/EquipmentTimeline";
import SampleTimeline from "./pages/sample/SampleTimeline/SampleTimeline";
import ReagentTimeline from "./pages/reagent/ReagentTimeline/ReagentTimeline";
import LocalizationTimeline from "./pages/localization/LocalizationTimeline/LocalizationTimeline";
import ProcedureTimeline from "./pages/procedure/ProcedureTimeline/ProcedureTimeline";
import DeliveryTimeline from "./pages/delivery/DeliveryTimeline/DeliveryTimeline";
import LocalizationEdit from "./pages/localization/LocalizationEdit/LocalizationEdit";
import SampleAddDate from "./pages/sample/SampleAddDate/SampleAddDate";
import PatientDetails from "./pages/patient/PatientDetails/PatientDetails";
import Dashboard from "./pages/dashboard/Dashboard";
import PasswordMail from "./pages/passwordMail/PasswordMail";
import DeleteEvent from "./components/calendar/DeleteEvent";
import PasswordReset from "./pages/passwordReset/PasswordReset";
import SampleEdit from "./pages/sample/SampleEdit/SampleEdit";
import UsersList from "./pages/users/UsersList/UsersList";
import UsersDetails from "./pages/users/UsersDetails/UsersDetails";
import ProcedureEdit from "./pages/procedure/ProcedureEdit/ProcedureEdit";
import SampleTreeList from "./pages/sample/SampleTreeList/SampleTreeList";
import SampleFlowChart from "./pages/sample/SampleFlowChart/SampleFlowChart";
import SampleBarcode from "./pages/sample/SampleBarcode/SampleBarcode";
import Report from "./pages/report/Report";
import PatientBarcode from "./pages/patient/PatientBarcode/PatientBarcode";
import OtpVerify from "./pages/otp/OtpVerify";
import OtpValidate from "./pages/otp/OtpValidate";
import ShopHomePage from "./pages/shop/ShopHomePage";
import ShopProcessing from "./pages/shop/ShopProcessing";
import { ErrorSharp } from "@material-ui/icons";
import ErrorPage from "./pages/shop/ErrorPage";
import SucessPage from "./pages/shop/SucessPage";
import HomePage from "./pages/homePage/HomePage";
import SampleTreeEdit from "./pages/sample/SampleTreeEdit";
import PatientFiles from "./pages/patient/PatientFiles";
import supportCenterPage from "./pages/supportCenter/supportCenterPage";
import NewsPage from "./pages/News/NewsPage";
import CooperationPage from "./pages/cooperation/CooperationPage";
import PrivacyPolicy from "./pages/privacypolicyPage/privacypolictPage";
import CookiePolicy from "./pages/privacypolicyPage/cookiePolicyPage";
import AttributeList from "./pages/attributes/AttributeList/AttributeList";
import AttributeEdit from "./pages/attributes/AttributeEdit/AttributeEdit";

function BaseRouter(props) {
  function PrivateRoute({ children, ...rest }) {
    if (props.loading) return <LoadingSpinner />;

    if (props.token_auth === true) {
      return <Route {...rest}>{children}</Route>;
    }
    return <Redirect to="/logowanie" />;
  }

  const isAuthenticated = props.isAuthenticated;

  console.log(isAuthenticated);

  return (
    <Switch>
      <PrivateRoute exact path="/" component={Dashboard} />
      <PrivateRoute exact path="/dashboard" component={Dashboard} />
      <Route exact path="/logowanie" component={Login} />
      <Route path="/otp-verify/" component={OtpVerify} />
      <Route path="/otp-validate/" component={OtpValidate} />
      {/* <Route exact path="/shop" component={ShopHomePage} />
      <Route exact path="/shop/processing" component={ShopProcessing} />
      <Route exact path="/shop/processing/error" component={ErrorPage} />
      <Route exact path="/shop/processing/success" component={SucessPage} />
      <Route exact path="/support-center" component={supportCenterPage} />
      <Route exact path="/news" component={NewsPage} />
      <Route exact path="/cooperation" component={CooperationPage} />
      <Route exact path="/privacy-policy" component={PrivacyPolicy} />
      <Route exact path="/cookie-policy" component={CookiePolicy} /> */}
      <Route path="/rejestracja" component={Register} />
      <PrivateRoute
        exact
        path="/account-change"
        component={AccountDetailChange}
      />
      <PrivateRoute exact path="/patient" component={PatientList} />
      <PrivateRoute exact path="/equipment" component={EquipmentList} />
      <PrivateRoute exact path="/equipment/:id" component={EquipmentDetails} />
      <PrivateRoute
        exact
        path="/procedure/:id/equipment/add"
        component={ProcedureEquipmentAdd}
      />
      <PrivateRoute
        exact
        path="/procedure/equipment/delete"
        component={ProcedureEquipmentDelete}
      />
      <PrivateRoute
        exact
        path="/equipment/edit/:id"
        component={EquipmentEdit}
      />
      <PrivateRoute exact path="/new-equipment" component={EquipmentAdd} />
      <PrivateRoute exact path="/process" component={ProcessList} />
      <PrivateRoute exact path="/process-add" component={ProcessAdd} />
      <PrivateRoute exact path="/process/:id/edit" component={ProcessEdit} />
      <PrivateRoute
        exact
        path="/process/:id/timeline"
        component={ProcessTimeline}
      />
      <PrivateRoute
        exact
        path="/procedure/:id/process/add"
        component={ProcedureProcessAdd}
      />
      <PrivateRoute
        exact
        path="/procedure/process/delete"
        component={ProcedureProcessDelete}
      />
      <PrivateRoute exact path="/sample-hierarchy" component={SampleTreeList} />
      <PrivateRoute exact path="/sample" component={SampleList} />
      <PrivateRoute exact path="/sample/archives" component={SampleList} />
      <PrivateRoute exact path="/sample-add" component={SampleAdd} />
      <PrivateRoute exact path="/sample/:id" component={SampleDetails} />
      <PrivateRoute exact path="/sample/:id/date" component={SampleAddDate} />
      <PrivateRoute exact path="/sample/:id/edit" component={SampleEdit} />
      <PrivateRoute
        exact
        path="/sample/:id/tree-edit"
        component={SampleTreeEdit}
      />
      <PrivateRoute
        exact
        path="/sample/:id/barcode"
        component={SampleBarcode}
      />
      <PrivateRoute exact path="/procedure" component={ProcedureList} />
      <PrivateRoute exact path="/procedure/:id" component={ProcedureDetails} />
      <PrivateRoute
        exact
        path="/procedure/:id/timeline"
        component={ProcedureTimeline}
      />
      <PrivateRoute exact path="/localization" component={LocalizationList} />
      <PrivateRoute
        exact
        path="/localization-add"
        component={LocatizationAdd}
      />
      <PrivateRoute exact path="/patient" component={PatientList} />
      <PrivateRoute exact path="/patient/:id" component={PatientDetails} />
      <PrivateRoute exact path="/patient/:id/edit" component={PatientEdit} />
      <PrivateRoute
        exact
        path="/patient/:id/timeline"
        component={PatientTimeline}
      />
      <PrivateRoute
        exact
        path="/patient/:id/barcode"
        component={PatientBarcode}
      />
      <PrivateRoute exact path="/patient/:id/files" component={PatientFiles} />
      <PrivateRoute path="/patient-add" component={PatientAdd} />
      <PrivateRoute exact path="/equipment" component={EquipmentList} />
      <PrivateRoute exact path="/equipment/:id" component={EquipmentDetails} />
      <PrivateRoute
        exact
        path="/equipment/:id/edit"
        component={EquipmentEdit}
      />
      <PrivateRoute
        exact
        path="/equipment/:id/timeline"
        component={EquipmentTimeline}
      />
      <PrivateRoute exact path="/delivery" component={DeliveryList} />
      <PrivateRoute exact path="/delivery/:id" component={DeliveryDetails} />
      <PrivateRoute
        exact
        path="/delivery/:id/timeline"
        component={DeliveryTimeline}
      />
      <PrivateRoute exact path="/delivery/:id/edit" component={DeliveryEdit} />
      <PrivateRoute exact path="/delivery-add" component={DeliveryAdd} />
      <PrivateRoute exact path="/delivery-add/:id" component={DeliveryAdd} />
      <PrivateRoute exact path="/sample" component={SampleList} />
      <PrivateRoute exact path="/sample-add/:id" component={SampleAdd} />
      <PrivateRoute
        exact
        path="/sample/:id/timeline"
        component={SampleTimeline}
      />
      <PrivateRoute exact path="/sample/:id/result" component={ResultAdd} />
      <PrivateRoute
        exact
        path="/sample/:id/procedures/edit"
        component={SampleProcedureEdit}
      />
      <PrivateRoute
        exact
        path="/sample/procedures/reagents/edit/:id"
        component={SampleReagentEdit}
      />
      <PrivateRoute
        exact
        path="/sample/:id/localization/edit"
        component={SampleLocalizationEdit}
      />
      <PrivateRoute
        exact
        path="/sample/:id/status"
        component={SampleStatusEdit}
      />
      <PrivateRoute exact path="/reagent" component={ReagentList} />
      <PrivateRoute exact path="/reagent-add" component={ReagentAdd} />
      <PrivateRoute exact path="/reagent/:id" component={ReagentDetails} />
      <PrivateRoute
        exact
        path="/reagent/:id/timeline"
        component={ReagentTimeline}
      />
      <PrivateRoute exact path="/reagent/:id/edit" component={ReagentEdit} />
      <PrivateRoute
        exact
        path="/procedure/reagent/add/:id"
        component={ProcedureReagentAdd}
      />
      <PrivateRoute
        exact
        path="/reagent/:id/procedure/add"
        component={ReagentProcedureAdd}
      />
      <PrivateRoute
        exact
        path="/procedure-reagent/update/"
        component={ReagentsProcedureEdit}
      />
      <PrivateRoute
        exact
        path="/procedure-reagent/delete/"
        component={ReagentsProcedureDelete}
      />
      <PrivateRoute
        exact
        path="/reagent/delivery/samples/:id"
        component={ReagentSamplesList}
      />
      <PrivateRoute exact path="/procedure-add" component={ProcedureAdd} />
      <PrivateRoute exact path="/procedure" component={ProcedureList} />
      <PrivateRoute exact path="/procedure/:id" component={ProcedureDetails} />
      <PrivateRoute
        exact
        path="/procedure/:id/edit"
        component={ProcedureEdit}
      />
      <PrivateRoute exact path="/disease-add" component={DiseaseAdd} />
      <PrivateRoute exact path="/disease" component={DiseaseList} />
      <PrivateRoute exact path="/disease/:icd" component={DiseaseEdit} />
      <PrivateRoute
        exact
        path="/localization/:id"
        component={LocalizationDetails}
      />
      <PrivateRoute
        exact
        path="/localization/:id/timeline"
        component={LocalizationTimeline}
      />
      <PrivateRoute
        exact
        path="/localization/:id/edit"
        component={LocalizationEdit}
      />
      <PrivateRoute exact path="/attributes-add" component={AddAttributes} />
      <PrivateRoute exact path="/attribute" component={AttributeList} />
      <PrivateRoute
        exact
        path="/attribute/:id/edit"
        component={AttributeEdit}
      />

      <PrivateRoute exact path="/test-table" component={TestExportTable} />
      <PrivateRoute exact path="/calendar" component={Calendar} />
      <PrivateRoute exact path="/event/delete" component={DeleteEvent} />
      <PrivateRoute exact path="/users" component={UsersList} />
      <PrivateRoute exact path="/users/:id" component={UsersDetails} />
      <PrivateRoute
        exact
        path="/result-parameter-add"
        component={AddParameter}
      />
      <Route exact path="/reset-password" component={PasswordMail} />
      <Route
        exact
        path="/reset-password/:uidb64/:token"
        component={PasswordReset}
      />
      <PrivateRoute exact path="/sample/:id/flow" component={SampleFlowChart} />
      <PrivateRoute exact path="/report" component={Report} />
      <Route path="*" component={Error} />
    </Switch>
  );
}

export default BaseRouter;
