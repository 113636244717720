import React from "react";
import TextField from "@mui/material/TextField";
import { Button, Grid } from "@mui/material";
import { Divider } from "rsuite";
import DownloadButton from "../Action/DownloadButton";

export default ({
  isSelected,
  textRef,
  nodeName,
  setNodeName,
  onSave,
  onDelete,
}) => {
  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.effectAllowed = "move";
  };

  console.log(nodeName);

  return (
    <aside>
      {isSelected ? (
        <>
          <Grid container spacing={3} sx={{ mb: 5 }}>
            <Grid item lg={12} md={12} xs={12}>
              <TextField
                id="outlined-multiline-static"
                label="Multiline"
                multiline
                rows={4}
                fullWidth
                value={nodeName}
                onChange={(evt) => setNodeName(evt.target.value)}
              />
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              <Button
                color="error"
                fullWidth
                variant="contained"
                onClick={onDelete}
              >
                Usuń obiekt
              </Button>
            </Grid>
          </Grid>

          {/* <textarea
            ref={textRef}
            value={nodeName}
            onChange={(evt) => setNodeName(evt.target.value)}
          /> */}
        </>
      ) : (
        <>
          <Grid container spacing={3} sx={{ mb: 5 }}>
            <Grid item lg={12} md={12} xs={12}>
              <div className="description">
                Wybierz obiekt i przenieś na pole robocze. Następnie stwórz
                połączenie między informacjami.
              </div>
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              {/* <Button
                fullWidth
                onDragStart={(event) => onDragStart(event, "input")}
                draggable
                style={{
                  borderWidth: "1px",
                  borderStyle: "solid",
                  borderColor: "#0041d0",
                  borderRadius: "3px",
                }}
              >
                Input Node
              </Button> */}
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              <Button
                fullWidth
                onDragStart={(event) => onDragStart(event, "default")}
                draggable
                style={{
                  borderWidth: "1px",
                  borderStyle: "solid",
                  borderColor: "#1a192b",
                  borderRadius: "3px",
                }}
              >
                Dodatkowe informacje
              </Button>
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              {/* <Button
                onDragStart={(event) => onDragStart(event, "output")}
                draggable
                fullWidth
                style={{
                  borderWidth: "1px",
                  borderStyle: "solid",
                  borderColor: "#ff0072",
                  borderRadius: "3px",
                }}
              >
                Output Node
              </Button> */}
            </Grid>
          </Grid>
          <Divider sx={{ m: 2 }} />
          <Grid container spacing={3} sx={{ mb: 3 }}>
            <Grid item lg={12} md={12} xs={12}>
              <Button
                color="primary"
                fullWidth
                variant="contained"
                onClick={onSave}
              >
                Zapisz obszar
              </Button>
            </Grid>
          </Grid>

          <DownloadButton />
        </>
      )}
    </aside>
  );
};
