const pageSize = {
  "4A0 1682 x 2378 mm": [4767.87, 6740.79],
  "2A0 1189 x 1682 mm": [3370.39, 4767.87],
  "A0 841 x 1189 mm": [2383.94, 3370.39],
  "A1 594 x 841 mm": [1683.78, 2383.94],
  "A2 420 x 594 mm": [1190.55, 1683.78],
  "A3 297 x 420 mm": [841.89, 1190.55],
  "A4 210 x 297 mm": [595.28, 841.89],
  "A5 148 x 210 mm": [419.53, 595.28],
  "A6 105 x 148 mm": [297.64, 419.53],
  "A7 74 x 105 mm": [209.76, 297.64],
  "A8 52 x 74 mm": [147.4, 209.76],
  "A9 37 x 52 mm": [104.88, 147.4],
  "A10 26 x 37 mm": [73.7, 104.88],
  "B0 1000 x 1414 mm": [2834.65, 4008.19],
  "B1 707 x 1000 mm": [2004.09, 2834.65],
  "B2 500 x 707 mm": [1417.32, 2004.09],
  "B3 353 x 500 mm": [1000.63, 1417.32],
  "B4 250 x 353 mm": [708.66, 1000.63],
  "B5 176 x 250 mm": [498.9, 708.66],
  "B6 125 x 176 mm": [354.33, 498.9],
  "B7 88 x 125 mm": [249.45, 354.33],
  "B8 62 x 88 mm": [175.75, 249.45],
  "B9 44 x 62 mm": [124.72, 175.75],
  "B10 31 x 44 mm": [87.87, 124.72],
  "C0 917 x 1297 mm": [2599.37, 3676.54],
  "C1 648 x 917 mm": [1836.85, 2599.37],
  "C2 458 x 648 mm": [1298.27, 1836.85],
  "C3 324 x 458 mm": [918.43, 1298.27],
  "C4 229 x 324 mm": [649.13, 918.43],
  "C5 162 x 229 mm": [459.21, 649.13],
  "C6 114 x 162 mm": [323.15, 459.21],
  "C7 81 x 114 mm": [229.61, 323.15],
  "C8 57 x 81 mm": [161.57, 229.61],
  "C9 40 x 57 mm": [113.39, 161.57],
  "C10 28 x 40 mm": [79.37, 113.39],
  "RA0 860 x 1220 mm": [2437.8, 3458.27],
  "RA1 610 x 860 mm": [1729.13, 2437.8],
  "RA2 430 x 610 mm": [1218.9, 1729.13],
  "RA3 305 x 430 mm": [864.57, 1218.9],
  "RA4 215 x 305 mm": [609.45, 864.57],
  "SRA0 900 x 1280 mm": [2551.18, 3628.35],
  "SRA1 640 x 900 mm": [1814.17, 2551.18],
  "SRA2 450 x 640 mm": [1275.59, 1814.17],
  "SRA3 320 x 450 mm": [907.09, 1275.59],
  "SRA4 225 x 320 mm": [637.8, 907.09],
  "EXECUTIVE 7.25 x 10.5 in": [185.42, 266.7],
  "FOLIO 8.5 x 13 in": [215.9, 330.2],
  "LEGAL 8.5 x 14 in": [215.9, 355.6],
  "LETTER 8.5 x 11 in": [215.9, 279.4],
  "TABLOID 11 x 17 in": [279.4, 431.8],
  "ID1 85.6 x 53.98 mm": [153, 243],
};

export default pageSize;
