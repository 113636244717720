import React, { Component } from "react";
import { connect } from "react-redux"; // new import
import * as actions from "../../../services/actions/auth";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Button,
  Typography,
  TextField,
} from "@mui/material";
import api from "../../../config/api/apiRoutes";

class AccountFilterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sampleToCheck: this.props.data.sample_to_check,
      sampleNoLocalization: this.props.data.sample_no_localization,
      sampleNoResult: this.props.data.sample_no_result,
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    console.log(this.state);
  };

  addEquipmentForm = (e) => {
    e.preventDefault();
    const formData = {
      sample_to_check: this.state.sampleToCheck,
      sample_no_localization: this.state.sampleNoLocalization,
      sample_no_result: this.state.sampleNoResult,
    };

    console.log(formData);

    this.props.onAuth(formData);
  };

  render() {
    if (this.props.adding == true) {
      this.props.props.history.push("/");
    }
    console.log(this.props);

    return (
      <>
        {this.props.loading ? (
          <LoadingSpinner />
        ) : (
          <Card>
            <Typography
              variant="h6"
              sx={{ position: "relative", padding: "16px 0 20px 16px" }}
            >
              <span
                style={{
                  content: "",
                  backgroundColor: "#111e6c",
                  position: "absolute",
                  left: "0",
                  top: "16px",
                  width: "4px",
                  height: "25px",
                }}
              ></span>
              Dashboard filtr
            </Typography>

            <Divider />
            <form onSubmit={this.addEquipmentForm}>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      type="number"
                      name="sampleToCheck"
                      label="sampleToCheck"
                      variant="outlined"
                      value={this.state.sampleToCheck}
                      onChange={this.onChange}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      type="number"
                      name="sampleNoLocalization"
                      label="sampleNoLocalization"
                      variant="outlined"
                      value={this.state.sampleNoLocalization}
                      onChange={this.onChange}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      type="number"
                      name="sampleNoResult"
                      label="sampleNoResult"
                      variant="outlined"
                      value={this.state.sampleNoResult}
                      onChange={this.onChange}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  p: 3,
                }}
              >
                <Button type="submit" variant="contained">
                  Zmień dane
                </Button>
              </Box>
            </form>
          </Card>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error,
    adding: state.adding,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (formData) =>
      dispatch(actions.requestApi(formData, api().DASHBOARD_FILTER_PUT, "put")),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountFilterForm);
