import TableActionButton from "../../Button/TableActionButton";
import VisibilityIcon from "@mui/icons-material/Visibility";

const renderCustomColumn = (row) => {
  //console.log(level);
  return (
    <b>
      <span style={{ color: "#fa5252" }}>{row.substring(0, 7)}</span>
      <span style={{ color: "#04a9f5" }}>{row.substring(7, 10)}</span>
      <span style={{ color: "#111e6c" }}>{row.substring(10, 13)}</span>
      <span style={{ color: "gray" }}>{row.substring(13)}</span>
    </b>
  );
};

const intialColumnsLocalizationSamples = [
  {
    accessorKey: "identifier",
    header: "Identyfikator",
    accessorFn: (originalRow) => originalRow.identifier,
    Cell: ({ cell }) => renderCustomColumn(cell.getValue()),
  },
  {
    accessorKey: "sample",
    header: "Próbka",
  },
  {
    accessorKey: "real_amount",
    header: "Rzeczywista ilość",
  },
  {
    accessorFn: (row) => (
      <TableActionButton
        data={[
          {
            label: "Szczegóły",
            icon: <VisibilityIcon />,
            linkTo: `/sample/${row.sample}`,
          },
        ]}
      />
    ),
    header: "Akcja",
    enableColumnFilter: false,
    enableSorting: false,
  },
];

export default intialColumnsLocalizationSamples;
