/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props

// @mui material components
import Icon from "@mui/material/Icon";
import MuiLink from "@mui/material/Link";

import { Box, Typography } from "@mui/material";

function FilledInfoCard({ icon, title, description, action, subtitle }) {

  const subtitleStyles = {
    fontSize: "1rem",
    fontWeight: "300",
    lineHeight: "1.6",
    display: "block",
    opacity: "1",
    textTransform: "none",
    verticalAlign: "unset",
    textDecoration: "none",
    color: "#0C3A6A",
    letterSpacing: "-0.125px",
  }

  const descriptionStyles = {
    fontSize: "1rem",
    fontWeight: "300",
    lineHeight: "1.6",
    display: "block",
    opacity: "1",
    textTransform: "none",
    verticalAlign: "unset",
    textDecoration: "none",
    color: "rgb(123, 128, 154)",
    letterSpacing: "-0.125px",
  }

  const buttonStyles = {
    width: "max-content",
    display: "flex",
    alignItems: "center",
    color: "rgb(17, 30, 108)",
    fontWeight: "bold",
    textDecoration: "none",

    "& .material-icons-round": {
      fontSize: "1.125rem",
      transform: `translateX(3px)`,
      transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
    },

    "&:hover .material-icons-round, &:focus .material-icons-round": {
      transform: `translateX(6px)`,
    },
  };

  return (
    <Box
      display={{ xs: "block", md: "flex" }}
      sx={{
        background: "rgb(248, 249, 250)",
        borderRadius: "0.75rem",
        boxShadow: 3,
      }}
      pt={3.5}
      pb={3}
      px={3}
    >
      <Typography display="block" variant="h3" mt={-0.625}>
        {icon}
      </Typography>
      <Box pt={{ xs: 3, md: 0 }} pl={{ xs: 0, md: 2 }} lineHeight={1}>
        <Typography display="block" variant="body2" mb={1} sx={subtitleStyles}>
          {subtitle}
        </Typography>

        <Typography
          display="block"
          fontWeight="bold"
          mb={1}
          style={{ letterSpacing: "-0.125px", fontSize: "20px" }}
        >
          {title}
        </Typography>
        <Typography display="block" variant="body2" mb={2} sx={descriptionStyles}>
          {description}
        </Typography>
        <Typography
          component={MuiLink}
          href={action.route}
          target="_blank"
          rel="noreferrer"
          variant="body2"
          fontWeight="regular"
          sx={buttonStyles}
        >
          {action.label}
        </Typography>
      </Box>
    </Box>
  );
}

export default FilledInfoCard;
