import React from "react";
import { Box, Container, Typography, Grid } from "@mui/material";
import ReagentFormAdd from "../../../components/Reagents/Form/ReagentFormAdd";
import { Divider } from "rsuite";
import HomeIcon from "@mui/icons-material/Home";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";

function ReagentAdd(props) {
  return (
    <Container maxWidth={false}>
      <Typography variant="h5" style={{ fontWeight: "400" }}>
        Dodaj odczynnik
      </Typography>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" to="/" style={{ color: "#888" }}>
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Dashboard
        </Link>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="#111"
          style={{ fontWeight: "600" }}
        >
          Dodaj odczynnik
        </Typography>
      </Breadcrumbs>
      <Divider />
      <Grid container spacing={3}>
        <Grid item lg={12} md={12} xs={12}>
          <ReagentFormAdd props={props} />
        </Grid>
      </Grid>
    </Container>
  );
}

export default ReagentAdd;
