import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container } from "@mui/material";
import api from "../../../config/api/apiRoutes";
import { Link } from "react-router-dom";
import CoreTable from "../../../components/Table/CoreTable";
import TreeTableExport from "../../../components/Table/TreeTableExport";
import intialColumnsLocalizationDetails from "../../../components/Localization/Table/intialColumnsLocalizationDetails";
import intialColumnsLocalizationChildren from "../../../components/Localization/Table/intialColumnsLocalizationChildren";
import BasicTable from "../../../components/Table/BasicTable";
import intialColumnsLocalizationSamples from "../../../components/Localization/Table/intialColumnsLocalizationSamples";
import { useParams } from "react-router-dom";
import { Box, Card, Typography, Grid } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Stack from "@mui/material/Stack";
import TableActionButton from "../../../components/Button/TableActionButton";
import EditIcon from "@mui/icons-material/Edit";
import TimelineIcon from "@mui/icons-material/Timeline";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const LocalizationDetails = () => {
  const [LocalizationDetails, setLocalizationDetails] = useState([]);
  const [LocalizationChildren, setLocalizationChildren] = useState([]);
  const [LocalizationSamples, setLocalizationSamples] = useState([]);
  const [value, setValue] = React.useState(0);

  const [isLoading, setIsLoading] = useState(true);
  let { id } = useParams();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    const getData = async () => {
      try {
        const getLocalizationDetails = await axios.get(
          api(id).LOCALIZATION_DETAILS_GET
        );
        const getDataLocalizationDetails = getLocalizationDetails.data;

        const getLocalizationChildren = await axios.get(
          api(id).LOCALIZATION_CHILDREN_GET
        );
        const getDataLocalizationChildren =
          getLocalizationChildren.data.children;
        console.log(getDataLocalizationChildren);

        const getLocalizationSamples = await axios.get(
          api(id).LOCALIZATION_SAMPLES_GET
        );
        const getDataLocalizationSamples = getLocalizationSamples.data;

        setLocalizationDetails(getDataLocalizationDetails);
        setLocalizationChildren(getDataLocalizationChildren);
        setLocalizationSamples(getDataLocalizationSamples);
        setIsLoading(false);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      }
    };

    getData();
  }, []);

  return (
    <Container maxWidth={false}>
      <Typography variant="h5" style={{ fontWeight: "400" }}>
        Lista lokalizacji
      </Typography>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" to="/" style={{ color: "#888" }}>
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Dashboard
        </Link>
        <Link underline="hover" to="/localization" style={{ color: "#888" }}>
          Lista lokalizacji
        </Link>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="#111"
          style={{ fontWeight: "600" }}
        >
          Szczegóły lokalizacji
        </Typography>
      </Breadcrumbs>
      <Box sx={{ width: "100%", mt: 2 }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Główne informacje" {...a11yProps(0)} />
            <Tab label="Lokalizacje podrzędne" {...a11yProps(1)} />
            <Tab label="Przypisane próbki" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            spacing={2}
          >
            <TableActionButton
              data={[
                {
                  label: "Timeline",
                  icon: <TimelineIcon />,
                  linkTo: `/localization/${id}/timeline`,
                },
                {
                  label: "Edytuj lokalizacje",
                  icon: <EditIcon />,
                  linkTo: `/localization/${id}/edit`,
                },
              ]}
            />
          </Stack>
          <Grid container spacing={6} sx={{ mt: 1 }}>
            <Grid item xs={12} lg={12}>
              <CoreTable
                data={[LocalizationDetails]}
                isLoading={isLoading}
                intialColumns={intialColumnsLocalizationDetails}
                headerTable={"Główne informacje lokalizacji"}
              />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid container spacing={6} sx={{ mt: 1 }}>
            <Grid item xs={12} lg={12}>
              <TreeTableExport
                data={LocalizationChildren}
                isLoading={isLoading}
                intialColumns={intialColumnsLocalizationChildren}
                headerTable={"Lista lokalizacji podrzędnych"}
              />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Grid container spacing={6} sx={{ mt: 1 }}>
            <Grid item xs={12} lg={12}>
              <BasicTable
                data={LocalizationSamples}
                isLoading={isLoading}
                intialColumns={intialColumnsLocalizationSamples}
                headerTable={"Lista przypisanych próbek"}
              />
            </Grid>
          </Grid>
        </TabPanel>
      </Box>
    </Container>
  );
};

export default LocalizationDetails;
