import React, { useEffect, useState } from "react";
import { Box, Button, Card, Container, Typography, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import axios from "axios";
import api from "../../../config/api/apiRoutes";
import { useParams } from "react-router-dom";
import CoreTable from "../../../components/Table/CoreTable";
import intialColumnsReagentDetails from "../../../components/Reagents/Table/intialColumnsReagentDetails";
import CoreTableFilter from "../../../components/Table/CoreTableFilter";
import intialColumnsReagentDelivery from "../../../components/Reagents/Table/intialColumnsReagentDelivery";
import intialColumnsReagentProcedure from "../../../components/Reagents/Table/intialColumnsReagentProcedure";
import { Divider } from "rsuite";
import HomeIcon from "@mui/icons-material/Home";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Stack from "@mui/material/Stack";
import TableActionButton from "../../../components/Button/TableActionButton";
import EditIcon from "@mui/icons-material/Edit";
import TimelineIcon from "@mui/icons-material/Timeline";
import AddIcon from "@mui/icons-material/Add";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function ReagentDetails(props) {
  const [reagentDetails, setReagentDetails] = useState([]);
  const [reagentDelivery, setReagentDelivery] = useState([]);
  const [reagentProcedure, setReagentProcedure] = useState([]);
  const [value, setValue] = React.useState(0);

  const [isLoading, setIsLoading] = useState(true);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let { id } = useParams();

  useEffect(() => {
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    const getData = async () => {
      try {
        const getReagentDetails = await axios.get(api(id).REAGENT_DETAIL_GET);
        const getDataReagentDetails = getReagentDetails.data;
        const getReagentDelivery = await axios.get(
          api(id).REAGENT_DELIVERY_GET
        );
        const getDataReagentDelivery = getReagentDelivery.data;
        const getReagentProcedure = await axios.get(
          api(id).REAGENT_PROCEDURE_GET
        );
        const getDataReagentProcedure = getReagentProcedure.data;

        console.log(getDataReagentDetails);
        setReagentDetails(getDataReagentDetails);
        setReagentDelivery(getDataReagentDelivery);
        setReagentProcedure(getDataReagentProcedure);

        setIsLoading(false);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      }
    };

    getData();
  }, []);
  return (
    <Container maxWidth={false}>
      <Typography variant="h5" style={{ fontWeight: "400" }}>
        Szczegóły odczynnika {reagentDetails.id}
      </Typography>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" to="/" style={{ color: "#888" }}>
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Dashboard
        </Link>
        <Link underline="hover" to="/reagent" style={{ color: "#888" }}>
          Lista odczynników
        </Link>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="#111"
          style={{ fontWeight: "600" }}
        >
          Szczegóły odczynnika
        </Typography>
      </Breadcrumbs>

      <Box sx={{ width: "100%", mt: 2 }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Główne informacje" {...a11yProps(0)} />
            <Tab label="Lista dostaw" {...a11yProps(1)} />
            <Tab label="Lista procedur" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            spacing={2}
          >
            <TableActionButton
              data={[
                {
                  label: "Timeline",
                  icon: <TimelineIcon />,
                  linkTo: `${id}/timeline/`,
                },
                {
                  label: "Edytuj odczynnik",
                  icon: <EditIcon />,
                  linkTo: `${id}/edit/`,
                },
              ]}
            />
          </Stack>
          <Grid container spacing={6} sx={{ mt: 1 }}>
            <Grid item xs={12} lg={12}>
              <CoreTable
                data={[reagentDetails]}
                isLoading={isLoading}
                intialColumns={intialColumnsReagentDetails}
                headerTable={"Główne informacje odczynnika"}
              />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            spacing={2}
          >
            <TableActionButton
              data={[
                {
                  label: "Dodaj dostawe odczynnika",
                  icon: <AddIcon />,
                  linkTo: `/delivery-add/?reagent_id=${id}`,
                },
              ]}
            />
          </Stack>
          <Grid container spacing={6} sx={{ mt: 1 }}>
            <Grid item xs={12} lg={12}>
              <CoreTableFilter
                data={reagentDelivery}
                isLoading={isLoading}
                intialColumns={intialColumnsReagentDelivery}
                headerTable={"Lista dostaw odczynnika"}
              />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            spacing={2}
          >
            <TableActionButton
              data={[
                {
                  label: " Dodaj procedurę dla odczynnika",
                  icon: <AddIcon />,
                  linkTo: `/reagent/${id}/procedure/add/`,
                },
              ]}
            />
          </Stack>
          <Grid container spacing={6} sx={{ mt: 1 }}>
            <Grid item xs={12} lg={12}>
              <CoreTableFilter
                data={reagentProcedure}
                isLoading={isLoading}
                intialColumns={intialColumnsReagentProcedure}
                headerTable={"Lista procedur"}
              />
            </Grid>
          </Grid>
        </TabPanel>
      </Box>
    </Container>
  );
}

export default ReagentDetails;
