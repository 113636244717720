// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { Box, Typography } from "@mui/material";
import DefaultReviewCard from "../mui/cards/DefaultReviewCard";
import demo1 from "../../assets/images/sample-register.PNG";
import demo2 from "../../assets/images/generate-raport.PNG";
import demo3 from "../../assets/images/folow-samples.PNG";
import bgImage from "../../assets/images/background.png";
import { Button, Stack } from "@mui/material";

function Information() {
  const titleStyles = {
    fontSize: "2rem",
    fontWeight: "500",
    lineHeight: "1.375",
    display: "block",
    opacity: "1",
    textTransform: "none",
    textAlign: "center",
    verticalAlign: "unset",
    textDecoration: "none",
    color: "black !important",
    letterSpacing: "-0.125px",
  };
  return (
    <Box
      component="section"
      py={12}
      sx={{
        backgroundImage: ` url(${bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Container>
        <Grid item xs={12} md={12} lg={12}>
          <Typography variant="h2" sx={titleStyles} mb={2}>
            Poznaj część udogodnień naszego systemu
          </Typography>
          <Box
            component="span"
            sx={{
              display: "block",
              width: "65%",
              borderBottom: "5px solid #B04C4E",
              marginTop: "0.5rem",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          ></Box>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 8 }}>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              name="Rejestracja próbek"
              review="Możesz łatwo wprowadzać i rejestrować nowe próbki biologiczne, nadając im unikalne identyfikatory. Wszystkie dane dotyczące próbek, takie jak rodzaj, pochodzenie, charakterystyka dawcy, będą przechowywane w systemie."
              image={demo1}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              name="Raport i analiza danych"
              review="Nasza aplikacja Biobank dostarcza narzędzia do generowania raportów i analizowania danych w celu monitorowania i oceny działalności Twojego biobanku. Będziesz mógł uzyskać cenne informacje na temat użyteczności próbek,  statystyk użytkowania i innych wskaźników."
              image={demo2}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              name="Przechowywanie i śledzenie"
              review="Nasz system zapewnia bezpieczne przechowywanie próbek biologicznych oraz umożliwia pełne śledzenie historii każdej próbki. 
              Będziesz mieć pełną kontrolę nad lokalizacją i dostępem do poszczególnych próbek w laboratorium. "
              image={demo3}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Stack
            direction="column"
            justifyContent="flex-end"
            alignItems="center"
            height="100%"
            spacing={2}
          >
            <Button 
              variant="outlined" 
              sx={{ 
                color: '#fff',
                borderColor: '#fff', 
                borderWidth: '5px', 
                fontWeight: 'bold',
                borderRadius: '43px',
                '&:hover': {
                  borderWidth: '5px', // Zachowaj stałą szerokość bordera na hover
                }, 
              }} 
              size="large"
              
            >
              Poznaj wszytskie możliwości
            </Button>
          </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Information;
