import React, { Component } from "react";
import { connect } from "react-redux"; // new import
import * as actions from "../../../services/actions/auth";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import api from "../../../config/api/apiRoutes";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Button,
  TextField,
  Alert,
  Typography,
  FormHelperText,
} from "@mui/material";
import Select from "react-select";
import SelectStyles from "../../select/SelectStyles";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";

const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach(
    // if we have an error string set valid to false
    (val) => val.length > 0 && (valid = false)
  );
  return valid;
};

class SampleEditForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sampleRoot: this.props.data.is_root,
      sampleParent: "",
      sampleParentId: this.props.data.parent,
      sampleOptions: this.props.sampleOptions,
      sampleAmountOld: this.props.data.amount,
      sampleAmount: this.props.data.amount,
      sampleUnit: this.props.data.unit,
      samplePatient: this.props.data.patient,
      samplePatientId: this.props.data.patient,
      patientOptions: this.props.patientOptions,
      sampleComment: this.props.data.comment,
      attributesOptions: this.props.attributeOptions,
      visible: true,
      sampleAttributesId: this.props.sampleExtraValuesId,
      sampleChildrenAmount: this.props.data.real_amount,
      sampleChildrenUnit: this.props.data.real_unit,

      attributes: [
        {
          attribute_name: "",
          values_type: "",
          input_type: "",
          add: false,
        },
      ],
      errors: {
        sampleAmount: "",
        sampleComment: "",
        sampleChildrenUnit: "",
        sampleChildrenAmount: "",
      },
    };
  }

  componentDidMount() {
    console.log(this.props.extraDate);
    const sampleData = this.props.data;
    const sampleExtraValues = this.props.sampleExtraValues;
    const parentSample = [
      { value: sampleData.parent, label: sampleData.parent },
    ];
    const patientSample = [
      { value: sampleData.patient, label: sampleData.patient },
    ];
    console.log(parentSample);
    this.setState({
      sampleParent: parentSample,
      samplePatient: patientSample,
      attributes: sampleExtraValues,
    });
  }

  handleChangeRadio = (event) => {
    console.log(this.state);
    this.setState({
      sampleRoot: event.target.checked,
    });
  };

  handleSampleParent = (e) => {
    this.setState({ sampleParent: e, sampleParentId: e.value });
    console.log(this.state);
  };

  handlePatient = (event) => {
    this.setState({ samplePatient: event, samplePatientId: event.value });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });

    const { name, value } = e.target;
    let errors = this.state.errors;
    console.log(Number(value), Number(this.props.data.amount));
    switch (name) {
      default:
        break;
    }

    this.setState({ errors, [name]: value });
    console.log(this.state);
  };

  addFields = () => {
    let object = {
      attribute_name: "",
      values_type: "",
      attributeID: "",
      add: false,
    };

    this.setState({
      attributes: [...this.state.attributes, object],
    });
  };

  removeFields = (index, form) => {
    let data = this.state.attributes;
    let data2 = this.state.sampleAttributesId;
    const newArray = data2.filter((element) => element !== form.attributeID);
    console.log(newArray);
    this.setState({ sampleAttributesId: newArray });
    data.splice(index, 1);
    console.log(data);
    this.setState(data);
  };

  removeAndAddFields = (index) => {
    this.removeFields(index);
    this.addFields(index);
  };

  handleFormChange = (event, index) => {
    let data = [...this.state.attributes];
    console.log(event);

    data[index][event.target.name] = event.target.value;
    this.setState(data);
  };

  handleFormChangeSelect = (event, index) => {
    let data = [...this.state.attributes];
    console.log(event);

    data[index]["attributeID"] = event.value;
    data[index]["attribute_name"] = event.label;
    this.setState(data);
  };

  addAttribute = async () => {
    try {
      const { attributes } = this.state;
      const PatientAttributesOld = this.state.sampleAttributesId;
      const token = localStorage.getItem("token");
      const user_id = localStorage.getItem("user_id");
      axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      };

      await Promise.all(
        attributes.map(async (item1, index) => {
          if (item1.old || item1.add) {
            return Promise.resolve();
          }

          const formData = [
            {
              attribute: item1.attributeID,
              creator: user_id,
              short_text: item1.values_type,
            },
          ];

          try {
            const res = await axios.post(api().VALUES_OPTIONS_POST, formData);
            console.log("tak");
            res.data.forEach((item1, index) => {
              PatientAttributesOld.push(item1.id);
              attributes[index].add = true;
            });
          } catch (error) {
            if (error.response) {
              console.log(axios.post);
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            }
          }
        })
      );
    } catch (err) {
      console.error(err);
    }
  };

  formAction = (e) => {
    let id_sample = this.props.id_sample;

    const transformedArray = this.state.attributes.map((item) => ({
      short_text: item.values_type,
      attribute_attribute: {
        attribute: item.attribute_name,
      },
    }));

    console.log(transformedArray);

    const formData = {
      amount: this.state.sampleAmount,
      unit: this.state.sampleUnit,
      is_root: this.state.sampleRoot,
      parent: this.state.sampleParentId,
      patient: this.state.samplePatientId,
      creator: `${localStorage.getItem("user_id")}`,
      extra_values: transformedArray,
      comment: this.state.sampleComment,
      procedures: [],
      ...(this.state.sampleChildrenAmount && {
        real_amount: this.state.sampleChildrenAmount,
      }),
      ...(this.state.sampleChildrenUnit && {
        real_unit: this.state.sampleChildrenUnit,
      }),
    };

    console.log(formData);

    const validate = () => {
      let validationError = this.state.errors;

      this.setState({ validationError, validationError });
      console.log("in validate", validationError);
      return validationError;
    };

    const validationError = validate();
    console.log(this.state.errors);
    if (validateForm(validationError)) {
      console.info("Valid Form");
      this.props.onAuth(formData, id_sample);
      console.log(formData);
    } else {
      console.error("Invalid Form");
    }
  };

  completeForm = async (e) => {
    e.preventDefault();
    //await this.addAttribute();
    this.formAction();
  };

  render() {
    const { errors } = this.state;

    let id_sample = this.props.id_sample;

    if (this.props.adding == true) {
      this.props.props.history.push(`/sample/${id_sample}`);
    }
    console.log(this.props);

    return (
      <>
        {this.props.loading ? (
          <LoadingSpinner />
        ) : (
          <Card>
            <Typography
              variant="h6"
              sx={{ position: "relative", padding: "16px 0 20px 16px" }}
            >
              <span
                style={{
                  content: "",
                  backgroundColor: "#111e6c",
                  position: "absolute",
                  left: "0",
                  top: "16px",
                  width: "4px",
                  height: "25px",
                }}
              ></span>
              Formularz edycji próbki
            </Typography>

            <Divider />
            <form onSubmit={this.completeForm}>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <div onChange={this.handleChangeRadio}>
                      <FormControlLabel
                        value={this.state.sampleRoot}
                        checked={this.state.sampleRoot}
                        control={<Checkbox />}
                        label="Czy to jest próbka nadrzędna ?"
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
                        labelPlacement="end"
                        type="radio"
                      />
                    </div>
                  </Grid>
                  {!this.state.sampleRoot && (
                    <>
                      <Grid item md={12} xs={12}>
                        <Select
                          options={this.state.sampleOptions}
                          placeholder="Przypisz próbkę nadrzędną"
                          onChange={(event) => {
                            this.handleSampleParent(event);
                          }}
                          styles={SelectStyles}
                          value={this.state.sampleParent}
                          name="color"
                          menuPortalTarget={document.body}
                        />
                        <FormHelperText
                          style={{
                            display: this.state.sampleParentId
                              ? "block"
                              : "none",
                          }}
                        >
                          <Link
                            to={`/sample/${this.state.sampleParentId}`}
                            target="_blank"
                          >
                            Szczegóły próbki <VisibilityIcon fontSize="small" />
                          </Link>
                        </FormHelperText>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*[.,]?[0-9]+",
                          }}
                          disabled={this.state.patientSelectID == ""}
                          label="Rzeczywista ilość próbki"
                          value={this.state.sampleChildrenAmount}
                          onChange={this.handleChange}
                          name="sampleChildrenAmount"
                          variant="outlined"
                          aria-describedby="component-helper-text"
                          helperText={
                            errors.sampleChildrenAmount.length > 0
                              ? errors.sampleChildrenAmount
                              : ""
                          }
                          error={
                            errors.sampleChildrenAmount.length ? true : false
                          }
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          type="text"
                          label="Rzeczywista jednostka"
                          disabled={this.state.patientSelectID == ""}
                          value={this.state.sampleChildrenUnit}
                          onChange={this.handleChange}
                          name="sampleChildrenUnit"
                          variant="outlined"
                          helperText={
                            errors.sampleChildrenUnit.length > 0
                              ? errors.sampleChildrenUnit
                              : ""
                          }
                          error={
                            errors.sampleChildrenUnit.length ? true : false
                          }
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      type="number"
                      inputProps={{
                        inputMode: "numeric",
                        pattern: "[0-9]*[.,]?[0-9]+",
                      }}
                      label={
                        this.state.sampleRoot
                          ? "Ilość próbki"
                          : "Ilość pobranej próbki nadrzędnej"
                      }
                      value={this.state.sampleAmount}
                      onChange={this.handleChange}
                      name="sampleAmount"
                      variant="outlined"
                      aria-describedby="component-helper-text"
                      helperText={
                        errors.sampleAmount.length > 0
                          ? errors.sampleAmount
                          : ""
                      }
                      error={errors.sampleAmount.length ? true : false}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      type="text"
                      label="Jednostka"
                      value={this.state.sampleUnit}
                      onChange={this.handleChange}
                      name="sampleUnit"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Select
                      options={this.state.patientOptions}
                      placeholder="Przypisz pacjenta"
                      onChange={(event) => {
                        this.handlePatient(event);
                      }}
                      styles={SelectStyles}
                      value={this.state.samplePatient}
                      name="color"
                      menuPortalTarget={document.body}
                    />
                    <FormHelperText
                      style={{
                        display: this.state.samplePatientId ? "block" : "none",
                      }}
                    >
                      <Link
                        to={`/patient/${this.state.samplePatientId}`}
                        target="_blank"
                      >
                        Szczegóły pacjenta <VisibilityIcon fontSize="small" />
                      </Link>
                    </FormHelperText>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Komentarz"
                      value={this.state.sampleComment}
                      onChange={this.handleChange}
                      name="sampleComment"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Alert severity="info">
                      Wybierz dodatkowy atrybut i przypisz mu wartość.
                    </Alert>
                  </Grid>
                  {this.state.visible && (
                    <>
                      {this.state.attributes.map((form, index) => {
                        return (
                          <>
                            <Grid item md={6} xs={12}>
                              <Select
                                isDisabled={
                                  this.state.attributes[index].add == true
                                    ? true
                                    : false
                                }
                                className="basic-single"
                                classNamePrefix="select"
                                options={this.state.attributesOptions}
                                styles={SelectStyles}
                                placeholder="Wybierz atrybut"
                                onChange={(event) =>
                                  this.handleFormChangeSelect(event, index)
                                }
                                value={{ label: form.attribute_name }}
                                noOptionsMessage={() => "name not found"}
                              />
                            </Grid>

                            <Grid item md={6} xs={12}>
                              <TextField
                                fullWidth
                                label={"Wartość"}
                                onChange={(event) =>
                                  this.handleFormChange(event, index)
                                }
                                value={form.values_type}
                                type="text"
                                name="values_type"
                                variant="outlined"
                                disabled={
                                  this.state.attributes[index].add == true
                                    ? true
                                    : false
                                }
                              />
                            </Grid>

                            <Grid item md={12} xs={12} align="center">
                              <Button
                                onClick={() => this.removeFields(index, form)}
                                startIcon={
                                  <RemoveCircleRoundedIcon
                                    style={{ color: "red" }}
                                  />
                                }
                              >
                                Usuń
                              </Button>
                            </Grid>
                          </>
                        );
                      })}
                    </>
                  )}
                  <Grid item md={12} xs={12} align="center" sx={{ mb: 3 }}>
                    <Button
                      onClick={() => this.addFields()}
                      startIcon={
                        <AddCircleRoundedIcon style={{ color: "green" }} />
                      }
                    >
                      Dodaj nowy atrybut
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  p: 2,
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  endIcon={<EditIcon />}
                >
                  Edytuj próbke
                </Button>
              </Box>
            </form>
          </Card>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error,
    adding: state.adding,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (formData, id_sample) =>
      dispatch(
        actions.requestApi(formData, api(id_sample).SAMPLE_EDIT_PIT, "put")
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SampleEditForm);
