const SelectStyles = {
  control: (base) => ({
    ...base,
    minHeight: 56,
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999, maxHeight: 100}),
  menuList: base => ({
    ...base,
    maxHeight: "250px" // your desired height
})
};

export default SelectStyles;
