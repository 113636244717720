import React, { Component, useState } from "react";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import Select from "react-select";
import {
  Box,
  Container,
  Grid,
  Divider,
  Button,
  Typography,
  CardContent,
  Card,
  Alert,
  AlertTitle,
} from "@mui/material";
import { connect } from "react-redux";
import queryString from "query-string";
import * as actions from "../../../services/actions/auth";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { TextField, MenuItem, FormHelperText } from "@mui/material";
import SelectStyles from "../../select/SelectStyles";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import api from "../../../config/api/apiRoutes";
import axios from "axios";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import Switch from "@mui/material/Switch";
import Creatable from "react-select/creatable";
import finalPropsSelectorFactory from "react-redux/es/connect/selectorFactory";

const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach(
    // if we have an error string set valid to false
    (val) => val.length > 0 && (valid = false)
  );
  return valid;
};

class SampleFormAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sampleOptions: this.props.sampleData,
      sampleSelect: "",
      sampleSelectID: "",
      sampleUnit: "",
      sampleAmount: "",
      sampleInfo: "",
      radioRoot: false,
      patientOptions: this.props.patientData,
      patientSelect: "",
      patientSelectID: "",
      procedureOptions: this.props.procedureData,
      procedureSelect: [],
      procedureSelectID: [],
      attributesOptions: this.props.attributesData,
      attributesID: [],
      sampleIdentifier: "",
      visible: true,
      showLocalization: false,
      showParentLocalization: false,
      localizationOptions: this.props.localizationData,
      localizationSample: "",
      parentLocalization: "",
      statusSample: "",
      procedureSample: "",
      showProcedure: false,
      Loading: false,
      AttributeOptions: this.props.resultData,
      sampleChildrenAmount: "",
      sampleChildrenUnit: "",

      AttributeInputs: [
        {
          AttributeValue: "",
          AttributeName: "",
          errorValue: "",
          errorName: "",
        },
      ],

      attributes: [
        {
          attribute_name: "",
          values_type: "",
          input_type: "",
          errorAttribute: "",
          errorValue: "",
          add: false,
        },
      ],

      errors: {
        sampleUnit: "",
        sampleAmount: "",
        patientSelectID: "",
        addLocalization: "",
        AttributeName: "",
        AttributeValue: "",
        sampleChildrenUnit: "",
        sampleChildrenAmount: "",
        sampleSelect: "",
      },
    };
  }

  handleCheckbox = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
    console.log(this.state);
  };

  handleFormChangeSelect2 = (event, index) => {
    let data = [...this.state.AttributeInputs];
    console.log(event);

    data[index]["AttributeName"] = event.label;

    console.log(this.state);

    this.setState(data);
  };

  handleFormChange2 = (event, index) => {
    let data = [...this.state.AttributeInputs];
    console.log(this.state);

    data[index][event.target.name] = event.target.value;
    this.setState(data);
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    console.log(this.state);

    const { name, value } = e.target;
    let errors = this.state.errors;
    console.log(value.length);
    switch (name) {
      case "sampleUnit":
        errors.sampleUnit = value.length >= 1 ? "" : "Wymagane pole";
        break;
      case "sampleAmount":
        errors.sampleAmount = value.length >= 1 ? "" : "Wymagane pole";
        break;

      default:
        break;
    }

    this.setState({ errors, [name]: value });
  };

  handleChange2 = (event) => {
    const valuesArray = event.map((item) => item.value);
    this.setState({ procedureSelect: event, procedureSelectID: valuesArray });
  };

  setTrueLoading = () => {
    this.setState({ Loading: true });
  };

  setFalseLoading = () => {
    this.setState({ Loading: false });
  };

  handleFormChange = (event, index) => {
    let data = [...this.state.attributes];
    console.log(event);

    data[index][event.target.name] = event.target.value;
    this.setState(data);
  };

  handleFormChangeSelect = (event, index) => {
    let data = [...this.state.attributes];
    console.log(event);

    data[index]["attributeID"] = event.value;
    data[index]["input_type"] = event.type;
    data[index]["attribute_name"] = event.label;
    this.setState(data);
  };

  handlePatient = (event) => {
    if (event.value) {
      this.state.errors.patientSelectID = "";
    } else {
      this.state.errors.patientSelectID = "Wymagane pole";
    }
    this.setState({ patientSelect: event, patientSelectID: event.value });
  };

  patientSet = (id) => {
    this.setState({ SamplePatient: id });
  };

  sampleSet = (id) => {
    this.setState({ sampleParentId: id });
  };

  handleSampleParent = (e) => {
    if (e.value) {
      this.state.errors.sampleSelect = "";
    } else {
      this.state.errors.sampleSelect = "Wymagane pole";
    }
    this.setState({ sampleSelect: e, sampleSelectID: e.value });
    console.log(this.state);
  };

  addFields = () => {
    let object = {
      attribute_name: "",
      values_type: "",
      input_type: "",
      creator: `${localStorage.getItem("user_id")}`,
      add: false,
    };

    this.setState({
      attributes: [...this.state.attributes, object],
    });
  };

  removeFields = (index) => {
    let data = this.state.attributes;
    data.splice(index, 1);
    this.setState(data);
  };

  removeExtraFields = (index) => {
    let data = this.state.attributes;
    data.splice(index, 1);
    this.setState(data);
  };

  handleChangeRadio = (event) => {
    console.log(event.target.checked);
    this.setState({
      radioRoot: event.target.checked,
    });
  };

  handleAddLocalizationChange = (addLocalization) => {
    this.setState({ localizationSample: addLocalization });
  };

  handleParentLocalization = (localization) => {
    this.setState({ parentLocalization: localization });
  };

  handleProcedureChange = (input) => (e, index) => {
    this.setState({ [input]: e });
  };

  handleAddProcedure = (data) => {
    this.setState({ procedureSample: data });
  };

  handleChangeStatus = (data) => {
    this.setState({ statusSample: data });
  };

  removeFields2 = (index) => {
    let data = this.state.AttributeInputs;
    data.splice(index, 1);
    this.setState(data);
  };

  removeAndAddFields = (index) => {
    this.removeFields(index);
    this.addFields(index);
  };

  addFields2 = () => {
    let object = {
      AttributeName: "",
      AttributeValue: "",
      errorValue: "",
      errorName: "",
    };

    this.setState({
      AttributeInputs: [...this.state.AttributeInputs, object],
    });
  };

  addAttribute = () => {
    const { attributes, attributesID } = this.state;
    const { user_id, token } = localStorage;

    const promises = attributes.map((attribute, attributeIndex) => {
      if (attribute.add || attribute.attribute_name == "") {
        return Promise.resolve();
      }

      const formData = [
        {
          attribute: attribute.attributeID,
          creator: user_id,
          short_text: attribute.values_type,
        },
      ];

      axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      };

      return axios
        .post(api().VALUES_OPTIONS_POST, formData)
        .then((response) => {
          response.data.forEach((item, index) => {
            attributesID.push(item.id);
            attributes[attributeIndex].add = true;
          });

          this.setState({ visible: true });
        })
        .catch((error) => {
          if (error.response) {
            console.log(axios.post);
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        });
    });

    return Promise.all(promises);
  };

  addSample = () => {
    const procedureId = [];

    if (Array.isArray(this.state.procedureSample)) {
      this.state.procedureSample.map((item, index) => {
        procedureId.push(item.value);
      });
    }

    const convertedData = this.state.AttributeInputs.map(
      ({ errorValue, errorName, ...rest }) => rest
    );

    const formData = [
      {
        sample: [
          {
            amount: this.state.sampleAmount,
            unit: this.state.sampleUnit,
            is_root: this.state.radioRoot,
            parent: this.state.sampleSelectID,
            patient: this.state.patientSelectID,
            creator: `${localStorage.getItem("user_id")}`,
            extra_values: this.state.attributesID,
            procedures: this.state.procedureSelectID,
            comment: this.state.sampleInfo,
            ...(this.state.sampleChildrenAmount && {
              real_amount: this.state.sampleChildrenAmount,
            }),
            ...(this.state.sampleChildrenUnit && {
              real_unit: this.state.sampleChildrenUnit,
            }),
          },
        ],
        localization: [
          {
            localization_name: this.state.localizationSample.label,
          },
        ],
        status: [
          {
            status: this.state.statusSample.value,
          },
        ],
        procedure: [
          {
            procedures: procedureId,
          },
        ],
        results: this.state.AttributeInputs.map((item) => ({
          value: item.AttributeValue,
          attribute_name: item.AttributeName,
        })),
      },
    ];

    console.log(formData);

    const validate = () => {
      let validationError = this.state.errors;
      if (!this.state.patientSelectID) {
        validationError.patientSelectID = "Wymagane pole";
      }
      if (!this.state.sampleUnit) {
        validationError.sampleUnit = "Wymagane pole";
      }
      if (!this.state.sampleAmount) {
        validationError.sampleAmount = "Wymagane pole";
      }

      console.log(this.state.radioRoot === false, this.state.sampleSelect === "");

      if (this.state.radioRoot === false && this.state.sampleSelect === "") {
        console.log(
          "to sie dzieje",
          !this.state.radioRoot,
          this.state.sampleSelectID === ""
        );
        validationError.sampleSelect = "Wymagane pole";
      }

      if (this.state.sampleSelect) {
        validationError.sampleSelect = "";
      }

      if (this.state.radioRoot) {
        validationError.sampleSelect = "";
      }

      // if (!this.state.EquipmentSerial) {
      //   validationError.EquipmentSerial = "Wymagane pole";
      // }
      // if (!this.state.EquipmentBig) {
      //   validationError.EquipmentBig = "Wymagane pole";
      // }

      this.setState({ validationError, validationError });
      console.log("in validate", validationError);
      return validationError;
    };

    const validationError = validate();
    console.log(this.state.errors);
    if (validateForm(validationError)) {
      console.info("Valid Form");
      console.log(formData);
      this.props.onAuth(formData);
    } else {
      console.log(formData);
      console.error("Invalid Form");
    }
  };

  completeForm = async (e) => {
    e.preventDefault();
    await this.addAttribute();
    this.addSample();
  };

  componentDidMount() {
    const queryParams = queryString.parse(window.location.search);
    console.log(this.state.sampleOptions);
    if (queryParams.sample_id) {
      const filteredArr = this.state.sampleOptions.filter(
        (item) => item.value == queryParams.sample_id
      );
      console.log(filteredArr);
      this.setState({
        sampleOptions: filteredArr,
        sampleSelect: filteredArr,
        sampleSelectID: filteredArr[0].value,
      });
    }
    if (queryParams.unit) {
      this.setState({ sampleUnit: queryParams.unit });
    }
    if (queryParams.patient_id) {
      const filteredArr = this.state.patientOptions.filter(
        (item) => item.value == queryParams.patient_id
      );
      axios
        .get(api(queryParams.patient_id).PATIENT_SAMPLE_GET)
        .then((response) => {
          const data = response.data;
          const patientSampleOptions = [];

          data.map((sample, index) => {
            return patientSampleOptions.push({
              value: sample.id,
              label: sample.id,
            });
          });
          this.setState({
            sampleOptions: patientSampleOptions,
          });

          console.log(data);
        })
        .catch((error) => {});
      console.log(filteredArr);
      this.setState({
        patientOptions: filteredArr,
        patientSelect: filteredArr,
        patientSelectID: filteredArr[0].value,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    console.log("tataa");
    console.log(this.state);

    if (prevState.patientSelectID !== this.state.patientSelectID) {
      console.log("tatatatata");

      axios
        .get(api(this.state.patientSelectID).GET_PATIENT_SAMPLE)
        .then((res) => {
          console.log(res);
          const data = res.data;
          console.log(data);
          const patientSampleOptions = [];

          data.map((sample, index) => {
            return patientSampleOptions.push({
              value: sample.id,
              label: sample.identifier,
            });
          });
          this.setState({
            sampleOptions: patientSampleOptions,
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .catch(function (error) {
          console.log(error);
          if (error.response) {
            console.log(axios.post);
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        });
    }

    if (
      prevState.sampleSelectID !== this.state.sampleSelectID ||
      prevState.patientSelectID !== this.state.patientSelectID
    ) {
      if (this.state.sampleSelectID && this.state.patientSelectID) {
        axios.defaults.headers = {
          "Content-Type": "application/json",
          Authorization: `Token ${localStorage.getItem("token")}`,
        };

        axios
          .get(
            api(this.state.sampleSelectID, this.state.patientSelectID)
              .NEW_SAMPLE_PARENT_IDENTIFIER
          )
          .then((res) => {
            console.log(res);
            const data = res.data;
            this.setState({ sampleIdentifier: data.identifier });
          })
          .catch((err) => {
            console.log(err);
          })
          .catch(function (error) {
            console.log(error);
            if (error.response) {
              console.log(axios.post);
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            }
          });
      } else {
        console.log("Jeden lub oba stany są fałszywe.");
      }
    }

    if (
      this.state.radioRoot &&
      prevState.patientSelectID !== this.state.patientSelectID
    ) {
      console.log("warunekkk");

      axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")}`,
      };

      axios
        .get(api(this.state.patientSelectID).NEW_SAMPLE_PATIENT_IDENTIFIER)
        .then((res) => {
          console.log(res);
          const data = res.data;
          this.setState({ sampleIdentifier: data.identifier });
        })
        .catch((err) => {
          console.log(err);
        })
        .catch(function (error) {
          console.log(error);
          if (error.response) {
            console.log(axios.post);
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        });
    }
  }

  render() {
    const { errors } = this.state;

    const queryParams = queryString.parse(window.location.search);

    if (this.props.adding == true) {
      this.props.props.history.push(`/sample`);
    }
    return (
      <Card>
        <Typography
          variant="h6"
          sx={{ position: "relative", padding: "16px 0 20px 16px" }}
        >
          <span
            style={{
              content: "",
              backgroundColor: "#111e6c",
              position: "absolute",
              left: "0",
              top: "16px",
              width: "4px",
              height: "25px",
            }}
          ></span>
          Formularz dodania próbki
        </Typography>

        <Divider />
        <form onSubmit={this.completeForm}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <Select
                  options={this.state.patientOptions}
                  placeholder="Przypisz pacjenta"
                  onChange={(event) => {
                    this.handlePatient(event);
                  }}
                  styles={SelectStyles}
                  value={this.state.patientSelect}
                  name="color"
                  menuPortalTarget={document.body}
                />
                {errors.patientSelectID.length > 0 && (
                  <FormHelperText error={true}>
                    {errors.patientSelectID}
                  </FormHelperText>
                )}
                <FormHelperText
                  style={{
                    display: this.state.patientSelectID ? "block" : "none",
                  }}
                >
                  <Link
                    to={`/patient/${this.state.patientSelectID}`}
                    target="_blank"
                  >
                    Szczegóły pacjenta <VisibilityIcon fontSize="small" />
                  </Link>
                </FormHelperText>
              </Grid>
              <Grid item md={12} xs={12}>
                <div
                  onChange={this.handleChangeRadio}
                  style={{ display: !queryParams.sample_id ? "block" : "none" }}
                >
                  <FormControlLabel
                    value="false"
                    control={<Checkbox />}
                    label="Czy to jest próbka nadrzędna ?"
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 30 } }}
                    labelPlacement="end"
                    type="radio"
                  />
                </div>
              </Grid>
              {!this.state.radioRoot && (
                <>
                  <Grid item md={12} xs={12}>
                    <Select
                      options={this.state.sampleOptions}
                      placeholder="Przypisz próbkę nadrzędną"
                      onChange={(event) => {
                        this.handleSampleParent(event);
                      }}
                      styles={SelectStyles}
                      value={this.state.sampleSelect}
                      name="color"
                      menuPortalTarget={document.body}
                      isDisabled={this.state.patientSelectID == ""}
                    />
                    {errors.sampleSelect.length > 0 && (
                      <FormHelperText error={true}>
                        {errors.sampleSelect}
                      </FormHelperText>
                    )}
                    <FormHelperText
                      style={{
                        display: this.state.sampleSelectID ? "block" : "none",
                      }}
                    >
                      <Link
                        to={`/sample/${this.state.sampleSelectID}`}
                        target="_blank"
                      >
                        Szczegóły próbki <VisibilityIcon fontSize="small" />
                      </Link>
                    </FormHelperText>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      inputProps={{
                        inputMode: "numeric",
                        pattern: "[0-9]*[.,]?[0-9]+",
                      }}
                      disabled={this.state.patientSelectID == ""}
                      label="Rzeczywista ilość próbki"
                      value={this.state.sampleChildrenAmount}
                      onChange={this.handleChange}
                      name="sampleChildrenAmount"
                      variant="outlined"
                      aria-describedby="component-helper-text"
                      helperText={
                        errors.sampleChildrenAmount.length > 0
                          ? errors.sampleChildrenAmount
                          : ""
                      }
                      error={errors.sampleChildrenAmount.length ? true : false}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      type="text"
                      label="Rzeczywista jednostka"
                      disabled={this.state.patientSelectID == ""}
                      value={this.state.sampleChildrenUnit}
                      onChange={this.handleChange}
                      name="sampleChildrenUnit"
                      variant="outlined"
                      helperText={
                        errors.sampleChildrenUnit.length > 0
                          ? errors.sampleChildrenUnit
                          : ""
                      }
                      error={errors.sampleChildrenUnit.length ? true : false}
                    />
                  </Grid>
                </>
              )}
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*[.,]?[0-9]+",
                  }}
                  label={
                    this.state.radioRoot
                      ? "Ilość próbki"
                      : "Ilość pobranej próbki nadrzędnej"
                  }
                  value={this.state.sampleAmount}
                  onChange={this.handleChange}
                  name="sampleAmount"
                  variant="outlined"
                  aria-describedby="component-helper-text"
                  helperText={
                    errors.sampleAmount.length > 0 ? errors.sampleAmount : ""
                  }
                  error={errors.sampleAmount.length ? true : false}
                />
                <FormHelperText
                  id="component-helper-text"
                  style={{ display: queryParams.amount ? "block" : "none" }}
                >
                  {`Pozostało : ${queryParams.amount} ${queryParams.unit}`}
                </FormHelperText>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  type="text"
                  label="Jednostka"
                  value={this.state.sampleUnit}
                  onChange={this.handleChange}
                  name="sampleUnit"
                  variant="outlined"
                  helperText={
                    errors.sampleUnit.length > 0 ? errors.sampleUnit : ""
                  }
                  error={errors.sampleUnit.length ? true : false}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="Komentarz"
                  value={this.state.sampleInfo}
                  onChange={this.handleChange}
                  name="sampleInfo"
                  variant="outlined"
                />
              </Grid>
              {this.state.sampleIdentifier && (
                <Grid item md={12} xs={12}>
                  <Alert severity="info">
                    Przewidywany identyfikator próbki:{" "}
                    <b>{this.state.sampleIdentifier}</b>
                  </Alert>
                </Grid>
              )}
              <Grid item md={12} xs={12}>
                <Alert severity="info">
                  Wybierz dodatkowy atrybut i przypisz mu wartość.
                </Alert>
              </Grid>
              {this.state.visible && (
                <>
                  {this.state.attributes.map((form, index) => {
                    return (
                      <>
                        <Grid item md={6} xs={12}>
                          <Select
                            isDisabled={
                              this.state.attributes[index].add == true
                                ? true
                                : false
                            }
                            className="basic-single"
                            classNamePrefix="select"
                            options={this.state.attributesOptions}
                            styles={SelectStyles}
                            placeholder="Wybierz atrybut"
                            onChange={(event) =>
                              this.handleFormChangeSelect(event, index)
                            }
                            value={{ label: form.attribute_name }}
                            noOptionsMessage={() => "name not found"}
                          />
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            label={"Wartość"}
                            onChange={(event) =>
                              this.handleFormChange(event, index)
                            }
                            value={form.values_type}
                            type="text"
                            name="values_type"
                            variant="outlined"
                            disabled={
                              this.state.attributes[index].add == true
                                ? true
                                : false
                            }
                          />
                        </Grid>

                        <Grid item md={12} xs={12} align="center">
                          <Button
                            disabled={
                              this.state.attributes[index].add == true ||
                              index === 0
                                ? true
                                : false
                            }
                            onClick={() => this.removeFields(index)}
                            startIcon={
                              <RemoveCircleRoundedIcon
                                style={{ color: "red" }}
                              />
                            }
                          >
                            Usuń
                          </Button>
                          <Button
                            onClick={() => this.removeAndAddFields(index)}
                            disabled={this.state.attributes[index].add == true}
                            startIcon={<RemoveCircleRoundedIcon />}
                          >
                            Wyczyść
                          </Button>
                        </Grid>
                      </>
                    );
                  })}
                </>
              )}
              <Grid item md={12} xs={12} align="center" sx={{ mb: 3 }}>
                <Button
                  onClick={() => this.addFields()}
                  startIcon={
                    <AddCircleRoundedIcon style={{ color: "green" }} />
                  }
                >
                  Dodaj nowy atrybut
                </Button>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      name="showLocalization"
                      checked={this.state.showLocalization}
                      onChange={this.handleCheckbox}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Dodaj lokalizacje"
                />
              </Grid>
              {this.state.showLocalization && (
                <>
                  <Grid item md={12} xs={12}>
                    <Creatable
                      options={this.state.localizationOptions}
                      placeholder="Nowa lokalizacja"
                      onChange={this.handleAddLocalizationChange}
                      name="color"
                      styles={SelectStyles}
                      menuPortalTarget={document.body}
                    />
                    {this.state.errors.addLocalization.length > 0 && (
                      <FormHelperText error={true}>
                        {this.state.errors.addLocalization}
                      </FormHelperText>
                    )}
                  </Grid>{" "}
                  {/* <Grid item md={12} xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          name="showParentLocalization"
                          checked={this.state.showParentLocalization}
                          onChange={this.handleCheckbox}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Dodaj do lokalizacji nadrzędnej"
                    />
                  </Grid>{" "}
                  {this.state.showParentLocalization && (
                    <Grid item md={12} xs={12}>
                      <Select
                        options={this.state.localizationOptions}
                        placeholder="Nowa lokalizacja"
                        onChange={this.handleParentLocalization}
                        name="color"
                        styles={SelectStyles}
                        menuPortalTarget={document.body}
                      />
                      {this.state.errors.addLocalization.length > 0 && (
                        <FormHelperText error={true}>
                          {this.state.errors.addLocalization}
                        </FormHelperText>
                      )}
                    </Grid>
                  )} */}
                </>
              )}

              <Grid item md={12} xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      name="showProcedure"
                      checked={this.state.showProcedure}
                      onChange={this.handleCheckbox}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Dodaj procedure"
                />
              </Grid>
              {this.state.showProcedure && (
                <Grid item md={12} xs={12}>
                  <Select
                    styles={SelectStyles}
                    isMulti
                    classNamePrefix="select"
                    menuPortalTarget={document.body}
                    value={this.state.procedureSample}
                    options={this.state.procedureOptions}
                    placeholder="Wybierz procedure"
                    name="procedureSample"
                    onChange={this.handleProcedureChange("procedureSample")}
                  />
                </Grid>
              )}

              <Grid item md={12} xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      name="showStatus"
                      checked={this.state.showStatus}
                      onChange={this.handleCheckbox}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Dodaj status"
                />
              </Grid>
              {this.state.showStatus && (
                <Grid item md={12} xs={12}>
                  <Select
                    options={[
                      { value: "NEW", label: "NOWA" },
                      {
                        value: "FOR_EXAMINATION",
                        label: "DO ZBADANIA",
                      },
                      { value: "EXAMINATED", label: "ZBADANA" },
                      { value: "ARCHIVED", label: "ZARCHIWIZOWANA" },
                    ]}
                    placeholder="Nadaj status"
                    onChange={this.handleChangeStatus}
                    styles={SelectStyles}
                    name="color"
                    menuPortalTarget={document.body}
                  />
                  {this.state.errors.addLocalization.length > 0 && (
                    <FormHelperText error={true}>
                      {this.state.errors.addLocalization}
                    </FormHelperText>
                  )}
                </Grid>
              )}

              <Grid item md={12} xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      name="showResult"
                      checked={this.state.showResult}
                      onChange={this.handleCheckbox}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Dodaj wynik"
                />
              </Grid>
              {this.state.showResult && (
                <>
                  {this.state.AttributeInputs.map((form, index) => {
                    return (
                      <>
                        <Grid item md={6} xs={12}>
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            options={this.state.AttributeOptions}
                            placeholder="Wybierz odczynnik"
                            styles={SelectStyles}
                            onChange={(event) =>
                              this.handleFormChangeSelect2(event, index)
                            }
                            value={{ label: form.AttributeName }}
                            name="color"
                          />
                          {form.errorName.length > 0 && (
                            <FormHelperText error={true}>
                              {form.errorName}
                            </FormHelperText>
                          )}
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(form.errorName)}
                            fullWidth
                            type="text"
                            label="Wartość wyniku"
                            name="AttributeValue"
                            onChange={(event) =>
                              this.handleFormChange2(event, index)
                            }
                            value={form.AttributeValue}
                            variant="outlined"
                          />

                          <FormHelperText error={Boolean(form.errorName)}>
                            {form.errorName}
                          </FormHelperText>
                        </Grid>

                        <Grid
                          item
                          md={12}
                          xs={12}
                          align="center"
                          sx={{ mb: 3 }}
                        >
                          <Button
                            disabled={
                              this.state.AttributeInputs[index].add == true ||
                              index === 0
                                ? true
                                : false
                            }
                            onClick={() => this.removeFields2(index)}
                            startIcon={
                              <RemoveCircleRoundedIcon
                                style={{ color: "red" }}
                              />
                            }
                          >
                            Usuń
                          </Button>
                        </Grid>
                      </>
                    );
                  })}
                  <Grid item md={12} xs={12} align="center">
                    <Button
                      onClick={() => this.addFields2()}
                      startIcon={
                        <AddCircleRoundedIcon style={{ color: "green" }} />
                      }
                    >
                      Dodaj nowy atrybut
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </CardContent>
          <Divider />

          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              p: 2,
            }}
          >
            <Button
              type="submit"
              variant="contained"
              size="large"
              endIcon={<AddIcon />}
            >
              Dodaj próbkę
            </Button>
          </Box>
        </form>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error,
    adding: state.adding,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (formData) =>
      dispatch(actions.requestApi(formData, api().SAMPLE_ADD_POST, "post")),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SampleFormAdd);
