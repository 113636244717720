import React, { useState, useEffect } from "react";
import axios from "axios";
import api from "../../config/api/apiRoutes";
import { useParams } from "react-router-dom";
import { Box, Container, Grid, Typography } from "@mui/material";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { Link } from "react-router-dom";
import { Divider } from "rsuite";
import HomeIcon from "@mui/icons-material/Home";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import SampleTreeEditForm from "../../components/Sample/form/SampleTreeEditForm";

function SampleTreeEdit(props) {
  const [sampleTree, setSampleTree] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  let { id } = useParams();
  console.log(sampleTree);

  useEffect(() => {
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    const getData = async () => {
      try {
        const getSampleTree = await axios.get(api(id).FAMILY_SAMPLE);
        const getDataSampleTree = getSampleTree.data;

        setSampleTree(getDataSampleTree);
        setIsLoading(false);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      }
    };

    getData();
  }, []);
  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Container maxWidth={false}>
      <Typography variant="h5" style={{ fontWeight: "400" }}>
        Drzewo powiązań próbki {sampleTree[0].actual_identifier}
      </Typography>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" to="/" style={{ color: "#888" }}>
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Dashboard
        </Link>
        <Link underline="hover" to="/sample" style={{ color: "#888" }}>
          Lista próbek
        </Link>
        <Link underline="hover" to={`/sample/${id}`} style={{ color: "#888" }}>
          Szczegóły próbki
        </Link>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="#111"
          style={{ fontWeight: "600" }}
        >
          Edytuj drzewo próbki
        </Typography>
      </Breadcrumbs>
      <Divider />
      <Grid container spacing={3}>
        <Grid item lg={12} md={12} xs={12}>
          <SampleTreeEditForm sampleTree={sampleTree} />
        </Grid>
      </Grid>
    </Container>
  );
}

export default SampleTreeEdit;
