import { Link } from "react-router-dom";
import { Button } from "@mui/material";

const intialColumnsReagentDelivery = [
  {
    accessorKey: "id",
    header: "ID",
    size: 40,
  },
  {
    accessorKey: "amount",
    header: "Ilość",
    size: 40,
  },
  {
    accessorKey: "remain",
    header: "Pozostało",
    size: 40,
  },
  {
    accessorKey: "unit",
    header: "Jednostka",
    size: 40,
  },
  {
    accessorKey: "date_created",
    header: "Data dostawy",
    size: 40,
  },
  {
    accessorKey: "date_expiration",
    header: "Data ważności",
    size: 40,
  },
  {
    accessorKey: "additional_info",
    header: "Dodatkowe informacje",
    size: 120,
  },
  {
    accessorFn: (row) => (
      <Link to={`delivery/samples/${row.id}`}>
        <Button variant="contained" size="small">
          Zobacz
        </Button>
      </Link>
    ),
    header: "Szczegóły",
    enableColumnFilter: false,
  },
];

export default intialColumnsReagentDelivery;
