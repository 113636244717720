import React, { Component } from "react";
import { connect } from "react-redux"; // new import
import * as actions from "../../services/actions/auth";
import axios from "axios";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import api from "../../config/api/apiRoutes";
import EditIcon from "@mui/icons-material/Edit";
import Switch from "@mui/material/Switch";

class EquipmentFormEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getDataEquipment: [],
      equipmentName: props.data.name,
      equipmentSerial: props.data.serial,
      equipmentInfo: props.data.additional_info,
      equipmentCreator: props.data.creator,
      equipmentBig: props.data.is_big,
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSwitch = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
    console.log(this.state);
  };

  editEquipmentForm = () => {
    const formData = {
      name: this.state.equipmentName,
      serial: this.state.equipmentSerial,
      is_big: this.state.equipmentBig,
      additional_info: this.state.equipmentInfo,
      creator: this.state.equipmentCreator,
    };

    this.props.onAuth(formData, this.props.id_equipment);
  };

  render() {
    const { errors } = this.state;

    if (this.props.adding == true) {
      this.props.props.history.push(`/equipment`);
    }

    return (
      <>
        {this.props.loading ? (
          <LoadingSpinner />
        ) : (
          <Card>
            <Typography
              variant="h6"
              sx={{ position: "relative", padding: "16px 0 20px 16px" }}
            >
              <span
                style={{
                  content: "",
                  backgroundColor: "#111e6c",
                  position: "absolute",
                  left: "0",
                  top: "16px",
                  width: "4px",
                  height: "25px",
                }}
              ></span>
              Formularz edycji wyposażenia
            </Typography>
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    type="text"
                    label="Nazwa sprzętu"
                    name="equipmentName"
                    value={this.state.equipmentName}
                    onChange={this.onChange}
                    variant="outlined"
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    type="text"
                    label="Numer seryjny"
                    name="equipmentSerial"
                    value={this.state.equipmentSerial}
                    onChange={this.onChange}
                    variant="outlined"
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        name="equipmentBig"
                        checked={this.state.equipmentBig}
                        onChange={this.handleSwitch}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Duży sprzęt?"
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    type="text"
                    label="Dodatkowe info"
                    name="equipmentInfo"
                    value={this.state.equipmentInfo}
                    onChange={this.onChange}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                p: 3,
              }}
            >
              <Button
                onClick={this.editEquipmentForm}
                variant="contained"
                endIcon={<EditIcon />}
              >
                Edytuj sprzęt
              </Button>
            </Box>
          </Card>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error,
    adding: state.adding,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (formData, id) =>
      dispatch(actions.requestApi(formData, api(id).EQUIPMENT_EDIT_PUT, "put")),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentFormEdit);
