import React, { useMemo } from "react";
import MaterialReactTable from "material-react-table";



const EquipmentDetailTable = ({ data }) => {

  const columns = useMemo(
    () => [
      {
        accessorKey: "id", 
        header: 'ID',
      },
      {
        accessorKey: "creator", 
        header: 'twórca',
      },
      {
        accessorKey: "name", 
        header: 'nazwa',
      },
      {
        accessorKey: "serial", 
        header: 'Numer seryjny',
      },
    ],
    [],
  );

  const patientData = [data];
  return <MaterialReactTable 
    columns={columns} 
    data={patientData}
    enableColumnActions={false}
    enableColumnFilters={false}
    enablePagination={false}
    enableSorting={false}
    enableBottomToolbar={false}
    enableTopToolbar={false}
    muiTableBodyRowProps={{ hover: false }}    
    />;
};

export default EquipmentDetailTable;