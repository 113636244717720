import { Button, Grid } from '@mui/material';
import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';

export function FileHeader({ file, onDelete }) {
    console.log(file)
    return (
        <Grid container justify="space-between" alignItems="center">
            <Grid item>{file.name}</Grid>
            <Grid item>
                <Button onClick={() => onDelete(file)} variant="text" color="secondary" endIcon={<DeleteIcon />}>
                    Usuń
                </Button>
            </Grid>
        </Grid>
    );
}
