import FeedIcon from "@mui/icons-material/Feed";
import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ReCAPTCHA from "react-google-recaptcha";
import InputOutlined from "../ui-component/input/InputOutlined";
import { useFormik, FieldArray } from "formik";
import * as Yup from "yup";
import MultiLineInput from "../ui-component/input/MultiLineInput";
import { FormHelperText, Grid } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import api from "../../config/api/apiRoutes";

export default function FormDialog() {
  const [open, setOpen] = useState(false);
  // const [errors, setErrors] = useState({});

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const initialValues = {
    name: "",
    email: "",
    message: "",
    captcha: "",
  };

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    handleBlur,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({
      name: Yup.string().required("Pole jest wymagane"),
      email: Yup.string()
        .email("Wprowadź poprawny adres e-mail")
        .max(255)
        .required("Pole jest wymagane"),
      message: Yup.string().required("Pole jest wymagane"),
      captcha: Yup.string().required("Pole jest wymagane"),
    }),
    onSubmit: (values) => {
      console.log(`submit`, values);

      const formData = {
        name: values.name,
        email: values.email,
        message: values.message,
        captcha: values.captcha,
      };

      axios.defaults.headers = {
        "Content-Type": "application/json",
      };
      axios
        .post(api().CONTACT_FORM_ADD, formData)
        .then((response) => {
          toast.success("Otrzymano Twoje zgłoszenie, dziękujęmy!");
          resetForm();
          handleClose();
          console.log(response.data);
        })
        .catch((error) => {
          // Obsługa błędu
          toast.error("Błąd!");
          console.error(error);
        });
    },
  });

  return (
    <div>
      <Button
        startIcon={<FeedIcon />}
        color="primary"
        size="medium"
        sx={{ textAlign: "center", marginInline: "auto" }}
        onClick={handleClickOpen} // Dodajemy tu naszą funkcję
      >
        Formularz
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Formularz kontaktowy</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ marginBottom: "1rem" }}>
            Wpisz swoje dane i wiadomość poniżej. Na maila wyślemy odpowiedź.
          </DialogContentText>
          <Grid container spacing={2}>
            <InputOutlined
              md={12}
              xs={12}
              inputLabel={"Imię i Nazwisko"}
              value={values.name}
              name={"name"}
              handleBlur={handleBlur}
              onChange={handleChange}
              touched={touched.name}
              errors={errors.name}
              inputId={"name"}
              inputType={"text"}
            />
            <InputOutlined
              md={12}
              xs={12}
              inputLabel={"Email"}
              value={values.email}
              name={"email"}
              handleBlur={handleBlur}
              onChange={handleChange}
              touched={touched.email}
              errors={errors.email}
              inputId={"email"}
              inputType={"text"}
            />
            <MultiLineInput
              md={12}
              xs={12}
              inputLabel={"Wiadomość"}
              value={values.message}
              name={"message"}
              handleBlur={handleBlur}
              onChange={handleChange}
              touched={touched.message}
              errors={errors.message}
              inputId={`message`}
              inputType={"text"}
            />
            <Grid item md={12} xs={12}>
              <ReCAPTCHA
                sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                onChange={(value) => setFieldValue("captcha", value)}
              />
              {errors.captcha && (
                <FormHelperText
                  error
                  id="standard-weight-helper-text-email-login"
                >
                  {errors.captcha}
                </FormHelperText>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between" }}>
          <Button onClick={handleClose}>Anuluj</Button>
          <Button onClick={handleSubmit}>Wyślij</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
