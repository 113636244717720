import React, { Component } from "react";
import { connect } from "react-redux"; // new import
import * as actions from "../../../services/actions/auth";
import axios from "axios";
import api from "../../../config/api/apiRoutes";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Button,
  TextField,
  Typography
} from "@mui/material";

class DiseaseFormEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getDataEquipment: [],
      DiseaseName: "",
      DiseaseICD: "",
      errors: {
        DiseaseName: "",
        DiseaseICD: "",
      },
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  componentDidMount() {
    let icd = this.props.props.match.params.icd;

    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    axios
      .get(api(icd).DISEASE_GET)
      .then((response) => {
        console.log(response);
        const DiseaseName = response.data.name;
        const DiseaseICD = response.data.icd;

        this.setState({ DiseaseName });
        this.setState({ DiseaseICD });
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  }

  editDiseaseForm = () => {
    let icd = this.props.props.match.params.icd;
    const formData = {
      name: this.state.DiseaseName,
      icd: this.state.DiseaseICD,
    };

    this.props.onAuth(formData, icd);
  };

  render() {
    const { errors } = this.state;

    if (this.props.adding == true) {
      window.location.reload(true);

      this.props.props.history.push(`/disease/${this.state.DiseaseICD}`);
    }

    return (
      <>
        {this.props.loading ? (
          <LoadingSpinner />
        ) : (
          <Card>
            <Typography
              variant="h6"
              sx={{ position: "relative", padding: "16px 0 20px 16px" }}
            >
              <span
                style={{
                  content: "",
                  backgroundColor: "#111e6c",
                  position: "absolute",
                  left: "0",
                  top: "16px",
                  width: "4px",
                  height: "25px",
                }}
              ></span>
              Formularz edycji choroby
            </Typography>

            <Divider />
            <form onSubmit={this.editDiseaseForm}>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      type="text"
                      label="Nazwa choroby"
                      error={errors.DiseaseName.length ? true : false}
                      name="DiseaseName"
                      value={this.state.DiseaseName}
                      onChange={this.onChange}
                      helperText={
                        errors.DiseaseName.length > 0 ? errors.DiseaseName : ""
                      }
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      type="text"
                      label="Numer ICD"
                      error={errors.DiseaseICD.length ? true : false}
                      name="DiseaseICD"
                      value={this.state.DiseaseICD}
                      onChange={this.onChange}
                      helperText={
                        errors.DiseaseICD.length > 0 ? errors.DiseaseICD : ""
                      }
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  p: 3,
                }}
              >
                <Button type="submit" variant="contained">
                  Edytuj chorobę
                </Button>
              </Box>
            </form>
          </Card>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error,
    adding: state.adding,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (formData, icd) =>
      dispatch(actions.requestApi(formData, api(icd).DISEASE_UPDATE, "put")),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DiseaseFormEdit);
