import React, { Component } from "react";
import { connect } from "react-redux"; // new import
import * as actions from "../../../services/actions/auth";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Button,
  Typography,
  Alert,
  TextField,
} from "@mui/material";
import api from "../../../config/api/apiRoutes";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

class AccountNotificationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      smallAmountReagent: this.props.data.small_amount_reagent,
      smallAmountParentSample: this.props.data.small_amount_parent_sample,
      usedArchivedParentSample: this.props.data.used_archived_parent_sample,
      proceduresArchivedParentSample:
        this.props.data.procedures_archived_parent_sample,
      reagentsArchivedParentSample:
        this.props.data.reagents_archived_parent_sample,
      eventScheduled: this.props.data.event_scheduled,
      locationArchivedParentSample:
        this.props.data.location_archived_parent_sample,
      expirationDateReagent: this.props.data.expiration_date_reagent,
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    console.log(this.state);
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
    console.log(this.state);
  };

  formAction = (e) => {
    e.preventDefault();
    const formData = {
      small_amount_reagent: this.state.smallAmountReagent,
      small_amount_parent_sample: this.state.smallAmountParentSample,
      used_archived_parent_sample: this.state.usedArchivedParentSample,
      procedures_archived_parent_sample:
        this.state.proceduresArchivedParentSample,
      reagents_archived_parent_sample: this.state.reagentsArchivedParentSample,
      event_scheduled: this.state.eventScheduled,
      location_archived_parent_sample: this.state.locationArchivedParentSample,
      expiration_date_reagent: this.state.expirationDateReagent,
    };

    console.log(formData);

    this.props.onAuth(formData);
  };

  render() {
    console.log(this.props);

    return (
      <>
        {this.props.loading ? (
          <LoadingSpinner />
        ) : (
          <Card>
            <Typography
              variant="h6"
              sx={{ position: "relative", padding: "16px 0 20px 16px" }}
            >
              <span
                style={{
                  content: "",
                  backgroundColor: "#111e6c",
                  position: "absolute",
                  left: "0",
                  top: "16px",
                  width: "4px",
                  height: "25px",
                }}
              ></span>
              Notyfikacja mailowa
            </Typography>

            <Divider />
            <form onSubmit={this.formAction}>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <Alert severity="info">
                      Ten formularz odnosi się do powiadomienia e-mailowego,
                      który jest wysyłany do użytkownika.
                    </Alert>
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.smallAmountReagent}
                          onChange={this.handleChange}
                          inputProps={{ "aria-label": "controlled" }}
                          name="smallAmountReagent"
                        />
                      }
                      label="Niska zawartość odczynnika"
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.smallAmountParentSample}
                          onChange={this.handleChange}
                          inputProps={{ "aria-label": "controlled" }}
                          name="smallAmountParentSample"
                        />
                      }
                      label="Niska zawartość próbki nadrzędnej"
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.usedArchivedParentSample}
                          onChange={this.handleChange}
                          inputProps={{ "aria-label": "controlled" }}
                          name="usedArchivedParentSample"
                        />
                      }
                      label="Wykorzystanie próbki zarchiwizowanej"
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.proceduresArchivedParentSample}
                          onChange={this.handleChange}
                          inputProps={{ "aria-label": "controlled" }}
                          name="proceduresArchivedParentSample"
                        />
                      }
                      label="Procedura próbki zarchiwizowanej"
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.reagentsArchivedParentSample}
                          onChange={this.handleChange}
                          inputProps={{ "aria-label": "controlled" }}
                          name="reagentsArchivedParentSample"
                        />
                      }
                      label="Procedura próbki zarchiwizowanej"
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.eventScheduled}
                          onChange={this.handleChange}
                          inputProps={{ "aria-label": "controlled" }}
                          name="eventScheduled"
                        />
                      }
                      label="Zaplanowane działania"
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.locationArchivedParentSample}
                          onChange={this.handleChange}
                          inputProps={{ "aria-label": "controlled" }}
                          name="locationArchivedParentSample"
                        />
                      }
                      label="Lokalizacja próbki zarchiwizowanej"
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.expirationDateReagent}
                          onChange={this.handleChange}
                          inputProps={{ "aria-label": "controlled" }}
                          name="expirationDateReagent"
                        />
                      }
                      label="Data ważności odczynnika"
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  p: 3,
                }}
              >
                <Button type="submit" variant="contained">
                  Zmień dane
                </Button>
              </Box>
            </form>
          </Card>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error,
    adding: state.adding,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (formData) =>
      dispatch(
        actions.requestApi(formData, api().USERS_NOTIFICATION_UPDATE_PUT, "put")
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountNotificationForm);
