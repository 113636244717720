import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../services/actions/auth";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import FormHelperText from "@mui/material/FormHelperText";
import {
  Box,
  Container,
  Button,
  Card,
  Divider,
  Typography,
  TextField,
  Alert,
} from "@mui/material";
import api from "../../../config/api/apiRoutes";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";

const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach(
    // if we have an error string set valid to false
    (val) => val.length > 0 && (valid = false)
  );
  return valid;
};

class PasswordResetForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false, // nowy stan
      password: "",
      password_confirm: "",
      showPin: false,
      showPassword: false,
      errors: {
        email: "",
        password: "",
        password_confirm: "",
      },
    };
  }
  onChange = (e) => {
    console.log(e);
    this.setState({ [e.target.name]: e.target.value });
    const { name, value } = e.target;
    let errors = this.state.errors;
    switch (name) {
      case "password":
        errors.password =
          value.length < 8
            ? "Password must be at least 8 characters long!"
            : "";
        break;
      case "password_confirm":
        errors.password_confirm =
          value != this.state.password ? "Hasła się nie zgadzają" : "";
        break;

      default:
        break;
    }

    this.setState({ errors, [name]: value });
  };

  // update function to call the action
  onSignupClick = (e) => {
    e.preventDefault();


    const uidb64 = this.props.uidb64;
    const token = this.props.token;

    const userData = {
      uidb64: uidb64,
      token: token,
      password: this.state.password,
      password_confirm: this.state.password_confirm,
    };

    const validate = () => {
      let validationError = this.state.errors;
      if (!this.state.password) {
        validationError.password = "To pole jest wymagane";
      }
      if (!this.state.password_confirm) {
        validationError.password_confirm = "To pole jest wymagane";
      }

      this.setState({ validationError, validationError });
      console.log("in validate", validationError);
      return validationError;
    };
    const validationError = validate();
    console.log(this.state.errors);
    if (validateForm(validationError)) {
      console.info("Valid Form");
      this.props.onAuth(userData, uidb64, token);
      console.log(userData);
    } else {
      console.error("Invalid Form");
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.adding !== this.props.adding) {
      console.log("dziala");
      this.setState({ disabled: true });
    }
  }

  handleClickShowPassword = (e) => {
    console.log(e);
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  handleClickShowPin = (e) => {
    console.log(e);
    this.setState((prevState) => ({
      showPin: !prevState.showPin,
    }));
  };

  handleMouseDownPassword = (event) => {
    //event.preventDefault();
  };

  render() {
    const { errors } = this.state;

    if (this.props.adding == true) {
      this.props.props.history.push("/logowanie");
    }

    console.log(this.state);
    return (
      <>
        {this.props.loading ? (
          <LoadingSpinner />
        ) : (
          <Box
            component="main"
            sx={{
              alignItems: "center",
              display: "flex",
              flexGrow: 1,
              minHeight: "100%",
            }}
          >
            <Container maxWidth="sm">
              <Card sx={{ p: 5 }}>
                <form onSubmit={this.onSignupClick}>
                  <Box sx={{ my: 3 }}>
                    <Typography color="textPrimary" variant="h4">
                      Reset password
                    </Typography>
                    <Typography
                      color="textSecondary"
                      gutterBottom
                      variant="body2"
                    >
                      Wprowadź nowe hasło
                    </Typography>
                  </Box>

                  {this.state.disabled && (
                    <Alert severity="success">Hasło zostało zmienione</Alert>
                  )}

                  <FormControl
                    sx={{ width: "100%" }}
                    variant="outlined"
                    margin="normal"
                  >
                    <InputLabel htmlFor="outlined-adornment-password">
                      Hasło
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={this.state.showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowPassword}
                            onMouseDown={this.handleMouseDownPassword}
                            edge="end"
                          >
                            {this.state.showPin ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Hasło"
                      name="password"
                      disabled={this.state.disabled}
                      error={errors.password.length ? true : false}
                      value={this.state.password}
                      onChange={this.onChange}
                    />
                    {errors.password.length > 0 && (
                      <FormHelperText error={true}>
                        {errors.password}
                      </FormHelperText>
                    )}
                  </FormControl>

                  <TextField
                    type="password"
                    name="password_confirm"
                    fullWidth
                    margin="normal"
                    placeholder="Powtórz Hasło"
                    disabled={this.state.disabled}
                    value={this.state.password_confirm}
                    onChange={this.onChange}
                    label="Powtórz Hasło"
                    variant="outlined"
                    helperText={
                      errors.password_confirm.length > 0
                        ? errors.password_confirm
                        : ""
                    }
                    error={errors.password_confirm.length ? true : false}
                  />

                  <Box sx={{ py: 2 }}>
                    <Button
                      color="primary"
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      disabled={this.state.disabled}
                    >
                      Reset password
                    </Button>
                  </Box>
                </form>
              </Card>
            </Container>
          </Box>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error,
    adding: state.adding,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (formData, uidb64, token) =>
      dispatch(
        actions.resetPassword(
          formData,
          api(uidb64, token).RESET_PASSWORD_POST,
          "post"
        )
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordResetForm);
