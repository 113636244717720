import React, { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import { Container } from "@mui/material";
import axios from "axios";
import api from "../../config/api/apiRoutes";
import { Link } from "react-router-dom";
import { Divider } from "rsuite";
import Typography from "@mui/material/Typography";
import BasicTable from "../../components/Table/BasicTable";
import intialColumnsSampleList from "../../components/Sample/table/intialColumnsSampleList";
import intialColumnsDashboardEvents from "../../components/calendar/table/intialColumnsDashboardEvents";
import CoreTableFilter from "../../components/Table/CoreTableFilter";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Dashboard(props) {
  const [dashboard, setDashboard] = useState([]);
  const [dashboardUser, setDashboardUser] = useState([]);
  const [value, setValue] = React.useState(0);

  const [isLoading, setIsLoading] = useState(true);

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const date = new Date().toLocaleDateString("pl-PL", options);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    const getData = async () => {
      try {
        const getDashboard = await axios.get(api().DASHBOARD_GET);
        const getDataDashboard = getDashboard.data;

        const getDashboardUser = await axios.get(api().DASHBOARD_USER_GET);
        const getDataDashboardUser = getDashboardUser.data;

        console.log(getDataDashboard);

        console.log(getDataDashboardUser);

        setDashboard(getDataDashboard);
        setIsLoading(false);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      }
    };

    getData();
  }, []);

  return (
    <Container maxWidth={false}>
      <Grid justify="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h5" display="inline">
            Witaj ponownie, {localStorage.getItem("user_email")}
          </Typography>

          <Typography variant="body2" ml={2} display="flex">
            {date}
          </Typography>
        </Grid>
      </Grid>
      <Box sx={{ width: "100%", mt: 2  }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Dzisiejsze zadania" {...a11yProps(0)} />
            <Tab label="Lista próbek bez lokalizacji" {...a11yProps(1)} />
            <Tab label="Lista próbek bez wyników" {...a11yProps(2)} />
            <Tab
              label="Lista próbek o statusie do zbadania"
              {...a11yProps(3)}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <CoreTableFilter
            data={dashboard.tasks}
            isLoading={isLoading}
            intialColumns={intialColumnsDashboardEvents}
            headerTable={"Dzisiejsze zadania"}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <CoreTableFilter
            data={dashboard.localization}
            isLoading={isLoading}
            intialColumns={intialColumnsSampleList}
            headerTable={"Lista próbek bez lokalizacji"}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <CoreTableFilter
            data={dashboard.results}
            isLoading={isLoading}
            intialColumns={intialColumnsSampleList}
            headerTable={"Lista próbek bez wyników"}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <CoreTableFilter
            data={dashboard.status}
            isLoading={isLoading}
            intialColumns={intialColumnsSampleList}
            headerTable={"Lista próbek o statusie do zbadania"}
          />
        </TabPanel>
      </Box>
      <Box sx={{ mt: 3, mb: 5 }}>
        <Grid container spacing={6}></Grid>
      </Box>
    </Container>
  );
}

export default Dashboard;
