import React, { useState, useEffect } from "react";
import axios from "axios";
import api from "../../../config/api/apiRoutes";
import { useParams } from "react-router-dom";
import { Box, Container, Grid, Typography } from "@mui/material";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import TimeLine from "../../../components/Timeline/TimeLine";
import { Divider } from "rsuite";
import HomeIcon from "@mui/icons-material/Home";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";

function ReagentTimeline(props) {
  const [data, setData] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  let { id } = useParams();

  console.log(id);

  useEffect(() => {
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    const getData = async () => {
      try {
        const getReagentTimeline = await axios.get(
          api("Reagent", id).TIMELINE_GET
        );
        const getDataReagentTimeline = getReagentTimeline.data;
        console.log(getDataReagentTimeline);

        setData(getDataReagentTimeline);
        setIsLoading(false);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      }
    };

    getData();
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Container maxWidth={false}>
      <Typography variant="h5" style={{ fontWeight: "400" }}>
        Timeline odczynnika {id}
      </Typography>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" to="/" style={{ color: "#888" }}>
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Dashboard
        </Link>
        <Link underline="hover" to="/reagent" style={{ color: "#888" }}>
          Lista odczynników
        </Link>
        <Link underline="hover" to={`/reagent/${id}`} style={{ color: "#888" }}>
          Szczegóły odczynnika {id}
        </Link>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="#111"
          style={{ fontWeight: "600" }}
        >
          Timeline odczynnika
        </Typography>
      </Breadcrumbs>
      <Divider />
      <Grid container spacing={3}>
        <Grid item lg={12} md={12} xs={12}>
          <TimeLine data={data} />
        </Grid>
      </Grid>
    </Container>
  );
}

export default ReagentTimeline;
