import React from "react";
import * as XLSX from "xlsx";
import { ExportToCsv } from "export-to-csv"; //or use your library of choice here
import { ExportToXlsx } from "../xlsx/ExportToXlsx";
export const exportSelectedRow = (selectedRow, visibleLeafColumns) => {
  console.log(selectedRow);

  let getSelectedRows = selectedRow;
  let visibleColumns = visibleLeafColumns;
  let getAllSelectedRows = getSelectedRows.rows;

  const newVisibleColumns = visibleColumns.filter(
    (item, index) =>
      index !== 0 &&
      item.id !== "Szczegóły" &&
      item.id !== "Edytuj" &&
      item.id !== "Akcja" &&
      item.id !== "Przejście do podpróbki" &&
      item.id !== "Usuń"
  );

  const getidVisibleColumns = newVisibleColumns.map((item) => item.id);

  console.log(getidVisibleColumns);

  const getAllSelectedRowsData = getAllSelectedRows.map(
    (item) => item.original
  );

  const transformedVisibleColumns = getidVisibleColumns.map((attribute) => {
    if (attribute === "attribute_attribute.attribute") {
      return "attribute_attribute";
    }
    return attribute;
  });

  console.log(transformedVisibleColumns);

  console.log(getAllSelectedRowsData);

  const transformedData = getAllSelectedRowsData.map((row) => {
    let newRow = { ...row };
    const extraValues = newRow.extra_values;
    const result = newRow.result;

    if (extraValues) {
      extraValues.forEach((extraValue) => {
        const attribute =
          extraValue.attribute_attribute.attribute.toLowerCase();
        const value = extraValue.short_text;
        newRow[attribute] = value;
      });

      delete newRow.extra_values;
    }

    if (newRow.attribute_attribute) {
      newRow.attribute_attribute = newRow.attribute_attribute.attribute;
    }

    if (result) {
      const resultMap = {};
      result.forEach((resultValue) => {
        const attribute = resultValue.attribute_name;
        const value = resultValue.short_text;
        if (resultMap[attribute] === undefined) {
          resultMap[attribute] = [];
        }
        if (value !== null) {
          resultMap[attribute].push(value);
        }
      });

      Object.entries(resultMap).forEach(([attribute, values]) => {
        newRow[attribute.toLowerCase()] = values.reduce((acc, val, index) => {
          if (index === 0) {
            return val;
          }
          return `${acc}, ${val}`;
        }, "");
      });

      delete newRow.result;
    }

    return newRow;
  });

  console.log(transformedData);

  const filteredItems = transformedData.map((item) => {
    let filteredItem = {};
    transformedVisibleColumns.forEach((property) => {
      if (item.hasOwnProperty(property.toLowerCase())) {
        filteredItem[property] = item[property];
      }
    });
    return filteredItem;
  });

  console.log(filteredItems);

  const csvOptions2 = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: getidVisibleColumns.map((c) => c),
  };

  const csvExporter2 = new ExportToCsv(csvOptions2);

  csvExporter2.generateCsv(filteredItems);
};
