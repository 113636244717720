import logo from '../assets/images/biobank-logo.png';
import { Link } from 'react-router-dom';

const Logo = () => {
  return (
    <Link to="/">
      <img src={logo} alt="biobank logo" width="250" />
    </Link>
  );
};

export default Logo;
