
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_DELIVERY_SUCCESS = 'AUTH_DELIVERY_SUCCESS';
export const AUTH_BARCODE_SUCCESS = 'AUTH_BARCODE_SUCCESS';
export const AUTH_DELIVERY_FAIL = 'AUTH_DELIVERY_FAIL';
export const CHECK_TOKEN_SUCCES = 'CHECK_TOKEN_SUCCES';
export const CHECK_TOKEN_FAIL = 'CHECK_TOKEN_FAIL';
export const AUTH_END = 'AUTH_END';
export const SET_LOADING = 'SET_LOADING';
export const DELETE_PROCEDURE_REAGENT_SUCCESS = 'DELETE_PROCEDURE_REAGENT_SUCCESS';
export const UPDATE_THEME_1 = 'UPDATE_THEME_1';
export const UPDATE_THEME_2 = 'UPDATE_THEME_2';