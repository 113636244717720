import React, { useState, useEffect } from "react";
import axios from "axios";
import api from "../../../config/api/apiRoutes";
import { Box, Container, Grid, Typography } from "@mui/material";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AccountTabsData from "../../../components/Account/Tabs/AccountTabsData";
import HomeIcon from "@mui/icons-material/Home";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";

function AccountDetailChange(props) {
  const [dashboardFilter, setDashboardFilter] = useState([]);
  const [userNotification, setUserNotification] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);

  const [isLoading, setIsLoading] = useState(true);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    const getData = async () => {
      try {
        const getDashboardFilter = await axios.get(api().DASHBOARD_USER_GET);
        const getDataDashboardFilter = getDashboardFilter.data;

        const getUserNotification = await axios.get(
          api().USERS_NOTIFICATION_GET
        );
        const getDataUserNotification = getUserNotification.data;

        console.log(getDataUserNotification);

        setDashboardFilter(getDataDashboardFilter);
        setUserNotification(getDataUserNotification);

        setIsLoading(false);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      }
    };

    getData();
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Container maxWidth={false}>
      <Typography variant="h5" style={{ fontWeight: "400" }}>
        Ustawienia użytkownika
      </Typography>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" to="/" style={{ color: "#888" }}>
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Dashboard
        </Link>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="#111"
          style={{ fontWeight: "600" }}
        >
          Ustawienia użytkownika
        </Typography>
      </Breadcrumbs>
      <Box sx={{ width: "100%", mt: 2 }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={selectedTab} onChange={handleTabChange}>
            {AccountTabsData(props, dashboardFilter, userNotification).map(
              (tab) => (
                <Tab key={tab.id} label={tab.label} />
              )
            )}
          </Tabs>
        </Box>

        {AccountTabsData(props, dashboardFilter, userNotification).map(
          (tab) =>
            selectedTab === tab.id && (
              <Box key={tab.id} sx={{ p: 3 }}>
                <Typography>{tab.content}</Typography>
              </Box>
            )
        )}
      </Box>
    </Container>
  );
}

export default AccountDetailChange;
