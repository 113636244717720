import {
  Box,
  Button,
  Card,
  CardContent,
  FormHelperText,
  Grid,
  LinearProgress,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { array, object, string } from "yup";
import axios from "axios";
import api from "../../../config/api/apiRoutes";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { MultipleFileUploadField } from "./MultipleFileUploadField";
import FileUploadIcon from "@mui/icons-material/FileUpload";

export default function FileAdd({
  patient_id,
  setLoading,
  refreshData,
  closePopup,
}) {
  const [files, setFiles] = useState([]);
  const [progress, setProgress] = useState(0);
  const history = useHistory();

  const onUpload = (file, url) => {
    setFiles((curr) =>
      curr.map((fw) => {
        if (fw.file === file) {
          return { ...fw, url };
        }
        return fw;
      })
    );
  };

  const onSubmit = async (values, { setSubmitting }) => {
    setLoading(true);
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
        onUploadProgress: (progressEvent) => {
          console.log(progressEvent.loaded);
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(progress);
        },
      };

      const formData = new FormData();
      values.files.forEach((item) => {
        console.log(item);
        formData.append("file", item.file);
      });

      await axios.post(api(patient_id).PATIENT_FILE_UPLOAD, formData, config);

      toast.success("Sukces!");
      setLoading(false);
      // W przypadku sukcesu możesz zaktualizować stan lub wykonać inne operacje
      setProgress(100);
      refreshData();
      closePopup();
    } catch (error) {
      // Obsłuż błędy w przypadku niepowodzenia żądania
      console.error("Error:", error);
    }
  };

  return (
    <Formik
      initialValues={{ files: [] }}
      validationSchema={Yup.object({
        files: Yup.array()
          .of(
            Yup.object({
              file: Yup.string().required(),
            })
          )
          .min(1, "Przynajmniej jeden plik musi być dodany."),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        console.log(values);
        try {
          onSubmit(values, setSubmitting);
        } catch (err) {
          console.error(err);
        }
      }}
    >
      {({ values, errors, isValid, isSubmitting, touched }) => (
        <Form>
          <Grid container spacing={3} direction="column">
            <MultipleFileUploadField
              name="files"
              files={files}
              setFiles={setFiles}
              onUpload={onUpload}
            />
            <Grid item>
              {touched.files && errors.files && (
                <FormHelperText
                  error
                  id="standard-weight-helper-text-email-login"
                >
                  {errors.files}
                </FormHelperText>
              )}
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "end", mt: 2 }}>
            <Button
              disableElevation
              disabled={isSubmitting}
              type="submit"
              variant="contained"
              color="primary"
              endIcon={<FileUploadIcon />}
            >
              Dodaj
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
}
