import { formLabelClasses } from "@mui/material";
import * as actionTypes from "../actions/ActionTypes";
import { updateObject } from "../utility/utility";

const initialState = {
  token: null,
  error: null,
  loading: false,
  loading_page: true,
  login_auth: false,
  token_auth: false,
  adding: false,
  selectedTheme: "theme1",
};

const authStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const authSuccess = (state, action) => {
  return updateObject(state, {
    token: action.token,
    error: null,
    loading: false,
    login_auth: true,
    loading_page: false,
    token_auth: true,
  });
};

const authFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    login_auth: false,
    loading_page: true,
  });
};

const authLogout = (state, action) => {
  console.log("tutaj");
  return updateObject(state, {
    token: null,
    login_auth: false,
    loading_page: false,
  });
};

const authDeliverySuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    adding: true,
    data: action.data,
  });
};

const authDeliveryFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const checkTokenSuccess = (state, action) => {
  return updateObject(state, {
    token_auth: true,
  });
};

const checkTokenFail = (state, action) => {
  return updateObject(state, {
    token_auth: false,
  });
};

const authEnd = (state, action) => {
  return updateObject(state, {
    adding: false,
  });
};

const authBarcodeSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    adding: true,
  });
};

const updateTheme1 = (state, action) => {
  return updateObject(state, {
    selectedTheme: "theme1",
  });
};

const updateTheme2 = (state, action) => {
  return updateObject(state, {
    selectedTheme: "theme2",
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);
    case actionTypes.AUTH_DELIVERY_SUCCESS:
      return authDeliverySuccess(state, action);
    case actionTypes.AUTH_DELIVERY_FAIL:
      return authDeliveryFail(state, action);
    case actionTypes.AUTH_END:
      return authEnd(state, action);
    case actionTypes.CHECK_TOKEN_FAIL:
      return checkTokenFail(state, action);
    case actionTypes.CHECK_TOKEN_SUCCES:
      return checkTokenSuccess(state, action);
    case actionTypes.UPDATE_THEME_1:
      return updateTheme1(state, action);
      case actionTypes.UPDATE_THEME_2:
        return updateTheme2(state, action);
    default:
      return state;
  }
};

export default reducer;
