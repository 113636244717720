import React, { useState, useEffect } from "react";
import MaterialReactTable from "material-react-table";
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  Divider,
  Stack,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { ExportToXlsx } from "../xlsx/ExportToXlsx";
import { exportSelectedRow } from "../exportData/exportSelectedRow";
import { Add } from "@material-ui/icons";
import { Link } from "react-router-dom";

const BasicTable = (tableData) => {
  const [open, setOpen] = React.useState(false);
  const [row, setRow] = useState("");
  const [column, setColumn] = useState("");
  const [data, setData] = useState("");

  const handleClickOpen = (selectedRow, visibleLeafColumns) => {
    setOpen(true);
    setRow(selectedRow);
    setColumn(visibleLeafColumns);
  };

  const exportCsv = () => {
    exportSelectedRow(row, column);
    setOpen(false);
  };

  const exportXlsx = () => {
    ExportToXlsx(row, column);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (
      tableData.props &&
      tableData.props.match &&
      tableData.props.match.params.id
    ) {
      const rowsWithId = tableData.data.map((row) => {
        return { ...row, id_path: tableData.props.match.params.id };
      });
      setData(rowsWithId);
      console.log(rowsWithId);
    } else {
      setData(tableData.data);
    }
  }, [tableData.props, tableData.data]);

  return (
    <>
      <Card>
        <CardContent sx={{ padding: "16px 0 0 16px" }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Typography
              variant="h6"
              sx={{ position: "relative", paddingLeft: "4px" }}
            >
              <span
                style={{
                  content: "",
                  backgroundColor: "#111e6c",
                  position: "absolute",
                  left: "-16px",
                  top: 0,
                  width: "4px",
                  height: "25px",
                }}
              ></span>
              {tableData.headerTable}
            </Typography>
            {tableData.isSecondary && (
              <Button
                variant="outlined"
                sx={{ marginRight: "20px !important" }}
                endIcon={<Add />}
                component={Link}
                to={tableData.secondaryAction}
              >
                Dodaj atrybut
              </Button>
            )}
          </Stack>
        </CardContent>
        <Divider sx={{ p: 1 }} />
        <MaterialReactTable
          columns={tableData.intialColumns}
          data={data ?? []}
          state={{ isLoading: tableData.isLoading }}
          enableRowSelection
          positionToolbarAlertBanner="bottom"
          enableRowNumbers
          initialState={{
            columnVisibility: { id: false },
            sorting: [
              { id: "id", desc: true }, //sort by state in ascending order by default
            ],
          }}
          rowNumberMode="original" //default
          renderTopToolbarCustomActions={({ table }) => (
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                p: "0.5rem",
                flexWrap: "wrap",
              }}
            >
              <Button
                color="primary"
                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                onClick={() =>
                  handleClickOpen(
                    table.getPrePaginationRowModel(),
                    table.getAllColumns()
                  )
                }
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export All Data
              </Button>
              <Button
                disabled={table.getPrePaginationRowModel().rows.length === 0}
                //export all rows, including from the next page, (still respects filtering and sorting)
                onClick={() =>
                  handleClickOpen(
                    table.getPrePaginationRowModel(),
                    table.getVisibleLeafColumns()
                  )
                }
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export All Rows
              </Button>
              <Button
                disabled={table.getRowModel().rows.length === 0}
                //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                onClick={() =>
                  handleClickOpen(
                    table.getRowModel(),
                    table.getVisibleLeafColumns()
                  )
                }
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export Page Rows
              </Button>
              <Button
                disabled={
                  !table.getIsSomeRowsSelected() &&
                  !table.getIsAllRowsSelected()
                }
                //only export selected rows
                onClick={() =>
                  handleClickOpen(
                    table.getSelectedRowModel(),
                    table.getVisibleLeafColumns()
                  )
                }
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export Selected Rows
              </Button>
            </Box>
          )}
        />
      </Card>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Wybierz format"}</DialogTitle>

        <DialogActions>
          <Button onClick={exportCsv}>.csv</Button>
          <Button onClick={exportXlsx} autoFocus>
            .xlsx
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BasicTable;
