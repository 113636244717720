import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  Controls,
  Background,
} from "reactflow";
import "reactflow/dist/style.css";
import { useParams } from "react-router-dom";
import ButtonEdge from "./ButtonEdge";
import api from "../../config/api/apiRoutes";
import axios from "axios";
import CustomEdge from "./CustomEdge";
import Sidebar from "./Sidebar/Sidebar";
import CustomNode from "./CustomNode";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import {
  nodes as initialNodes,
  edges as initialEdges,
  edges,
} from "./initialElements";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import "./index.css";

let id = 0;
const getId = () => `dndnode_${id++}`;

const flowKey = "example-flow";

const nodeTypes = {
  turbo: CustomNode,
};

const nodesx = [
  {
    id: "52552b9c-abab-4325-ba5b-c06b72d00529",
    type: "turbo",
    position: {
      x: 500,
      y: 300,
    },
    data: {
      title: "Status : NOWA",
    },
    style: {
      background: "#2B6CB0",
    },
  },
  {
    id: "2745",
    type: "turbo",
    position: {
      x: 500,
      y: 600,
    },
    data: {
      title: "Status : DO ZBADANIA",
    },
    style: {
      background: "#2B6CB0",
    },
  },
  {
    id: "2746",
    type: "turbo",
    position: {
      x: 500,
      y: 900,
    },
    data: {
      title: "Status : ZBADANA",
    },
    style: {
      background: "#2B6CB0",
    },
  },
  {
    id: "2747",
    type: "turbo",
    position: {
      x: 500,
      y: 1100,
    },
    data: {
      title: "Status : ZARCHIWIZOWANA",
    },
    style: {
      background: "#2B6CB0",
    },
  },
];

const edgesx = [
  {
    id: 1353,
    source: "52552b9c-abab-4325-ba5b-c06b72d00529",
    target: "2745",
    type: "step",
    style: {
      stroke: "#111e6c",
    },
    label: null,
    animated: false,
    label_style: {},
  },
  {
    id: 1354,
    source: "2745",
    target: "2746",
    type: "step",
    style: {
      stroke: "#111e6c",
    },
    label: null,
    animated: false,
    label_style: {},
  },
  {
    id: 1355,
    source: "2746",
    target: "2747",
    type: "step",
    style: {
      stroke: "#111e6c",
    },
    label: null,
    animated: false,
    label_style: {},
  },
];

const DnDFlow = ({ sampleNodes, sampleEdges }) => {
  console.log(sampleNodes);
  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState(sampleNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(sampleEdges);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [isSelected, setIsSelected] = useState(false);
  const [selectedNode, setSelectedNode] = useState(null);
  const [nodeName, setNodeName] = useState("Node 1");
  const [rfInstance, setRfInstance] = useState(null);
  const [loading, setLoading] = useState(false);

  let { id } = useParams();

  let id_sample = id;

  const textRef = useRef(null);

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge({ ...params, type: "step" }, eds)),
    []
  );
  const edgeTypes = useMemo(
    () => ({
      step: (props) => (
        <ButtonEdge
          {...props}
          onClick={(event, toDelete) => {
            console.log(toDelete);
            setEdges((edgs) => edgs.filter((edg) => edg.id !== toDelete));
          }}
        />
      ),
    }),
    []
  );

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  useEffect(() => {
    const node = nodes.filter((node) => {
      if (node.selected) return true;
      return false;
    });
    if (node[0]) {
      setSelectedNode(node[0]);
      setIsSelected(true);
    } else {
      setSelectedNode("");
      setIsSelected(false);
    }
  }, [nodes]);

  useEffect(() => {
    console.log(selectedNode?.data);
    setNodeName(selectedNode?.data?.title || selectedNode);
  }, [selectedNode]);
  useEffect(() => {
    textRef?.current?.focus();
  }, [selectedNode]);
  useEffect(() => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === selectedNode?.id) {
          node.data = {
            ...node.data,
            title: nodeName || " ",
          };
        }
        return node;
      })
    );
  }, [nodeName, setNodes]);

  const createNode = async (nodes) => {
    setLoading(true);
    console.log(nodes);
    const nodesFilter = nodes.map(({ id, ...item }) => ({
      ...item,
      sample: id_sample,
    }));

    console.log(nodesFilter);

    const roundedData = nodesFilter.map((item) => ({
      ...item,
      position: {
        x: Math.round(item.position.x),
        y: Math.round(item.position.y),
      },
      positionAbsolute: {
        x: Math.round(item.positionAbsolute.x),
        y: Math.round(item.positionAbsolute.y),
      },
    }));

    console.log(roundedData);

    //setLoading(true);
    console.log("statrt");
    const response = await axios.post(
      api().FLOW_NODE_CREATE_POST,
      roundedData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      }
    );
    console.log(response.data);

    console.log("1");
  };

  const createEdges = async (edges) => {
    console.log(edges);

    const edgesFilter = edges.map(({ id, ...item }) => ({
      ...item,
      sample: id_sample,
      type: "step",
      label: "",
      style: { stroke: "#111e6c" },
    }));

    console.log(edgesFilter);

    try {
      const response = await axios.post(
        api().FLOW_EDGE_CREATE_POST,
        edgesFilter,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${localStorage.getItem("token")}`,
          },
        }
      );
      console.log(response.data);
      toast.success("Sukces!")
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
    console.log("2");
  };

  const onSave = useCallback(() => {
    if (reactFlowInstance) {
      console.log(reactFlowInstance.getNodes());
      const flow = reactFlowInstance.toObject();

      createNode(reactFlowInstance.getNodes());
      createEdges(reactFlowInstance.getEdges());

      console.log(reactFlowInstance.toObject());
      console.log(reactFlowInstance.getEdges());

      //localStorage.setItem(flowKey, JSON.stringify(flow));
    }
  }, [reactFlowInstance]);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData("application/reactflow");

      const position = reactFlowInstance.project({
        x: Math.round(event.clientX - reactFlowBounds.left),
        y: Math.round(event.clientY - reactFlowBounds.top),
      });
      console.log(reactFlowInstance.getNodes());
      const highestId = Math.max(
        ...reactFlowInstance.getNodes().map((item) => parseInt(item.id_node))
      );
      const newId = highestId + 1;
      const uuid = uuidv4();
      console.log(newId);

      console.log(nodes);

      const newNode = {
        id: uuid,
        id_node: newId.toString(),
        checksum: uuid,
        type: "turbo",
        position,
        data: { title: `Dodatkowe informacje` },
        style: {
          background: "#2B6CB0",
        },
      };

      setNodes((nds) => nds.concat(newNode));
    },
    [reactFlowInstance]
  );

  const onDeleteNode = useCallback(() => {
    console.log("ddd");
    if (selectedNode) {
      setEdges((eds) =>
        eds.filter(
          (edge) =>
            edge.source !== selectedNode.id && edge.target !== selectedNode.id
        )
      );
      setNodes((nds) => nds.filter((node) => node.id !== selectedNode.id));
      setSelectedNode(null);
    }
  }, [selectedNode, setEdges, setNodes]);

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="dndflow">
          <ReactFlowProvider>
            <div className="reactflow-wrapper" ref={reactFlowWrapper}>
              <ReactFlow
                nodes={nodes}
                edges={edges}
                edgeTypes={edgeTypes}
                nodeTypes={nodeTypes}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                onInit={setReactFlowInstance}
                onDrop={onDrop}
                onDragOver={onDragOver}
                attributionPosition="bottom-left"
                fitView
              >
                <Controls />
                <Background gap={25} />
              </ReactFlow>
            </div>
            <Sidebar
              isSelected={isSelected}
              textRef={textRef}
              nodeName={nodeName}
              setNodeName={setNodeName}
              onSave={onSave}
              onDelete={onDeleteNode}
            />
          </ReactFlowProvider>
        </div>
      )}
    </>
  );
};

export default DnDFlow;
