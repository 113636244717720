import BootstrapDialogTitle from "./BootstrapDialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";

export default function Popup(props) {
  return (
    <BootstrapDialog
      onClose={props.handleClosePopover}
      aria-labelledby="customized-dialog-title"
      open={props.showPopover}
      fullWidth={props.fullWidth}
      maxWidth={props.maxWidth}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={props.handleClosePopover}
      >
        {props.title}
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <DialogContentText id="alert-dialog-description">
          {props.content}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ display: props.displayDialogActions }}>
        <Button
          variant="contained"
          onClick={props.request1}
          endIcon={<props.iconButton1 />}
          color="primary"
        >
          {props.closeText}
        </Button>
        <Button
          variant="contained"
          onClick={props.request2}
          endIcon={<props.iconButton2 />}
          color="primary"
        >
          {props.confirmText}
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
