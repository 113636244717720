import React, { useState } from "react";
import MaterialReactTable from "material-react-table";
import {
  Box,
  Button,
  TextField,
  Grid,
  Card,
  CardContent,
  Typography,
  Divider,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { ExportToXlsx } from "../xlsx/ExportToXlsx";
import { exportSelectedRow } from "../exportData/exportSelectedRow";
import { Link } from "react-router-dom";

const ExtensionTableSample = (tableData) => {
  console.log(tableData);
  const [open, setOpen] = React.useState(false);
  const [row, setRow] = useState("");
  const [column, setColumn] = useState(tableData.intialColumns);
  const [columns, setColumns] = useState("");
  const [name, setName] = useState("");

  const handleClickOpen = (selectedRow, visibleLeafColumns) => {
    setOpen(true);
    setRow(selectedRow);
    setColumns(visibleLeafColumns);
  };

  const changeName = (event) => {
    setName(event.target.value);
    console.log(name);
  };

  const exportCsv = () => {
    exportSelectedRow(row, columns);
    setOpen(false);
  };

  const exportXlsx = () => {
    ExportToXlsx(row, columns);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const addColumn = () => {
    setColumn((prevCols) => [
      ...prevCols,
      {
        accessorFn: (row) =>
          row.result.map((item) => (
            <>
              {item.attribute_name.toLowerCase() == name.toLowerCase() ? (
                <div>
                  <div>Atrybut: {item.attribute_name}</div>
                  <div>Wartość: {item.short_text}</div>
                </div>
              ) : (
                ""
              )}
            </>
          )),

        header: name,
        size: 120,
      },
    ]);
  };

  return (
    <>
      <Card>
        <CardContent sx={{ padding: "16px 0 0 16px" }}>
          <Typography
            variant="h6"
            sx={{ position: "relative", paddingLeft: "4px" }}
          >
            <span
              style={{
                content: "",
                backgroundColor: "#111e6c",
                position: "absolute",
                left: "-16px",
                top: 0,
                width: "4px",
                height: "25px",
              }}
            ></span>
            {tableData.headerTable}
          </Typography>
        </CardContent>
        <Divider sx={{ p: 1 }} />
        <Grid container spacing={2} sx={{ m: 1 }}>
          <Grid item display="flex" justifyContent="center" alignItems="center">
            <TextField
              label="Nazwa"
              id="outlined-helperText"
              onChange={changeName}
            />
          </Grid>
          <Grid item display="flex" justifyContent="center" alignItems="center">
            <Button
              disabled={!name}
              variant="contained"
              onClick={addColumn}
              size="medium"
            >
              Dodaj kolumnę
            </Button>
          </Grid>
        </Grid>
        <MaterialReactTable
          columns={column}
          enableRowNumbers
          rowNumberMode="original"
          data={tableData.data ?? []}
          state={{ isLoading: tableData.isLoading }}
          initialState={{
            columnVisibility: { id: false },
            sorting: [
              { id: "id", desc: true }, //sort by state in ascending order by default
            ],
          }}
          enableRowSelection
          positionToolbarAlertBanner="bottom"
          style={{ boxShadow: "0 0 14px 0 rgba(53,64,82,.05) !important" }}
          renderTopToolbarCustomActions={({ table }) => (
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                p: "0.5rem",
                flexWrap: "wrap",
              }}
            >
              <Button
                color="primary"
                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                onClick={() =>
                  handleClickOpen(
                    table.getPrePaginationRowModel(),
                    table.getAllColumns()
                  )
                }
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export All Data
              </Button>
              <Button
                disabled={table.getPrePaginationRowModel().rows.length === 0}
                //export all rows, including from the next page, (still respects filtering and sorting)
                onClick={() =>
                  handleClickOpen(
                    table.getPrePaginationRowModel(),
                    table.getVisibleLeafColumns()
                  )
                }
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export All Rows
              </Button>
              <Button
                disabled={table.getRowModel().rows.length === 0}
                //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                onClick={() =>
                  handleClickOpen(
                    table.getRowModel(),
                    table.getVisibleLeafColumns()
                  )
                }
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export Page Rows
              </Button>
              <Button
                disabled={
                  !table.getIsSomeRowsSelected() &&
                  !table.getIsAllRowsSelected()
                }
                //only export selected rows
                onClick={() =>
                  handleClickOpen(
                    table.getSelectedRowModel(),
                    table.getVisibleLeafColumns()
                  )
                }
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export Selected Rows
              </Button>
            </Box>
          )}
        />
      </Card>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Wybierz format"}</DialogTitle>

        <DialogActions>
          <Button onClick={exportCsv}>.csv</Button>
          <Button onClick={exportXlsx} autoFocus>
            .xlsx
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ExtensionTableSample;
