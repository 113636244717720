/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-router-dom components
import { Link } from "react-router-dom";
import { Typography, Box, Button } from "@mui/material";

function DefaultNavbarDropdown({ name, icon, route, ...rest }) {
  return (
    <>
      <Box
        {...rest}
        mx={1}
        p={1}
        display="flex"
        alignItems="baseline"
        color="#111e6c"
        sx={{ cursor: "pointer", userSelect: "none" }}
      >
        <Button variant="text" startIcon={icon} component={Link} to={route}>
          {name}
        </Button>
      </Box>
    </>
  );
}

export default DefaultNavbarDropdown;
