import React, { useEffect, useState } from "react";
import { Box, Button, Card, Container, Typography, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import axios from "axios";
import api from "../../../config/api/apiRoutes";
import BasicTable from "../../../components/Table/BasicTable";
import { useParams } from "react-router-dom";
import intialColumnsProcedureEquipment from "../../../components/Procedure/Table/intialColumnsProcedureEquipment";
import intialColumnsProcedureReagent from "../../../components/Procedure/Table/intialColumnsProcedureReagent";
import intialColumnsProcedureProcess from "../../../components/Procedure/Table/intialColumnsProcedureProcess";
import intialColumnsProcedureSamples from "../../../components/Procedure/Table/intialColumnsProcedureSamples";
import CoreTable from "../../../components/Table/CoreTable";
import HomeIcon from "@mui/icons-material/Home";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import intialColumnsProcedureDetails from "../../../components/Procedure/Table/intialColumnsProcedureDetails";
import Stack from "@mui/material/Stack";
import TableActionButton from "../../../components/Button/TableActionButton";
import EditIcon from "@mui/icons-material/Edit";
import TimelineIcon from "@mui/icons-material/Timeline";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function ProcedureDetails(props) {
  const [procedureDetails, setProcedureDetails] = useState([]);
  const [procedureEquipment, setProcedureEquipment] = useState([]);
  const [procedureReagent, setProcedureReagent] = useState([]);
  const [procedureProcess, setProcedureProcess] = useState([]);
  const [procedureSamples, setProcedureSamples] = useState([]);
  const [value, setValue] = React.useState(0);

  const [isLoading, setIsLoading] = useState(true);

  let { id } = useParams();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    const getData = async () => {
      try {
        const getProcedureDetails = await axios.get(
          api(id).PROCEDURE_DETAILS_GET
        );
        const getDataProcedureDetails = getProcedureDetails.data;
        const getDataProcedureEquipment = getDataProcedureDetails.equipment;
        const getDataProcedureReagents = getDataProcedureDetails.reagents;
        const getDataProcedureProcess = getDataProcedureDetails.process;
        const getProcedureSample = await axios.get(
          api(id).PROCEDURE_SAMPLE_GET
        );
        const getDataProcedureSample = getProcedureSample.data;

        console.log(getDataProcedureDetails);
        setProcedureDetails(getDataProcedureDetails);
        setProcedureEquipment(getDataProcedureEquipment);
        setProcedureReagent(getDataProcedureReagents);
        setProcedureProcess(getDataProcedureProcess);
        setProcedureSamples(getDataProcedureSample);

        setIsLoading(false);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      }
    };

    getData();
  }, []);
  return (
    <Container maxWidth={false}>
      <Typography variant="h5" style={{ fontWeight: "400" }}>
        Szczegóły procedury {procedureDetails.id}
      </Typography>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" to="/" style={{ color: "#888" }}>
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Dashboard
        </Link>
        <Link underline="hover" to="/procedure" style={{ color: "#888" }}>
          Lista procedur
        </Link>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="#111"
          style={{ fontWeight: "600" }}
        >
          Szczegóły procedury
        </Typography>
      </Breadcrumbs>
      <Box sx={{ width: "100%", mt: 2 }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Główne informacje" {...a11yProps(0)} />
            <Tab label="Wyposażenie" {...a11yProps(1)} />
            <Tab label="Odczynniki" {...a11yProps(2)} />
            <Tab label="Procesy" {...a11yProps(3)} />
            <Tab label="Wykorzystywane próbki" {...a11yProps(4)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            spacing={2}
          >
            <TableActionButton
              data={[
                {
                  label: "Timeline",
                  icon: <TimelineIcon />,
                  linkTo: `/procedure/${id}/timeline`,
                },
                {
                  label: "Edytuj procedure",
                  icon: <EditIcon />,
                  linkTo: `/procedure/${id}/edit`,
                },
              ]}
            />
          </Stack>
          <Grid container spacing={6} sx={{ mt: 1 }}>
            <Grid item xs={12} lg={12}>
              <CoreTable
                data={[procedureDetails]}
                isLoading={isLoading}
                intialColumns={intialColumnsProcedureDetails}
                headerTable={"Szczegóły procedury"}
              />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            spacing={2}
          >
            <TableActionButton
              data={[
                {
                  label: "Edytuj wyposażenie procedury",
                  icon: <EditIcon />,
                  linkTo: `/procedure/${id}/edit`,
                },
              ]}
            />
          </Stack>
          <Grid container spacing={6} sx={{ mt: 1 }}>
            <Grid item xs={12} lg={12}>
              <BasicTable
                data={procedureEquipment}
                isLoading={isLoading}
                intialColumns={intialColumnsProcedureEquipment}
                headerTable={"Wyposażenie wykorzystywane w procedurze"}
                props={props}
              />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            spacing={2}
          >
            <TableActionButton
              data={[
                {
                  label: "Edytuj odczynniki procedury",
                  icon: <EditIcon />,
                  linkTo: `/procedure/${id}/edit`,
                },
              ]}
            />
          </Stack>
          <Grid container spacing={6} sx={{ mt: 1 }}>
            <Grid item xs={12} lg={12}>
              <BasicTable
                data={procedureReagent}
                isLoading={isLoading}
                intialColumns={intialColumnsProcedureReagent}
                headerTable={"Odczynniki wykorzystywane w procedurze"}
                props={props}
              />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            spacing={2}
          >
            <TableActionButton
              data={[
                {
                  label: "Edytuj procesy procedury",
                  icon: <EditIcon />,
                  linkTo: `/procedure/${id}/edit`,
                },
              ]}
            />
          </Stack>
          <Grid container spacing={6} sx={{ mt: 1 }}>
            <Grid item xs={12} lg={12}>
              <BasicTable
                data={procedureProcess}
                isLoading={isLoading}
                intialColumns={intialColumnsProcedureProcess}
                headerTable={"Procesy wykorzystywane w procedurze"}
                props={props}
              />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Card sx={{ mt: 5, mb: 5 }}>
            <CoreTable
              data={procedureSamples}
              isLoading={isLoading}
              intialColumns={intialColumnsProcedureSamples}
              headerTable={"Wykorzysytwane próbki w procedurze"}
            />
          </Card>
        </TabPanel>
      </Box>
    </Container>
  );
}

export default ProcedureDetails;
