import TableActionButton from "../../Button/TableActionButton";
import VisibilityIcon from "@mui/icons-material/Visibility";

const customRenderColumn = (row) => {
  return (
    <div>
      <b>
        <span style={{ color: "#fa5252" }}>{row.substring(0, 7)}</span>
        <span style={{ color: "gray" }}>{row.substring(7)}</span>
      </b>
    </div>
  );
};

const intialColumnsPatientDetailsLink = [
  // {
  //   accessorKey: "id",
  //   header: "ID",
  // },
  {
    accessorKey: "identifier",
    accessorFn: (originalRow) => originalRow.identifier,
    Cell: ({ cell }) => customRenderColumn(cell.getValue()),
    header: "Identyfikator",
    size: 120,
  },
  {
    accessorKey: "pesel",
    header: "PESEL",
  },
  {
    accessorKey: "main_disease_name",
    header: "Głowna choroba",
  },
  {
    accessorKey: "comment",
    header: "Komentarz",
  },
  {
    accessorFn: (row) => (
      <TableActionButton
        data={[
          {
            label: "Szczegóły",
            icon: <VisibilityIcon />,
            linkTo: `/patient/${row.id}/`,
          },
        ]}
      />
    ),
    header: "Akcja",
    enableColumnFilter: false,
    enableSorting: false,
  },
];

export default intialColumnsPatientDetailsLink;
