import React, { useState } from "react";
import MaterialReactTable from "material-react-table";
import { Card, CardContent, Typography, Divider } from "@mui/material";

const CoreTableFilter = (tableData) => {
  return (
    <Card>
      <CardContent sx={{ padding: "16px 0 0 16px" }}>
        <Typography
          variant="h6"
          sx={{ position: "relative", paddingLeft: "4px" }}
        >
          <span
            style={{
              content: "",
              backgroundColor: "#111e6c",
              position: "absolute",
              left: "-16px",
              top: 0,
              width: "4px",
              height: "25px",
            }}
          ></span>
          {tableData.headerTable}
        </Typography>
      </CardContent>
      <Divider sx={{ p: 1 }} />

      <MaterialReactTable
        enableRowNumbers
        rowNumberMode="original" //default
        columns={tableData.intialColumns}
        data={tableData.data ?? []}
        state={{ isLoading: tableData.isLoading }}
        positionToolbarAlertBanner="bottom"
      />
    </Card>
  );
};

export default CoreTableFilter;
