import PropTypes from "prop-types";
import {
  Box,
  Button,
  Divider,
  Drawer,
  Typography,
  useMediaQuery,
} from "@mui/material";
import SubMenu from "./Sidebar/SideMenu";
import { SidebarData } from "./Sidebar/SidebarData";
import LogoGreycolab from "../assets/images/nowe-logo-greycolab.png";
import { Scrollbar } from "smooth-scrollbar-react";

export const DashboardSidebar = (props) => {
  const { open, onClose } = props;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"), {
    defaultMatches: true,
    noSsr: false,
  });

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        backgroundColor: "primary.main",
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        {SidebarData.map((item, index) => {
          return <SubMenu item={item} key={index} />;
        })}
      </Box>
      <Divider sx={{ borderColor: "#2D3748" }} />
    </Box>
  );

  if (lgUp) {
    return (
      <>
        <Drawer
          anchor="left"
          open
          PaperProps={{
            sx: {
              backgroundColor: "primary.main",
              color: "#FFFFFF",
              width: 280,
            },
          }}
          variant="permanent"
        >
          <Scrollbar
            plugins={{
              overscroll: {
                effect: "bounce",
              },
            }}
          >
            {content}
          </Scrollbar>
        </Drawer>
      </>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: "primary.main",
          color: "#fff",
          width: 280,
        },
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
