import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import TableActionButton from "../../Button/TableActionButton";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { requestTable } from "../../../services/actions/auth";
import api from "../../../config/api/apiRoutes";
import axios from "axios";
import { Box } from "@mui/material";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const renderTreeColumn = (row, level) => {
  //console.log(level);
  const padding = level * 15 || "0px";
  return <div style={{ paddingLeft: padding }}>{row}</div>;
};

const renderCustomTreeColumn = (row, level) => {
  //console.log(level);
  const padding = level * 15 || "0px";
  return (
    <div style={{ paddingLeft: padding }}>
      <b>
        <span style={{ color: "#fa5252" }}>{row.substring(0, 7)}</span>
        <span style={{ color: "#82368C" }}>{row.substring(7, 11)}</span>
        <span style={{ color: "#363A89" }}>{row.substring(11)}</span>
      </b>
    </div>
  );
};

const renderStatusColumn = (row, level) => {
  //console.log(level);
  const padding = level * 15 || "0px";
  return (
    <div style={{ paddingLeft: padding }}>
      <Box
        sx={{
          color:
            row === "NOWA"
              ? "#04a9f5"
              : row === "DO ZBADANIA"
              ? "#f5b759"
              : row === "ZBADANA"
              ? "#05a677"
              : row === "ZARCHIWIZOWANA"
              ? "#fa5252"
              : "inherit",
        }}
      >
        <>
          {row}
          <FiberManualRecordIcon
            style={{ paddingBottom: "3px", fontSize: "15px" }}
          />
        </>
      </Box>
    </div>
  );
};

const intialColumnsSampleTreeList = [
  // {
  //   accessorKey: "id",
  //   header: "id",
  //   accessorFn: (originalRow) => originalRow.id,
  //   Cell: ({ cell }) =>
  //     renderTreeColumn(cell.getValue(), cell.row.original.level),
  // },
  {
    accessorKey: "identifier",
    header: "Identyfikator",
    accessorFn: (originalRow) => originalRow.identifier,
    Cell: ({ cell }) =>
      renderCustomTreeColumn(cell.getValue(), cell.row.original.level),
  },
  {
    accessorKey: "amount",
    header: "Ile zostało?",
    accessorFn: (originalRow) => originalRow.amount,
    Cell: ({ cell }) =>
      renderTreeColumn(cell.getValue(), cell.row.original.level),
  },
  {
    accessorKey: "unit",
    header: "jednostka",
    accessorFn: (originalRow) => originalRow.unit,
    Cell: ({ cell }) =>
      renderTreeColumn(cell.getValue(), cell.row.original.level),
  },

  {
    accessorKey: "comment",
    header: "Komentarz",
    accessorFn: (originalRow) => originalRow.comment,
    Cell: ({ cell }) =>
      renderTreeColumn(cell.getValue(), cell.row.original.level),
  },

  {
    header: "status",
    enableColumnFilter: false,
    accessorFn: (originalRow) => originalRow.status,
    Cell: ({ cell }) =>
      renderStatusColumn(cell.getValue(), cell.row.original.level),
  },
  {
    accessorFn: (row) => (
      <TableActionButton
        data={[
          {
            label: "Szczegóły",
            icon: <VisibilityIcon />,
            linkTo: `/sample/${row.id}`,
          },
          {
            label: "Dodaj podpróbkę",
            icon: <AddIcon />,
            linkTo: `/sample-add/?sample_id=${row.id}&patient_id=${row.patient}&amount=${row.amount}&unit=${row.unit}`,
          },
          {
            label: "Edytuj",
            icon: <EditIcon />,
            linkTo: `/sample/${row.id}/edit`,
          },
          {
            label: "Usuń",
            icon: <DeleteIcon />,
            onClick: () => requestTable(api(row.id).SAMPLE_DELETE, "delete"),
          },
        ]}
      />
    ),
    header: "Akcja",
    enableColumnFilter: false,
    enableSorting: false,
  },
];

export default intialColumnsSampleTreeList;
