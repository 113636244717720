import TableActionButton from "../../Button/TableActionButton";
import VisibilityIcon from "@mui/icons-material/Visibility";

const renderTreeColumn = (row, level) => {
  //console.log(level);
  const padding = level * 15 || "0px";
  return <div style={{ paddingLeft: padding }}>{row}</div>;
};

const renderCustomTreeColumn = (row, level) => {
  //console.log(level);
  const padding = level * 15 || "0px";
  return (
    <div style={{ paddingLeft: padding }}>
      <b>
        <span style={{ color: "#fa5252" }}>{row.substring(0, 7)}</span>
        <span style={{ color: "#82368C" }}>{row.substring(7, 11)}</span>
        <span style={{ color: "#363A89" }}>{row.substring(11)}</span>
      </b>
    </div>
  );
};

const intialColumnsSampleListPatient = [
  {
    accessorKey: "identifier",
    header: "Identyfikator",
    accessorFn: (originalRow) => originalRow.identifier,
    Cell: ({ cell }) =>
      renderCustomTreeColumn(cell.getValue(), cell.row.original.level),
  },
  {
    accessorKey: "amount",
    header: "Ile zostało?",
    accessorFn: (originalRow) => originalRow.amount,
    Cell: ({ cell }) =>
      renderTreeColumn(cell.getValue(), cell.row.original.level),
  },
  {
    accessorKey: "unit",
    header: "jednostka",
    accessorFn: (originalRow) => originalRow.unit,
    Cell: ({ cell }) =>
      renderTreeColumn(cell.getValue(), cell.row.original.level),
  },
  {
    accessorKey: "real_amount",
    header: "Rzeczywista ilość",
    accessorFn: (originalRow) => originalRow.real_amount,
    Cell: ({ cell }) =>
      renderTreeColumn(cell.getValue(), cell.row.original.level),
  },

  {
    accessorKey: "comment",
    header: "Komentarz",
    accessorFn: (originalRow) => originalRow.comment,
    Cell: ({ cell }) =>
      renderTreeColumn(cell.getValue(), cell.row.original.level),
  },
  {
    accessorFn: (row) => (
      <TableActionButton
        data={[
          {
            label: "Szczegóły",
            icon: <VisibilityIcon />,
            linkTo: `/sample/${row.id}`,
          },
        ]}
      />
    ),
    header: "Akcja",
    enableColumnFilter: false,
    enableSorting: false,
  },
];

export default intialColumnsSampleListPatient;
