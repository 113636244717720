import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import { Button, Stack } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import StarIcon from "@mui/icons-material/StarBorder";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import GlobalStyles from "@mui/material/GlobalStyles";
import Container from "@mui/material/Container";
import { Link } from "react-router-dom";
import bgImage from "../../assets/images/background.png";
import footerRoutes from "../../config/footer-routes";
import Footer from "../../components/footer";
import NavbarHomePage from "../../components/NavbarHomePage";
import NavbarRoutes from "../../components/navbar-routes";

export default function PricingHomePage({ produstList }) {

  const titleStyles = {
    fontSize: "2rem",
    fontWeight: "500",
    lineHeight: "1.375",
    display: "block",
    opacity: "1",
    textTransform: "none",
    textAlign: "center",
    verticalAlign: "unset",
    textDecoration: "none",
    color: "black !important",
    letterSpacing: "-0.125px",
  };

  return (
    <>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <Box
        component="section"
        py={12}
        sx={{
          backgroundImage: ` url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          pt: 20,
          pb: 20,
        }}
      >
          <Grid item xs={12} md={12} lg={12}>
            <Typography variant="h2" sx={titleStyles} mb={2}>
              Nasza oferta 
            </Typography>
            <Box
              component="span"
              sx={{
                display: "block",
                width: "15%",
                borderBottom: "5px solid #B04C4E",
                marginTop: "0.5rem",
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: "3rem",
              }}
            ></Box>
          </Grid>
        <Container maxWidth="md" component="main">

          <Grid container spacing={5} alignItems="flex-end">
            {produstList.map((tier) => (
              // Enterprise card is full width at sm breakpoint
              <Grid
                item
                key={tier.title}
                xs={12}
                sm={tier.title === "Enterprise" ? 12 : 6}
                md={4}
              >
                <Card>
                  <CardHeader
                    title={tier.title}
                    subheader={tier.subheader}
                    titleTypographyProps={{ align: "center" }}
                    action={tier.title === "Pro" ? <StarIcon /> : null}
                    subheaderTypographyProps={{
                      align: "center",
                    }}
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === "light"
                          ? theme.palette.grey[200]
                          : theme.palette.grey[700],
                    }}
                  />
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "baseline",
                        mb: 2,
                      }}
                    >
                      <Typography
                        component="h2"
                        variant="h3"
                        color="text.primary"
                      >
                        ${tier.price}
                      </Typography>
                      <Typography variant="h6" color="text.secondary">
                        /mo
                      </Typography>
                    </Box>
                    <ul>
                      {tier.description.map((line) => (
                        <Typography
                          component="li"
                          variant="subtitle1"
                          align="center"
                          key={line}
                        >
                          {line}
                        </Typography>
                      ))}
                    </ul>
                  </CardContent>
                  <CardActions>
                    <Button
                      fullWidth
                      variant={tier.buttonVariant}
                      color="primary"
                      size="large"
                      type="submit"
                      component={Link}
                      to={tier.linkTo}
                    >
                      {tier.buttonText}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12} md={12} lg={12} mt={5} >
            <Stack
            direction="column"
            justifyContent="flex-end"
            alignItems="center"
            height="100%"
            spacing={2}
          >
            <Button 
              variant="outlined" 
              sx={{ 
                color: '#fff',
                borderColor: '#fff', 
                borderWidth: '5px', 
                fontWeight: 'bold',
                borderRadius: '43px',
                '&:hover': {
                  borderWidth: '5px', // Zachowaj stałą szerokość bordera na hover
                }, 
              }} 
              size="large"
              
            >
              Zobacz więcej
            </Button>
          </Stack>
          </Grid>

        </Container>
      </Box>
    </>
  );
}
