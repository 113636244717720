import React from "react"; //, {useState }
import { TextField, Grid, Typography } from "@material-ui/core";

const PaymentForm = () => {
  return (
    <>
      <Grid container item xs={12}>
        <Grid item xs={12} sm={3}>
          <Typography variant="h6">Payment Data</Typography>
        </Grid>
      </Grid>
      <Grid item xs={6} sm={3}></Grid>
      <Grid item xs={6} sm={3}>
        <TextField
          label="Amount"
          name="amount"
          variant="outlined"
          required
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Credit Card Number"
          name="ccnumber"
          variant="outlined"
          required
          fullWidth
        />
      </Grid>
      <Grid item xs={6} sm={6}>
        <TextField
          label="Expiration Date"
          name="ccexp"
          variant="outlined"
          required
          fullWidth
        />
      </Grid>
      <Grid item xs={6} sm={6}>
        <TextField
          label="CVC"
          name="cvc"
          variant="outlined"
          required
          fullWidth
        />
      </Grid>
    </>
  );
};

export default PaymentForm;

