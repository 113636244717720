import React from "react";
import { connect } from "react-redux";
import * as actions from "../../services/actions/auth";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Alert from "@mui/material/Alert";
import axios from "axios";
import {
  Box,
  Container,
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  Grid,
  TextField,
  Tooltip,
  Paper,
} from "@mui/material";
import ReactCodeInput from "react-code-input";
import { MuiOtpInput } from "mui-one-time-password-input";
import FormHelperText from "@mui/material/FormHelperText";
import api from "../../config/api/apiRoutes";

const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach(
    // if we have an error string set valid to false
    (val) => val.length > 0 && (valid = false)
  );
  return valid;
};

class OtpVerifyForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pinCode: "",
      errors: {
        pinCode: "",
      },
    };
  }

  onChange = (e) => {
    console.log(e);
    this.setState({ pinCode: e });
    console.log(this.state);
  };

  onLoginClick = (e) => {
    e.preventDefault();

    const userData = {
      email: localStorage.getItem("email"),
      token: this.state.pinCode,
      one_time_token: localStorage.getItem("one_time_token"),
    };

    const validate = () => {
      let validationError = this.state.errors;
      if (this.state.pinCode < 6) {
        validationError.pinCode = "Wymagane pole";
      }

      this.setState({ validationError, validationError });
      console.log("in validate", validationError);
      return validationError;
    };

    const validationError = validate();
    console.log(this.state.errors);
    if (validateForm(validationError)) {
      console.info("Valid Form");
      this.props.onAuth(userData, this.props);
    } else {
      console.error("Invalid Form");
    }
  };

  render() {
    const { errors } = this.state;
    let errorMessage = null;
    if (this.props.error) {
      errorMessage = <p>{this.props.error.message}</p>;
    }
    console.log(this.props);

    if (this.props.login_auth == true) {
      this.props.history.push("/");
    }

    return (
      <>
        {this.props.loading ? (
          <LoadingSpinner />
        ) : (
          <Box
            component="main"
            sx={{
              alignItems: "center",
              display: "flex",
              flexGrow: 1,
              minHeight: "100%",
            }}
          >
            <Container maxWidth="sm">
              <Card sx={{ p: 5 }}>
                <form onSubmit={this.onLoginClick}>
                  <Box sx={{ my: 3 }}>
                    <Typography color="textPrimary" variant="h4">
                      Weryfikacja dwuetapowa
                    </Typography>
                  </Box>
                  <Typography color="textPrimary" gutterBottom variant="h6">
                    Konfiguracja Google Authenticator lub Authy
                  </Typography>
                  <Divider sx={{ mt: 1, mb: 1 }} />
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    <ol>
                      <li>
                      Zainstaluj Google Authenticator (IOS - Android) lub Authy (IOS - Android).
                      </li>
                      <li>W aplikacji autentykatora wybierz ikonę "+".</li>
                      <li>
                      Wybierz "Zeskanuj kod kreskowy (lub kod QR)" i użyj aparatu telefonu, aby zeskanować ten kod kreskowy.
                      </li>
                    </ol>
                  </Typography>
                  <Typography color="textPrimary" gutterBottom variant="h6">
                    Zeskanuj QR Code
                  </Typography>
                  <Divider sx={{ mt: 1, mb: 1 }} />
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    <ol>
                      <li>Zeskanuj kod QR w wybranej aplikacji.</li>
                    </ol>
                  </Typography>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    xs={12}
                    style={{ width: "fit-content", margin: " 0 auto" }}
                  >
                    <img src={this.props.qrcodeUrl} alt="qrcode url" />
                  </Grid>
                  <Typography color="textPrimary" gutterBottom variant="h6">
                    Sprawdź kod
                  </Typography>
                  <Divider sx={{ mt: 1, mb: 1 }} />
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Wprowadź kod z aplikacji.
                  </Typography>
                  <Grid item md={12} xs={12} sx={{ m: 2 }}>
                    <MuiOtpInput
                      value={this.state.pinCode}
                      onChange={this.onChange}
                      length={6}
                    />
                    {errors.pinCode.length > 0 && (
                      <FormHelperText error={true}>
                        {errors.pinCode}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Box sx={{ py: 2 }}>
                    <Divider sx={{ mt: 1, mb: 3 }} />

                    <Button
                      id="submit-login"
                      color="primary"
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Weryfikuj
                    </Button>
                  </Box>
                </form>
              </Card>
            </Container>
          </Box>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error,
    login_auth: state.login_auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (formData) =>
      dispatch(actions.verifyOtp(formData, api().VERIFY_OTP, "post")),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OtpVerifyForm));
