import React, { Component } from "react";
import { connect } from "react-redux"; // new import
import * as actions from "../../services/actions/auth";
import axios from "axios";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Button,
  TextField,
  Typography,
  Container
} from "@mui/material";
import api from "../../config/api/apiRoutes";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { Redirect } from "react-router-dom";

class ReagentsProcedureFormEdit extends Component {
    constructor(props) {
    super(props);
    this.state = {
        getDataReagentProcedure: [],
        };
        const queryParams = new URLSearchParams(this.props.location.search);
        this.state.procedureName = queryParams.get("procedureName");
        this.state.procedureReagentId = queryParams.get("procedureReagentId");
        this.state.procedureReagentValue = 0
        this.state.ReagentId = queryParams.get("ReagentId");
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleaddLocalizationChange = (addLocalization) => {
    this.setState({ addLocalization });
  }

  handleChangeRadio = (event) => {
    console.log(event.target.value);
    this.setState({ EquipmentBig: event.target.value });
  };

  componentDidMount() {}

  editReagentsProcedure = () => {

    const formData = {
      procedure: this.state.procedureReagentId,
      reagent: this.state.ReagentId,
      reagent_value: this.state.procedureReagentValue,
      creator: this.state.EquipmentCreator,
    };

    let toSliceId = window.location.pathname;
    let id = toSliceId.slice(24);
    this.props.onAuth(formData, id);
  };

  render() {
    const { errors } = this.state;

    if (this.props.adding === true) {
        return <Redirect to={`/reagent/${this.state.ReagentId}`} />;
    }

    return (
        <Box
            component="main"
            sx={{
            flexGrow: 1,
            py: 8,
            }}
        >
        <Container maxWidth="lg">
          <Typography sx={{ mb: 3 }} variant="h4">
            Edytuj ilość odczynnika w procedurze
          </Typography>
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} xs={12}>
                {this.props.loading ? (
                    <LoadingSpinner />
                ) : (
                    <Card>
                    <Divider />
                    <CardContent>
                    <Grid container  alignItems="center" justifyContent={"center"}>
                        <Grid item xs={4}>
                            <TextField
                            type="text"
                            label="Nazwa procedury"
                            value={this.state.procedureName}
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                            label="Ilość odczynnika"
                            name="procedureReagentValue"
                            value={this.state.procedureReagentValue}
                            onChange={this.onChange}
                            variant="outlined"
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            />
                            </Grid>
                    </Grid>
                    </CardContent>
                    <Divider />
                    <Box
                        sx={{
                        display: "flex",
                        justifyContent: "center",
                        p: 3,
                        }}
                    >
                        <Button onClick={this.editReagentsProcedure} variant="contained">
                        Edytuj ilość
                        </Button>
                    </Box>
                    </Card>
                )}
            </Grid>
          </Grid>
        </Container>
      </Box>
      );
  
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error,
    adding: state.adding,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (formData, id) => dispatch(actions.requestApi(formData, api(id).PROCEDURE_REAGENT_PUT, 'put')),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReagentsProcedureFormEdit);
