import axios from "axios";
import * as actionTypes from "./ActionTypes";
import api from "../../config/api/apiRoutes";
import { ToastContainer, toast, Zoom, Bounce } from "react-toastify";
import { push } from "connected-react-router";

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (token) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    token: token,
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};

export const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("expirationDate");
  logoutApi();
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const authDeliverySuccess = (data) => {
  console.log(data);
  return {
    type: actionTypes.AUTH_DELIVERY_SUCCESS,
    data: data,
  };
};

export const checkTokenSuccess = () => {
  return {
    type: actionTypes.CHECK_TOKEN_SUCCES,
  };
};

export const checkTokenFail = () => {
  return {
    type: actionTypes.CHECK_TOKEN_FAIL,
  };
};

export const authLoading = () => {
  return {
    type: actionTypes.SET_LOADING,
  };
};

export const authDeliveryFail = (error) => {
  return {
    type: actionTypes.AUTH_DELIVERY_FAIL,
    error: error,
  };
};

export const authEnd = () => {
  return {
    type: actionTypes.AUTH_END,
  };
};

export const authBarcodeSuccess = () => {
  return {
    type: actionTypes.AUTH_BARCODE_SUCCESS,
  };
};

export const deleteProcedureReagentSuccess = () => {
  return {
    type: actionTypes.DELETE_PROCEDURE_REAGENT_SUCCESS,
  };
};

export const updateTheme1 = () => {
  return {
    type: actionTypes.UPDATE_THEME_1,
  };
};

export const updateTheme2 = () => {
  return {
    type: actionTypes.UPDATE_THEME_2,
  };
};

export const logoutApi = (props) => {
  console.log(props);
  return (dispatch) => {
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    axios
      .post(api().LOGOUT)
      .then((res) => {
        console.log(res);
        localStorage.clear();
        window.location.reload();
        toast.success("Pomyślnie wylogowano!");
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
    dispatch(logout());
  };
  props.props.history.push("/logowanie");
};

export const checkAuthTimeout = (expirationTime) => {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(logout());
    }, expirationTime * 1000);
  };
};

export const checkToken = () => {
  console.log(localStorage.getItem("token"));

  return (dispatch) => {
    const token = localStorage.getItem("token");

    if (!token) {
      dispatch(checkTokenFail());
      return;
    }
    axios
      .get(api().CHECK_TOKEN, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        console.log(res);

        console.log("poprawny token");
        dispatch(checkTokenSuccess());
      })
      .catch(function (error) {
        dispatch(checkTokenFail());

        if (error.response) {
          console.log("niepoprawny token");

          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  };
};

export const authCheckState = () => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    if (token == undefined) {
      console.log("ta");
      dispatch(logout());
    } else {
      const expirationDate = new Date(localStorage.getItem("expirationDate"));
      if (expirationDate <= new Date()) {
        dispatch(logout());
      } else {
        dispatch(authSuccess(token));
        dispatch(
          checkAuthTimeout(
            (expirationDate.getTime() - new Date().getTime()) / 1000
          )
        );
      }
    }
  };
};

export const authLogin = (userData, props) => {
  return (dispatch) => {
    dispatch(authStart());
    axios
      .post(api().LOGIN, userData)
      .then((res) => {
        console.log(props);
        const data = res.data;
        const token = res.data.token;
        const identifier = res.data.user.identifier;
        const role = res.data.user.role;
        const id = res.data.user.id;
        const email = res.data.user.email;
        const laboratoryName = res.data.laboratory.laboratory_name;
        const oneTimeToken = res.data.one_time_token;
        const laboratoryVat = res.data.laboratory.laboratory_vat;
        const vault = res.data.laboratory.is_vault;

        const pin = res.data.user.pin;

        if (role == "LABORANT") {
          localStorage.setItem("role", "LABORANT");
        }
        if (role == "KIEROWNIK") {
          localStorage.setItem("role", "KIEROWNIK");
        }

        if (data.otp_enabled) {
          console.log("tutaj");
          localStorage.setItem("one_time_token", data.one_time_token);
        } else {
          localStorage.setItem("token", token);
          downloadImage(token);
        }

        const expirationDate = new Date(new Date().getTime() + 3600 * 10000);
        localStorage.setItem("identifier", identifier);
        localStorage.setItem("expirationDate", expirationDate);
        localStorage.setItem("user_id", id);
        localStorage.setItem("user_email", email);
        localStorage.setItem("user_laboratory_name", laboratoryName);
        localStorage.setItem("user_laboratory_vat", laboratoryVat);
        localStorage.setItem("user_pin", pin);
        localStorage.setItem("is_pin", true);
        localStorage.setItem("vault", vault);

        toast.success("Pomyślnie zalogowano!");

        dispatch(checkAuthTimeout(3600));
        if (data.otp_enabled) {
          dispatch(authDeliverySuccess());

          props.history.push("/otp-validate");
        } else {
          console.log("pushuj");
          props.history.push("/dashboard");
          dispatch(authSuccess(token));
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Błąd logowania!");

        dispatch(authFail(err));
      });
  };
};

export const authSignup = (userData) => {
  return (dispatch) => {
    dispatch(authStart());
    axios.defaults.headers = {
      "Content-Type": "multipart/form-data",
    };
    axios
      .post(api().REGISTER_USER, userData)
      .then((res) => {
        const data = res.data;

        localStorage.setItem("base32", data.base32);
        localStorage.setItem("one_time_token", data.one_time_token);
        localStorage.setItem("otpauth_url", data.otpauth_url);

        dispatch(authDeliverySuccess());
        dispatch(authEnd());
        toast.success("Pomyślnie zarejestrowano!");
      })
      .catch((err) => {
        console.log(err);
        dispatch(authFail(err));
      })
      .catch(function (error) {
        console.log(error);
        if (error.response) {
          toast.error("Błąd rejestracji!");

          console.log(axios.post);
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  };
};

export const addBarcode = (formData, id) => {
  return (dispatch) => {
    dispatch(authStart());

    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    console.log(formData);
    axios
      .post(api(id).BARCODE_ADD_POST, formData)
      .then((res) => {
        const file = res.data;
        const link = document.createElement("a");
        const url = URL.createObjectURL(new Blob([file]));
        link.href = url;
        link.download = "etykieta.pdf";
        document.body.appendChild(link);
        link.click();
        link.remove();
        toast.success("Pomyślnie dodano!");
        dispatch(authDeliverySuccess());
        dispatch(authEnd());
      })
      .catch((err) => {
        console.log(err);
        dispatch(authFail(err));
      })
      .catch(function (error) {
        console.log(error);
        if (error.response) {
          toast.error("Błąd!");
          console.log(axios.post);
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  };
};

export const verifyOtp = (formData, apiUrl, method) => {
  return (dispatch) => {
    dispatch(authStart());
    const config = {
      method: method,
      url: apiUrl,
      data: formData,
    };
    axios.defaults.headers = {
      "Content-Type": "application/json",
    };
    console.log(config);

    axios
      .request(config)
      .then((res) => {
        const data = res.data;
        console.log(res.data);
        //toast.success(res.data);
        const token = res.data.token;
        const identifier = res.data.user.identifier;
        const role = res.data.user.role;
        const id = res.data.user.id;
        const email = res.data.user.email;
        const laboratoryName = res.data.laboratory.laboratory_name;
        const laboratoryVat = res.data.laboratory.laboratory_vat;
        const pin = res.data.user.pin;

        localStorage.clear();
        if (role == "LABORANT") {
          localStorage.setItem("role", "LABORANT");
        }
        if (role == "KIEROWNIK") {
          localStorage.setItem("role", "KIEROWNIK");
        }

        const expirationDate = new Date(new Date().getTime() + 3600 * 10000);
        localStorage.setItem("token", token);
        localStorage.setItem("identifier", identifier);
        localStorage.setItem("expirationDate", expirationDate);
        localStorage.setItem("user_id", id);
        localStorage.setItem("user_email", email);
        localStorage.setItem("user_laboratory_name", laboratoryName);
        localStorage.setItem("user_laboratory_vat", laboratoryVat);
        localStorage.setItem("user_pin", pin);
        localStorage.setItem("is_pin", true);
        toast.success("Sukces!");
        dispatch(authSuccess(token));
        dispatch(checkAuthTimeout(3600));
        dispatch(authDeliverySuccess());
        dispatch(authEnd());
      })
      .catch((err) => {
        console.log(err);
        //toast.error(err.response.data.message);
        toast.error("Błąd!");
        dispatch(authDeliveryFail(err));
      })
      .catch(function (error) {
        console.log(error);
        if (error.response) {
          toast.error(error.response.data);

          console.log(axios.post);
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  };
};

export const validateOtp = (formData, apiUrl, method) => {
  return (dispatch) => {
    dispatch(authStart());
    const config = {
      method: method,
      url: apiUrl,
      data: formData,
    };
    axios.defaults.headers = {
      "Content-Type": "application/json",
    };
    console.log(config);

    axios
      .request(config)
      .then((res) => {
        const data = res.data;
        console.log(res.data);
        const token = res.data.token;

        localStorage.setItem("token", token);
        downloadImage(token);

        toast.success("Sukces!");
        dispatch(authSuccess(token));
        dispatch(checkAuthTimeout(3600));
        dispatch(authDeliverySuccess());
        dispatch(authEnd());
      })
      .catch((err) => {
        console.log(err);
        //toast.error(err.response.data.message);
        toast.error("Błąd!");
        dispatch(authDeliveryFail(err));
      })
      .catch(function (error) {
        console.log(error);
        if (error.response) {
          toast.error(error.response.data);

          console.log(axios.post);
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  };
};

export const requestApi = (formData, apiUrl, method) => {
  return (dispatch) => {
    dispatch(authStart());
    const config = {
      method: method,
      url: apiUrl,
      data: formData,
    };
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    console.log(config);

    axios
      .request(config)
      .then((res) => {
        console.log(res);
        //toast.success(res.data);
        toast.success("Sukces!");
        dispatch(authDeliverySuccess());
        dispatch(authEnd());
      })
      .catch((err) => {
        console.log(err);
        //toast.error(err.response.data.message);
        toast.error("Błąd!");
        dispatch(authDeliveryFail(err));
      })
      .catch(function (error) {
        console.log(error);
        if (error.response) {
          toast.error(error.response.data);

          console.log(axios.post);
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  };
};

export const requestWithoutTokenApi = (formData, apiUrl, method) => {
  return (dispatch) => {
    dispatch(authStart());
    const config = {
      method: method,
      url: apiUrl,
      data: formData,
    };
    axios.defaults.headers = {
      "Content-Type": "application/json",
    };
    console.log(config);

    axios
      .request(config)
      .then((res) => {
        console.log(res);
        //toast.success(res.data);
        toast.success("Sukces!");
        dispatch(authDeliverySuccess(res.data));
        dispatch(authEnd());
      })
      .catch((err) => {
        console.log(err);
        //toast.error(err.response.data.message);
        toast.error("Błąd!");
        dispatch(authDeliveryFail(err));
      })
      .catch(function (error) {
        console.log(error);
        if (error.response) {
          toast.error(error.response.data);

          console.log(axios.post);
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  };
};

export const resetPassword = (formData, apiUrl, method) => {
  return (dispatch) => {
    dispatch(authStart());
    const config = {
      method: method,
      url: apiUrl,
      data: formData,
    };

    axios
      .request(config)
      .then((res) => {
        console.log(res.data.detail);
        toast.success(res.data.detail);
        dispatch(authDeliverySuccess());
        dispatch(authEnd());
      })
      .catch((err) => {
        console.log(err);
        dispatch(authDeliveryFail(err));
      })
      .catch(function (error) {
        console.log(error);
        if (error.response) {
          toast.error("Bład!");

          console.log(axios.post);
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  };
};

export const requestTable = (apiUrl, method) => {
  authStart();
  const config = {
    method: method,
    url: apiUrl,
  };

  axios
    .request(config)
    .then((res) => {
      console.log(res.data.detail);
      toast.success("Obiekt został pomyślnie usunięty.");
      authDeliverySuccess();
      authEnd();
      window.location.reload(false);
    })
    .catch((err) => {
      console.log(err);
      authDeliveryFail(err);
    })
    .catch(function (error) {
      console.log(error);
      if (error.response) {
        toast.error("Bład!");

        console.log(axios.post);
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    });
};

const downloadImage = (token) => {
  console.log("dziala zdjecie");
  axios.defaults.headers = {
    Authorization: `Token ${localStorage.getItem("token")}`,
  };

  axios({
    url: api().USER_IMAGE_GET,
    method: "GET",
    responseType: "blob",
  })
    .then((response) => {
      const reader = new FileReader();
      console.log(response);
      reader.readAsDataURL(response.data);
      reader.onload = () => {
        const imageDataUrl = reader.result;
        localStorage.setItem("image", imageDataUrl);
      };
    })
    .catch((error) => {
      console.error(error);
    });
};

export const uploadImage = (formData, apiUrl, method) => {
  return (dispatch) => {
    dispatch(authStart());
    const config = {
      method: method,
      url: apiUrl,
      data: formData,
    };

    axios.defaults.headers = {
      "Content-Type": "multipart/form-data",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    axios
      .request(config)
      .then((res) => {
        console.log(res.data.detail);
        toast.success(res.data.detail);
        downloadImage();
        dispatch(authDeliverySuccess());
        dispatch(authEnd());
        window.location.reload(true);
      })
      .catch((err) => {
        console.log(err);
        dispatch(authDeliveryFail(err));
      })
      .catch(function (error) {
        console.log(error);
        if (error.response) {
          toast.error("Bład!");

          console.log(axios.post);
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  };
};
