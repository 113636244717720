import React, { useState, useEffect } from "react";
import MaterialReactTable from "material-react-table";
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  Divider,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import BootstrapDialogTitle from "../../components/Popup/BootstrapDialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import axios from "axios";
import { ExportToXlsxRaport } from "../xlsx/ExportToXlsxRaport";
import { toast } from "react-toastify";
import { exportCsvRaport } from "../exportData/exportCsvRaport";

const ReportTable = (tableData) => {
  const [open, setOpen] = React.useState(false);
  const [row, setRow] = useState("");
  const [column, setColumn] = useState("");
  const [data, setData] = useState("");
  const [sendEmail, setSendEmail] = useState(false);

  const handleClickOpen = (selectedRow, visibleLeafColumns) => {
    setOpen(true);
    setRow(selectedRow);
    setColumn(visibleLeafColumns);
  };

  const handleEmail = (e) => {
    setSendEmail(e.target.checked);
    console.log(sendEmail);
  };

  const exportCsv = () => {
    const fileArrayBuffer = exportCsvRaport(row, column);
    if (sendEmail) {
      handleSendEmailCsv(fileArrayBuffer);
    }
    setOpen(false);
  };

  const handleSendEmailCsv = (file) => {
    setSendEmail(false);

    const formData = new FormData();
    
    formData.append('file', new Blob([file], { type: 'text/csv;charset=utf-8' }), 'filename.csv');

    axios.defaults.headers = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    axios
      .post("http://127.0.0.1:8000/report/send/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log(response.data);
        toast.success("Email został wysłany");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Błąd wysyłania");
      });
  };


  const handleSendEmail = (file) => {
    setSendEmail(false);

    const formData = new FormData();
    formData.append(
      "file",
      new Blob([file], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }),
      "sheetjs.xlsx"
    );
    axios.defaults.headers = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    axios
      .post("http://127.0.0.1:8000/report/send/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log(response.data);
        toast.success("Email został wysłany");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Błąd wysyłania");
      });
  };

  const exportXlsx = () => {
    const fileArrayBuffer = ExportToXlsxRaport(row, column);
    if (sendEmail) {
      handleSendEmail(fileArrayBuffer);
    }
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (
      tableData.props &&
      tableData.props.match &&
      tableData.props.match.params.id
    ) {
      const rowsWithId = tableData.data.map((row) => {
        return { ...row, id_path: tableData.props.match.params.id };
      });
      setData(rowsWithId);
      console.log(rowsWithId);
    } else {
      setData(tableData.data);
    }
  }, [tableData.props, tableData.data]);

  return (
    <>
      <Card>
        <CardContent sx={{ padding: "16px 0 0 16px" }}>
          <Typography
            variant="h6"
            sx={{ position: "relative", paddingLeft: "4px" }}
          >
            <span
              style={{
                content: "",
                backgroundColor: "#111e6c",
                position: "absolute",
                left: "-16px",
                top: 0,
                width: "4px",
                height: "25px",
              }}
            ></span>
            {tableData.headerTable}
          </Typography>
        </CardContent>
        <Divider sx={{ p: 1 }} />
        <MaterialReactTable
          columns={tableData.intialColumns}
          data={data ?? []}
          state={{ isLoading: tableData.isLoading }}
          enableRowSelection
          enablePagination={false}
          positionToolbarAlertBanner="bottom"
          renderTopToolbarCustomActions={({ table }) => (
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                p: "0.5rem",
                flexWrap: "wrap",
              }}
            >
              <Button
                color="primary"
                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                onClick={() =>
                  handleClickOpen(
                    table.getPrePaginationRowModel(),
                    table.getAllColumns()
                  )
                }
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export All Data
              </Button>
              <Button
                disabled={table.getPrePaginationRowModel().rows.length === 0}
                //export all rows, including from the next page, (still respects filtering and sorting)
                onClick={() =>
                  handleClickOpen(
                    table.getPrePaginationRowModel(),
                    table.getVisibleLeafColumns()
                  )
                }
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export All Rows
              </Button>
              <Button
                disabled={table.getRowModel().rows.length === 0}
                //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                onClick={() =>
                  handleClickOpen(
                    table.getRowModel(),
                    table.getVisibleLeafColumns()
                  )
                }
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export Page Rows
              </Button>
              <Button
                disabled={
                  !table.getIsSomeRowsSelected() &&
                  !table.getIsAllRowsSelected()
                }
                //only export selected rows
                onClick={() =>
                  handleClickOpen(
                    table.getSelectedRowModel(),
                    table.getVisibleLeafColumns()
                  )
                }
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export Selected Rows
              </Button>
            </Box>
          )}
        />
      </Card>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Wyeksportuj raport
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    name="sendEmail"
                    checked={sendEmail}
                    onChange={handleEmail}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Wyślij raport na adres email"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={exportCsv}>
            .csv
          </Button>
          <Button variant="contained" color="primary" onClick={exportXlsx}>
            .xlsx
          </Button>
        </DialogActions>
      </BootstrapDialog>
      {/* <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Wybierz format"}</DialogTitle>

        <DialogActions>
          <Button onClick={exportCsv}>.csv</Button>
          <Button onClick={exportXlsx} autoFocus>
            .xlsx
          </Button>
        </DialogActions>
      </Dialog> */}
    </>
  );
};

export default ReportTable;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
