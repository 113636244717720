import React, { Component, useState } from "react";
import FormHelperText from "@mui/material/FormHelperText";
import { Form, Col } from "react-bootstrap";
import Select from "react-select";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import api from "../../../config/api/apiRoutes";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import { toast } from "react-toastify";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import SelectStyles from "../../select/SelectStyles";
import AddIcon from '@mui/icons-material/Add';

class ProcedureFormAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      ProcedureReagents: [],
      ProcedureName: "",
      ProcedureReagentValue: "",
      ProcedureEquipment: "",
      PatientExtraValues: [],
      PatientAttribute: [],
      ProcedureProcess: "",
      ProcedureAddInfo: "",
      Loading: false,
      visible: false,
      options: [],
      options2: [],
      options3: [],
      open: false,
      reagents_procedure: [
        {
          reagent: "",
          reagent_value: "",
          reagent_name: "",
          add: false,
        },
      ],

      errors: {
        ProcedureName: "",
        reagents_procedure: "",
        ProcedureProcess: "",
        ProcedureReagentName: "",
        ProcedureReagentValue: "",
      },
    };
  }

  handleClickOpen = (e) => {
    e.preventDefault();

    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  setTrueLoading = () => {
    this.setState({ Loading: true });
  };

  setFalseLoading = () => {
    this.setState({ Loading: false });
  };

  // Handle fields change
  handleChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });

    const { name, value } = e.target;
    let errors = this.state.errors;
    switch (name) {
      case "ProcedureName":
        errors.ProcedureName =
          value.length > 1 ? "" : "Numer ICD powinien mieć 10 znaków";
        break;
      default:
        break;
    }

    this.setState({ errors, [name]: value });
    console.log(this.state);
  };

  handleChange2 = (input) => (e, index) => {
    console.log(e.label);
    this.setState({ [input]: e });
    console.log(this.state);
  };

  handleFormChange = (event, index) => {
    let data = [...this.state.reagents_procedure];
    console.log(event);

    data[index][event.target.name] = event.target.value;
    this.setState(data);
    console.log(data);
  };

  handleFormChangeSelect = (event, index) => {
    let data = [...this.state.reagents_procedure];
    console.log(event);

    data[index]["reagent"] = event.value;
    data[index]["reagent_name"] = event.label;
    this.setState(data);
  };

  addFields = () => {
    let object = {
      reagent: "",
      reagent_name: "",
      reagent_value: "",
    };

    this.setState({
      reagents_procedure: [...this.state.reagents_procedure, object],
    });
  };

  removeFields = (index) => {
    let data = this.state.reagents_procedure;
    data.splice(index, 1);
    this.setState(data);
  };

  removeAndAddFields = (index) => {
    this.removeFields(index);
    this.addFields(index);
  };

  validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
      // if we have an error string set valid to false
      (val) => val.length > 0 && (valid = false)
    );
    return valid;
  };

  Continue = (e) => {
    e.preventDefault();

    const validate = () => {
      let validationError = this.state.errors;
      console.log(this.state);
      if (!this.state.ProcedureName) {
        validationError.ProcedureName = "Wymagane pole";
      }

      // this.state.reagents_procedure.forEach((item) => {
      //   if (item.reagent_value !== null && !item.reagent_name) {
      //     validationError.ProcedureReagentName = "Uzupełnij puste pole";
      //     return false; // break the loop
      //   }
      // });
      // const emptyReagents = this.state.reagents_procedure.filter(
      //   (item) => !item.reagent || !item.reagent_value
      // );

      // const isAnyReagentsEmpty = emptyReagents.some(
      //   (item) => item.reagent || !item.reagent_value
      // );

      const errors = [];

      this.state.reagents_procedure.forEach((item) => {
        if (item.reagent_value && !item.reagent) {
          console.log(`Error: Reagent is empty for ${item.reagent_name}`);
          validationError.ProcedureReagentName = "Uzupełnij puste pole";
        } else {
          validationError.ProcedureReagentName = "";
        }
        if (item.reagent && !item.reagent_value) {
          console.log(`Error: Reagent value is empty for ${item.reagent_name}`);
          validationError.ProcedureReagentValue = "Uzupełnij puste pole";
        } else {
          validationError.ProcedureReagentValue = "";
        }
      });

      //console.log(isAnyReagentsEmpty);

      //   if (isAnyReagentsEmpty == true) {
      //     validationError.reagents_procedure = "Wymagane pole";
      //   } else {
      //     validationError.reagents_procedure = "";
      //   }

      // if (values.ProcedureProcess.length < 1) {
      //   validationError.ProcedureProcess = "Wymagane pole";
      // } else {
      //   validationError.ProcedureProcess = "";
      // }

      // if (values.ProcedureEquipment.length < 1) {
      //   validationError.ProcedureEquipment = "Wymagane pole";
      // } else {
      //   validationError.ProcedureEquipment = "";
      // }

      validationError = validationError;
      console.log("in validate", validationError);
      return validationError;
    };

    const validationError = validate();

    if (this.validateForm(validationError)) {
      console.info("Valid Form");
      this.completeForm();
    } else {
      toast.warn("Uzupełnij pole wymagane");
    }
  };

  completeForm = () => {
    this.setTrueLoading();
    let regents = [];

    let idProcedureProcess = [];

    console.log(this.state.ProcedureProcess.length);
    console.log(this.state);

    if (this.state.ProcedureProcess.length >= 1) {
      this.state.ProcedureProcess.forEach((item1, index) => {
        idProcedureProcess.push(item1.value);
        console.log("ta");
      });
    }

    let idProcedureEquipment = [];
    if (this.state.ProcedureEquipment.length >= 1) {
      this.state.ProcedureEquipment.forEach((item1, index) => {
        idProcedureEquipment.push(item1.value);
        console.log("ta");
      });
    }

    let regentsItems = [];
    this.state.reagents_procedure.forEach((item1, index) => {
      regentsItems.push({
        reagent: item1.reagent,
        reagent_value: item1.reagent_value,
      });
      console.log("ta");
    });

    console.log(regentsItems[0].reagent);

    const formData = {
      name: this.state.ProcedureName,
      ...(regentsItems[0].reagent && { reagents_procedure: regentsItems }),
      ...(idProcedureProcess && { process: idProcedureProcess }),
      ...(idProcedureEquipment && { equipment: idProcedureEquipment }),
      creator: `${localStorage.getItem("user_id")}`,
      ...(this.state.ProcedureAddInfo && {
        additional_info: this.state.ProcedureAddInfo,
      }),
    };

    axios
      .post(api().PROCEDURE_ADD_POST, formData)
      .then((res) => {
        console.log(res);
        toast.success("Dodano!");
        this.setFalseLoading();
        this.props.history.push("/procedure");
      })
      .catch(function (error) {
        if (error.response) {
          toast.error("Błąd!");
          console.log(axios.post);
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          this.setFalseLoading();
        }
      })
      .catch((err) => {
        toast.error("Błąd!");
      });
  };

  componentDidMount() {
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    axios.get(api().REAGENT_GET).then((res) => {
      let result = res.data;
      console.log(result);
      result.map((laboratory, index) => {
        return this.state.options.push({
          value: laboratory.id,
          label: laboratory.short_name,
        });
      });
    });

    axios.get(api().PROCESS_GET).then((res) => {
      let result = res.data;
      console.log(result);
      result.map((laboratory, index) => {
        return this.state.options2.push({
          value: laboratory.id,
          label: laboratory.name,
        });
      });
      console.log(this.state);
    });

    axios.get(api().EQUIPMENT_GET).then((res) => {
      let result = res.data;
      console.log(result);
      result.map((laboratory, index) => {
        return this.state.options3.push({
          value: laboratory.id,
          label: laboratory.name,
        });
      });
    });
  }

  render() {
    return (
      <Card>
        <Typography
          variant="h6"
          sx={{ position: "relative", padding: "16px 0 20px 16px" }}
        >
          <span
            style={{
              content: "",
              backgroundColor: "#111e6c",
              position: "absolute",
              left: "0",
              top: "16px",
              width: "4px",
              height: "25px",
            }}
          ></span>
          Formularz dodania procedury
        </Typography>

        <Divider />
        <form onSubmit={this.handleClickOpen}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  type="text"
                  label="Nazwa procedury"
                  error={this.state.errors.ProcedureName.length ? true : false}
                  name="ProcedureName"
                  value={this.state.ProcedureName}
                  onChange={this.handleChange("ProcedureName")}
                  helperText={
                    this.state.errors.ProcedureName.length > 0
                      ? this.state.errors.ProcedureName
                      : ""
                  }
                  variant="outlined"
                />
              </Grid>

              {this.state.reagents_procedure.map((form, index) => {
                return (
                  <>
                    <Grid item md={6} xs={12}>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        options={this.state.options}
                        placeholder="Wybierz odczynnik"
                        styles={SelectStyles}
                        onChange={(event) =>
                          this.handleFormChangeSelect(event, index)
                        }
                        value={{ label: form.reagent_name }}
                        name="color"
                      />
                      {this.state.errors.ProcedureReagentName.length > 0 && (
                        <FormHelperText error={true}>
                          {this.state.errors.ProcedureReagentName}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        type="number"
                        label="Wprowadź wartość"
                        name="reagent_value"
                        onChange={(event) =>
                          this.handleFormChange(event, index)
                        }
                        value={form.reagent_value}
                        variant="outlined"
                      />
                      {this.state.errors.ProcedureReagentValue.length > 0 && (
                        <FormHelperText error={true}>
                          {this.state.errors.ProcedureReagentValue}
                        </FormHelperText>
                      )}
                    </Grid>

                    <Grid item md={12} xs={12} align="center">
                      <Button
                        disabled={
                          this.state.reagents_procedure[index].add == true ||
                          index === 0
                            ? true
                            : false
                        }
                        onClick={() => this.removeFields(index)}
                        startIcon={
                          <RemoveCircleRoundedIcon style={{ color: "red" }} />
                        }
                      >
                        Usuń
                      </Button>
                      <Button
                        onClick={() => this.removeAndAddFields(index)}
                        disabled={
                          this.state.reagents_procedure[index].add == true
                        }
                        startIcon={<RemoveCircleRoundedIcon />}
                      >
                        Wyczyść
                      </Button>
                    </Grid>
                  </>
                );
              })}

              <Grid item md={12} xs={12} align="center" sx={{ mb: 3 }}>
                <Button
                  onClick={() => this.addFields()}
                  startIcon={
                    <AddCircleRoundedIcon style={{ color: "green" }} />
                  }
                >
                  Dodaj nowy atrybut
                </Button>
              </Grid>

              <Grid item md={12} xs={12}>
                <Select
                  placeholder="Wybierz proces"
                  isMulti
                  onChange={this.handleChange2("ProcedureProcess")}
                  value={this.state.ProcedureProcess}
                  options={this.state.options2}
                  styles={SelectStyles}
                  noOptionsMessage={() => "name not found"}
                ></Select>
              </Grid>
              <Grid item md={12} xs={12}>
                <Select
                  placeholder="Wybierz wyposażenie"
                  isMulti
                  onChange={this.handleChange2("ProcedureEquipment")}
                  value={this.state.ProcedureEquipment}
                  options={this.state.options3}
                  styles={SelectStyles}
                  noOptionsMessage={() => "name not found"}
                ></Select>
              </Grid>

              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  type="text"
                  label="Dodatkowa informacja"
                  name="ProcedureAddInfo"
                  value={this.state.ProcedureAddInfo}
                  onChange={this.handleChange("ProcedureAddInfo")}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Dialog
              open={this.state.open}
              onClose={this.handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Zaakceptuj formularz"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Czy na pewno chcesz kontynuować?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleClose}>Odmów</Button>
                <Button onClick={this.Continue} autoFocus>
                  Akceptuj
                </Button>
              </DialogActions>
            </Dialog>
          </CardContent>
          <Divider />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              p: 2,
            }}
          >
            <Button type="submit" variant="contained" endIcon={<AddIcon />}>
              Dodaj procedure
            </Button>
          </Box>
        </form>
      </Card>
    );
  }
}

export default ProcedureFormAdd;
