import { Link } from "react-router-dom";
import { Button } from "@mui/material";

const intialColumnsSampleReagent = [
  {
    accessorKey: "value",
    header: "ID procedury",
    size: 40,
  },
  {
    accessorKey: "label",
    header: "Nazwa",
    size: 60,
  },
  {
    accessorFn: (row) => (
      <Link to={`/reagent/${row.id}`}>
        <Button variant="contained" size="small">
          Zobacz
        </Button>
      </Link>
    ),
    header: "Szczegóły",
    enableColumnFilter: false,
  },
];

export default intialColumnsSampleReagent;
