// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";

// Images
import logoCT from "../assets/images/greycolab_logo-01.png";
import { Typography } from "@mui/material";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "Greycolab sp. z o.o. z siedzibą w Krakowie, ul. Wybickiego 3A/112, wpisana do rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy dla Krakowa Śródmieścia w Krakowie pod nr KRS 0000803499, NIP 6793189750. Kapitał zakładowy 10 000 PLN.",
    image: logoCT,
    route: "",
    fullInfo: "",
  },
  menus: [
    {
      name: "Firma",
      items: [
        { name: "O nas", href: "/#about-us" },
        {
          name: "Oferta",
          href: "/#offer",
        },
        {
          name: "Współpraca",
          href: "/cooperation",
        },
      ],
    },
    {
      name: "Pomoc | kontakt",
      items: [
        { name: "Kontakt", href: "/#contact" },
        {
          name: "Centrum wiedzy",
          href: "/support-center",
        },
        {
          name: "Nowości",
          href: "/news",
        },
      ],
    },
    {
      name: "Regulamin | Polityka prywatności",
      items: [
        {
          name: "Regulamin",
          href: "https://www.creative-tim.com/terms",
        },
        {
          name: "Polityka prywatności",
          href: "/privacy-policy",
        },
        {
          name: "Polityka Cookies",
          href: "/cookie-policy",
        },
      ],
    },
  ],
  copyright: (
    <Typography variant="button" fontWeight="regular">
      Wszelkie prawa zastrzeżone. Copyright &copy; {date}{" "}
      <Typography
        component="a"
        href="https://greycolab.pl"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
        fontFamily={"Lato"}
      >
        Greycolab sp. z o.o.
      </Typography>
    </Typography>
  ),
};
