import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  Box,
  Container,
  Grid,
  Button,
  Typography,
  Card,
  CardContent,
  Divider,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import api from "../../config/api/apiRoutes";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { Select as MuiSelect } from "@mui/material";
import Select from "react-select";
import SelectStyles from "../../components/select/SelectStyles";
import BasicTable from "../../components/Table/BasicTable";
import ReportTable from "../../components/Table/ReportTable";
import { Divider as DividerRs } from "rsuite";
import AddIcon from "@mui/icons-material/Add";

function Report(props) {
  const [reportData, setReportData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectModel, setSelectModel] = React.useState("");
  const [reportOptions, setReportOptions] = useState([]);
  const [reportOptionsSelect, setReportOptionsSelect] = useState([]);
  const [reportGenerateData, setReportGenerateData] = useState([]);
  const [intialColumnsReport, setIntialColumnsReport] = useState([]);

  let { id } = useParams();

  const handleSelect = (event) => {
    setReportOptionsSelect(event);
    console.log(reportOptionsSelect);
  };

  const generateReport = async (event) => {
    const result = reportData
      .map((item) => {
        const filteredItem = {};
        reportOptionsSelect.forEach((filter) => {
          if (item.hasOwnProperty(filter.value)) {
            filteredItem[filter.value] = item[filter.value];
          }
        });
        return filteredItem;
      })
      .filter((item) => Object.keys(item).length !== 0);

    console.log(result);

    const intialColumns = reportOptionsSelect.map((item) => ({
      accessorKey: item.value,
      header: item.value,
      accessorFn: (row) =>
        `${row[item.value]}`.length > 50
          ? `${row[item.value]}`.slice(0, 50) + "..."
          : `${row[item.value]}`,
    }));

    console.log(intialColumns);

    setIntialColumnsReport(intialColumns);

    setReportGenerateData(result);
  };

  const handleChange = async (event) => {
    setSelectModel(event.target.value);
    setIsLoading(true);

    try {
      axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")}`,
      };

      let url;
      if (event.target.value === "samples") {
        url = api().REPORT_SAMPLE_GET;
      }
      if (event.target.value === "events") {
        url = api().REPORT_EVENTS_GET;
      }
      if (event.target.value === "patient") {
        url = api().REPORT_PATIENT_GET;
      }
      if (event.target.value === "procedures") {
        url = api().REPORT_PROCEDURE_GET;
      }
      if (event.target.value === "delivery") {
        url = api().REPORT_REAGENT_DELIVERY_GET;
      }
      if (event.target.value === "results") {
        url = api().REPORT_SAMPLE_RESULT_GET;
      }
      const getReport = await axios.get(url);
      const getDataReport = getReport.data;
      console.log(getDataReport);
      setReportOptionsSelect([]);
      const reportOptions = Object.keys(getDataReport[0]).map((key) => ({
        value: key,
        label: key,
      }));
      console.log(reportOptions);
      setReportData(getDataReport);
      setReportOptions(reportOptions);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      }
    }
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Container maxWidth={false}>
      <Typography variant="h5" style={{ fontWeight: "400" }}>
        Raport
      </Typography>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" to="/" style={{ color: "#888" }}>
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Dashboard
        </Link>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="#111"
          style={{ fontWeight: "600" }}
        >
          Raport
        </Typography>
      </Breadcrumbs>
      <DividerRs />
      <Grid container spacing={6} sx={{ mb: 5 }}>
        <Grid item xs={12}>
          <Card>
            <Typography
              variant="h6"
              sx={{ position: "relative", padding: "16px 0 20px 16px" }}
            >
              <span
                style={{
                  content: "",
                  backgroundColor: "#111e6c",
                  position: "absolute",
                  left: "0",
                  top: "16px",
                  width: "4px",
                  height: "25px",
                }}
              ></span>
              Generuj raport
            </Typography>
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Model</InputLabel>
                    <MuiSelect
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectModel}
                      label="Model"
                      onChange={handleChange}
                    >
                      <MenuItem value={"samples"}>Próbki</MenuItem>
                      <MenuItem value={"events"}>Wydarzenia</MenuItem>
                      <MenuItem value={"patient"}>Pacjenci</MenuItem>
                      <MenuItem value={"procedures"}>Procedury</MenuItem>
                      <MenuItem value={"delivery"}>
                        Dostawy odczynników
                      </MenuItem>
                      <MenuItem value={"results"}>Wyniki próbek</MenuItem>
                    </MuiSelect>
                  </FormControl>
                </Grid>
                {reportOptions.length ? (
                  <Grid item xs={12}>
                    <Select
                      isMulti
                      options={reportOptions}
                      placeholder="Wybierz dane do generowania"
                      onChange={(event) => {
                        handleSelect(event);
                      }}
                      styles={SelectStyles}
                      value={reportOptionsSelect}
                      name="color"
                      menuPortalTarget={document.body}
                    />
                  </Grid>
                ) : null}
              </Grid>
            </CardContent>
            <Divider />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                p: 2,
              }}
            >
              <Button
                variant="contained"
                onClick={generateReport}
                endIcon={<AddIcon />}
              >
                Generuj raport
              </Button>
            </Box>
          </Card>
        </Grid>

        {intialColumnsReport.length ? (
          <Grid item xs={12}>
            <ReportTable
              data={reportGenerateData}
              isLoading={isLoading}
              intialColumns={intialColumnsReport}
              headerTable={"Wygenerowany raport"}
            />
          </Grid>
        ) : null}
      </Grid>
    </Container>
  );
}

export default Report;
