import React, { useState, useEffect } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import LoginForm from "../../components/Login/Form/LoginForm";
import axios from "axios";
import api from "../../config/api/apiRoutes";
import AuthWrapper from "../../components/AuthWrapper";
import OtpVerifyForm from "../../components/otp/OtpVerifyForm";
import QRCode from "qrcode";
import queryString from "query-string";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";

function OtpVerify(props) {
  const [data, setData] = useState([]);
  const [qrcodeUrl, setqrCodeUrl] = useState("");
  const queryParams = queryString.parse(window.location.search);
  const [isLoading, setIsLoading] = useState(true);

  console.log(queryParams);

  useEffect(() => {
    QRCode.toDataURL(localStorage?.getItem("otpauth_url")).then(setqrCodeUrl);
    setIsLoading(false);
  }, []);

  console.log(qrcodeUrl);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <AuthWrapper>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} xs={12}>
              <OtpVerifyForm qrcodeUrl={qrcodeUrl} {...props} />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </AuthWrapper>
  );
}

export default OtpVerify;
