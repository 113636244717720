const intialColumnsSampleResult = [
  {
    accessorKey: "attribute_name",
    header: "Atrybut",
    size: 40,
  },
  {
    accessorKey: "value",
    header: "Wartość",
    size: 120,
  },
];

export default intialColumnsSampleResult;
