import React, { useState, useEffect } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import axios from "axios";
import api from "../../../config/api/apiRoutes";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import PatientAddForm from "../../../components/Patient/form/PatientAddForm";
import { Divider } from "rsuite";
import HomeIcon from "@mui/icons-material/Home";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";

function PatientAdd(props) {
  const [data, setData] = useState([]);
  const [dataAttribute, setDataAttribute] = useState([]);
  const [patientIdentifier, setPatientIdentifier] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  let { id } = useParams();

  useEffect(() => {
    const getData = async () => {
      axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")}`,
      };
      try {
        let selectDisease = [];
        let selectAttribute = [];

        const getDisease = await axios.get(api().DISEASE_LIST_GET);
        const getDataDisease = getDisease.data;
        const getAttribute = await axios.get(api().ATTRIBUTES_LIST_GET);
        const getDataAttribute = getAttribute.data;
        const getIdentifierPatient = await axios.get(api().NEW_PATIENT_IDENTIFIER);
        const getIdentifierPatientData = getIdentifierPatient.data;

        console.log(getIdentifierPatientData)

        getDataDisease.map((item, index) => {
          selectDisease.push({
            value: item.id,
            label: item.name,
          });
        });

        getDataAttribute.map((item, index) => {
          selectAttribute.push({
            value: item.id,
            label: item.attribute,
          });
        });

        console.log(selectAttribute);

        setData(selectDisease);
        setDataAttribute(selectAttribute);
        setPatientIdentifier(getIdentifierPatientData.identifier);

        setIsLoading(false);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      }
    };

    getData();
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Container maxWidth={false}>
      <Typography variant="h5" style={{ fontWeight: "400" }}>
        Dodaj pacjenta
      </Typography>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" to="/" style={{ color: "#888" }}>
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Dashboard
        </Link>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="#111"
          style={{ fontWeight: "600" }}
        >
          Dodaj pacjenta
        </Typography>
      </Breadcrumbs>
      <Divider />
      <Grid container spacing={3}>
        <Grid item lg={12} md={12} xs={12}>
          <PatientAddForm
            props={props}
            disease={data}
            attribute={dataAttribute}
            patientIdentifier={patientIdentifier}

          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default PatientAdd;
