import React, { useState, useEffect } from "react";
import axios from "axios";
import api from "../../../config/api/apiRoutes";
import { useParams } from "react-router-dom";
import { Box, Container, Grid, Typography } from "@mui/material";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import SampleDateForm from "../../../components/Sample/form/SampleDateForm";
import { Link } from "react-router-dom";
import { Divider } from "rsuite";
import HomeIcon from "@mui/icons-material/Home";
import Breadcrumbs from "@mui/material/Breadcrumbs";

function SampleAddDate(props) {
  const [SampleDate, setSampleDate] = useState([]);
  const [sampleExtraDate, setSampleExtraDate] = useState([]);
  const [sampleIdentifier, setSampleIdentifier] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  let { id } = useParams();
  console.log(id);

  useEffect(() => {
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    const getData = async () => {
      try {
        const extraDateArray = [];

        const getSampleDate = await axios.get(api(id).SAMPLE_DATE_GET);
        const getDataSampleDate = getSampleDate.data;
        const getDataSampleExtraDate = getDataSampleDate.extra_date_serializer;

        const getSampleIdentifier = await axios.get(api(id).SAMPLE_IDENTIFIER_GET);
        const getDataSampleIdentifier = getSampleIdentifier.data;
        setSampleIdentifier(getDataSampleIdentifier.identifier);

        console.log(getDataSampleDate.length);
        console.log(getDataSampleExtraDate);

        if (getDataSampleExtraDate != null) {
          getDataSampleExtraDate.map((item, index) => {
            extraDateArray.push({
              eventCalendarExtra: item.extra_date,
              eventCalendarExtraFormat: item.extra_date,
              extraDateName: item.extra_date_name,
            });
          });
        }
        

        console.log(extraDateArray);

        setSampleDate(getDataSampleDate);
        setSampleExtraDate(extraDateArray);
        setIsLoading(false);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      }
    };

    getData();
  }, []);
  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Container maxWidth={false}>
      <Typography variant="h5" style={{ fontWeight: "400" }}>
        Daty dla próbki {sampleIdentifier}
      </Typography>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" to="/" style={{ color: "#888" }}>
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Dashboard
        </Link>
        <Link underline="hover" to="/sample" style={{ color: "#888" }}>
          Lista próbek
        </Link>
        <Link underline="hover" to={`/sample/${id}`} style={{ color: "#888" }}>
          Szczegóły próbki 
        </Link>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="#111"
          style={{ fontWeight: "600" }}
        > 
          Data próbki 
        </Typography>
      </Breadcrumbs>
      <Divider />
      <Grid container spacing={3}>
        <Grid item lg={12} md={12} xs={12}>
          <SampleDateForm
            data={SampleDate}
            id_sample={id}
            props={props}
            extraDate={sampleExtraDate}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default SampleAddDate;
