import React, { useState, useEffect } from "react";
import axios from "axios";
import api from "../../../config/api/apiRoutes";
import { useParams } from "react-router-dom";
import { Box, Container, Grid, Typography, Button } from "@mui/material";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import EquipmentDetailTable from "../../../components/Equipment/table/EquipmentDetailTable";
import { Link } from "react-router-dom";


function EquipmentDetails(props) {
  const [data, setData] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  let { id } = useParams();

  useEffect(() => {
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    const getData = async () => {
      try {
        const getEquipment = await axios.get(api(id).EQUIPMENT_DETAILS_GET);
        const getDataEquipment = getEquipment.data;

        setData(getDataEquipment);

        setIsLoading(false);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      }
    };

    getData();
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              m: -1,
            }}
          >
            <Typography sx={{ m: 1 }} variant="h4">
            Szczegóły wyposażenia
            </Typography>
            <Box sx={{ m: 3 }}>
              <Link to={`/equipment/${id}/timeline`}>
                <Button color="error" variant="contained" sx={{ m: "20px" }}>
                  Timeline
                </Button>
              </Link>
            </Box>
          </Box>
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} xs={12}>
              <EquipmentDetailTable data={data} />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default EquipmentDetails;
