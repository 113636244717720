import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { Container } from "@mui/material";
import axios from "axios";
import api from "../../../config/api/apiRoutes";
import ExtensionTable from "../../../components/Table/ExtensionTable";
import intialColumnsPatientList from "../../../components/Patient/table/intialColumnsPatientList";
import { Link } from "react-router-dom";
import { Divider } from "rsuite";
import HomeIcon from "@mui/icons-material/Home";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
function PatientList(props) {
  const [patientList, setPatientList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    const getData = async () => {
      try {
        const getListPatient = await axios.get(api().PATIENT_LIST_GET);
        const getDataListPatient = getListPatient.data;

        const sortedArray = getDataListPatient
          .sort((a, b) => a.id - b.id) // sortowanie wg pola "id"
          .map((item, index) => {
            return {
              ...item, // zachowaj pozostałe pola
              nr: index + 1, // dodaj pole "nr" o wartości indeksu zwiększonego o 1
            };
          });

        console.log(sortedArray);

        console.log(sortedArray);

        setPatientList(sortedArray);
        setIsLoading(false);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      }
    };

    getData();
  }, []);

  return (
    <Container maxWidth={false}>
      <Typography variant="h5" style={{ fontWeight: "400" }}>
        Lista pacjentów
      </Typography>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" to="/" style={{ color: "#888" }}>
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Dashboard
        </Link>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="#111"
          style={{ fontWeight: "600" }}
        >
          Lista pacjentów
        </Typography>
      </Breadcrumbs>
      <Divider />
      <Box sx={{ mt: 3, mb: 5 }}>
        <ExtensionTable
          data={patientList}
          isLoading={isLoading}
          intialColumns={intialColumnsPatientList}
          headerTable={"Lista wszystkich pacjentów"}
          enableRowNumbers={false}
          intialState={{
            columnVisibility: { id: false },
            sorting: [{ id: "id", asc: true }],
          }}
        />
      </Box>
    </Container>
  );
}

export default PatientList;
