import React, { useState, useEffect } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import DeliveryFormAdd from "../../../components/Delivery/Form/DeliveryFormAdd";
import api from "../../../config/api/apiRoutes";
import axios from "axios";
import queryString from "query-string";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { Divider } from "rsuite";
import HomeIcon from "@mui/icons-material/Home";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";

function DeliveryAdd(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);

  const queryParams = queryString.parse(window.location.search);
  useEffect(() => {
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    const getData = async () => {
      try {
        const reagent = [];

        const getReagent = await axios.get(api().REAGENT_GET);

        getReagent.data.map((user) => {
          return reagent.push({ value: user.id, label: user.name });
        });

        setData(getReagent);

        console.log(reagent);

        if (queryParams.reagent_id) {
          console.log("ta");
          const filteredData = reagent.filter(
            (item) => item.value == queryParams.reagent_id
          );
          setData(filteredData);
          setData2(filteredData);
        } else {
          setData(reagent);
        }
        setIsLoading(false);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      }
    };

    getData();
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Container maxWidth={false}>
      <Typography variant="h5" style={{ fontWeight: "400" }}>
        Dodaj dostawę
      </Typography>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" to="/" style={{ color: "#888" }}>
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Dashboard
        </Link>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="#111"
          style={{ fontWeight: "600" }}
        >
          Dodaj dostawę
        </Typography>
      </Breadcrumbs>
      <Divider />
      <Grid container spacing={3}>
        <Grid item lg={12} md={12} xs={12}>
          <DeliveryFormAdd props={props} data={data} data2={data2} />
        </Grid>
      </Grid>
    </Container>
  );
}

export default DeliveryAdd;
