import React, { Component } from "react";
import { connect } from "react-redux"; // new import
import * as actions from "../../../services/actions/auth";
import axios from "axios";
import api from "../../../config/api/apiRoutes";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Button,
  TextField,
  Alert,
  AlertTitle,
  Typography
} from "@mui/material";
import Select from "react-select";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import { FormHelperText } from "@material-ui/core";
import SelectStyles from "../../select/SelectStyles";

class SampleFormReagentEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionsCurrentReagent: this.props.data,
      optionsListReagent: this.props.dataReagent,
      selectReagent: this.props.data,
    };
  }

  handleReagentsChange = (input) => (e, index) => {
    this.setState({ [input]: e });
  };

  editSampleReagentsForm = (e) => {
    e.preventDefault();
    const reagentId = [];

    this.state.selectReagent.map((item, index) => {
      reagentId.push(item.value);
    });

    const formData = {
      reagents: reagentId,
      sample: this.props.id_sample,
    };

    this.props.onAuth(formData, this.props.id_sample);
  };

  render() {
    if (this.props.adding == true) {
      this.props.props.history.push(`/sample/${this.props.id_sample}`);
    }
    return (
      <>
        {this.props.loading ? (
          <LoadingSpinner />
        ) : (
          <Card>
            <Typography
              variant="h6"
              sx={{ position: "relative", padding: "16px 0 20px 16px" }}
            >
              <span
                style={{
                  content: "",
                  backgroundColor: "#111e6c",
                  position: "absolute",
                  left: "0",
                  top: "16px",
                  width: "4px",
                  height: "25px",
                }}
              ></span>
              Formularz edycji odczynnika próbki
            </Typography>

            <Divider />
            <form onSubmit={this.editSampleReagentsForm}>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <Select
                      styles={SelectStyles}
                      isMulti
                      classNamePrefix="select"
                      menuPortalTarget={document.body}
                      value={this.state.selectReagent}
                      options={this.state.optionsListReagent}
                      placeholder="Wybierz odczynnik"
                      name="selectReagent"
                      onChange={this.handleReagentsChange("selectReagent")}
                    />
                    <FormHelperText>Usuń lub dodaj odczynniki.</FormHelperText>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  p: 2,
                }}
              >
                <Button type="submit" variant="contained" size="large">
                  Edytuj odczynniki dla próbki
                </Button>
              </Box>
            </form>
          </Card>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error,
    login_auth: state.login_auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (formData, id) =>
      dispatch(
        actions.requestApi(
          formData,
          api(id).SAMPLE_PROCEDURES_REAGENTS_PUT,
          "post"
        )
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SampleFormReagentEdit);
