import React, { useState } from "react";
import MaterialReactTable from "material-react-table";
import { Card, CardContent, Typography, Divider } from "@mui/material";

const TreeTable = (tableData) => {
  console.log(tableData);
  return (
    <Card>
      <CardContent sx={{ padding: "16px 0 0 16px" }}>
        <Typography
          variant="h6"
          sx={{ position: "relative", paddingLeft: "4px" }}
        >
          <span
            style={{
              content: "",
              backgroundColor: "#111e6c",
              position: "absolute",
              left: "-16px",
              top: 0,
              width: "4px",
              height: "25px",
            }}
          ></span>
          {tableData.headerTable}
        </Typography>
      </CardContent>
      <Divider sx={{ p: 1 }} />
      <MaterialReactTable
        columns={tableData.intialColumns}
        state={{ isLoading: tableData.isLoading }}
        data={tableData.data ?? []}
        enableExpanding
        enableExpandAll //default
        getSubRows={(originalRow) => originalRow.children} //default, can customize
        initialState={{ expanded: true }}
        enableColumnActions={false}
        enableColumnFilters={false}
        enablePagination={false}
        enableSorting={false}
        enableBottomToolbar={false}
        enableTopToolbar={false}
        muiTableBodyRowProps={{ hover: false }}
      />
    </Card>
  );
};

export default TreeTable;
