import React from "react";
import {
  Box,
  Button,
  Divider,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  useMediaQuery,
  Container,
  Card,
} from "@mui/material";
import * as actions from "../../../services/actions/auth";
import { connect } from "react-redux"; // new import
import * as Yup from "yup";
import { Formik, setFieldValue } from "formik";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import api from "../../../config/api/apiRoutes";
import InputOutlined from "../../ui-component/input/InputOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleButton: this.props.contactFormState.visibleButton,
      visibleCompany: this.props.contactFormState.visibleCompany,
      foundCompany: this.props.contactFormState.foundCompany,
      vat: this.props.contactFormState.vat,
      company: this.props.contactFormState.company,
      formData: this.props.formData,
    };
  }

  handleButtonClick = async () => {
    console.log(this.formik.values);
    const companyData = await this.getCompany(this.formik.values.companyVat);
    if (companyData) {
      this.setState({ visibleButton: true });
    } else {
      this.setState({ visibleButton: true });
    }
  };

  handleShowButton = () => {
    this.setState((prevState) => ({
      visibleCompany: !prevState.visibleCompany,
    }));
  };

  handleClose = () => {
    this.setState({ showPopover: false });
  };

  completeForm = async (form) => {
    this.props.nextStep();
    this.props.setFormData({ ...this.props.formData, ...form },{ ...this.props.contactFormState, ...this.state });
  };

  getCompany = (vat) => {
    return axios
      .get(api(vat).COMPANY_VAT)
      .then((response) => {
        let data = response.data;
        this.setState({
          vat: vat,
          company: data,
          foundCompany: true,
          visibleCompany: true,
        });
        console.log(data);
        return data;
      })
      .catch((error) => {
        console.error(error);
        if (error.response && error.response.status === 404) {
          this.setState({
            vat: vat,
            company: "",
            foundCompany: false,
            visibleCompany: false,
            visibleCompany: true,
            requiredCompany: false,
          });

          return false;
        } else {
          throw error;
        }
      });
  };

  componentDidUpdate(prevProps, prevState) {
    console.log("componentDidUpdate");
    console.log(this.state.company.id);
    const { values, setValues, validateForm } = this.formik;

    if (prevState.vat != this.state.vat) {
      if (this.state.foundCompany == false) {
        console.log("powinno byc puste");
        setValues({
          ...values,
          companyName: "",
          companyAddress: "",
          companyCity: "",
          companyZip: "",
        });
      }
      console.log("tataa");
      console.log(this.state.company.name);
      setValues({
        ...values,
        companyName: this.state.company.name ? this.state.company.name : "",
        companyAddress: this.state.company.address
          ? this.state.company.address
          : "",
        companyCity: this.state.company.city ? this.state.company.city : "",
        companyZip: this.state.company.post_code
          ? this.state.company.post_code
          : "",
      });

      if (prevState.foundCompany !== this.state.foundCompany) {
        //validateForm();
      }
    }
  }

  render() {
    const { fields, formData } = this.state;

    const { theme, scriptedRef, matchDownSM } = this.props;

    return (
      <>
        <Formik
          innerRef={(formik) => (this.formik = formik)}
          initialValues={{
            companyVat: formData.companyVat,
            companyName: formData.companyName,
            companyAddress: formData.companyAddress,
            companyCity: formData.companyCity,
            companyZip: formData.companyZip,
            findCompany: formData.findCompany,
            email: formData.email,
            firstName: formData.firstName,
            lastName: formData.lastName,
          }}
          validationSchema={Yup.object({
            companyVat: Yup.string().max(10).required("Pole wymagane"),
            findCompany: Yup.boolean().oneOf([true], "Wyszukaj firmę"),
            companyName: Yup.string().required("Pole wymagane"),
            companyAddress: Yup.string().required("Pole wymagane"),
            companyCity: Yup.string().required("Pole wymagane"),
            companyZip: Yup.string().required("Pole wymagane"),
            email: Yup.string().required("Pole wymagane"),
            firstName: Yup.string().required("Pole wymagane"),
            lastName: Yup.string().required("Pole wymagane"),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            console.log(values);
            try {
              setStatus({ success: true });
              this.completeForm(values);
              setSubmitting(false);
              console.log("ta");
            } catch (err) {
              console.error(err);
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue,
            setValues,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <InputOutlined
                  md={6}
                  xs={12}
                  inputLabel={"NIP"}
                  value={values.companyVat}
                  name={"companyVat"}
                  handleBlur={handleBlur}
                  onChange={handleChange}
                  touched={touched.companyVat}
                  errors={errors.companyVat}
                  inputId={"company-vat"}
                  inputType={"number"}
                  inputProps={{ autoFocus: true }}
                />
                <Grid
                  item
                  md={6}
                  xs={12}
                  direction="column"
                  align="left"
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    onClick={() => {
                      this.handleButtonClick({ nip: values.companyVat });

                      setValues((values) => ({
                        ...values,
                        findCompany: true,
                      }));
                    }}
                    disabled={!values.companyVat}
                    fullWidth
                    color="secondary"
                    endIcon={<SearchIcon />}
                    style={{ padding: "9px 8px" }}
                  >
                    Wyszukaj firmę
                  </Button>
                </Grid>

                <Grid
                  item
                  container
                  justify="center"
                  style={{ margin: "0 auto", width: "fit-content" }}
                >
                  <FormHelperText
                    error
                    id="standard-weight-helper-text--register"
                  >
                    {errors.findCompany}
                  </FormHelperText>
                </Grid>

                <Grid item xs={12} sm={12}>
                  {this.state.visibleButton && (
                    <Button
                      onClick={this.handleShowButton}
                      fullWidth
                      color="secondary"
                      endIcon={
                        this.state.visibleCompany ? (
                          <ExpandLessIcon />
                        ) : (
                          <ExpandMoreIcon />
                        )
                      }
                    >
                      {this.state.visibleCompany
                        ? "Ukryj dane firmy"
                        : "Wyświetl dane firmy"}
                    </Button>
                  )}
                </Grid>
                {this.state.visibleCompany && (
                  <>
                    <InputOutlined
                      md={12}
                      xs={12}
                      inputLabel={"Nazwa firmy"}
                      value={values.companyName}
                      name={"companyName"}
                      handleBlur={handleBlur}
                      onChange={handleChange}
                      touched={touched.companyName}
                      errors={errors.companyName}
                      disabled={this.state.foundCompany ? true : false}
                      inputId={"company-name"}
                      inputType={"text"}
                    />

                    <InputOutlined
                      md={6}
                      xs={12}
                      inputLabel={"Miasto"}
                      value={values.companyCity}
                      name={"companyCity"}
                      handleBlur={handleBlur}
                      onChange={handleChange}
                      touched={touched.companyCity}
                      errors={errors.companyCity}
                      disabled={this.state.foundCompany ? true : false}
                      inputId={"company-city"}
                      inputType={"text"}
                    />

                    <InputOutlined
                      md={6}
                      xs={12}
                      inputLabel={"Kod pocztowy"}
                      value={values.companyZip}
                      name={"companyZip"}
                      handleBlur={handleBlur}
                      onChange={handleChange}
                      touched={touched.companyZip}
                      errors={errors.companyZip}
                      disabled={this.state.foundCompany ? true : false}
                      inputId={"company-zip"}
                      inputType={"text"}
                    />

                    <InputOutlined
                      md={12}
                      xs={12}
                      inputLabel={"Adres"}
                      value={values.companyAddress}
                      name={"companyAddress"}
                      handleBlur={handleBlur}
                      onChange={handleChange}
                      touched={touched.companyAddress}
                      errors={errors.companyAddress}
                      disabled={this.state.foundCompany ? true : false}
                      inputId={"company-adress"}
                      inputType={"text"}
                    />
                  </>
                )}
                <InputOutlined
                  md={6}
                  xs={12}
                  inputLabel={"Imie"}
                  value={values.firstName}
                  name={"firstName"}
                  handleBlur={handleBlur}
                  onChange={handleChange}
                  touched={touched.firstName}
                  errors={errors.firstName}
                  inputId={"first-name"}
                  inputType={"text"}
                />
                <InputOutlined
                  md={6}
                  xs={12}
                  inputLabel={"Nazwisko"}
                  value={values.lastName}
                  name={"lastName"}
                  handleBlur={handleBlur}
                  onChange={handleChange}
                  touched={touched.lastName}
                  errors={errors.lastName}
                  inputId={"last-name"}
                  inputType={"text"}
                />

                <InputOutlined
                  md={12}
                  xs={12}
                  inputLabel={"Email"}
                  value={values.email}
                  name={"email"}
                  handleBlur={handleBlur}
                  onChange={handleChange}
                  touched={touched.email}
                  errors={errors.email}
                  inputId={"email"}
                  inputType={"text"}
                />
              </Grid>

              <Button
                disableElevation
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitting}
              >
                Next
              </Button>
            </form>
          )}
        </Formik>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    loading: state.loading,
    error: state.error,
    adding: state.adding,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (formData) =>
      dispatch(
        actions.requestWithoutTokenApi(
          formData,
          api().TRANSACTION_CREATE,
          "post"
        )
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);
