import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import DiseaseFormAdd from "../../../components/Diseases/Form/DiseaseFormAdd";
import { Link } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Divider } from "rsuite";
import HomeIcon from "@mui/icons-material/Home";

function DiseaseAdd(props) {
  return (
    <Container maxWidth={false}>
      <Typography variant="h5" style={{ fontWeight: "400" }}>
        Dodaj chorobę
      </Typography>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" to="/" style={{ color: "#888" }}>
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Dashboard
        </Link>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="#111"
          style={{ fontWeight: "600" }}
        >
          Dodaj chorobę
        </Typography>
      </Breadcrumbs>
      <Divider />
      <Box sx={{ mt: 3, mb: 5 }}>
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} xs={12}>
            <DiseaseFormAdd props={props} />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default DiseaseAdd;
