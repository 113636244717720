import React, { Component } from "react";
import {
  Button,
  Typography,
  Box,
  Grid,
  CircularProgress,
  Card,
} from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import {
  SentimentVerySatisfied,
  SentimentVeryDissatisfied,
} from "@material-ui/icons";
import StepperIcon from "./StepperIcon";
import ContactForm from "../form/shop/ContactForm";
import PaymentForm from "../form/shop/PaymentForm";

class Steppers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      loading: false,
      cardStatus: true,
      cardMessage: "",
      formData: {
        companyVat: "",
        companyName: "",
        companyAddress: "",
        companyCity: "",
        companyZip: "",
        findCompany: false,
        email: '',
        firstName: "",
        lastName: ""
      },
      contactFormState: {
        visibleButton: false,
        visibleCompany: false,
        foundCompany: null,
        vat: "",
        company: "",
      },
    };
  }

  StepContent = ({ step }) => {
    switch (step) {
      case 0:
        return (
          <ContactForm
            nextStep={this.handleNext}
            contactFormState={this.state.contactFormState}
            formData={this.state.formData}
            setFormData={this.setFormData}
          />
        );
      case 1:
        return <PaymentForm />;
      default:
        return null;
    }
  };

  handleNext = () => {
    if (this.state.activeStep === 2) {
      this.capture();
    } else {
      this.setState((prevState) => ({
        activeStep: prevState.activeStep + 1,
      }));
    }
  };

  handleBack = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep - 1,
    }));
  };

  handleReset = () => {
    this.setState({ activeStep: 0 });
  };

  capture = async () => {
    this.setState({ loading: true });

    this.setState((prevState) => ({
      activeStep: prevState.activeStep + 1,
      loading: false,
    }));
  };

  render() {
    const { activeStep, loading, cardStatus, cardMessage } = this.state;

    return (
      <>
        <Box sx={{ width: "100%" }}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {[1, 2].map((e) => (
              <Step key={e}>
                <StepLabel StepIconComponent={StepperIcon}></StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <Box
          component="main"
          sx={{
            alignItems: "center",
            display: "flex",
            flexGrow: 1,
            minHeight: "100%",
          }}
        >
          <Card sx={{ p: 5 }}>
            {activeStep === 3 ? (
              <Grid
                container
                spacing={3}
                direction="column"
                justify="space-around"
                alignItems="center"
                style={{ height: "400px" }}
              >
                {cardStatus ? (
                  <SentimentVerySatisfied fontSize="large" color="primary" />
                ) : (
                  <SentimentVeryDissatisfied fontSize="large" color="error" />
                )}
                <Typography variant="h4">{cardMessage}</Typography>
                <Button
                  onClick={cardStatus ? this.handleReset : this.handleBack}
                >
                  {cardStatus ? "Reset" : "Back"}
                </Button>
              </Grid>
            ) : (
              <Grid container spacing={3}>
                <this.StepContent step={activeStep} />
                <Grid container item justify="flex-end" spacing={2}>
                  <Button disabled={activeStep === 0} onClick={this.handleBack}>
                    Back
                  </Button>
                  {/* <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? (
                        <CircularProgress size={24} />
                      ) : activeStep === 1 ? (
                        "Pay"
                      ) : (
                        "Next"
                      )}
                    </Button> */}
                </Grid>
              </Grid>
            )}
          </Card>
        </Box>
      </>
    );
  }
}

export default Steppers;
