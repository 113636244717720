import React from "react";
import { toPng } from "html-to-image";
import {
  PDFDownloadLink,
  Document,
  Page,
  usePDF,
  pdf,
  Text,
  View,
  Image,
} from "@react-pdf/renderer";
import { Button, Grid } from "@mui/material";

function downloadImage(dataUrl) {
  const a = document.createElement("a");

  a.setAttribute("download", "reactflow.png");
  a.setAttribute("href", dataUrl);
  a.click();
}

function DownloadButton() {
  const onClick = () => {
    toPng(document.querySelector(".react-flow"), {
      filter: (node) => {
        // we don't want to add the minimap and the controls to the image
        if (
          node?.classList?.contains("react-flow__minimap") ||
          node?.classList?.contains("react-flow__controls")
        ) {
          return false;
        }

        return true;
      },
    }).then(downloadImage);
  };

  const downloadPdfButton = () => {
    toPng(document.querySelector(".react-flow"), {
      filter: (node) => {
        // we don't want to add the minimap and the controls to the image
        if (
          node?.classList?.contains("react-flow__minimap") ||
          node?.classList?.contains("react-flow__controls")
        ) {
          return false;
        }

        return true;
      },
    }).then((dataUrl) => {
      const MyDoc = (
        <Document>
          <Page size="A4">
            <View>
              <Image src={dataUrl} />
            </View>
          </Page>
        </Document>
      );

      pdf(MyDoc)
        .toBlob()
        .then((blob) => {
          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "reactflow.pdf";
          a.click();
          URL.revokeObjectURL(url);
        });
    });
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item lg={12} md={12} xs={12}>
          <Button variant="contained" onClick={onClick} fullWidth>
            Pobierz .png
          </Button>
        </Grid>
        <Grid item lg={12} md={12} xs={12}>
          <Button variant="contained" onClick={downloadPdfButton} fullWidth>
            Pobierz .pdf
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default DownloadButton;
