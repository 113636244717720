import React, { useState } from "react";
import MaterialReactTable from "material-react-table";
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  Divider,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { ExportToXlsx } from "../xlsx/ExportToXlsx";
import { exportSelectedRow } from "../exportData/exportSelectedRow";
import { darken } from "polished";

const TreeTableExport = (tableData) => {
  console.log(tableData);
  const [open, setOpen] = React.useState(false);
  const [row, setRow] = useState("");
  const [column, setColumn] = useState("");

  const handleClickOpen = (selectedRow, visibleLeafColumns) => {
    setOpen(true);
    setRow(selectedRow);
    setColumn(visibleLeafColumns);
  };

  const exportCsv = () => {
    exportSelectedRow(row, column);
    setOpen(false);
  };

  const exportXlsx = () => {
    ExportToXlsx(row, column);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Card>
        <CardContent sx={{ padding: "16px 0 0 16px" }}>
          <Typography
            variant="h6"
            sx={{ position: "relative", paddingLeft: "4px" }}
          >
            <span
              style={{
                content: "",
                backgroundColor: "#111e6c",
                position: "absolute",
                left: "-16px",
                top: 0,
                width: "4px",
                height: "25px",
              }}
            ></span>
            {tableData.headerTable}
          </Typography>
        </CardContent>
        <Divider sx={{ p: 1 }} />
        <MaterialReactTable
          columns={tableData.intialColumns}
          data={tableData.data ?? []}
          state={{ isLoading: tableData.isLoading }}
          enableRowSelection
          muiSelectCheckboxProps={({ row }) => ({
            sx: {
              paddingLeft: `${parseInt(row.original.level) * 15}px`,
            },
          })}
          enableExpanding
          enableExpandAll
          getSubRows={(originalRow, index) => originalRow.children}
          positionToolbarAlertBanner="bottom"
          muiTableHeadCellProps={{
            sx: {
              "& .Mui-TableHeadCell-Content": {
                width: "unset !important",
                margin: "unset !important",
              },
              "& .MuiTableCell-head": {
                width: "unset !important",
                margin: "unset !important",
              },
            },
          }}
          muiTableBodyRowProps={({ row }) => ({
            onClick: row.getToggleSelectedHandler(),
            sx: {
              backgroundColor:
                row.original.level == "1"
                  ? "#F9FAFC"
                  : darken(parseInt(row.original.level) / 50, "#F9FAFC"),

              "& .MuiTableCell-root": {
                textAlign: "unset !important",
              },
              "& .Mui-TableHeadCell-Content": {
                width: "unset !important",
                margin: "unset !important",
              },
              "& .MuiTableCell-head": {
                width: "unset !important",
                margin: "unset !important",
              },
            },
          })}
          renderTopToolbarCustomActions={({ table }) => (
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                p: "0.5rem",
                flexWrap: "wrap",
              }}
            >
              <Button
                color="primary"
                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                onClick={() =>
                  handleClickOpen(
                    table.getPrePaginationRowModel(),
                    table.getAllColumns()
                  )
                }
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export All Data
              </Button>
              <Button
                disabled={table.getPrePaginationRowModel().rows.length === 0}
                //export all rows, including from the next page, (still respects filtering and sorting)
                onClick={() =>
                  handleClickOpen(
                    table.getPrePaginationRowModel(),
                    table.getVisibleLeafColumns()
                  )
                }
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export All Rows
              </Button>
              <Button
                disabled={table.getRowModel().rows.length === 0}
                //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                onClick={() =>
                  handleClickOpen(
                    table.getRowModel(),
                    table.getVisibleLeafColumns()
                  )
                }
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export Page Rows
              </Button>
              <Button
                disabled={
                  !table.getIsSomeRowsSelected() &&
                  !table.getIsAllRowsSelected()
                }
                //only export selected rows
                onClick={() =>
                  handleClickOpen(
                    table.getSelectedRowModel(),
                    table.getVisibleLeafColumns()
                  )
                }
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export Selected Rows
              </Button>
            </Box>
          )}
        />
      </Card>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Wybierz format"}</DialogTitle>

        <DialogActions>
          <Button onClick={exportCsv}>.csv</Button>
          <Button onClick={exportXlsx} autoFocus>
            .xlsx
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TreeTableExport;
