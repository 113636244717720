import React, { Component, useState } from "react";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Button,
  TextField,
  Alert,
  AlertTitle,
} from "@mui/material";
import * as actions from "../../../services/actions/auth";
import { connect } from "react-redux";
import axios from "axios";
import api from "../../../config/api/apiRoutes";
import Select from "react-select";
import SelectStyles from "../../select/SelectStyles";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import AddIcon from "@mui/icons-material/Add";

const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach(
    // if we have an error string set valid to false
    (val) => val.length > 0 && (valid = false)
  );
  return valid;
};

class PatientAddForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      PatientPesel: "",
      DiseaseOptions: this.props.disease,
      SelectDisease: "",
      PatientDiseaseID: "",
      SelectAdditionalDisease: [],
      PatientDiseaseAdditionalID: [],
      PatientComment: "",
      AttributeOptions: this.props.attribute,
      attributes: [],
      attributesId: [],

      Loading: false,

      errors: {
        PatientPesel: "",
        SelectDisease: "",
        PatientMainDisease: "",
        PatientExtraValues: "",
        PatientAttributeName: "",
        PatientAttributeValue: "",
      },
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });

    const { name, value } = e.target;
    let errors = this.state.errors;
    switch (name) {
      default:
        break;
    }

    this.setState({ errors, [name]: value });
  };

  handleChange2 = (input) => (e, index) => {
    this.setState({ [input]: e });
    const name = input;
    const value = e;
    let errors = this.state.errors;
    switch (name) {
      case "PatientMainDisease":
        errors.PatientMainDisease =
          !value == false ? "" : "Numer ICD powinien mieć 10 znaków";
        break;
      default:
        break;
    }

    this.setState({ errors, [name]: value });
  };

  handleChangeDisease = (e) => {
    this.setState({ SelectDisease: e, PatientDiseaseID: e.value });
    // const name = input;
    // const value = e;
    // console.log(!value);
    // let errors = this.state.errors;
    // switch (name) {
    //   case "PatientMainDisease":
    //     errors.PatientMainDisease =
    //       !value == false ? "" : "Numer ICD powinien mieć 10 znaków";
    //     break;
    //   default:
    //     break;
    // }

    // this.setState({ errors, [name]: value });
  };

  setTrueLoading = () => {
    this.setState({ Loading: true });
  };

  setFalseLoading = () => {
    this.setState({ Loading: false });
  };

  handleFormChange = (event, index) => {
    let data = [...this.state.attributes];

    data[index][event.target.name] = event.target.value;
    this.setState(data);
  };

  handleFormChangeSelect = (event, index) => {
    let data = [...this.state.attributes];

    data[index]["attributeId"] = event.value;
    data[index]["attributeName"] = event.label;
    this.setState(data);
  };

  addFields = () => {
    let object = {
      attributeName: "",
      attributeId: "",
      attributeValue: "",
      add: false,
    };

    this.setState({
      attributes: [...this.state.attributes, object],
    });
  };

  removeFields = (index) => {
    let data = this.state.attributes;
    data.splice(index, 1);
    this.setState(data);
  };

  removeAndAddFields = (index) => {
    this.removeFields(index);
    this.addFields(index);
  };

  addAttribute = async () => {
    try {
      const { attributes } = this.state;
      const attributesId = this.state.attributesId;
      const token = localStorage.getItem("token");
      const user_id = localStorage.getItem("user_id");
      axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      };

      await Promise.all(
        attributes.map(async (item1, index) => {
          if (item1.old || item1.add) {
            return Promise.resolve();
          }

          const formData = [
            {
              attribute: item1.attributeId,
              creator: user_id,
              short_text: item1.attributeValue,
            },
          ];

          try {
            const res = await axios.post(api().VALUES_OPTIONS_POST, formData);
            console.log("tak");
            res.data.forEach((item1, index) => {
              attributesId.push(item1.id);
              attributes[index].add = true;
            });
          } catch (error) {
            if (error.response) {
              console.log(axios.post);
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            }
          }
        })
      );
    } catch (err) {
      console.error(err);
    }
  };

  addPatient = () => {
    let idAdditionalDisease = [];
    console.log(this.state.attributes);
    this.state.SelectAdditionalDisease.forEach((item, index) => {
      idAdditionalDisease.push(item.value);
    });
    const formData = {
      ...(this.state.PatientPesel && { pesel: this.state.PatientPesel }),
      ...(this.state.PatientDiseaseID && {
        main_disease: this.state.PatientDiseaseID,
      }),
      additional_disease: idAdditionalDisease,
      ...(this.state.PatientComment && { comment: this.state.PatientComment }),
      ...(localStorage.getItem("user_id") && {
        creator: `${localStorage.getItem("user_id")}`,
      }),
      extra_values: this.state.attributesId,
    };

    console.log(formData);
    const validate = () => {
      let validationError = this.state.errors;
      // if (!this.state.EquipmentName) {
      //   validationError.EquipmentName = "Wymagane pole";
      // }
      // if (!this.state.EquipmentSerial) {
      //   validationError.EquipmentSerial = "Wymagane pole";
      // }
      // if (!this.state.EquipmentBig) {
      //   validationError.EquipmentBig = "Wymagane pole";
      // }

      this.setState({ validationError, validationError });
      return validationError;
    };

    const validationError = validate();
    if (validateForm(validationError)) {
      this.props.onAuth(formData);
    } else {
      console.error("Invalid Form");
    }
  };

  completeForm = async (e) => {
    e.preventDefault();
    await this.addAttribute();
    this.addPatient();
  };

  render() {
    if (this.props.adding == true) {
      this.props.props.history.push(`/patient`);
    }
    return (
      <>
        {this.props.loading ? (
          <LoadingSpinner />
        ) : (
          <Card>
            <form onSubmit={this.completeForm}>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="PESEL/Nr pacjenta"
                      onChange={this.handleChange}
                      value={this.state.PatientPesel}
                      type="number"
                      name="PatientPesel"
                      helperText={
                        this.state.errors.PatientPesel.length
                          ? "Pesel powinien zawierać 11 cyfr"
                          : ""
                      }
                      error={
                        this.state.errors.PatientPesel.length ? true : false
                      }
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Select
                      placeholder="Wybierz główną chorobę"
                      className="basic-single"
                      classNamePrefix="select"
                      onChange={this.handleChangeDisease}
                      name="SelectDisease"
                      value={this.state.SelectDisease}
                      options={this.state.DiseaseOptions}
                      styles={SelectStyles}
                      noOptionsMessage={() => "name not found"}
                    ></Select>
                    {this.state.errors.SelectDisease.length > 0 && (
                      <>{this.state.errors.SelectDisease}</>
                    )}
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Alert severity="info">
                      Przewidywany identyfikator pacjenta:{" "}
                      <b> {this.props.patientIdentifier}</b>
                    </Alert>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Select
                      placeholder="Wybierz dodatkowe choroby"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={this.handleChange2("SelectAdditionalDisease")}
                      value={this.state.SelectAdditionalDisease}
                      fullWidth
                      options={this.state.DiseaseOptions}
                      styles={SelectStyles}
                      isMulti
                      noOptionsMessage={() => "name not found"}
                    ></Select>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="Komentarz"
                      value={this.state.PatientComment}
                      onChange={this.handleChange}
                      type="text"
                      name="PatientComment"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Alert severity="info">
                      Wybierz dodatkowy atrybut i przypisz mu wartość.
                    </Alert>
                  </Grid>
                  {this.state.attributes.map((form, index) => {
                    return (
                      <>
                        <Grid item md={6} xs={12}>
                          <Select
                            isDisabled={
                              this.state.attributes[index].add == true
                                ? true
                                : false
                            }
                            className="basic-single"
                            classNamePrefix="select"
                            options={this.state.AttributeOptions}
                            styles={SelectStyles}
                            onChange={(event) =>
                              this.handleFormChangeSelect(event, index)
                            }
                            value={{ label: form.attributeName }}
                            noOptionsMessage={() => "name not found"}
                          />
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            label={"Wartość"}
                            onChange={(event) =>
                              this.handleFormChange(event, index)
                            }
                            value={form.attributeValue}
                            type="text"
                            name="attributeValue"
                            variant="outlined"
                            disabled={
                              this.state.attributes[index].add == true
                                ? true
                                : false
                            }
                          />
                        </Grid>

                        <Grid item md={12} xs={12} align="center">
                          <Button
                            disabled={
                              this.state.attributes[index].add == true ||
                              index === 0
                                ? true
                                : false
                            }
                            onClick={() => this.removeFields(index)}
                            startIcon={
                              <RemoveCircleRoundedIcon
                                style={{ color: "red" }}
                              />
                            }
                          >
                            Usuń
                          </Button>
                          <Button
                            onClick={() => this.removeAndAddFields(index)}
                            startIcon={<RemoveCircleRoundedIcon />}
                          >
                            Wyczyść
                          </Button>
                        </Grid>
                      </>
                    );
                  })}

                  <Grid item md={12} xs={12} align="center" sx={{ mb: 3 }}>
                    <Button
                      onClick={() => this.addFields()}
                      startIcon={
                        <AddCircleRoundedIcon style={{ color: "green" }} />
                      }
                    >
                      Dodaj atrybut
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  p: 3,
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  endIcon={<AddIcon />}
                >
                  Dodaj pacjenta
                </Button>
              </Box>
            </form>
          </Card>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error,
    adding: state.adding,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (formData) =>
      dispatch(actions.requestApi(formData, api().PATIENT_ADD_POST, "post")),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientAddForm);
