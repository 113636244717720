import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import { Grid, Stack, FormHelperText, TextField } from "@mui/material";

export default function InputOutlined({
  inputLabel,
  value,
  name,
  handleBlur,
  onChange,
  categoryOptions,
  touched,
  errors,
  inputId,
  disabled,
  inputType,
  md,
  xs,
  inputProps,
  rows,
}) {
  return (
    <Grid item md={md} xs={xs}>
      <TextField
        id={inputId}
        type={inputType}
        disabled={disabled}
        fullWidth
        margin="normal"
        label={inputLabel}
        value={value}
        name={name}
        onBlur={handleBlur}
        onChange={onChange}
        inputProps={inputProps}
        rows={4}
        />
      {touched && errors && (
        <FormHelperText error id="standard-weight-helper-text-email-login">
          {errors}
        </FormHelperText>
      )}
    </Grid>
  );
}
