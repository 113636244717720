import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ViewFile({
  showPopover,
  handleClosePopover,
  title,
  content,
}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      fullScreen
      open={showPopover}
      onClose={handleClosePopover}
      TransitionComponent={Transition}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {},
          "& .MuiToolbar-root": {
            background: "#111e6c !important",
          },
        },
      }}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClosePopover}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography
            sx={{ ml: 2, flex: 1 }}
            variant="h6"
            component="div"
            color="#fff"
          >
            {title}
          </Typography>
          <Button autoFocus color="inherit" onClick={handleClosePopover}>
            Zamknij
          </Button>
        </Toolbar>
      </AppBar>
      {content}
    </Dialog>
  );
}
