import { Box } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const customRenderColumn = (row) => {
  return (
    <div>
      <b>
        <span style={{ color: "#fa5252" }}>{row.substring(0, 7)}</span>
        <span style={{ color: "#82368C" }}>{row.substring(7, 11)}</span>
        <span style={{ color: "#363A89" }}>{row.substring(11)}</span>
      </b>
    </div>
  );
};

const intialColumnsSampleDetails = [
  // {
  //   accessorKey: "id",
  //   header: "ID próbki",
  // },
  {
    accessorKey: "identifier",
    accessorFn: (originalRow) => originalRow.identifier,
    Cell: ({ cell }) => customRenderColumn(cell.getValue()),
    header: "Identyfikator",
  },
  {
    accessorKey: "amount",
    header: "Ilość",
  },
  {
    accessorKey: "difference",
    header: "Ile zostało ?",
  },
  {
    accessorKey: "unit",
    header: "jednostka",
  },
  {
    accessorKey: "real_amount",
    header: "Rzeczywista ilość",
  },
  {
    accessorKey: "real_unit",
    header: "Rzeczywista jednostka",
  },
  {
    accessorKey: "comment",
    header: "Komentarz",
  },
  {
    accessorFn: (row) => (
      <Box
        sx={{
          color:
            row.status === "NOWA"
              ? "#04a9f5"
              : row.status === "DO ZBADANIA"
              ? "#f5b759"
              : row.status === "ZBADANA"
              ? "#05a677"
              : row.status === "ZARCHIWIZOWANA"
              ? "#fa5252"
              : "inherit",
          alignItems: "center",
        }}
      >
        <>
          {row.status}
          <FiberManualRecordIcon
            style={{ paddingBottom: "3px", fontSize: "15px" }}
          />
        </>
      </Box>
    ),
    header: "status",
    enableColumnFilter: false,
  },
  {
    accessorKey: "parent",
    header: "Próbka nadrzędna",
  },
];

export default intialColumnsSampleDetails;
