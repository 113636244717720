import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../../../../services/actions/auth";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Button,
  TextField,
  Typography,
  Switch,
  FormControlLabel,
} from "@mui/material";
import api from "../../../../../config/api/apiRoutes";
import LoadingSpinner from "../../../../../components/LoadingSpinner/LoadingSpinner";
import { Edit } from "@material-ui/icons";

class AttributeEditForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attribute: props.data.attribute,
      checked: props.data.sample_result,
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  completeForm = (e) => {
    e.preventDefault();
    const formData = {
      attribute: this.state.attribute,
      values_type: "KRÓTKI OPIS",
      creator: `${localStorage.getItem("user_id")}`,
      //sample_result: this.state.checked,
    };
    console.log(formData);
    this.props.onAuth(formData, this.props.id);
  };

  handleChange = (event) => {
    this.setState({ checked: event.target.checked });
  };

  render() {
    let errorMessage = null;
    if (this.props.error) {
      errorMessage = <p>{this.props.error.message}</p>;
    }

    if (this.props.adding == true) {
      this.props.props.history.push("/attribute");
    }
    console.log(this.props);

    return (
      <>
        {this.props.loading ? (
          <LoadingSpinner />
        ) : (
          <Card>
            <Typography
              variant="h6"
              sx={{ position: "relative", padding: "16px 0 20px 16px" }}
            >
              <span
                style={{
                  content: "",
                  backgroundColor: "#111e6c",
                  position: "absolute",
                  left: "0",
                  top: "16px",
                  width: "4px",
                  height: "25px",
                }}
              ></span>
              Formularz dodania atrybutu
            </Typography>

            <Divider />
            <form onSubmit={this.completeForm}>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="Nazwa atrybutu"
                      onChange={this.onChange}
                      value={this.state.attribute}
                      type="text"
                      name="attribute"
                      variant="outlined"
                    />
                  </Grid>
                  {/* <Grid item md={2} xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.checked}
                          onChange={this.handleChange}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Dotyczy próbki?"
                    />
                  </Grid> */}
                </Grid>
              </CardContent>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  p: 2,
                }}
              >
                <Button type="submit" variant="contained" endIcon={<Edit />}>
                  Edytuj atrybut
                </Button>
              </Box>
            </form>
          </Card>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error,
    adding: state.adding,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (formData, id) =>
      dispatch(actions.requestApi(formData, api(id).ATTRIBUTE_EDIT, "put")),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AttributeEditForm);
