import React, { Component } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
} from "@mui/material";
import api from "../../config/api/apiRoutes";
class AccountProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attribute: "",
    };
  }

  render() {
    const user = {
      avatar: "/static/images/avatars/avatar_6.png",
      laboratoryName: `${localStorage.getItem("user_laboratory_name")}`,
      email: `${localStorage.getItem("user_email")}`,
      laboratoryVat: `${localStorage.getItem("user_laboratory_vat")}`,
      userRole: `${localStorage.getItem("role")}`,
    };
    return (
      <>
        <Card>
          <Typography
            variant="h6"
            sx={{ position: "relative", padding: "16px 0 20px 16px" }}
          >
            <span
              style={{
                content: "",
                backgroundColor: "#111e6c",
                position: "absolute",
                left: "0",
                top: "16px",
                width: "4px",
                height: "25px",
              }}
            ></span>
            Główne informacje
          </Typography>
          <Divider />

          <CardContent>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Avatar
                src={localStorage.getItem("image")}
                sx={{
                  height: 64,
                  mb: 2,
                  width: 64,
                }}
              />
              <Typography color="textPrimary" gutterBottom variant="h5">
                {user.email}
              </Typography>
              <Typography color="textSecondary" variant="body2">
                Laboratorium: {user.laboratoryName}
              </Typography>
              <Typography color="textSecondary" variant="body2">
                NIP: {user.laboratoryVat}
              </Typography>
              <Typography color="textSecondary" variant="body2">
                Rola: {user.userRole}
              </Typography>
            </Box>
          </CardContent>
          <Divider />
          <CardActions>
            {/* <Button
              color="primary"
              fullWidth
              variant="text"
              href={api().CHANGE_PASSWORD}
              target="_blank"
            >
              Zmień hasło
            </Button> */}
          </CardActions>
        </Card>
      </>
    );
  }
}

export default AccountProfile;
