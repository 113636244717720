import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import {
  Grid,
  Stack,
  FormHelperText,
  Select as SelectMui,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

export default function Select({
  inputLabel,
  value,
  name,
  handleBlur,
  onChange,
  dataArray,
  touched,
  errors,
  inputId,
  md,
  xs,
  valueKey,
  nameKey,
  style,
  styleGrid,
  stackSpacing,
}) {
  return (
    <Grid item md={md} xs={xs} style={styleGrid}>
      <Stack spacing={stackSpacing == undefined ? 1 : stackSpacing}>
        <InputLabel htmlFor={inputId}>{inputLabel}</InputLabel>
        <SelectMui
          labelId="demo-simple-select-label"
          id={inputId}
          fullWidth
          value={value}
          name={name}
          onBlur={handleBlur}
          onChange={onChange}
          style={style}
          sx={style}
        >
          {dataArray?.map((item) => (
            <MenuItem key={item.id} value={item[valueKey]}>
              {item[nameKey]}
            </MenuItem>
          ))}
        </SelectMui>
        {touched && errors && (
          <FormHelperText error id="standard-weight-helper-text-email-login">
            {errors}
          </FormHelperText>
        )}
      </Stack>
    </Grid>
  );
}
