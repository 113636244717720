import React, { Component } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Alert,
  AlertTitle,
  Typography,
} from "@mui/material";
import Select from "react-select";
import axios from "axios";
import { connect } from "react-redux";
import * as actions from "../../services/actions/auth";
import api from "../../config/api/apiRoutes";
import SelectStyles from "../select/SelectStyles";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import AddIcon from "@mui/icons-material/Add";

const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach(
    // if we have an error string set valid to false
    (val) => val.length > 0 && (valid = false)
  );
  return valid;
};

export class PatientEditForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      PatientPesel: this.props.data.pesel,
      PatientMainDisease: this.props.data.main_disease_name,
      PatientAdditionalDisease: this.props.data.additional_disease_name,
      PatientComment: this.props.data.comment,
      PatientExtraValues: this.props.data.extra_values,
      PatientExtraValuesID: [],
      PatientAttributesOld: this.props.data.extra_values_id,
      Diseases: this.props.data2,
      DiseaseValue: {},
      DieseasesOptions: [],
      DiseasesAdditionalValue: [],
      visible: false,
      attributes: [
        {
          attributeName: "",
          attributeValue: "",
          attributeId: "",
        },
      ],
      errors: {
        PatientPesel: "",
      },
      OptionsAttributes: [],
    };
  }

  componentWillMount() {
    const diseasesArray = this.state.Diseases;
    const attributtesArray = this.props.data3;

    const filteredMainDisease = this.state.Diseases.filter((item) =>
      item.name.includes(this.state.PatientMainDisease)
    );

    if (filteredMainDisease[0]) {
      console.log(filteredMainDisease[0]);
      this.setState({
        DiseaseValue: {
          value: filteredMainDisease[0].id,
          label: filteredMainDisease[0].name,
        },
      });
    }

    const filterArray = this.state.PatientAdditionalDisease;

    const filteredArray = diseasesArray.filter((item) =>
      filterArray.includes(item.name_list)
    );

    this.state.Diseases.map((item, index) => {
      return this.state.DieseasesOptions.push({
        value: item.id,
        label: item.name,
      });
    });

    filteredArray.map((item, index) => {
      return this.state.DiseasesAdditionalValue.push({
        value: item.id,
        label: item.name,
      });
    });

    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    axios
      .get(api().ATTRIBUTES_LIST_GET)
      .then((res) => {
        let result = res.data;
        console.log(result);

        result.map((item, index) => {
          attributtesArray.push({
            value: item.id,
            label: item.attribute,
            type: item.value_type,
          });
        });

        result.map((item, index) => {
          return this.state.OptionsAttributes.push({
            value: item.id,
            label: item.attribute,
            type: item.value_type,
          });
        });
      })
      .catch(function (error) {
        if (error.response) {
          console.log(axios.post);
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      })
      .catch((err) => {});

    const convertedArray = this.props.data.extra_values.map((item) => ({
      attributeName: item.attribute_attribute.attribute,
      attributeValue: item.short_text,
      old: true,
      attributeId: item.id,
    }));

    this.setState({ attributes: convertedArray });
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    console.log(this.state);

    const { name, value } = e.target;
    let errors = this.state.errors;
    switch (name) {
      default:
        break;
    }

    this.setState({ errors, [name]: value });
    console.log(this.state);
  };

  handleSelectMainDisease = (input) => (e, index) => {
    this.setState({ DiseaseValue: e });
  };

  handleSelectAdditionalDisease = (input) => (e, index) => {
    this.setState({ DiseasesAdditionalValue: e });
  };

  handleFormChangeSelect = (event, index) => {
    let data = [...this.state.attributes];
    console.log(event);

    data[index]["attributeId"] = event.value;
    data[index]["attributeName"] = event.label;
    this.setState(data);
  };

  handleFormChange = (event, index) => {
    let data = [...this.state.attributes];
    console.log(event);

    data[index][event.target.name] = event.target.value;
    this.setState(data);
  };

  removeFields = (index, form) => {
    let data = this.state.attributes;
    let data2 = this.state.PatientAttributesOld;
    const newArray = data2.filter((element) => element !== form.attributeId);
    console.log(newArray);
    this.setState({ PatientAttributesOld: newArray });
    data.splice(index, 1);
    console.log(data);
    this.setState(data);
  };

  addFields = () => {
    let object = {
      attributeName: "",
      attributeValue: "",
      old: false,
      attributeId: "",
    };

    this.setState({
      attributes: [...this.state.attributes, object],
    });
  };

  addAttribute = async () => {
    try {
      const { attributes } = this.state;
      const PatientAttributesOld = this.state.PatientAttributesOld;
      const token = localStorage.getItem("token");
      const user_id = localStorage.getItem("user_id");
      axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      };

      await Promise.all(
        attributes.map(async (item1, index) => {
          if (item1.old || item1.add) {
            return Promise.resolve();
          }

          const formData = [
            {
              attribute: item1.attributeId,
              creator: user_id,
              short_text: item1.attributeValue,
            },
          ];

          try {
            const res = await axios.post(api().VALUES_OPTIONS_POST, formData);
            console.log("tak");
            res.data.forEach((item1, index) => {
              PatientAttributesOld.push(item1.id);
              attributes[index].add = true;
            });
          } catch (error) {
            if (error.response) {
              console.log(axios.post);
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            }
          }
        })
      );
    } catch (err) {
      console.error(err);
    }
  };

  editPatientForm = () => {
    const id_patient = this.props.id_patient;

    const idAdditionalDisease = this.state.DiseasesAdditionalValue.map(
      (item) => item.value
    );
    const user_id = localStorage.getItem("user_id");

    const formData = {
      ...(this.state.PatientPesel && { pesel: this.state.PatientPesel }),
      ...(this.state.DiseaseValue.value && {
        main_disease: this.state.DiseaseValue.value,
      }),
      additional_disease: idAdditionalDisease,
      ...(this.state.PatientComment && { comment: this.state.PatientComment }),
      ...(user_id && { creator: user_id }),
      extra_values: this.state.PatientAttributesOld,
    };

    console.log(formData);

    const validate = () => {
      const validationError = this.state.errors;
      // if (!this.state.EquipmentName) {
      //   validationError.EquipmentName = "Wymagane pole";
      // }

      this.setState({ validationError });
      console.log("in validate", validationError);
      return validationError;
    };

    const validationError = validate();
    console.log(this.state.errors);

    if (validateForm(validationError)) {
      console.info("Valid Form");
      this.props.onAuth(formData, id_patient);
    } else {
      console.error("Invalid Form");
    }
  };

  completeForm = async (e) => {
    e.preventDefault();
    await this.addAttribute();
    this.editPatientForm();
  };

  render() {
    const { errors } = this.state;
    if (this.props.adding == true) {
      this.props.props.history.push(`/patient/${this.props.id_patient}`);
    }
    return (
      <>
        {this.props.loading ? (
          <LoadingSpinner />
        ) : (
          <Card>
            <Typography
              variant="h6"
              sx={{ position: "relative", padding: "16px 0 20px 16px" }}
            >
              <span
                style={{
                  content: "",
                  backgroundColor: "#111e6c",
                  position: "absolute",
                  left: "0",
                  top: "16px",
                  width: "4px",
                  height: "25px",
                }}
              ></span>
              Formularz edycji pacjenta
            </Typography>

            <Divider />
            <form onSubmit={this.completeForm}>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Nr pacjenta / pesel"
                      value={this.state.PatientPesel}
                      onChange={this.onChange}
                      name="PatientPesel"
                      variant="outlined"
                      helperText={
                        errors.PatientPesel.length > 0
                          ? errors.PatientPesel
                          : ""
                      }
                      error={errors.PatientPesel.length ? true : false}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Select
                      placeholder="Wybierz główna chorobe"
                      className="basic-single"
                      classNamePrefix="select"
                      onChange={this.handleSelectMainDisease()}
                      options={this.state.DieseasesOptions}
                      styles={SelectStyles}
                      value={this.state.DiseaseValue}
                      noOptionsMessage={() => "name not found"}
                    ></Select>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Select
                      isMulti
                      placeholder="Wybierz dodatkowa chorobe"
                      className="select"
                      styles={SelectStyles}
                      onChange={this.handleSelectAdditionalDisease()}
                      options={this.state.DieseasesOptions}
                      value={this.state.DiseasesAdditionalValue}
                      noOptionsMessage={() => "name not found"}
                    ></Select>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Alert severity="info" style={{ marginTop: "50px" }}>
                      Wybierz dodatkowy atrybut i przypisz mu wartość.
                    </Alert>
                  </Grid>

                  {this.state.attributes.map((form, index) => {
                    return (
                      <>
                        <Grid item md={6} xs={12}>
                          <Select
                            isDisabled={
                              this.state.attributes[index].old == true ||
                              this.state.attributes[index].add == true
                                ? true
                                : false
                            }
                            placeholder="Wybierz dodatkowe choroby"
                            className="basic-single"
                            classNamePrefix="select"
                            options={this.state.OptionsAttributes}
                            styles={SelectStyles}
                            onChange={(event) =>
                              this.handleFormChangeSelect(event, index)
                            }
                            value={{ label: form.attributeName }}
                            noOptionsMessage={() => "name not found"}
                          />
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            label="Wprowadź wartość"
                            onChange={(event) =>
                              this.handleFormChange(event, index)
                            }
                            value={form.attributeValue}
                            type="text"
                            name="attributeValue"
                            variant="outlined"
                            disabled={
                              this.state.attributes[index].old == true ||
                              this.state.attributes[index].add == true
                                ? true
                                : false
                            }
                          />
                        </Grid>

                        <Grid item md={12} xs={12} align="center">
                          <Button
                            disabled={
                              this.state.attributes[index].add == true
                                ? true
                                : false
                            }
                            onClick={() => this.removeFields(index, form)}
                            startIcon={
                              <RemoveCircleRoundedIcon
                                style={{ color: "red" }}
                              />
                            }
                          >
                            Usuń
                          </Button>
                        </Grid>
                      </>
                    );
                  })}

                  <Grid
                    item
                    md={12}
                    xs={12}
                    align="center"
                    sx={{ mb: 3 }}
                    spacing={3}
                  >
                    <Button
                      onClick={() => this.addFields()}
                      startIcon={
                        <AddCircleRoundedIcon style={{ color: "green" }} />
                      }
                    >
                      Dodaj nowy atrybut
                    </Button>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="Komentarz"
                      value={this.state.PatientComment}
                      onChange={this.onChange}
                      name="PatientComment"
                      type="text"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </form>
            <Divider />
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                p: 2,
              }}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={this.completeForm}
                size="large"
                endIcon={<AddIcon />}
              >
                Edytuj pacjenta
              </Button>
            </Box>
          </Card>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error,
    adding: state.adding,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (formData, id_patient) =>
      dispatch(
        actions.requestApi(formData, api(id_patient).PATIENT_EDIT_PUT, "put")
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientEditForm);
