import React, { useState, useEffect } from "react";
import axios from "axios";
import api from "../../../config/api/apiRoutes";
import PatientEditForm from "../../../components/Patient/PatientEditForm";
import { useParams } from "react-router-dom";
import { Box, Container, Grid, Typography } from "@mui/material";
import { Divider } from "rsuite";
import HomeIcon from "@mui/icons-material/Home";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import SampleEditForm from "../../../components/Sample/form/SampleEditForm";
function SampleEdit(props) {
  const [sampleDetails, setSampleDetails] = useState([]);
  const [sampleOptions, setSampleOptions] = useState([]);
  const [patientOptions, setPatientOptions] = useState([]);
  const [sampleExtraValues, setSampleExtraValues] = useState([]);
  const [sampleExtraValuesId, setSampleExtraValuesId] = useState([]);
  const [attributeOptions, setAttributeOptions] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  let { id } = useParams();

  useEffect(() => {
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    const getData = async () => {
      try {
        const sampleOptions = [];
        const patientOptions = [];
        const sampleAttributes = [];
        const sampleAttributesId = [];
        const attributesOptions = [];

        const getSampleDetails = await axios.get(api(id).SAMPLE_GET);
        const getDataSampleDetails = getSampleDetails.data;
        const getDataSampleExtraValues = getDataSampleDetails.extra_values;

        const getSamples = await axios.get(api().SAMPLE_LIST_ALL_GET);
        const getDataSample = getSamples.data;

        const getPatient = await axios.get(api().PATIENT_LIST_GET);
        const getDataPatient = getPatient.data;

        const getAttributes = await axios.get(api().ATTRIBUTES_LIST_GET);
        const getDataAttributes = getAttributes.data;

        getDataSample.map((item, index) => {
          return sampleOptions.push({
            value: item.id,
            label: item.id,
          });
        });

        getDataPatient.map((item, index) => {
          return patientOptions.push({
            value: item.id,
            label: item.id,
          });
        });

        if (getDataSampleExtraValues != null) {
          getDataSampleExtraValues.map((item, index) => {
            return sampleAttributes.push({
              attribute_name: item.attribute_attribute.attribute,
              values_type: item.short_text,
              attributeID: item.id,
              add: true,
            });
          });
        }

        if (getDataSampleExtraValues != null) {
          getDataSampleExtraValues.map((item, index) => {
            return sampleAttributesId.push(item.id);
          });
        }

        getDataAttributes.map((laboratory, index) => {
          return attributesOptions.push({
            value: laboratory.id,
            label: laboratory.attribute,
          });
        });

        console.log(sampleAttributes);

        setSampleDetails(getDataSampleDetails);
        setSampleOptions(sampleOptions);
        setPatientOptions(patientOptions);
        setSampleExtraValues(sampleAttributes);
        setSampleExtraValuesId(sampleAttributesId);

        setAttributeOptions(attributesOptions);

        setIsLoading(false);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      }
    };

    getData();
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Container maxWidth={false}>
      <Typography variant="h5" style={{ fontWeight: "400" }}>
        Edytuj próbkę {sampleDetails.identifier}
      </Typography>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" to="/" style={{ color: "#888" }}>
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Dashboard
        </Link>
        <Link underline="hover" to="/sample" style={{ color: "#888" }}>
          Lista próbek
        </Link>
        <Link underline="hover" to={`/sample/${id}`} style={{ color: "#888" }}>
          Szczegóły próbki {sampleDetails.identifier}
        </Link>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="#111"
          style={{ fontWeight: "600" }}
        >
          Edytuj próbkę
        </Typography>
      </Breadcrumbs>
      <Divider />
      <Grid container spacing={3} sx={{ mb: 5 }}>
        <Grid item lg={12} md={12} xs={12}>
          <SampleEditForm
            data={sampleDetails}
            sampleOptions={sampleOptions}
            patientOptions={patientOptions}
            sampleExtraValues={sampleExtraValues}
            sampleExtraValuesId={sampleExtraValuesId}
            attributeOptions={attributeOptions}
            id_sample={id}
            props={props}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default SampleEdit;
