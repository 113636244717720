import React, { useState, useEffect } from "react";
import axios from "axios";
import api from "../../../config/api/apiRoutes";
import { useParams } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Button,
  TextField,
  Container,
  Typography,
} from "@mui/material";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import ResultFormAdd from "../../../components/Sample/form/ResultFormAdd";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { Link } from "react-router-dom";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Divider } from "rsuite";
import HomeIcon from "@mui/icons-material/Home";
import Breadcrumbs from "@mui/material/Breadcrumbs";

function ResultAdd(props) {
  const [data, setData] = useState([]);
  const [sampleResult, setSampleResult] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);

  let { id } = useParams();

  function refreshList() {
    setIsLoading(true);
    setRefresh(!refresh);
  }

  useEffect(() => {
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    const getData = async () => {
      try {
        const getResults = await axios.get(api().RESULTS_OPTIONS_GET);
        const getDataResults = getResults.data;
        const getSampleResults = await axios.get(api(id).SAMPLE_RESULTS_GET);
        const getDataSampleResults = getSampleResults.data;

        setSampleResult(getDataSampleResults);
        console.log(getDataResults);
        setData(getDataResults);

        setIsLoading(false);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      }
    };

    getData();
  }, [refresh]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Container maxWidth={false}>
      <Typography variant="h5" style={{ fontWeight: "400" }}>
        Wyniki próbki
      </Typography>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" to="/" style={{ color: "#888" }}>
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Dashboard
        </Link>
        <Link underline="hover" to="/sample" style={{ color: "#888" }}>
          Lista próbek
        </Link>
        <Link underline="hover" to={`/sample/${id}`} style={{ color: "#888" }}>
          Szczegóły próbki
        </Link>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="#111"
          style={{ fontWeight: "600" }}
        >
          Wyniki próbki
        </Typography>
      </Breadcrumbs>
      <Divider />
      <Grid container spacing={3}>
        <Grid item lg={12} md={12} xs={12}>
          <Card>
            <Alert severity="info">
              <AlertTitle>Informacja</AlertTitle>
              Aby dodać nowy atrybut wyniku —{" "}
              <strong>
                <Link
                  to="/result-parameter-add"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  przejdź tutaj.
                </Link>
              </strong>{" "}
              Dodanie nowego parametru wymaga odświeżenia{" "}
              <button onClick={refreshList} style={{ background: "none" }}>
                <RefreshIcon onClick={refreshList} />
              </button>
            </Alert>{" "}
          </Card>
        </Grid>
        <Grid item lg={12} md={12} xs={12}>
          <ResultFormAdd
            data={data}
            id_sample={id}
            props={props}
            sampleResult={sampleResult}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default ResultAdd;
