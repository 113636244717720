import React, { Component } from "react";
import { connect } from "react-redux"; // new import
import * as actions from "../../../services/actions/auth";
import axios from "axios";
import api from "../../../config/api/apiRoutes";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import SelectStyles from "../../select/SelectStyles";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Button,
  TextField,
  Container,
  Typography,
  Alert,
  AlertTitle,
  FormHelperText,
} from "@mui/material";
import Creatable from "react-select/creatable";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Select from "react-select";

const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach(
    // if we have an error string set valid to false
    (val) => val.length > 0 && (valid = false)
  );
  return valid;
};

class SampleFormLocalizationEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getSampleLocalization: this.props.localizationSample.status,
      getSampleStatus: "NOWA",
      getLocalization: this.props.data,
      addLocalization: "",
      parentLocalization: "",
      showParentLocalization: false,
      sampleId: this.props.id_sample,
      id: "",
      errors: {
        addLocalization: "",
      },
    };
  }

  handleaddLocalizationChange = (addLocalization) => {
    this.setState({ addLocalization });
  };

  handleParentLocalization = (localization) => {
    this.setState({ parentLocalization: localization });
  };

  handledeleteLocalizationChange = (deleteLocalization) => {
    this.setState({ deleteLocalization });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
    console.log(this.state);
  };

  editSampleLocalizationForm = (e) => {
    e.preventDefault();

    const { addLocalization } = this.state;

    const validate = () => {
      console.log(!this.state.addLocalization.value);
      let validationError = this.state.errors;
      if (!addLocalization.value) {
        validationError.addLocalization = "Wymagane pole";
      } else {
        validationError.addLocalization = "";
      }

      this.setState({ validationError, validationError });
      console.log("in validate", validationError);
      return validationError;
    };

    const formData = {
      sample: this.state.sampleId,
      localization_name: this.state.addLocalization["label"],
      localization_parent: this.state.parentLocalization["value"]

    };

    const validationError = validate();
    console.log(this.state.errors);
    if (validateForm(validationError)) {
      console.info("Valid Form");
      console.log(formData);
      this.props.onAuth(formData, this.state.sampleId);
    } else {
      console.error("Invalid Form");
    }
  };

  StatusAlert() {
    if (this.state.getSampleStatus === "ZARCHIWIZOWANA") {
      return (
        <Grid item md={12} xs={12}>
          <Alert severity="warning">
            <AlertTitle>UWAGA!</AlertTitle>
            Próbka jest zaarchiwizowana — o ewentualnej zmianie{" "}
            <strong>zostaną poinformawani Kierownicy laboratorium.</strong>
          </Alert>
        </Grid>
      );
    }
  }

  render() {
    if (this.props.adding == true) {
      this.props.props.history.push(`/sample/${this.state.sampleId}`);
    }
    return (
      <>
        {this.props.loading ? (
          <LoadingSpinner />
        ) : (
          <Card>
            <Typography
              variant="h6"
              sx={{ position: "relative", padding: "16px 0 20px 16px" }}
            >
              <span
                style={{
                  content: "",
                  backgroundColor: "#111e6c",
                  position: "absolute",
                  left: "0",
                  top: "16px",
                  width: "4px",
                  height: "25px",
                }}
              ></span>
              Formularz edycji lokalizacji próbki
            </Typography>

            <Divider />
            <form onSubmit={this.editSampleLocalizationForm}>
              <CardContent>
                <Grid container spacing={3}>
                  {this.StatusAlert()}

                  <Grid item md={12} xs={12}>
                    <Creatable
                      options={this.state.getLocalization}
                      placeholder="Nowa lokalizacja"
                      onChange={this.handleaddLocalizationChange}
                      name="color"
                      styles={SelectStyles}
                      menuPortalTarget={document.body}
                    />
                    {this.state.errors.addLocalization.length > 0 && (
                      <FormHelperText error={true}>
                        {this.state.errors.addLocalization}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          name="showParentLocalization"
                          checked={this.state.showParentLocalization}
                          onChange={this.handleChange}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Dodaj do lokalizacji nadrzędnej"
                    />
                  </Grid>

                  {this.state.showParentLocalization && (
                    <Grid item md={12} xs={12}>
                      <Select
                        options={this.state.getLocalization}
                        placeholder="Nowa lokalizacja"
                        onChange={this.handleParentLocalization}
                        name="color"
                        styles={SelectStyles}
                        menuPortalTarget={document.body}
                      />
                      {this.state.errors.addLocalization.length > 0 && (
                        <FormHelperText error={true}>
                          {this.state.errors.addLocalization}
                        </FormHelperText>
                      )}
                    </Grid>
                  )}
                </Grid>
              </CardContent>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  p: 3,
                }}
              >
                <Button type="submit" variant="contained">
                  Edytuj lokalizację
                </Button>
              </Box>
            </form>
          </Card>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error,
    adding: state.adding,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (formData, id) =>
      dispatch(
        actions.requestApi(formData, api(id).SAMPLE_LOCALIZATION_PUT, "put")
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SampleFormLocalizationEdit);
