import React, { useState, useEffect } from "react";
import { Box, Container, Grid, Typography, Card, Stack } from "@mui/material";
import background from "../../assets/images/background-home-page.png";
import { Button } from "@mui/material";
import { connect } from "react-redux";

import Download from "../../components/sections/Download";
import FilledInfoCard from "../../components/mui/cards/FilledInfoCard";
import SupportIcon from "@mui/icons-material/Support";
import CenteredBlogCard from "../../components/mui/cards/ImageCard";
import ImageCard from "../../components/mui/cards/ImageCard";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import SupportLab from "../../components/sections/SupportLab";
import imagecard1 from "../../assets/images/home1.png";
import imagecard2 from "../../assets/images/home2.png";
import Information from "../../components/sections/Information";
import Testing from "../../components/sections/Testing";
import PricingHomePage from "../../components/sections/Pricing";
import AboutUs from "../../components/sections/AboutUs";
import Contact from "../../components/sections/Contact";
import footerRoutes from "../../config/footer-routes";
import Footer from "../../components/footer";
import NavbarHomePage from "../../components/NavbarHomePage";
import NavbarRoutes from "../../components/navbar-routes";
import demo2 from "../../assets/images/demo2.png";
import video from "../../assets/video/video-v2.mp4";
import { useHistory } from 'react-router-dom';
import axios from "axios";
import api from "../../config/api/apiRoutes";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";

function HomePage(props) {
  
  const history = useHistory();
  if (props.token_auth) {
    history.push('/dashboard');
  }

    const [product, setProduct] = useState([]);
    const [format, setFormat] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const fieldRef = React.useRef<HTMLInputElement>(null);

    const titleStyles = {
      fontSize: "2rem",
      fontWeight: "500",
      lineHeight: "1.375",
      display: "block",
      opacity: "1",
      textTransform: "none",
      textAlign: "center",
      verticalAlign: "unset",
      textDecoration: "none",
      color: "black !important",
      letterSpacing: "-0.125px",
    };
  
    let currentSectionIndex = 0;

    useEffect(() => {
      const getData = async () => {
        try {
          const getProduct = await axios.get(api().PRODUCT_LIST);
          const getProductData = getProduct.data;
    
          const transformedData = getProductData.map((item) => {
            const transformedItem = {
              id: item.id,
              title: item.name,
              subheader: item.description,
              price: item.amount ? item.amount.toString() : null,
              description: item.extra_values.map((value) => value.value),
              buttonText: "Zobacz",
              buttonVariant: item.amount ? "contained" : "outlined",
              linkTo: `/shop/processing/?product_id=${item.id}`,
            };
    
            return transformedItem;
          });
    
          // Znajdź najtańszy produkt
          const cheapestProduct = transformedData.reduce((minProduct, currentProduct) => {
            if (!minProduct.price || (currentProduct.price && parseFloat(currentProduct.price) < parseFloat(minProduct.price))) {
              return currentProduct;
            }
            return minProduct;
          }, {});
    
          // Znajdź środkowy produkt
          const middleProduct = transformedData[Math.floor(transformedData.length / 2)];
    
          // Znajdź najdroższy produkt
          const mostExpensiveProduct = transformedData.reduce((maxProduct, currentProduct) => {
            if (!maxProduct.price || (currentProduct.price && parseFloat(currentProduct.price) > parseFloat(maxProduct.price))) {
              return currentProduct;
            }
            return maxProduct;
          }, {});
    
          setProduct(transformedData);
          setIsLoading(false);
    
          const productsArray = [];
          productsArray.push(cheapestProduct);
          productsArray.push(middleProduct);
          productsArray.push(mostExpensiveProduct);

          setFormat(productsArray);

        } catch (error) {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
        }
      };
    
      getData();


    }, []);
  
  
    if (isLoading) {
      return <LoadingSpinner />;
    }
    else {
      const currentHash = window.location.hash;
      if (currentHash && currentHash !== '#') {
        const element = document.getElementById(currentHash);
        if (element) {
          element.scrollIntoView({ block: "end"});
          setTimeout(() => {
            element.scrollIntoView({ block: 'end' });
          }, 200);          
        }
      }
    }

  return (
    <>
      <NavbarHomePage
        routes={NavbarRoutes}
        action={{
          type: "external",
          route: "https://www.creative-tim.com/product/material-kit-react",
          label: "free download",
          color: "info",
        }}
        sticky
      />
      <Box
        minHeight="90vh"
        width="100%"
        sx={{
          display: "grid",
          placeItems: "center",
          //width: "100vw",
          height: "100vh",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <video
          autoPlay
          loop
          muted
          style={{
            minWidth: "100%",
            minHeight: "100%",
            width: "auto",
            height: "120%",
            zIndex: "-1",
            position: "absolute",
            left: "50%",
            transform: " translateX(-50%)",
          }}
        >
          <source src={video} type="video/mp4" />
        </video>
        <Stack
          direction="column"
          justifyContent="flex-end"
          alignItems="center"
          height="100%"
          spacing={2}
        >
          <Button 
            variant="outlined" 
            sx={{ 
              mb: 12, 
              color: '#fff',
              borderColor: '#fff', 
              borderWidth: '5px', 
              fontWeight: 'bold',
              borderRadius: '43px' ,
              '&:hover': {
                borderWidth: '5px', // Zachowaj stałą szerokość bordera na hover
              },
            }} 
            size="large"
          >
            ZACZNIJ JUŻ DZIŚ
          </Button>

        </Stack>
      </Box>
      <div id="#home" />
      
      <Box component="section" sx={{height: '100vh'}}>
        <Download />
        <Container>
          <Grid container spacing={3} mt={2} mb={8}>
            <Grid item xs={12} lg={8}>
              <FilledInfoCard
                variant="gradient"
                color="info"
                icon={<SupportIcon style={{ color: "rgb(17, 30, 108)" }} />}
                title={
                  <Typography variant="h5" sx={{ fontFamily: 'Lucida Bright' }}>
                    Laboratorium ze wsparciem naszego systemu
                  </Typography>
                }
                subtitle={
                  <Typography variant="subtitle1" sx={{ fontFamily: 'Lato'}}>
                    Ułatwienia ze strony naszego systemu
                  </Typography>
                }
                description={
                  <Typography variant="body1" sx={{ fontWeight: 'lighter', textAlign: 'justify'}}>
                    Nasza aplikacja to zaawansowany system do zarządzania Twoim biobankiem. Pozwala on na skuteczne gromadzenie, przechowywanie i udostępnianie próbek biologicznych w ramach Twojej instytucji.
                  </Typography>
                }
                action={{
                  type: "external",
                  //route:
                  //  "https://www.creative-tim.com/learning-lab/react/overview/material-kit/",
                  //label: "Let's start",
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <ImageCard image={imagecard1} />
            </Grid>
            <Grid item xs={12} lg={4}>
              <ImageCard image={imagecard2} />
            </Grid>
            <Grid item xs={12} lg={8}>
              <FilledInfoCard
                variant="gradient"
                color="info"
                icon={<WysiwygIcon style={{ color: "rgb(17, 30, 108)" }} />}
                title={
                  <Typography variant="h5" sx={{ fontFamily: 'Lucida Bright' }}>
                    Nasze Oprogramowanie
                  </Typography>
                }
                subtitle={
                  <Typography variant="subtitle1" sx={{ fontFamily: 'Lato'}}>
                    Funkcje systemu
                  </Typography>
                }
                description={
                  <Typography variant="body1" sx={{ fontWeight: 'lighter', textAlign: 'justify'}}>
                    Oprogramowanie do biobanku jest niezwykle pomocne przy zarządzaniu i organizacji biobanku, który jest miejscem przechowywania próbek biologicznych (takich jak próbki krwi, tkanek, DNA itp.) oraz związanych z nimi informacji.
                  </Typography>
                }                
                action={{
                  type: "external",
                  //route:
                  //  "https://www.creative-tim.com/learning-lab/react/overview/material-kit/",
                  //label: "Wypróbuj demo !",
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

        <Box component="section" sx={{height: '100vh'}}>
          <PricingHomePage produstList={format} />
        </Box>

        <Box component="section" sx={{height: '100vh'}}>
          <Container
            sx={{ mt: 0, maxWidth: "100% !important", padding: "0 !important" }}
          >
            <Grid item xs={12} md={12} lg={12} mt={5}>
              <Typography variant="h2" sx={titleStyles} mb={2}>
                Co oferuje nasza aplikacja ?
              </Typography>
              <Box
                component="span"
                sx={{
                  display: "block",
                  width: "65%",
                  borderBottom: "5px solid #B04C4E",
                  marginTop: "0.5rem",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginBottom: "5rem"
                }}
              ></Box>
            </Grid>
            <SupportLab />
          </Container>
        </Box>

        <Box component="section" sx={{height: '100vh'}}>
          <Container
            sx={{ mt: 0, maxWidth: "100% !important", padding: "0 !important" }}
          >
            <Grid item xs={12} md={12} lg={12} >
              <Typography variant="h2" sx={titleStyles} mb={2}>
                Nasze usługi
              </Typography>
              <Box
                component="span"
                sx={{
                  display: "block",
                  width: "65%",
                  borderBottom: "5px solid #B04C4E",
                  marginTop: "0.5rem",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginBottom: "5rem"
                }}
              ></Box>
            </Grid>
            <Testing />
            
          </Container>
        </Box>
        <div id="#services" />

        <Information />
        <div id="#offer" />

        <AboutUs />
        <div id="#about-us" />

        <Contact />
        
      <Box sx={{ pt: 6, px: 1, mt: 6 }}>
        <Footer content={footerRoutes} />
      </Box>
      <div id="#contact" />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    token_auth: state.token_auth,
  };
};

export default connect(mapStateToProps, null)(HomePage);
