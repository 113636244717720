import { Link } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Typography, Box } from "@mui/material";

function Footer({ content }) {
  const { brand, socials, menus, copyright } = content;

  return (
    <Box component="footer" sx={{ backgroundColor: "#f2f2f5" }}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3} sx={{ ml: "auto", mb: 3 }}>
            <Box>
              <Link to={brand.route}>
                <Box
                  component="img"
                  src={brand.image}
                  alt={brand.name}
                  maxWidth="14rem"
                  mb={2}
                />
              </Link>
            </Box>
          </Grid>

          {menus.map(({ name: title, items }) => (
            <Grid key={title} item xs={6} md={2} sx={{ mb: 3 }}>
              <Typography
                display="block"
                variant="button"
                fontWeight="bold"
                textTransform="capitalize"
                mb={1}
              >
                {title}
              </Typography>
              <Box component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
                {items.map(({ name, route, href }) => (
                  <Box key={name} component="li" p={0} m={0} lineHeight={1.25}>
                    {href ? (
                      <Typography
                        component="a"
                        href={href}
                        target="_blank"
                        rel="noreferrer"
                        variant="button"
                        fontWeight="regular"
                        textTransform="capitalize"
                      >
                        {name}
                      </Typography>
                    ) : (
                      <Typography
                        component={Link}
                        to={route}
                        variant="button"
                        fontWeight="regular"
                        textTransform="capitalize"
                      >
                        {name}
                      </Typography>
                    )}
                  </Box>
                ))}
              </Box>
            </Grid>
          ))}
          <Grid item xs={12} md={3} sx={{ ml: "auto"}}>
            <Box>
              <Typography sx={{fontWeight:100, textAlign: "center", fontSize: "0.8rem"}}>{brand.name}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "center", mb: 3, mt: -5 }}>
            {copyright}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Footer;
