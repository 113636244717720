import React, { useEffect, useState } from "react";
import { Container } from "@mui/material";
import { Box, Grid, Alert, AlertTitle, Button } from "@mui/material";
import axios from "axios";
import api from "../../../config/api/apiRoutes";
import intialColumnsSampleList from "../../../components/Sample/table/intialColumnsSampleList";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { Divider } from "rsuite";
import HomeIcon from "@mui/icons-material/Home";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import ReactCodeInput from "react-code-input";
import ExtensionTableSample from "../../../components/Table/ExtensionTableSample";
import Stack from "@mui/material/Stack";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
function SampleList(props) {
  const [sampleList, setSampleList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isArchives, setisArchives] = useState(false);
  const [isPin, setIsPin] = useState(false);
  const [isPinCodeValid, setIsPinCodeValid] = useState(true);
  const [pinCode, setPinCode] = useState("");
  const [btnIsPressed, setBtnIsPressed] = useState(false);

  const location = useLocation();

  const CORRECT_PIN_CODE = localStorage.getItem("user_pin");

  const checkPinCode = (e) => {
    e.preventDefault();

    const isPinCodeValid = pinCode === CORRECT_PIN_CODE;

    setBtnIsPressed(true);
    setIsPinCodeValid(isPinCodeValid);
    if (isPinCodeValid) {
      setIsPin(false);
      localStorage.setItem("is_pin", false);
    }
    if (!isPinCodeValid) setPinCode("");
  };

  const handlePinChange = (pinCode) => {
    setPinCode(pinCode);
    setBtnIsPressed(false);
  };

  useEffect(() => {
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    const getData = async () => {
      const isArchives = window.location.href.includes("archives");
      const url = isArchives
        ? api().SAMPLE_LIST_ARCHIVES_GET
        : api().SAMPLE_LIST_GET;
      isArchives ? setisArchives(true) : setisArchives(false);
      isArchives ? setIsPin(true) : setIsPin(false);

      try {
        const getListSample = await axios.get(url);
        const getDataListSample = getListSample.data;
        setSampleList(getDataListSample);
        setIsLoading(false);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      }
    };

    getData();
  }, [location.pathname]);

  const isPinLocalStorage = localStorage.getItem("is_pin") == "true";

  return (
    <>
      {isPinLocalStorage && isArchives ? (
        <Container maxWidth={false}>
          {" "}
          <form onSubmit={checkPinCode}>
            <Grid
              container
              spacing={2}
              direction="column"
              alignItems="center"
              justifyContent="center"
              minHeight="80vh"
            >
              <Grid item lg={12} md={12} xs={12}>
                <Typography variant="h3" gutterBottom align="center">
                  Strona wymaga autoryzacji
                </Typography>
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <Typography variant="subtitle1" gutterBottom align="center">
                  Wprowadź PIN do poniższego formularza
                </Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <ReactCodeInput
                  id="pinCode"
                  type="password"
                  isValid={isPinCodeValid}
                  fields={4}
                  onChange={handlePinChange}
                  value={pinCode}
                />
              </Grid>
              <Grid item md={6} xs={6}>
                {!isPinCodeValid ? (
                  <Alert severity="error">Niepoprawny kod PIN!</Alert>
                ) : (
                  <></>
                )}
              </Grid>
              <Grid item>
                <Button type="submit" variant="contained" size="large">
                  Autoryzuj
                </Button>
              </Grid>
            </Grid>
          </form>
        </Container>
      ) : (
        <Container maxWidth={false}>
          <Typography variant="h5" style={{ fontWeight: "400" }}>
            Lista próbek
          </Typography>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" to="/" style={{ color: "#888" }}>
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              Dashboard
            </Link>
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="#111"
              style={{ fontWeight: "600" }}
            >
              Lista próbek
            </Typography>
          </Breadcrumbs>
          <Divider />
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Link to="sample-hierarchy">
              <Button variant="contained" startIcon={<AccountTreeIcon />}>
                Lista hierarchi próbek
              </Button>
            </Link>
          </Stack>
          <Grid container spacing={4} style={{ marginTop: "20px" }}>
            {isArchives && (
              <>
                <Grid item md={12} xs={12}>
                  <Alert severity="warning">
                    <AlertTitle>UWAGA!</AlertTitle>
                    To jest archiwum - modyfikacje danych próbki są ograniczone.
                    Wszelkie zmiany będą przekazywane kierownikom laboratoriu.
                  </Alert>
                </Grid>
              </>
            )}
          </Grid>
          <Box sx={{ mt: 3, mb: 5 }}>
            <ExtensionTableSample
              data={sampleList}
              isLoading={isLoading}
              intialColumns={intialColumnsSampleList}
              headerTable={"Lista wszystkich próbek"}
            />
          </Box>
        </Container>
      )}
    </>
  );
}

export default SampleList;
