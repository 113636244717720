import React, { Component } from "react";
import { connect } from "react-redux"; // new import
import * as actions from "../../../services/actions/auth";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import api from "../../../config/api/apiRoutes";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Button,
  TextField,
  Typography
} from "@mui/material";

const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach(
    // if we have an error string set valid to false
    (val) => val.length > 0 && (valid = false)
  );
  return valid;
};

class LocalizationEditForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      localizationName: this.props.data.name,
      localizationComment: this.props.data.comment,
      localizationRoot: this.props.data.is_root,

      errors: {
        localizationName: "",
        localizationComment: "",
      },
    };
  }

  onChange = (e) => {
    e.persist();
    this.setState({ [e.target.name]: e.target.value });

    const { name, value } = e.target;
    let errors = this.state.errors;
    console.log(value.length);
    switch (name) {
      case "localizationName":
        errors.localizationName = value.length >= 1 ? "" : "Min 1 znak";
        break;
      default:
        break;
    }

    this.setState({ errors, [name]: value });
    console.log(value.length);
  };

  componentDidMount() {}

  addEquipmentForm = (e) => {
    e.preventDefault();
    let id_process = this.props.id_process;
    const formData = {
      name: this.state.localizationName,
      comment: this.state.localizationComment,
      creator: `${localStorage.getItem("user_id")}`,
      is_root: this.state.localizationRoot,
    };

    const validate = () => {
      let validationError = this.state.errors;
      if (!this.state.localizationName) {
        validationError.localizationName = "Wymagane pole";
      }

      this.setState({ validationError, validationError });
      console.log("in validate", validationError);
      return validationError;
    };

    const validationError = validate();
    console.log(this.state.errors);
    if (validateForm(validationError)) {
      console.info("Valid Form");
      this.props.onAuth(formData, id_process);
    } else {
      console.error("Invalid Form");
    }
  };

  render() {
    const { errors } = this.state;

    let errorMessage = null;
    let id_process = this.props.id_process;

    if (this.props.error) {
      errorMessage = <p>{this.props.error.message}</p>;
    }

    if (this.props.adding == true) {
      this.props.props.history.push(`/localization/${id_process}`);
    }
    console.log(this.props);

    return (
      <>
        {this.props.loading ? (
          <LoadingSpinner />
        ) : (
          <Card>
            <Typography
              variant="h6"
              sx={{ position: "relative", padding: "16px 0 20px 16px" }}
            >
              <span
                style={{
                  content: "",
                  backgroundColor: "#111e6c",
                  position: "absolute",
                  left: "0",
                  top: "16px",
                  width: "4px",
                  height: "25px",
                }}
              ></span>
              Formularz edycji lokalizacji
            </Typography>

            <Divider />
            <form onSubmit={this.addEquipmentForm}>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      type="text"
                      label="Nazwa lokalizacji"
                      error={errors.localizationName.length ? true : false}
                      name="localizationName"
                      value={this.state.localizationName}
                      onChange={this.onChange}
                      helperText={
                        errors.localizationName.length > 0
                          ? errors.localizationName
                          : ""
                      }
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      type="text"
                      label="Dodatkowe informacje"
                      error={errors.localizationComment.length ? true : false}
                      name="localizationComment"
                      value={this.state.localizationComment}
                      onChange={this.onChange}
                      helperText={
                        errors.localizationComment.length > 0
                          ? errors.localizationComment
                          : ""
                      }
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  p: 2,
                }}
              >
                <Button type="submit" variant="contained">
                  Edytuj lokalizacje
                </Button>
              </Box>
            </form>
          </Card>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error,
    adding: state.adding,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (formData, id_process) =>
      dispatch(
        actions.requestApi(
          formData,
          api(id_process).LOCALIZATION_EDIT_PUT,
          "put"
        )
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocalizationEditForm);
