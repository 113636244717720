import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import TableActionButton from "../../Button/TableActionButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import { requestTable } from "../../../services/actions/auth";
import api from "../../../config/api/apiRoutes";

const intialColumnsProcedureEquipment = [
  // {
  //   accessorKey: "id",
  //   header: "ID",
  //   size: 40,
  // },
  {
    accessorKey: "name",
    header: "Nazwa",
    size: 120,
  },
  {
    accessorKey: "serial",
    header: "Nr seryjny",
    size: 120,
  },
  {
    accessorFn: (row, id) => (
      <TableActionButton
        data={[
          {
            label: "Szczegóły",
            icon: <VisibilityIcon />,
            linkTo: `/equipment/${row.id}`,
          },
          {
            label: "Usuń",
            icon: <DeleteIcon />,
            onClick: () =>
              requestTable(
                api(row.id_path, row.id).PROCEDURE_EQUIPMENT_DELETE,
                "delete"
              ),
          },
        ]}
      />
    ),
    header: "Akcja",
    enableColumnFilter: false,
    enableSorting: false,
  },
];

export default intialColumnsProcedureEquipment;
