import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  Card,
  Alert,
  AlertTitle,
} from "@mui/material";
import axios from "axios";
import api from "../../../config/api/apiRoutes";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import SampleFormReagentEdit from "../../../components/Sample/form/SampleFormReagentEdit";
import { Link } from "react-router-dom";
import { Divider } from "rsuite";
import HomeIcon from "@mui/icons-material/Home";
import Breadcrumbs from "@mui/material/Breadcrumbs";

function SampleProcedureEdit(props) {
  const [data, setData] = useState([]);
  const [dataReagentList, setDataReagentList] = useState([]);
  const [sampleIdentifier, setSampleIdentifier] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  let { id } = useParams();

  useEffect(() => {
    const getData = async () => {
      axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")}`,
      };
      try {
        const getCurrentReagent = await axios.get(api(id).SAMPLE_REAGENT_GET);
        const getDataCurrentReagent = getCurrentReagent.data;
        const getReagent = await axios.get(
          api(id).SAMPLE_PROCEDURES_REAGENTS_GET
        );
        const getDataReagent = getReagent.data;

        setData(getDataCurrentReagent);
        setDataReagentList(getDataReagent);

        const getSampleIdentifier = await axios.get(api(id).SAMPLE_IDENTIFIER_GET);
        const getDataSampleIdentifier = getSampleIdentifier.data;
        setSampleIdentifier(getDataSampleIdentifier.identifier);

        setIsLoading(false);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      }
    };

    getData();
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Container maxWidth={false}>
      <Typography variant="h5" style={{ fontWeight: "400" }}>
        Edytuj odczynnik próbki {sampleIdentifier}
      </Typography>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" to="/" style={{ color: "#888" }}>
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Dashboard
        </Link>
        <Link underline="hover" to="/sample" style={{ color: "#888" }}>
          Lista próbek
        </Link>
        <Link underline="hover" to={`/sample/${id}`} style={{ color: "#888" }}>
          Szczegóły próbki
        </Link>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="#111"
          style={{ fontWeight: "600" }}
        >
        Edytuj odczynnik próbki
        </Typography>
      </Breadcrumbs>
      <Divider />
      <Grid container spacing={3}>
        <Grid item lg={12} md={12} xs={12}>
          <Card>
            <Alert severity="info">
              <AlertTitle>
                Przypisanie konkretnego odczynnika do próbki
              </AlertTitle>
              W liście odczynników widoczne są możliwości pochodzące z
              przypisaneych procedur do próbki. Do wybóru mamy konkretne
              odczynniki.
              <br />
              Widoczne wartości to kolejno: nazwa odczynnika - data ważności -
              dodatkowe informacje
            </Alert>
          </Card>
        </Grid>
        <Grid item lg={12} md={12} xs={12}>
          <SampleFormReagentEdit
            props={props}
            data={data}
            id_sample={id}
            dataReagent={dataReagentList}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default SampleProcedureEdit;
