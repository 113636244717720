const dateFormat = (row) => {
  const date = new Date(row.date_created);

  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);
  const hours = ("0" + date.getHours()).slice(-2);
  const minutes = ("0" + date.getMinutes()).slice(-2);
  const seconds = ("0" + date.getSeconds()).slice(-2);

  const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

  return formattedDate;
};

const intialColumnsUserEvent = [
  {
    accessorKey: "id",
    header: "ID",
  },
  {
    accessorKey: "info",
    header: "Czynność",
  },
  {
    accessorFn: (row) => dateFormat(row),

    header: "Data",
  },
];

export default intialColumnsUserEvent;
