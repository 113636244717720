import React, { useState, useEffect } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import axios from "axios";
import api from "../../../config/api/apiRoutes";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { Link } from "react-router-dom";
import { Divider } from "rsuite";
import HomeIcon from "@mui/icons-material/Home";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import SampleBarcodeForm from "../../../components/Sample/form/SampleBarcodeForm";
import pageSize from "../../../components/pageSize/pageSize";

function SampleBarcode(props) {
  const [barcodeDetail, setBarcodeDetail] = useState([]);
  const [sampleOptions, setSampleOptions] = useState([]);
  const [barcodePatientDetail, setBarcodePatientDetail] = useState([]);
  const [sampleIdentifier, setSampleIdentifier] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  let { id } = useParams();

  const pageSizeArray = Object.entries(pageSize).map(([key, value]) => ({
    value,
    label: `${key}`,
  }));

  console.log(pageSizeArray)

  useEffect(() => {
    const getData = async () => {
      axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")}`,
      };
      try {
        const sampleOptions = [];

        const getBarcodeDetail = await axios.get(
          api(id).BARCODE_SAMPLE_DETAIL_GET
        );
        const getDataBarcodeDetail = getBarcodeDetail.data;

        const getBarcodePatientDetail = await axios.get(
          api(id).BARCODE_PATIENT_DETAIL_GET
        );
        const getDataBarcodePatientDetail = getBarcodePatientDetail.data;

        // getDataSample.map((item, index) => {
        //     return sampleOptions.push({
        //       value: item.id,
        //       label: item.id,
        //     });
        //   });

        for (const [key, value] of Object.entries(getDataBarcodeDetail)) {
          sampleOptions.push({ label: key, value });
        }

        console.log(sampleOptions);

        console.log(getDataBarcodeDetail);
        console.log(getDataBarcodePatientDetail);

        setBarcodeDetail(getDataBarcodeDetail);
        setBarcodePatientDetail(getDataBarcodePatientDetail);
        setSampleOptions(sampleOptions);

        const getSampleIdentifier = await axios.get(api(id).SAMPLE_IDENTIFIER_GET);
        const getDataSampleIdentifier = getSampleIdentifier.data;
        setSampleIdentifier(getDataSampleIdentifier.identifier);


        setIsLoading(false);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      }
    };

    getData();
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Container maxWidth={false}>
      <Typography variant="h5" style={{ fontWeight: "400" }}>
        Wygeneruj etykietę próbki {sampleIdentifier}
      </Typography>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" to="/" style={{ color: "#888" }}>
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          Dashboard
        </Link>
        <Link underline="hover" to="/sample" style={{ color: "#888" }}>
          Lista próbek
        </Link>
        <Link underline="hover" to={`/sample/${id}`} style={{ color: "#888" }}>
          Szczegóły próbki
        </Link>
        <Typography
          sx={{ display: "flex", alignItems: "center" }}
          color="#111"
          style={{ fontWeight: "600" }}
        >
          Wygeneruj etykietę próbki
        </Typography>
      </Breadcrumbs>
      <Divider />
      <Grid container spacing={3} sx={{ mb: 5 }}>
        <Grid item lg={12} md={12} xs={12}>
          <SampleBarcodeForm
            props={props}
            data={barcodeDetail}
            sampleOptions={sampleOptions}
            sampleId={id}
            pageSize={pageSizeArray}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default SampleBarcode;
