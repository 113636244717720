import React, { useEffect, useState } from "react";
import MaterialReactTable from "material-react-table";
import { Box, Button, TextField, Grid } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ExportToCsv } from "export-to-csv"; //or use your library of choice here
import axios from "axios";
import { Link } from "react-router-dom";
import api from "../../config/api/apiRoutes";
//defining columns outside of the component is fine, is stable

const intialColumns = [
  {
    accessorKey: "sample",
    header: "ID",
  },
  {
    accessorFn: (row) => (
      <Link to={`/sample/${row.sample}`}>
        <Button variant="contained" size="small">
          Zobacz
        </Button>
      </Link>
    ),
    header: "Szczegóły",
    enableColumnFilter: false,
  },
];

const csvOptions = {
  fieldSeparator: ",",
  quoteStrings: '"',
  decimalSeparator: ".",
  showLabels: true,
  useBom: true,
  useKeysAsHeaders: false,
  headers: intialColumns.map((c) => c.header),
};

const csvExporter = new ExportToCsv(csvOptions);

const ReagentSamplesTableList = () => {
  const [columns, setColumns] = useState(intialColumns);
  const [name, setName] = useState("");

  const changeName = (event) => {
    setName(event.target.value);
    console.log(name);
  };

  const handleExportRows = (rows) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  const handleExportData = () => {
    csvExporter.generateCsv(data);
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    let toSliceId = window.location.pathname;
    let id = toSliceId.slice(26);
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    axios
      .get(api(id).REAGENT_DELIVERY_SAMPLES_GET)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {});
  }, []);
  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={data}
        enableRowSelection
        positionToolbarAlertBanner="bottom"
        renderTopToolbarCustomActions={({ table }) => (
          <Box
            sx={{ display: "flex", gap: "1rem", p: "0.5rem", flexWrap: "wrap" }}
          >
            <Button
              color="primary"
              onClick={handleExportData}
              startIcon={<FileDownloadIcon />}
              variant="contained"
            >
              Export All Data
            </Button>
            <Button
              disabled={table.getPrePaginationRowModel().rows.length === 0}
              onClick={() =>
                handleExportRows(table.getPrePaginationRowModel().rows)
              }
              startIcon={<FileDownloadIcon />}
              variant="contained"
            >
              Export All Rows
            </Button>
            <Button
              disabled={table.getRowModel().rows.length === 0}
              onClick={() => handleExportRows(table.getRowModel().rows)}
              startIcon={<FileDownloadIcon />}
              variant="contained"
            >
              Export Page Rows
            </Button>
            <Button
              disabled={
                !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
              }
              onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
              startIcon={<FileDownloadIcon />}
              variant="contained"
            >
              Export Selected Rows
            </Button>
          </Box>
        )}
      />
    </>
  );
};

export default ReagentSamplesTableList;
