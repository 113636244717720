import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import RegisterForm from "../../components/Register/Form/RegisterForm";
import AuthWrapper from "../../components/AuthWrapper";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

function Register(props) {
  const history = useHistory();

  if (props.token_auth) {
    history.push("/dashboard");
  }
  return (
    <AuthWrapper>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="lg">
          <Typography
            sx={{ mb: 3 }}
            variant="h4"
            style={{ textAlign: "center" }}
          ></Typography>
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} xs={12}>
              <RegisterForm props={props} />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </AuthWrapper>
  );
}
const mapStateToProps = (state) => {
  return {
    token_auth: state.token_auth,
  };
};

export default connect(mapStateToProps, null)(Register);
