import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Button, Box, Typography } from "@mui/material";
import ImageSlider from "./ImageSlider";
import GreycolabLogo from "../../assets/images/greycolab_logo_biale.png";
import OfficePng from "../../assets/images/high-angle-view-software-developer-writing-code-while-using-computer-data-systems-office.png";
import CardActions from "@mui/material/CardActions";
import { Link } from "react-router-dom";
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import FeedIcon from '@mui/icons-material/Feed';
import ContactFormPopup from "../form/ContactFormPopup";
import React, { useState } from 'react';

import { useTheme } from '@mui/material/styles';

function AboutUs(props) {
  const titleStyles = {
    fontSize: "1.5rem",
    fontWeight: "700",
    lineHeight: "1.375",
    display: "block",
    opacity: "1",
    textAlign: "center",
    textTransform: "none",
    verticalAlign: "unset",
    textDecoration: "none",
    letterSpacing: "-0.125px",
  };

  const subtitleStyles = {
    fontSize: "1rem",
    fontWeight: "700",
    lineHeight: "1.375",
    display: "block",
    opacity: "1",
    textAlign: "center",
    textTransform: "none",
    verticalAlign: "unset",
    textDecoration: "none",
    letterSpacing: "-0.125px",
    color: useTheme().palette.primary.main,
  };

  const textStyles = {
    fontSize: "1.25rem",
    fontWeight: "400",
    lineHeight: "1.625",
    opacity: "0.8",
    textTransform: "none",
    verticalAlign: "unset",
    textDecoration: "none",
    color: "rgb(255, 255, 255)",
    letterSpacing: "-0.125px",
  };

  const bgImage =
    "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/desktop.jpg";

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
  

  return (
    <Box
      display="flex"
      alignItems="center"
      my={2}
      py={6}

    >
      <Container>
        <Grid container spacing={2} sx={{ display: 'flex', marginLeft: -1, marginRight: -1 }}>

            <Grid item xs={12} lg={12}>
                <Typography
                variant="h1"
                color="white"
                fontWeight="bold"
                sx={titleStyles}
                mb={2}
                >
                Kontakt
                </Typography>
            </Grid>
            <Grid item xs={12} lg={4}>
                <Typography
                    variant="h2"
                    color="white"
                    fontWeight="bold"
                    sx={subtitleStyles}
                    mb={2}
                    >
                    Poprzez email
                </Typography>
                <Typography color="white" opacity={0.8} mb={2} sx={{ textStyles, textAlign: "center"  }}>
                Jeżeli do kontatku potrzebujesz przesłać do nas pliki, dokumenty itp. skorzystaj z tej formy kontaktu. <br /><br /><br />
                </Typography>
                <CardActions >
                    <Button
                      startIcon={<EmailIcon />}
                      color="primary"
                      size="medium"
                      href="mailto:biuro@greycolab.pl?subject=Kontakt%20w%20sprawie%20aplikacji%20biobank"
                      sx={{textAlign: "center", marginInline: "auto"}}
                    >
                        biuro@greycolab.pl
                    </Button>
                </CardActions>
            </Grid>
            <Grid item xs={12} lg={4}>
                <Typography
                    variant="h2"
                    color="white"
                    fontWeight="bold"
                    sx={subtitleStyles}
                    mb={2}
                    >
                    Poprzez formularz kontaktowy
                </Typography>
                <Typography color="white" opacity={0.8} mb={2} sx={{ textStyles, textAlign: "center" }}>
                Wypełnij krótki formularz, który pojawi się po wcisnięciu poniższego przycisku. Odpowiemy na Twoje pytania i uwagi tak szybko, jak to możliwe, aby zapewnić kompleksową obsługę.
                </Typography>
                <CardActions sx={{justifyContent: "center"}}>
                    <ContactFormPopup open={open} handleClose={handleClose} sx={{marginInline: "auto"}}/> {/* Tutaj wywołujemy nasz FormDialog z odpowiednimi props */}
                </CardActions>
                
            </Grid>
            <Grid item xs={12} lg={4} >
                <Typography
                    variant="h2"
                    color="white"
                    fontWeight="bold"
                    sx={subtitleStyles}
                    mb={2}
                    >
                    Telefonicznie
                </Typography>
                <Typography color="white" opacity={0.8} mb={2} sx={{ textStyles, textAlign: "center"  }}>
                W przypadku jeżeli nie chcesz rozpisywać się w mailu, czy formularzu kontatkowym, a chcesz omówić szybko wybrane kwestie skorzystaj z tej metody.
                </Typography>
                <CardActions >
                    <Button
                      startIcon={<PhoneIcon />}
                      color="primary"
                      size="medium"
                      sx={{textAlign: "center", marginInline: "auto"}}
                    >
                        + 48 518 818 373
                    </Button>
                </CardActions>
            </Grid>
          </Grid>
      </Container>
    </Box>
  );
}

export default AboutUs;
