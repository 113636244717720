import React, { Component } from "react";
import { connect } from "react-redux"; // new import
import * as actions from "../../../services/actions/auth";
import api from "../../../config/api/apiRoutes";
import SelectStyles from "../../select/SelectStyles";
import FormHelperText from "@mui/material/FormHelperText";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import Select from "react-select";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Button,
  TextField,
  Container,
  Typography,
} from "@mui/material";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import AddIcon from "@mui/icons-material/Add";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";

const validateForm = (errors) => {
  let valid = true;
  errors.map((error) => {
    if (error.errorName.length > 0 || error.errorValue.length > 0) {
      valid = false;
    }
  });
  return valid;
};

class ResultFormAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getResultAttributes: this.props.data,
      AttributeName: "",
      AttributeValue: "",
      AttributeOptions: [],
      sampleResult: this.props.sampleResult,
      id: "",
      AttributeInputs: [
        {
          AttributeValue: "",
          AttributeName: "",
          errorValue: "",
          errorName: "",
        },
      ],
      errors: {
        AttributeName: "",
        AttributeValue: "",
      },
    };
  }

  componentDidMount() {
    const { getResultAttributes, sampleResult } = this.state;

    getResultAttributes.map((item, index) => {
      return this.state.AttributeOptions.push({
        value: item.id,
        label: item.attribute,
      });
    });

    const convertedArray = sampleResult.map((item) => ({
      AttributeValue: item.value,
      AttributeName: item.attribute_name,
      errorValue: "",
      errorName: "",
    }));

    if (convertedArray[0]) {
      this.setState({ AttributeInputs: convertedArray });
    } else {
      this.setState({
        AttributeInputs: [
          {
            AttributeValue: "",
            AttributeName: "",
            errorValue: "",
            errorName: "",
          },
        ],
      });
    }

    console.log(this.state);
  }

  handleSelectValue = (input) => (e, index) => {
    console.log(e);
    this.setState({ AttributeValue: e });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    console.log(this.state);

    const { name, value } = e.target;
    let errors = this.state.errors;
    switch (name) {
      case "AttributeName":
        errors.AttributeName = value.length >= 1 ? "" : "Min 1 znak";
        break;

      default:
        break;
    }

    this.setState({ errors, [name]: value });
    console.log(this.state);
  };

  addFields = () => {
    let object = {
      AttributeName: "",
      AttributeValue: "",
      errorValue: "",
      errorName: "",
    };

    this.setState({
      AttributeInputs: [...this.state.AttributeInputs, object],
    });
  };

  handleFormChangeSelect = (event, index) => {
    let data = [...this.state.AttributeInputs];
    console.log(event);

    data[index]["AttributeName"] = event.label;

    console.log(this.state);

    this.setState(data);
  };

  handleFormChange = (event, index) => {
    let data = [...this.state.AttributeInputs];
    console.log(this.state);

    data[index][event.target.name] = event.target.value;
    this.setState(data);
  };

  removeFields = (index) => {
    let data = this.state.AttributeInputs;
    data.splice(index, 1);
    this.setState(data);
  };

  addResult = (e) => {
    e.preventDefault();
    let id_sample = this.props.id_sample;
    let id_attribute = [];

    console.log(id_attribute);

    const formData = this.state.AttributeInputs.map(
      ({ AttributeValue, AttributeName }) => ({
        attribute_name: AttributeValue,
        value: AttributeName,
      })
    );

    console.log(formData);

    const validate1 = () => {
      let validationError = this.state.AttributeInputs;

      validationError.forEach((item) => {
        if (!item.AttributeValue) {
          console.log(`Error: Reagent is empty for ${item.reagent_name}`);
          item.errorValue = "Uzupełnij puste pole";
        } else {
          item.errorValue = "";
        }
        if (!item.AttributeName) {
          console.log(`Error: Reagent value is empty for ${item.reagent_name}`);
          item.errorName = "Uzupełnij puste pole";
        } else {
          item.errorName = "";
        }
      });

      console.log("in validate", validationError);
      return validationError;
    };

    const validate = () => {
      const { AttributeInputs } = this.state;

      const errors = AttributeInputs.map((input) => {
        let errorName = "";
        let errorValue = "";
        if (!input.AttributeName) {
          input.errorName = "Uzupełnij puste pole";
          errorName = "Uzupełnij puste pole";
        }
        if (!input.AttributeValue) {
          input.errorValue = "Uzupełnij puste pole";
          errorValue = "Uzupełnij puste pole";
        }
        return { errorName, errorValue };
      });

      console.log("in validate", errors);
      return errors;
    };

    const validationError = validate();
    console.log(validationError);

    if (validateForm(validationError)) {
      console.info("Valid Form");
      this.props.onAuth(formData, id_sample);
    } else {
      console.error("Invalid Form");
    }
  };

  render() {
    const { errors } = this.state;

    if (this.props.adding == true) {
      this.props.props.history.push(`/sample/${this.props.id_sample}`);
    }
    return (
      <>
        {this.props.loading ? (
          <LoadingSpinner />
        ) : (
          <Card>
            <Typography
              variant="h6"
              sx={{ position: "relative", padding: "16px 0 20px 16px" }}
            >
              <span
                style={{
                  content: "",
                  backgroundColor: "#111e6c",
                  position: "absolute",
                  left: "0",
                  top: "16px",
                  width: "4px",
                  height: "25px",
                }}
              ></span>
              Formularz dodania wyniku próbki
            </Typography>

            <Divider />
            <form onSubmit={this.addResult}>
              <CardContent>
                <Grid container spacing={3}>
                  {this.state.AttributeInputs.map((form, index) => {
                    return (
                      <>
                        <Grid item md={6} xs={12}>
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            options={this.state.AttributeOptions}
                            placeholder="Wybierz odczynnik"
                            styles={SelectStyles}
                            onChange={(event) =>
                              this.handleFormChangeSelect(event, index)
                            }
                            value={{ label: form.AttributeName }}
                            name="color"
                          />
                          {form.errorName.length > 0 && (
                            <FormHelperText error={true}>
                              {form.errorName}
                            </FormHelperText>
                          )}
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <TextField
                            error={Boolean(form.errorName)}
                            fullWidth
                            type="text"
                            label="Wartość wyniku"
                            name="AttributeValue"
                            onChange={(event) =>
                              this.handleFormChange(event, index)
                            }
                            value={form.AttributeValue}
                            variant="outlined"
                          />

                          <FormHelperText error={Boolean(form.errorName)}>
                            {form.errorName}
                          </FormHelperText>
                        </Grid>

                        <Grid
                          item
                          md={12}
                          xs={12}
                          align="center"
                          sx={{ mb: 3 }}
                        >
                          <Button
                            disabled={
                              this.state.AttributeInputs[index].add == true ||
                              index === 0
                                ? true
                                : false
                            }
                            onClick={() => this.removeFields(index)}
                            startIcon={
                              <RemoveCircleRoundedIcon
                                style={{ color: "red" }}
                              />
                            }
                          >
                            Usuń
                          </Button>
                        </Grid>
                      </>
                    );
                  })}
                  <Grid item md={12} xs={12} align="center">
                    <Button
                      onClick={() => this.addFields()}
                      startIcon={
                        <AddCircleRoundedIcon style={{ color: "green" }} />
                      }
                    >
                      Dodaj nowy atrybut
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  p: 2,
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  endIcon={<AddIcon />}
                >
                  Dodaj wynik
                </Button>
              </Box>
            </form>
          </Card>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error,
    adding: state.adding,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (formData, id) =>
      dispatch(
        actions.requestApi(formData, api(id).SAMPLE_RESULTS_ADD_POST, "post")
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResultFormAdd);
