import React, { useEffect, useState, useRef } from "react";
import MaterialReactTable from "material-react-table";
import { Box, Button, TextField, Grid } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ExportToCsv } from "export-to-csv"; //or use your library of choice here
import axios from "axios";
import { Link } from "react-router-dom";
import api from "../../../config/api/apiRoutes";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { exportSelectedRow } from "../../exportData/exportSelectedRow";
//defining columns outside of the component is fine, is stable
import * as XLSX from "xlsx";
import { ExportToXlsx } from "../../xlsx/ExportToXlsx";


const intialColumns = [
  {
    accessorKey: "id",
    header: "ID",
    size: 40,
  },
  {
    accessorKey: "identifier",
    header: "Identyfikator",
    size: 120,
  },
  {
    accessorKey: "pesel",
    header: "pesel",
    size: 120,
  },
  {
    accessorKey: "comment",
    header: "Komentarz",
    size: 120,
  },
  {
    accessorFn: (row) => (
      <Link to={`patient/${row.id}`}>
        <Button variant="contained" size="small">Zobacz</Button>
      </Link>
    ),
    header: "Szczegóły",
    enableColumnFilter: false,
    size: 120,
  },
];

const csvOptions = {
  fieldSeparator: ",",
  quoteStrings: '"',
  decimalSeparator: ".",
  showLabels: true,
  useBom: true,
  useKeysAsHeaders: false,
  headers: intialColumns.map((c) => c.header),
};

const csvExporter = new ExportToCsv(csvOptions);

const PatientListTable = () => {
  const [columns, setColumns] = useState(intialColumns);
  const [name, setName] = useState("");
  const [open, setOpen] = React.useState(false);
  const [row, setRow] = useState("");
  const [column, setColumn] = useState("");

  const handleClickOpen = (selectedRow, visibleLeafColumns) => {
    setOpen(true);
    setRow(selectedRow);
    setColumn(visibleLeafColumns);
  };


  const exportCsv = () => {
    exportSelectedRow(row, column);
    setOpen(false);
  };

  const exportXlsx = () => {
    ExportToXlsx(row, column);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const addColumn = () => {
    setColumns((prevCols) => [
      ...prevCols,
      {
        accessorFn: (row) =>
          row.extra_values.map((item) => (
            <div>
              {item.attribute_attribute.attribute.toLowerCase() ==
              name.toLowerCase() ? (
                <span style={{ marginRight: "10px" }}>
                  Krótki tekst: {item.short_text}
                </span>
              ) : (
                ""
              )}
            </div>
          )),

        header: name,
        size: 120,
      },
    ]);
  };

  const changeName = (event) => {
    setName(event.target.value);
    console.log(name);
  };


  const [data, setData] = useState([]);

  useEffect(() => {
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    axios
      .get(api().PATIENT_LIST_GET)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {});
  }, []);
  console.log(intialColumns);

  const tableInstanceRef = useRef(null);


  return (
    <>
      <Grid container spacing={2} sx={{ m: 1 }}>
        <Grid
          item
          xs={4}
          md={"auto"}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <TextField
            label="Nazwa"
            id="outlined-helperText"
            onChange={changeName}
          />
        </Grid>
        <Grid
          item
          xs={3}
          md={"auto"}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            disabled={!name}
            variant="contained"
            onClick={addColumn}
            size="medium"
          >
            Dodaj kolumnę{" "}
          </Button>
        </Grid>
      </Grid>
      <MaterialReactTable
        columns={columns}
        data={data}
        enableRowSelection
        positionToolbarAlertBanner="bottom"
        tableInstanceRef={tableInstanceRef} //get a reference to the underlying table instance (optional)
        renderTopToolbarCustomActions={({ table, tableInstanceRef }) => (
          <Box
            sx={{ display: "flex", gap: "1rem", p: "0.5rem", flexWrap: "wrap" }}
          >
            <Button
              color="primary"
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)

              onClick={() =>
                handleClickOpen(
                  table.getPrePaginationRowModel(),
                  table.getAllColumns()
                )
              }
              startIcon={<FileDownloadIcon />}
              variant="contained"
            >
              Export All Data
            </Button>
            <Button
              disabled={table.getPrePaginationRowModel().rows.length === 0}
              //export all rows, including from the next page, (still respects filtering and sorting)
              onClick={() =>
                handleClickOpen(
                  table.getPrePaginationRowModel(),
                  table.getVisibleLeafColumns()
                )
              }
              startIcon={<FileDownloadIcon />}
              variant="contained"
            >
              Export All Rows
            </Button>
            <Button
              disabled={table.getRowModel().rows.length === 0}
              //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
              onClick={() =>
                handleClickOpen(
                  table.getRowModel(),
                  table.getVisibleLeafColumns()
                )
              }
              startIcon={<FileDownloadIcon />}
              variant="contained"
            >
              Export Page Rows
            </Button>
            <Button
              disabled={
                !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
              }
              //only export selected rows
              onClick={() =>
                handleClickOpen(
                  table.getSelectedRowModel(),
                  table.getVisibleLeafColumns()
                )
              }
              startIcon={<FileDownloadIcon />}
              variant="contained"
            >
              Export Selected Rows
            </Button>
          </Box>
        )}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Wybierz format"}</DialogTitle>

        <DialogActions>
          <Button onClick={exportCsv}>.csv</Button>
          <Button onClick={exportXlsx} autoFocus>
            .xlsx
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PatientListTable;
