const intialColumnsReagentDetails = [
  {
    accessorKey: "name",
    header: "Nazwa",
    size: 40,
  },
  {
    accessorKey: "short_name",
    header: "Nazwa skrócona",
    size: 40,
  },
  {
    accessorKey: "cas",
    header: "Nr CAS",
    size: 40,
  },
  {
    accessorKey: "lazy_mass",
    header: "Masa molowa",
    size: 120,
  },
  {
    accessorKey: "additional_info",
    header: "Dodatkowa informacja",
    size: 120,
  },
];

export default intialColumnsReagentDetails;
