import React, { Component } from "react";
import { connect } from "react-redux"; // new import
import * as actions from "../../../services/actions/auth";
import Select from "react-select";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import {
  Box,
  Grid,
  Divider,
  Button,
  Typography,
  CardContent,
  Card,
  TextField,
} from "@mui/material";
import SelectStyles from "../../select/SelectStyles";
import FormControlLabel from "@mui/material/FormControlLabel";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import BarcodeGenerator from "../../Barcode/BarcodeGenerator";
import InputAdornment from "@mui/material/InputAdornment";
import Switch from "@mui/material/Switch";
import { Select as MuiSelect } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import BarcodePatientGenerator from "../../Barcode/BarcodePatientGenerator";

class PatientBarcodeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      barcodeWidth: "50",
      barcodeHeight: "",
      barcodeData: {
        format: "CODE128",
        text: props.data.identifier,
        textPosition: "bottom",
      },
      barcodeFormat: "ean13",
      barcodeText: "",
      barcodeOptions: props.sampleOptions,
      barcodeOptionsSelect: [],
      barcodeAlign: true,
      barcodeGenerate: true,
      barcodeFontSize: "15",
      barcodeSampleId: props.sampleId,
      barcodePageSize: "[124.72, 175.75]",
      pageSize: props.pageSize,
      labelOrientation: "landscape",
      labelDisplay: true,
      attributes: [
        {
          attributeLabel: "",
          attributeValue: "",
        },
      ],
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    console.log(this.state);
  };

  handleCheckbox = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
    console.log(this.state);
  };

  handleBarcodeOptions = (e) => {
    this.setState({ barcodeOptionsSelect: e });
  };

  handleFormChange = (event, index) => {
    let data = [...this.state.attributes];
    console.log(event);

    data[index][event.target.name] = event.target.value;
    this.setState(data);
  };

  addFields = () => {
    let object = {
      attributeLabel: "",
      attributeValue: "",
    };

    this.setState({
      attributes: [...this.state.attributes, object],
    });
  };

  handleChangeSelect = (event) => {
    console.log(event.target);
    this.setState({ barcodePageSize: event.target.value });
    console.log(this.state);
  };

  handleChangeSelectOrientation = (event) => {
    console.log(event.target);
    this.setState({ labelOrientation: event.target.value });
    console.log(this.state);
  };

  render() {
    console.log(this.props);
    let errorMessage = null;
    if (this.props.error) {
      errorMessage = <p>{this.props.error.message}</p>;
    }

    if (this.props.adding == true) {
      this.props.history.push(`/sample/${this.getId()}`);
    }

    const data = {
      format: this.state.barcodeFormat,
      text: "425638130487",
      textPosition: "bottom",
    };

    return (
      <>
        {this.props.loading ? (
          <LoadingSpinner />
        ) : (
          <>
            <Card>
              <Typography
                variant="h6"
                sx={{ position: "relative", padding: "16px 0 20px 16px" }}
              >
                <span
                  style={{
                    content: "",
                    backgroundColor: "#111e6c",
                    position: "absolute",
                    left: "0",
                    top: "16px",
                    width: "4px",
                    height: "25px",
                  }}
                ></span>
                Formularz generowania etykiety próbki
              </Typography>
              <Divider />
              <form onSubmit={this.addEquipmentForm}>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        type="number"
                        label="Szerokość kodu kreskowego w %"
                        value={this.state.barcodeWidth}
                        onChange={this.handleChange}
                        name="barcodeWidth"
                        variant="outlined"
                        disabled={
                          this.state.barcodeGenerate == false ? true : false
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        type="number"
                        label="Wysokość kodu kreskowego w px"
                        value={this.state.barcodeHeight}
                        onChange={this.handleChange}
                        name="barcodeHeight"
                        variant="outlined"
                        disabled={
                          this.state.barcodeGenerate == false ? true : false
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">px</InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Wielkość etykiety
                        </InputLabel>

                        <MuiSelect
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={this.state.barcodePageSize}
                          label="Model"
                          MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                          onChange={this.handleChangeSelect}
                        >
                          <MenuItem value={"[124.72, 175.75]"}>
                            B9 44 x 62 mm
                          </MenuItem>

                          {this.state.pageSize.map((option) => (
                            <MenuItem key={option.label} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </MuiSelect>
                      </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        type="number"
                        label="Wielkość czcionki"
                        value={this.state.barcodeFontSize}
                        onChange={this.handleChange}
                        name="barcodeFontSize"
                        variant="outlined"
                        helperText={
                          this.state.barcodeFontSize > 48
                            ? "Wartość nie może być wieksza od 48."
                            : ""
                        }
                        error={this.state.barcodeFontSize > 48 ? true : false}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Orientacja etykiety
                        </InputLabel>
                        <MuiSelect
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={this.state.labelOrientation}
                          label="Orientacja etykiety"
                          onChange={this.handleChangeSelectOrientation}
                        >
                          <MenuItem value={"landscape"}>Pozioma</MenuItem>
                          <MenuItem value={"portrait"}>Pionowa</MenuItem>
                        </MuiSelect>
                      </FormControl>
                    </Grid>


                    <Grid item md={6} xs={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            name="barcodeAlign"
                            checked={this.state.barcodeAlign}
                            onChange={this.handleCheckbox}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="Wyśrodkować?"
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            name="labelDisplay"
                            checked={this.state.labelDisplay}
                            onChange={this.handleCheckbox}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="Podgląd etykiety"
                      />
                    </Grid>
                  </Grid>
                </CardContent>

                <BarcodePatientGenerator
                  data={this.state.barcodeData}
                  width={this.state.barcodeWidth}
                  height={this.state.barcodeHeight}
                  center={this.state.barcodeAlign}
                  barcodeOptions={this.state.barcodeOptionsSelect}
                  fontSize={this.state.barcodeFontSize}
                  attributes={this.state.attributes}
                  displayBarcode={this.state.barcodeGenerate}
                  barcodeSampleId={this.state.barcodeSampleId}
                  pageSize={this.state.barcodePageSize}
                  labelDisplay={this.state.labelDisplay}
                  labelOrientation={this.state.labelOrientation}
                />
              </form>
            </Card>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error,
    adding: state.adding,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (formData, id) => dispatch(actions.addBarcode(formData, id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientBarcodeForm);
