import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import api from "../../../config/api/apiRoutes";
import { requestTable } from "../../../services/actions/auth";
import TableActionButton from "../../Button/TableActionButton";

const intialColumnsDashboardEvents = [
  {
    accessorKey: "id",
    header: "ID wydarzenia",
    size: 40,
  },
  {
    accessorKey: "name",
    header: "Nazwa",
    size: 40,
  },
  {
    accessorKey: "additional_info",
    header: "Opis",
    size: 200,
  },
  {
    accessorFn: (row) => (
      <TableActionButton
        data={[
          {
            label: "Usuń",
            icon: <DeleteIcon />,
            onClick: () =>
              requestTable(api(row.id).CALENDAR_DELETE_EVENT, "delete"),
          },
        ]}
      />
    ),
    header: "Akcja",
    enableColumnFilter: false,
    enableSorting: false,
  },
];

export default intialColumnsDashboardEvents;
