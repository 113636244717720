import React, { useState, useEffect } from "react";
import axios from "axios";
import api from "../../../config/api/apiRoutes";
import {
  Box,
  Card,
  Container,
  Typography,
  Button,
  Alert,
  Grid,
  AlertTitle,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import CoreTable from "../../../components/Table/CoreTable";
import intialColumnsSampleDetails from "../../../components/Sample/table/intialColumnsSampleDetails";
import TreeTable from "../../../components/Table/TreeTable";
import intialColumnsSampleTree from "../../../components/Sample/table/intialColumnsSampleTree";
import intialColumnsSampleResult from "../../../components/Sample/table/intialColumnsSampleResult";
import BasicTable from "../../../components/Table/BasicTable";
import intialColumnsSampleAttribute from "../../../components/Sample/table/intialColumnsSampleAttribute";
import intialColumnsSampleChildren from "../../../components/Sample/table/intialColumnsSampleChildren";
import intialColumnsSampleProcedures from "../../../components/Sample/table/intialColumnsSampleProcedures";
import CoreTableFilter from "../../../components/Table/CoreTableFilter";
import intialColumnsSampleReagent from "../../../components/Sample/table/intialColumnsSampleReagent";
import HomeIcon from "@mui/icons-material/Home";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ReactCodeInput from "react-code-input";
import Stack from "@mui/material/Stack";
import TableActionButton from "../../../components/Button/TableActionButton";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import TimelineIcon from "@mui/icons-material/Timeline";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import ScienceIcon from "@mui/icons-material/Science";
import DateRangeIcon from "@mui/icons-material/DateRange";
import intialColumnsPatientDetailsLink from "../../../components/Patient/table/intialColumnsPatientDetailsLink";
import SchemaIcon from "@mui/icons-material/Schema";
import TreeTableExportTreeSample from "../../../components/Table/TreeTableExportTreeSample";
import intialColumnsSampleDate from "../../../components/Sample/table/intialColumnsSampleDate";
import AccountTreeIcon from "@mui/icons-material/AccountTree";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function SampleDetails(props) {
  const [sampleDetails, setSampleDetails] = useState([]);
  const [samplePatientId, setSamplePatientId] = useState([]);
  const [patientDetails, setPatientDetails] = useState([]);
  const [sampleChildren, setSampleChildren] = useState([]);
  const [sampleAmount, setSampleAmount] = useState([]);
  const [sampleFlag, setSampleFlag] = useState("");
  const [sampleLocalization, setSampleLocalization] = useState([]);
  const [sampleResult, setSampleResult] = useState([]);
  const [sampleAttribute, setSampleAttribute] = useState([]);
  const [sampleProcedures, setSampleProcedures] = useState([]);
  const [sampleReagent, setSampleReagent] = useState([]);
  const [sampleDate, setSampleDate] = useState([]);
  const [showWarning, setShowWarning] = useState(false);
  const [showError, setShowError] = useState(false);
  const [value, setValue] = React.useState(0);
  const [isPin, setIsPin] = useState(false);
  const [isPinCodeValid, setIsPinCodeValid] = useState(true);
  const [pinCode, setPinCode] = useState("");
  const [btnIsPressed, setBtnIsPressed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  let { id } = useParams();

  const alertBox = async () => {
    await new Promise((resolve) => {
      setTimeout(resolve, 200);
    });
    if (sampleFlag != 100) {
      if (sampleFlag > 0) {
        setShowWarning(true);
      } else {
        setShowError(true);
      }
    } else {
      setShowError(false);
      setShowWarning(false);
    }
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const CORRECT_PIN_CODE = localStorage.getItem("user_pin");

  const checkPinCode = (e) => {
    e.preventDefault();

    const isPinCodeValid = pinCode === CORRECT_PIN_CODE;

    setBtnIsPressed(true);
    setIsPinCodeValid(isPinCodeValid);
    if (isPinCodeValid) {
      setIsPin(false);
      localStorage.setItem("is_pin", false);
    }
    if (!isPinCodeValid) setPinCode("");
  };

  const handlePinChange = (pinCode) => {
    setPinCode(pinCode);
    setBtnIsPressed(false);
  };

  useEffect(() => {
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    const getData = async () => {
      try {
        const getSampleDetails = await axios.get(api(id).SAMPLE_GET);
        alertBox();
        const getDataSampleDetails = getSampleDetails.data;
        const getDataSampleAttribute = getDataSampleDetails.extra_values;
        const getDataSamplePatient = getDataSampleDetails.patient;

        if (getDataSampleDetails.status === "ZARCHIWIZOWANA") {
          setIsPin(true);
        }

        setSampleDetails(getDataSampleDetails);
        setSampleAttribute(getDataSampleAttribute);

        setSamplePatientId(getDataSamplePatient);
        const getPatientDetails = await axios.get(
          api(getDataSamplePatient).PATIENT_DETAIL_GET
        );
        const getDataPatientDetails = getPatientDetails.data;
        setPatientDetails(getDataPatientDetails);
        const getSampleChildren = await axios.get(api(id).SAMPLE_CHILDREN_GET);
        const getDataSampleChildren = getSampleChildren.data;
        setSampleChildren(getDataSampleChildren);
        const getSampleAmount = await axios.get(api(id).SAMPLE_AMOUNT_GET);
        const getDataSample = getSampleAmount.data;
        const getDataSampleAmount = getDataSample.amount;
        const getDataSampleFlag = getDataSample.flag;
        setSampleAmount(getDataSampleAmount);
        setSampleFlag(getDataSampleFlag);
        const getSampleTree = await axios.get(
          api(id).SAMPLE_LOCALIZATION_TREE_GET
        );
        const getDataSampleTree = getSampleTree.data;
        setSampleLocalization(getDataSampleTree);
        const getSampleResult = await axios.get(api(id).SAMPLE_RESULTS_GET);
        const getDataSampleResult = getSampleResult.data;
        setSampleResult(getDataSampleResult);
        const getSampleProcedures = await axios.get(
          api(id).SAMPLE_PROCEDURES_GET
        );
        const getDataSampleProcedures = getSampleProcedures.data;
        setSampleProcedures(getDataSampleProcedures);
        const getSampleReagent = await axios.get(api(id).SAMPLE_REAGENT_GET);
        const getDataSampleReagent = getSampleReagent.data;
        setSampleReagent(getDataSampleReagent);
        const getSampleDate = await axios.get(api(id).SAMPLE_DATE_GET);
        const getDataSampleDate = getSampleDate.data;
        setSampleDate([getDataSampleDate]);

        setIsLoading(false);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      }
    };

    getData();
  }, [sampleFlag]);

  const isPinLocalStorage = localStorage.getItem("is_pin") == "true";

  return (
    <>
      {isPinLocalStorage && isPin ? (
        <Container maxWidth={false}>
          <form onSubmit={checkPinCode}>
            <Grid
              container
              spacing={2}
              direction="column"
              alignItems="center"
              justifyContent="center"
              minHeight="80vh"
            >
              <Grid item lg={12} md={12} xs={12}>
                <Typography variant="h3" gutterBottom align="center">
                  Strona wymaga autoryzacji
                </Typography>
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <Typography variant="subtitle1" gutterBottom align="center">
                  Wprowadź PIN do poniższego formularza
                </Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <ReactCodeInput
                  id="pinCode"
                  type="password"
                  isValid={isPinCodeValid}
                  fields={4}
                  onChange={handlePinChange}
                  value={pinCode}
                />
              </Grid>
              <Grid item md={6} xs={6}>
                {!isPinCodeValid ? (
                  <Alert severity="error">Niepoprawny kod PIN!</Alert>
                ) : (
                  <></>
                )}
              </Grid>
              <Grid item>
                <Button type="submit" variant="contained" size="large">
                  Autoryzuj
                </Button>
              </Grid>
            </Grid>
          </form>
        </Container>
      ) : (
        <Container maxWidth={false}>
          <Typography variant="h5" style={{ fontWeight: "400" }}>
            Szczegóły próbki {sampleDetails.identifier}
          </Typography>

          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" to="/" style={{ color: "#888" }}>
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              Dashboard
            </Link>
            <Link underline="hover" to="/sample" style={{ color: "#888" }}>
              Lista próbek
            </Link>
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="#111"
              style={{ fontWeight: "600" }}
            >
              Szczegóły próbki
            </Typography>
          </Breadcrumbs>

          <Box sx={{ width: "100%", mt: 2 }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Główne informacje" {...a11yProps(0)} />
                <Tab label="Lokalizacja" {...a11yProps(1)} />
                <Tab label="Wyniki" {...a11yProps(2)} />
                <Tab label="Próbki podrzędne" {...a11yProps(3)} />
                <Tab label="Wykorzystywane procedury" {...a11yProps(4)} />
                <Tab label="Wykorzystywane odczynniki" {...a11yProps(5)} />
              </Tabs>
              <Grid container spacing={4} sx={{ mt: 1 }}>
                {(showWarning && (
                  <Grid item md={12} xs={12}>
                    <Alert severity="warning">
                      <AlertTitle>UWAGA!</AlertTitle>
                      Uwaga pozostało mniej niż: <strong>{sampleFlag}</strong> %
                      zawartości próbki!
                    </Alert>
                  </Grid>
                )) ||
                  (showError && (
                    <Grid item md={12} xs={12}>
                      <Alert severity="error">
                        <AlertTitle>UWAGA!</AlertTitle>
                        Dana próbka nie powinna zawierać materiału do badań !
                      </Alert>
                    </Grid>
                  ))}
              </Grid>
            </Box>
            <TabPanel value={value} index={0}>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-start"
                spacing={2}
              >
                <TableActionButton
                  data={[
                    {
                      label: "Timeline",
                      icon: <TimelineIcon />,
                      linkTo: `/sample/${id}/timeline`,
                    },
                    {
                      label: "Zmień status próbki",
                      icon: <ScienceIcon />,
                      linkTo: `/sample/${id}/status`,
                    },
                    {
                      label: "Wygeneruj etykiete",
                      icon: <QrCode2Icon />,
                      linkTo: `/sample/${id}/barcode`,
                    },
                    {
                      label: "Edytuj próbkę",
                      icon: <EditIcon />,
                      linkTo: `/sample/${id}/edit`,
                    },
                    {
                      label: "Dodaj datę próbki",
                      icon: <DateRangeIcon />,
                      linkTo: `/sample/${id}/date/`,
                    },
                    {
                      label: "Flow próbki",
                      icon: <SchemaIcon />,
                      linkTo: `/sample/${id}/flow/`,
                    },
                    {
                      label: "Edytuj drzewo próbki",
                      icon: <AccountTreeIcon />,
                      linkTo: `/sample/${id}/tree-edit/`,
                    },
                  ]}
                />
              </Stack>
              <Grid container spacing={6} sx={{ mt: 1 }}>
                <Grid item xs={12} lg={12}>
                  <CoreTable
                    data={[sampleDetails]}
                    isLoading={isLoading}
                    intialColumns={intialColumnsSampleDetails}
                    headerTable={"Główne informacje próbki"}
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <CoreTable
                    data={sampleDate}
                    isLoading={isLoading}
                    intialColumns={intialColumnsSampleDate}
                    headerTable={"Data przyjęcia/pobrania próbki"}
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <BasicTable
                    data={sampleAttribute}
                    isLoading={isLoading}
                    intialColumns={intialColumnsSampleAttribute}
                    headerTable={"Dodatkowe informacje"}
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <CoreTable
                    data={[patientDetails]}
                    isLoading={isLoading}
                    intialColumns={intialColumnsPatientDetailsLink}
                    headerTable={"Dane pacjenta"}
                  />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-start"
                spacing={2}
              >
                <TableActionButton
                  data={[
                    {
                      label: "Edytuj lokalizację próbki",
                      icon: <EditIcon />,
                      linkTo: `/sample/${id}/localization/edit`,
                    },
                  ]}
                />
              </Stack>
              <Grid container spacing={6} sx={{ mt: 1 }}>
                <Grid item xs={12} lg={12}>
                  <TreeTable
                    data={sampleLocalization}
                    isLoading={isLoading}
                    intialColumns={intialColumnsSampleTree}
                    headerTable={"Lokalizacja próbki"}
                  />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-start"
                spacing={2}
              >
                <TableActionButton
                  data={[
                    {
                      label: "Dodaj wynik próbki",
                      icon: <AddIcon />,
                      linkTo: `/sample/${id}/result`,
                    },
                  ]}
                />
              </Stack>
              <Grid container spacing={6} sx={{ mt: 1 }}>
                <Grid item xs={12} lg={12}>
                  <BasicTable
                    data={sampleResult}
                    isLoading={isLoading}
                    intialColumns={intialColumnsSampleResult}
                    headerTable={"Wyniki próbki"}
                  />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-start"
                spacing={2}
              >
                <TableActionButton
                  data={[
                    {
                      label: "Dodaj nową podpróbkę",
                      icon: <AddIcon />,
                      linkTo: `/sample-add/?sample_id=${id}&patient_id=${samplePatientId}&amount=${sampleAmount}&unit=${sampleDetails.unit}`,
                    },
                  ]}
                />
              </Stack>
              <Grid container spacing={6} sx={{ mt: 1 }}>
                <Grid item xs={12} lg={12}>
                  <TreeTableExportTreeSample
                    data={sampleChildren}
                    isLoading={isLoading}
                    intialColumns={intialColumnsSampleChildren}
                    headerTable={"Lista próbek podrzędnych"}
                  />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={4}>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-start"
                spacing={2}
              >
                <TableActionButton
                  data={[
                    {
                      label: "Dodaj procedurę do próbki",
                      icon: <AddIcon />,
                      linkTo: `/sample/${id}/procedures/edit/`,
                    },
                  ]}
                />
              </Stack>
              <Grid container spacing={6} sx={{ mt: 1 }}>
                <Grid item xs={12} lg={12}>
                  <CoreTableFilter
                    data={sampleProcedures}
                    isLoading={isLoading}
                    intialColumns={intialColumnsSampleProcedures}
                    headerTable={"Lista wykorzystywanych procedur"}
                  />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={5}>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-start"
                spacing={2}
              >
                <TableActionButton
                  data={[
                    {
                      label: "Dodaj odczynnik do próbki",
                      icon: <AddIcon />,
                      linkTo: `/sample/procedures/reagents/edit/${id}`,
                    },
                  ]}
                />
              </Stack>
              <Grid container spacing={6} sx={{ mt: 1 }}>
                <Grid item xs={12} lg={12}>
                  <CoreTableFilter
                    data={sampleReagent}
                    isLoading={isLoading}
                    intialColumns={intialColumnsSampleReagent}
                    headerTable={"Lista wykorzystywanych odczynników"}
                  />
                </Grid>
              </Grid>
            </TabPanel>
          </Box>
        </Container>
      )}
    </>
  );
}

export default SampleDetails;
