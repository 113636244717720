import Icon from "@mui/material/Icon";
import GroupIcon from "@mui/icons-material/Group";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CallIcon from "@mui/icons-material/Call";
import LoginIcon from '@mui/icons-material/Login';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import DesignServicesIcon from '@mui/icons-material/DesignServices';

const NavbarRoutes = [
  {
    name: "Co oferujemy ?",
    icon: <LocalOfferIcon />,
    route: "/#offer",
  },
  {
    name: "Nasze usługi",
    icon: <DesignServicesIcon />,
    route: "/#services",
  },
  {
    name: "Sklep",
    icon: <ShoppingCartIcon />,
    route: "/shop",
  },
  {
    name: "Kontakt",
    icon: <CallIcon />,
    route: "/#contact",
  },
  {
    name: "O nas",
    icon: <GroupIcon />,
    route: "/#about-us",
  },
  {
    name: "Logowanie",
    icon: <LoginIcon />,
    route: "/logowanie",
  },
];

export default NavbarRoutes;
