import React, { Component } from "react";
import { Chrono } from "react-chrono";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ImageIcon from "@mui/icons-material/Image";
import WorkIcon from "@mui/icons-material/Work";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";

export class TimeLine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      TimelineData: this.props.data,
    };
  }

  formatDate = (dateStr) => {
    const dateObj = new Date(dateStr);
    const formattedDate = `${dateObj.getFullYear()}-${(dateObj.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${dateObj
      .getDate()
      .toString()
      .padStart(2, "0")} ${dateObj
      .getHours()
      .toString()
      .padStart(2, "0")}:${dateObj
      .getMinutes()
      .toString()
      .padStart(2, "0")}:${dateObj.getSeconds().toString().padStart(2, "0")}`;
    return formattedDate;
  };

  render() {
    const items = this.state.TimelineData.map((item) => ({
      title: this.formatDate(item.date_created),
      cardTitle: item.info,
      cardDetailedText: `Lorem ipsum`,
      timelineContent: (
        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        >
          {item.changes.map((change) => (
            <ListItem key={change.model_attribute}>
              <ListItemText
                primary={change.model_attribute}
                secondary={change.model_value}
              />
            </ListItem>
          ))}
        </List>
      ),
    }));

    return (
      <>
        <Chrono
          items={items}
          mode="VERTICAL_ALTERNATING"
          focusActiveItemOnLoad
          activeItemIndex={7}
          disableNavOnKey={true}
          useReadMore
          theme={{
            primary: "#111e6c",
            secondary: "white",
          }}
        ></Chrono>
      </>
    );
  }
}

export default TimeLine;
