import { Link } from "react-router-dom";
import { Button } from "@mui/material";

const intialColumnsProcedureDetails = [
  // {
  //   accessorKey: "id",
  //   header: "ID",
  // },
  {
    accessorKey: "name",
    header: "Nazwa",
  },
  {
    accessorFn: (row) => row.reagents.map((item) => <div>{item.name}</div>),
    header: "Odczynniki",
  },
  {
    accessorFn: (row) => row.equipment.map((item) => <div>{item.name}</div>),
    header: "Wyposażenie",
  },
  {
    accessorFn: (row) => row.process.map((item) => <div>{item.name}</div>),
    header: "Procesy",
  },

  {
    accessorKey: "additional_info",
    header: "Dodatkowe informacje",
  },
];

export default intialColumnsProcedureDetails;
