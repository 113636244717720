import React, { Component } from "react";
import { connect } from "react-redux"; // new import
import * as actions from "../../services/actions/auth";
import axios from "axios";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import api from "../../config/api/apiRoutes";
import AddIcon from "@mui/icons-material/Add";
import Switch from "@mui/material/Switch";

const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach(
    // if we have an error string set valid to false
    (val) => val.length > 0 && (valid = false)
  );
  return valid;
};

class EquipmentFormAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      getDataEquipment: [],
      EquipmentName: "",
      EquipmentSerial: "",
      EquipmentInfo: "",
      EquipmentCreator: "",
      equipmentBig: false,
      errors: {
        EquipmentName: "",
        EquipmentSerial: "",
      },
    };
  }

  onChange = (e) => {
    e.persist();
    this.setState({ [e.target.name]: e.target.value });

    const { name, value } = e.target;
    let errors = this.state.errors;
    console.log(value.length);
    switch (name) {
      case "EquipmentName":
        errors.EquipmentName = value.length >= 1 ? "" : "Min 1 znak";
        break;
      case "EquipmentSerial":
        errors.EquipmentSerial = value.length >= 1 ? "" : "Min 1 znak";
        break;

      default:
        break;
    }

    this.setState({ errors, [name]: value });
  };

  handleSwitch = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
    console.log(this.state);
  };

  componentDidMount() {}

  addEquipmentForm = (e) => {
    e.preventDefault();

    const formData = {
      name: this.state.EquipmentName,
      serial: this.state.EquipmentSerial,
      is_big: this.state.equipmentBig,
      additional_info: this.state.EquipmentInfo,
      creator: `${localStorage.getItem("user_id")}`,
    };

    const validate = () => {
      let validationError = this.state.errors;
      if (!this.state.EquipmentName) {
        validationError.EquipmentName = "Wymagane pole";
      }
      if (!this.state.EquipmentSerial) {
        validationError.EquipmentSerial = "Wymagane pole";
      }


      this.setState({ validationError, validationError });
      console.log("in validate", validationError);
      return validationError;
    };

    const validationError = validate();
    console.log(this.state.errors);
    if (validateForm(validationError)) {
      console.info("Valid Form");
      this.props.onAuth(formData);
    } else {
      console.error("Invalid Form");
    }
  };

  render() {
    const { errors } = this.state;

    let errorMessage = null;
    if (this.props.error) {
      errorMessage = <p>{this.props.error.message}</p>;
    }

    if (this.props.adding == true) {
      this.props.props.history.push("/equipment");
    }
    console.log(this.props);

    return (
      <>
        {this.props.loading ? (
          <LoadingSpinner />
        ) : (
          <Card>
            <Typography
              variant="h6"
              sx={{ position: "relative", padding: "16px 0 20px 16px" }}
            >
              <span
                style={{
                  content: "",
                  backgroundColor: "#111e6c",
                  position: "absolute",
                  left: "0",
                  top: "16px",
                  width: "4px",
                  height: "25px",
                }}
              ></span>
              Formularz dodania wyposażenia
            </Typography>

            <Divider />
            <form onSubmit={this.addEquipmentForm}>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      type="text"
                      label="Nazwa sprzętu"
                      error={errors.EquipmentName.length ? true : false}
                      name="EquipmentName"
                      value={this.state.EquipmentName}
                      onChange={this.onChange}
                      helperText={
                        errors.EquipmentName.length > 0
                          ? errors.EquipmentName
                          : ""
                      }
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      type="text"
                      label="Numer seryjny"
                      error={errors.EquipmentSerial.length ? true : false}
                      name="EquipmentSerial"
                      value={this.state.EquipmentSerial}
                      onChange={this.onChange}
                      helperText={
                        errors.EquipmentSerial.length > 0
                          ? errors.EquipmentSerial
                          : ""
                      }
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          name="equipmentBig"
                          checked={this.state.equipmentBig}
                          onChange={this.handleSwitch}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Duży sprzęt?"
                    />

                  </Grid>

                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      type="text"
                      label="Dodatkowe info"
                      name="EquipmentInfo"
                      value={this.state.EquipmentInfo}
                      onChange={this.onChange}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  p: 2,
                }}
              >
                <Button type="submit" variant="contained" endIcon={<AddIcon />}>
                  Dodaj sprzęt
                </Button>
              </Box>
            </form>
          </Card>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error,
    adding: state.adding,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (formData) =>
      dispatch(actions.requestApi(formData, api().EQUIPMENT_ADD_POST, "post")),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentFormAdd);
