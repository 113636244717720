import React, { Component } from "react";
import { connect } from "react-redux"; // new import
import * as actions from "../../../services/actions/auth";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import api from "../../../config/api/apiRoutes";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Button,
  TextField,
  Typography,
  FormHelperText,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import AddIcon from "@mui/icons-material/Add";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import dayjs from "dayjs";

const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach(
    // if we have an error string set valid to false
    (val) => val.length > 0 && (valid = false)
  );
  return valid;
};

class SampleDateForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sampleDateReceipt: this.props.data.date_receipt,
      sampleDateExamination: this.props.data.date_examination,
      sampleComment: this.props.data.comment,
      eventCalendarReceipt: this.props.data.date_receipt
        ? this.props.data.date_receipt
        : null,
      eventCalendarExamination: this.props.data.date_examination
        ? this.props.data.date_examination
        : null,
      attributes: [
        {
          eventCalendarExtra: null,
          eventCalendarExtraFormat: "",
          extraDateName: "",
        },
      ],
      errors: {
        sampleDate: "",
        sampleComment: "",
        sampleDateReceipt: "",
        sampleDateExamination: "",
      },
    };
  }

  componentDidMount() {
    console.log(this.props.extraDate);
    this.setState({ attributes: this.props.extraDate });
  }

  onChange = (e) => {
    e.persist();
    this.setState({ [e.target.name]: e.target.value });

    const { name, value } = e.target;
    let errors = this.state.errors;
    console.log(value.length);
    switch (name) {
      case "sampleDateReceipt":
        errors.sampleDateReceipt = value.length >= 1 ? "" : "Min 1 znak";
        break;
      default:
        break;
    }

    this.setState({ errors, [name]: value });
    console.log(this.state);
  };

  calendarChangeReceipt = (newValue) => {
    this.setState({ eventCalendarReceipt: newValue });
    const date = newValue;

    const assignReminderDate = async () => {
      const utcDate = new Date(
        date.$d.getTime() - date.$d.getTimezoneOffset() * 60000
      );
      const isoString = utcDate.toISOString();
      let errors = this.state.errors;

      if (isoString) {
        errors.sampleDateReceipt = "";
      } else {
        errors.sampleDateReceipt = "Wybierz date";
      }

      this.setState({ sampleDateReceipt: isoString });
    };
    if (date) {
      assignReminderDate();
    } else {
      this.setState({ sampleDateReceipt: null });
    }
  };

  calendarChangeExamination = (newValue) => {
    this.setState({ eventCalendarExamination: newValue });
    const date = newValue;

    const assignReminderDate = async () => {
      const utcDate = new Date(
        date.$d.getTime() - date.$d.getTimezoneOffset() * 60000
      );
      const isoString = utcDate.toISOString();

      let errors = this.state.errors;

      if (isoString) {
        errors.sampleDateExamination = "";
      } else {
        errors.sampleDateExamination = "Wybierz date";
      }

      this.setState({ sampleDateExamination: isoString });
    };

    if (date) {
      assignReminderDate();
    } else {
      this.setState({ sampleDateExamination: null });
    }
  };

  addFields = () => {
    let object = {
      extraDateName: "",
      eventCalendarExtra: null,
      eventCalendarExtraFormat: "",
    };

    this.setState({
      attributes: [...this.state.attributes, object],
    });
  };

  removeFields = (index) => {
    let data = this.state.attributes;
    data.splice(index, 1);
    this.setState(data);
  };

  removeAndAddFields = (index) => {
    this.removeFields(index);
    this.addFields(index);
  };

  handleFormChangeSelect = (event, index) => {
    let data = [...this.state.attributes];
    data[index]["eventCalendarExtra"] = event;

    this.setState(data);

    const date = event;

    const assignReminderDate = async () => {
      const utcDate = new Date(
        date.$d.getTime() - date.$d.getTimezoneOffset() * 60000
      );
      const isoString = utcDate.toISOString();

      data[index]["eventCalendarExtraFormat"] = isoString;
    };

    if (date) {
      assignReminderDate();
    } else {
      data[index]["eventCalendarExtraFormat"] = null;
    }
  };

  handleFormChange = (event, index) => {
    let data = [...this.state.attributes];

    data[index][event.target.name] = event.target.value;
    this.setState(data);
    console.log(this.state);
  };

  addEquipmentForm = (e) => {
    e.preventDefault();
    let id_sample = this.props.id_sample;

    const transformArray = this.state.attributes.map((item) => ({
      extra_date: item.eventCalendarExtraFormat,
      extra_date_name: item.extraDateName,
    }));

    console.log(transformArray);

    const formData = {
      sample: id_sample,
      date_receipt: this.state.sampleDateReceipt,
      date_examination: this.state.sampleDateExamination,
      extra_dates: transformArray,
      creator: `${localStorage.getItem("user_id")}`,
    };
    console.log(formData);

    const validate = () => {
      let validationError = this.state.errors;
      console.log(!this.state.sampleDateReceipt);
      //if (this.state.sampleDateReceipt || this.state.sampleDateExamination) {
      //  validationError.sampleDateExamination = "";
      //}
      //else {
      //  validationError.sampleDateExamination = "Wprowadź datę";
      //
      //}

      // if (!this.state.sampleDateExamination) {
      //   validationError.sampleDateExamination = "Wymagane pole";
      // }

      this.setState({ validationError, validationError });
      console.log("in validate", validationError);
      return validationError;
    };

    const validationError = validate();
    console.log(this.state.errors);
    if (validateForm(validationError)) {
      console.info("Valid Form");
      console.log(formData);
      this.props.onAuth(formData, id_sample);
    } else {
      console.error("Invalid Form");
    }
  };

  render() {
    const { errors } = this.state;

    let errorMessage = null;
    let id_sample = this.props.id_sample;

    if (this.props.error) {
      errorMessage = <p>{this.props.error.message}</p>;
    }

    if (this.props.adding == true) {
      this.props.props.history.push(`/sample/${id_sample}`);
    }
    console.log(this.props);

    return (
      <>
        {this.props.loading ? (
          <LoadingSpinner />
        ) : (
          <Card>
            <Typography
              variant="h6"
              sx={{ position: "relative", padding: "16px 0 20px 16px" }}
            >
              <span
                style={{
                  content: "",
                  backgroundColor: "#111e6c",
                  position: "absolute",
                  left: "0",
                  top: "16px",
                  width: "4px",
                  height: "25px",
                }}
              ></span>
              Formularz dodania daty próbki
            </Typography>

            <Divider />
            <form onSubmit={this.addEquipmentForm}>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      style={{ width: "100%" }}
                    >
                      <DateTimePicker
                        label="Wybierz date przyjęcia"
                        value={dayjs(
                          this.state.eventCalendarReceipt
                            ? this.state.eventCalendarReceipt
                            : null
                        )}
                        name="eventCalendarReceipt"
                        inputFormat="YYYY/MM/DD HH:mm"
                        onChange={this.calendarChangeReceipt}
                        renderInput={(params) => (
                          <TextField {...params} sx={{ width: "100%" }} />
                        )}
                        fullWidth
                      />
                    </LocalizationProvider>{" "}
                    {errors.sampleDateReceipt.length > 0 && (
                      <FormHelperText error={true}>
                        {errors.sampleDateReceipt}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        fullWidth
                        label="Wybierz date pobrania"
                        value={dayjs(
                          this.state.eventCalendarExamination
                            ? this.state.eventCalendarExamination
                            : null
                        )}
                        inputFormat="YYYY/MM/DD HH:mm"
                        onChange={this.calendarChangeExamination}
                        renderInput={(params) => (
                          <TextField {...params} sx={{ width: "100%" }} />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    {errors.sampleDateExamination.length > 0 && (
                      <FormHelperText error={true}>
                        {errors.sampleDateExamination}
                      </FormHelperText>
                    )}
                  </Grid>

                  {this.state.attributes.map((form, index) => {
                    return (
                      <>
                        <Grid item md={6} xs={12}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                              fullWidth
                              label="Wybierz date"
                              value={dayjs(
                                form.eventCalendarExtra
                                  ? form.eventCalendarExtra
                                  : null
                              )}
                              inputFormat="YYYY/MM/DD HH:mm"
                              onChange={(event) =>
                                this.handleFormChangeSelect(event, index)
                              }
                              renderInput={(params) => (
                                <TextField {...params} sx={{ width: "100%" }} />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            label={"Nazwa wydarzenia"}
                            onChange={(event) =>
                              this.handleFormChange(event, index)
                            }
                            value={form.extraDateName}
                            type="text"
                            name="extraDateName"
                            variant="outlined"
                          />
                        </Grid>

                        <Grid item md={12} xs={12} align="center">
                          <Button
                            disabled={
                              this.state.attributes[index].add == true ||
                              index === 0
                                ? true
                                : false
                            }
                            onClick={() => this.removeFields(index)}
                            startIcon={
                              <RemoveCircleRoundedIcon
                                style={{ color: "red" }}
                              />
                            }
                          >
                            Usuń
                          </Button>
                          <Button
                            onClick={() => this.removeAndAddFields(index)}
                            startIcon={<RemoveCircleRoundedIcon />}
                          >
                            Wyczyść
                          </Button>
                        </Grid>
                      </>
                    );
                  })}
                  <Grid item md={12} xs={12} align="center" sx={{ mb: 3 }}>
                    <Button
                      onClick={() => this.addFields()}
                      startIcon={
                        <AddCircleRoundedIcon style={{ color: "green" }} />
                      }
                    >
                      Dodaj nowy atrybut
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  p: 2,
                }}
              >
                <Button type="submit" variant="contained" endIcon={<AddIcon />}>
                  Dodaj datę próbki
                </Button>
              </Box>
            </form>
          </Card>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error,
    adding: state.adding,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (formData, id_sample) =>
      dispatch(
        actions.requestApi(
          formData,
          api(id_sample).SAMPLE_DATE_ADD_POST,
          "post"
        )
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SampleDateForm);
