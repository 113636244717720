import React, { Component } from "react";
import { connect } from "react-redux"; // new import
import * as actions from "../../services/actions/auth";
import axios from "axios";
import Select from "react-select";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import * as mod from "./DynamicFields";
import api from "../../config/api/apiRoutes";
class BarcodeAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      BarcodeWidth: "",
      BarcodeHeight: "",
      BarcodeList: [],
      GenerateBarcode: false,
      sample_keys_array: [],
      patient_keys_array: [],
      test: [],
      agregate_list: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  getId = () => {
    let toSliceId = window.location.pathname;
    let id = toSliceId.slice(16);
    return id;
  };

  getObject = (url) => {
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    return axios.get(url);
  };

  getObjectKeys = (url, callback) => {
    this.getObject(url).then((res) => {
      let data = res.data;
      let keys = Object.keys(data);
      callback(keys);
    });
  };

  getSampleKeys = () => {
    let id = this.getId();
    let sample_keys_array = [];
    this.getObject(api(id).BARCODE_SAMPLE_DETAIL_GET).then((res) => {
      let data = res.data;
      Object.entries(data).forEach(([key, value]) => {
        sample_keys_array.push({ label: key, value: value });
      });
      this.setState({ sample_keys_array });
    });
  };

  getPatientKeys = () => {
    let id = this.getId();
    let patient_keys_array = [];
    this.getObject(api(id).BARCODE_PATIENT_DETAIL_GET).then((res) => {
      let data = res.data;
      Object.entries(data).forEach(([key, value]) => {
        patient_keys_array.push({ label: key, value: value });
      });
      this.setState({ patient_keys_array });
    });
  };

  async componentDidMount() {
    await Promise.all([this.getSampleKeys(), this.getPatientKeys()]);
    await new Promise((resolve) => {
      setTimeout(resolve, 1000);
    });
    let agregate_list = [
      ...this.state.sample_keys_array,
      ...this.state.patient_keys_array,
    ];
    this.setState({ agregate_list });
  }

  handleCallback = (childData) => {
    this.setState({ Loading: childData });
  };

  onChange = (e) => {
    if (e.target.name === "selectedOptions") {
      this.setState({
        [e.target.name]: [...this.state.selectedOptions, e.target.value],
      });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  handleChange(selectedOptions) {
    this.setState((prevState) => ({
      ...prevState,
      selectedOptions,
    }));
  }

  handleSubmit() {
    console.log(mod.ExtraValues.list);
    let postOptions = [];
    Object.entries(this.state.selectedOptions).forEach(([key, value]) => {
      postOptions.push(value["label"] + " : " + value["value"]);
    });
    if (mod.ExtraValues.list.length > 0) {
      Object.entries(mod.ExtraValues.list).forEach(([key, value]) => {
        postOptions.push(value["label"] + " : " + value["value"]);
      });
    }

    this.addBarcodeForm(postOptions);
    // you can use this.state.selectedOptions for further processing
  }

  addBarcodeForm = (data) => {
    let wordArray = this.state.BarcodeList;

    const formData = {
      width: this.state.BarcodeWidth,
      height: this.state.BarcodeHeight,
      data: data,
      generate_barcode: this.state.GenerateBarcode,
    };
    let toSliceId = window.location.pathname;
    let id = toSliceId.slice(16);
    this.props.onAuth(formData, id);
  };

  RadioButtonList = (keys) => {
    return (
      <div>
        <Select
          isMulti
          closeMenuOnSelect={false}
          name="colors"
          options={keys}
          className="basic-multi-select"
          classNamePrefix="select"
          onChange={(selectedOptions) => this.handleChange(selectedOptions)}
        />
      </div>
    );
  };

  render() {
    let errorMessage = null;
    if (this.props.error) {
      errorMessage = <p>{this.props.error.message}</p>;
    }

    if (this.props.adding == true) {
      this.props.history.push(`/sample/${this.getId()}`);
    }

    return (
      <>
        {this.props.loading ? (
          <LoadingSpinner />
        ) : (
          <section class=" gradient-custom">
            <div class="container py-5 h-100">
              <h1>Wygeneruj etykietę</h1>
              {errorMessage}
              <br />
              <br />
              <form onSubmit={this.handleSubmit}>
                <input
                  type="number"
                  id="typeEmailX"
                  class="form-control form-control-lg"
                  name="BarcodeWidth"
                  value={this.state.BarcodeWidth}
                  onChange={this.onChange}
                />
                <label class="form-label" for="typeEmailX">
                  Szerokość
                </label>
                <br />
                <br />

                <input
                  type="number"
                  id="typeEmailX"
                  class="form-control form-control-lg"
                  name="BarcodeHeight"
                  value={this.state.BarcodeHeight}
                  onChange={this.onChange}
                />
                <label class="form-label" for="typeEmailX">
                  Wysokość
                </label>
                <br />
                <br />

                <div>
                  {this.RadioButtonList(this.state.agregate_list)}
                  <label class="form-label" for="typeEmailX">
                    Dostępne dane
                  </label>
                </div>
                <br />

                <div>
                  <mod.MyForm />
                </div>
                <br />

                <input
                  type="checkbox"
                  id="typeEmailX"
                  name="GenerateBarcode"
                  value={this.state.GenerateBarcode}
                  onChange={this.onChange}
                />

                <label class="form-label" for="typeEmailX">
                  Wygenerować kod kreskowy ?
                </label>

                <br />
                <br />
                <button
                  class="btn btn-primary"
                  type="submit"
                  //parentCallback={this.handleCallback}
                >
                  Wygeneruj etykietę
                </button>
              </form>
            </div>
          </section>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error,
    adding: state.adding,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (formData, id) => dispatch(actions.addBarcode(formData, id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BarcodeAdd);
