import React, { Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { NavLink, Bars, NavMenu, NavBtn, NavBtnLink } from "./NavbarElements";
import * as actions from "../../services/actions/auth";
import { Navbar, Nav } from "rsuite";
import HomeIcon from "@rsuite/icons/legacy/Home";
import CogIcon from "@rsuite/icons/legacy/Cog";



const guestLinks2 = (
  <Fragment>
    <Nav.Item>
      <Link to="/logowanie" style={{ color: "#575757", textDecoration: "none" }}>
        Logowanie
      </Link>
    </Nav.Item>
    <Nav.Item>
      <Link
        to="rejestracja"
        style={{ color: "#575757", textDecoration: "none" }}
      >
        Rejestracja
      </Link>
    </Nav.Item>
  </Fragment>
);

class NavbarC extends React.Component {
  render() {
    const authLinks2 = (
      <Fragment>
        <Nav.Menu title="Sprzęt">
          <Nav.Item>
            <Link
              to="/equipment"
              style={{ color: "#575757", textDecoration: "none" }}
            >
              Lista sprzętu
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link
              to="/new-equipment"
              style={{ color: "#575757", textDecoration: "none" }}
            >
              Dodaj sprzęt
            </Link>
          </Nav.Item>
        </Nav.Menu>

        <Nav.Menu title="Pacjent">
          <Nav.Item>
            <Link
              to="/patient"
              style={{ color: "#575757", textDecoration: "none" }}
            >
              Lista pacjencji
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link
              to="/patient-add"
              style={{ color: "#575757", textDecoration: "none" }}
            >
              Dodaj pacjenta
            </Link>
          </Nav.Item>
        </Nav.Menu>

        <Nav.Menu title="Dostawa">
          <Nav.Item>
            <Link
              to="/delivery"
              style={{ color: "#575757", textDecoration: "none" }}
            >
              Lista dostaw
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link
              to="/delivery-add"
              style={{ color: "#575757", textDecoration: "none" }}
            >
              Dodaj dostawe
            </Link>
          </Nav.Item>
        </Nav.Menu>

        <Nav.Menu title="Proces">
          <Nav.Item>
            <Link
              to="/process"
              style={{ color: "#575757", textDecoration: "none" }}
            >
              Lista procesów
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link
              to="/process-add"
              style={{ color: "#575757", textDecoration: "none" }}
            >
              Dodaj proces
            </Link>
          </Nav.Item>
        </Nav.Menu>

        <Nav.Menu title="Próbka">
          <Nav.Item>
            <Link
              to="/sample?now=true"
              style={{ color: "#575757", textDecoration: "none" }}
            >
              Lista próbek
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link
              to="/sample-add"
              style={{ color: "#575757", textDecoration: "none" }}
            >
              Dodaj próbke
            </Link>
          </Nav.Item>
        </Nav.Menu>

        <Nav.Menu title="Procedury">
          <Nav.Item>
            <Link
              to="/procedure"
              style={{ color: "#575757", textDecoration: "none" }}
            >
              Lista procedur
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link
              to="/procedure-add"
              style={{ color: "#575757", textDecoration: "none" }}
            >
              Dodaj procedure
            </Link>
          </Nav.Item>
        </Nav.Menu>
        
        <Nav.Menu title="Lokalizacja">
          <Nav.Item>
            <Link
              to="/localization"
              style={{ color: "#575757", textDecoration: "none" }}
            >
               Lista lokalizacji próbek
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link
              to="/localization-add"
              style={{ color: "#575757", textDecoration: "none" }}
            >
              Dodaj lokalizacje
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link
              to="/localization-sample-add"
              style={{ color: "#575757", textDecoration: "none" }}
            >
              Dodaj lokalizacje próbki
            </Link>
          </Nav.Item>
        </Nav.Menu>

        <Nav.Item>
          <Link
            to="/reagent-add"
            style={{ color: "#575757", textDecoration: "none" }}
          >
            Dodaj odczynnik
          </Link>
        </Nav.Item>
        <Nav.Item>
          <a className="nav-link" onClick={this.props.logoutApi}>
            Logout
          </a>
        </Nav.Item>
        
      </Fragment>
    );


    return (
      <>
        <Navbar>
          <Nav>
            {this.props.isAuthenticated ? authLinks2 : guestLinks2}
          </Nav>
        </Navbar>
        {this.props.children}
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    logoutApi: () => dispatch(actions.logoutApi()),
  };
};

export default withRouter(connect(null, mapDispatchToProps)(NavbarC));
