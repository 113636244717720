import BootstrapDialogTitle from "../../Popup/BootstrapDialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Select from "react-select";
import SelectStyles from "../../select/SelectStyles";

export default function CalendarEditEventPopup(props) {
  console.log(props);

  return (
    <BootstrapDialog
      onClose={props.handleClosePopover}
      aria-labelledby="customized-dialog-title"
      open={props.showPopover}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={props.handleClosePopover}
      >
        Informacje o wydarzeniu
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <Grid container spacing={3} sx={{ mt: 1 }}>
          <Grid item md={12} xs={12}>
            <TextField
              fullWidth
              type="text"
              label="Nazwa wydarzenia"
              name="popoverTitle"
              value={props.popoverTitle}
              onChange={props.onChange}
              variant="outlined"
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <TextField
              fullWidth
              type="text"
              label="Opis wydarzenia"
              name="popoverDescription"
              value={props.popoverDescription}
              onChange={props.onChange}
              variant="outlined"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                label="Godzina wydarzenia"
                value={props.popoverDateStart}
                onChange={(newValue) => {
                  props.calendarPopupTimeChange(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} sx={{ width: "100%" }} />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item md={6} xs={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                fullWidth
                label="Date przypomnienia"
                value={props.popoverDateReminder}
                onChange={props.calendarPopupChange}
                renderInput={(params) => (
                  <TextField {...params} sx={{ width: "100%" }} />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item md={12} xs={12}>
            <Select
              isMulti
              isDisabled={true}
              className="basic-multi-select"
              options={props.popoverUsers}
              placeholder="Przypisz pracownika"
              menuPortalTarget={document.body}
              styles={SelectStyles}
              value={props.popoverUsers}
              noOptionsMessage={() => "name not found"}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ justifyContent: "center" }}>
        <Button
          onClick={props.editEvent}
          variant="contained"
          color="success"
          endIcon={<EditIcon />}
        >
          Edytuj
        </Button>
        <Button
          onClick={props.handleEventDelete}
          variant="contained"
          color="error"
          endIcon={<DeleteIcon />}
        >
          Usuń
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
