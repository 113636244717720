import React, { Component } from "react";
import { connect } from "react-redux"; // new import
import * as actions from "../../../services/actions/auth";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Button,
  TextField,
  Typography,
  CardHeader,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import api from "../../../config/api/apiRoutes";
class AccountDetailForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: localStorage.getItem("user_email"),
      pin: localStorage.getItem("user_pin"),
      showPassword: false,
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    console.log(this.state);
  };

  addEquipmentForm = (e) => {
    e.preventDefault();
    const formData = {
      email: this.state.email,
      pin: this.state.pin,
    };

    this.props.onAuth(formData);
  };

  handleClickShowPassword = (e) => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  render() {
    if (this.props.adding == true) {
      this.props.props.history.push("/");
    }
    console.log(this.props);

    return (
      <>
        {this.props.loading ? (
          <LoadingSpinner />
        ) : (
          <Card>
            <Typography
              variant="h6"
              sx={{ position: "relative", padding: "16px 0 20px 16px" }}
            >
              <span
                style={{
                  content: "",
                  backgroundColor: "#111e6c",
                  position: "absolute",
                  left: "0",
                  top: '16px',
                  width: "4px",
                  height: "25px",
                }}
              ></span>
              Edytuj profil
            </Typography>

            <Divider />
            <form onSubmit={this.addEquipmentForm}>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      type="email"
                      name="email"
                      label="Email"
                      variant="outlined"
                      value={this.state.email}
                      onChange={this.onChange}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <FormControl sx={{ width: "100%" }} variant="outlined">
                      <InputLabel htmlFor="outlined-adornment-password">
                        Pin
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-password"
                        type={this.state.showPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={this.handleClickShowPassword}
                              onMouseDown={this.handleMouseDownPassword}
                              edge="end"
                            >
                              {this.state.showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Pin"
                        name="pin"
                        value={this.state.pin}
                        onChange={this.onChange}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  p: 3,
                }}
              >
                <Button type="submit" variant="contained">
                  Zmień dane
                </Button>
              </Box>
            </form>
          </Card>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error,
    adding: state.adding,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (formData) =>
      dispatch(actions.requestApi(formData, api().ACCOUNT_EDIT_PUT, "put")),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetailForm);
