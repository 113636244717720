import React, { Component } from "react";
import { connect } from "react-redux";
import SelectStyles from "../../select/SelectStyles";
import * as actions from "../../../services/actions/auth";
import axios from "axios";
import Select from "react-select";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import api from "../../../config/api/apiRoutes";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FormHelperText from "@mui/material/FormHelperText";
import queryString from "query-string";
import dayjs from "dayjs";

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Button,
  TextField,
  Typography
} from "@mui/material";

const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach(
    // if we have an error string set valid to false
    (val) => val.length > 0 && (valid = false)
  );
  return valid;
};

const queryParams = queryString.parse(window.location.search);

class DeliveryFormEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      DeliveryAmount: this.props.data.amount,
      DeliveryAmountOld: this.props.data.amount,
      DeliverUnit: this.props.data.unit,
      DeliverInfo: this.props.data.additional_info,
      DeliveryCalendar: dayjs(this.props.data.date_expiration),
      DeliveryCalendarFormatted: "",
      DeliveryReagentName: this.props.data.reagent_name,
      DeliveryReagentId: this.props.data.reagent,

      errors: {
        FormatReagent: "",
        DeliveryAmount: "",
      },
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });

    const { name, value } = e.target;
    let errors = this.state.errors;
    console.log(value.length);
    switch (name) {
      case "DeliveryAmount":
        errors.DeliveryAmount =
          value < this.state.DeliveryAmountOld
            ? "Wartość nie może być mniejsza"
            : "";
        break;

      default:
        break;
    }

    this.setState({ errors, [name]: value });
  };

  onChangeSelect = (e) => {
    console.log(e);
    this.setState({ FormatReagent: e });
    const name = "FormatReagent";
    const value = e;
    let errors = this.state.errors;
    console.log(!e);
    switch (name) {
      case "FormatReagent":
        errors.FormatReagent = !e == false ? "" : "Wybierz odczynnik";

      default:
        break;
    }

    this.setState({ errors, [name]: value });

    console.log(this.state);
  };

  calendarChange = (newValue, value) => {
    console.log(newValue.$d);
    const date = new Date(newValue.$d);
    const formattedDate = date.toISOString().slice(0, 10);

    this.setState({ DeliveryCalendar: newValue });
    this.setState({ DeliveryCalendarFormatted: formattedDate });
  };

  addEquipmentForm = (e) => {
    e.preventDefault();

    const formData = {
      reagent: this.state.DeliveryReagentId,
      amount: this.state.DeliveryAmount,
      ...(this.state.DeliverUnit && { unit: this.state.DeliverUnit }),
      ...(this.state.DeliverInfo && {
        additional_info: this.state.DeliverInfo,
      }),
      ...(this.state.DeliveryCalendarFormatted && {
        date_expiration: this.state.DeliveryCalendarFormatted,
      }),
      creator: `${localStorage.getItem("user_id")}`,
    };

    console.log(formData);

    const validate = () => {
      let validationError = this.state.errors;

      // if (this.state.reagent.length == 0) {
      //   validationError.reagent = "Wymagane pole";
      // } else {
      //   validationError.reagent = "";
      // }

      if (!this.state.DeliveryAmount) {
        validationError.DeliveryAmount = "Wymagane pole";
      }

      this.setState({ validationError, validationError });
      console.log("in validate", validationError);
      return validationError;
    };

    const validationError = validate();
    console.log(this.state.errors);
    if (validateForm(validationError)) {
      console.info("Valid Form");
      this.props.onAuth(formData, this.props.id_delivery);
    } else {
      console.error("Invalid Form");
    }
  };

  render() {
    const { errors } = this.state;

    let errorMessage = null;
    if (this.props.error) {
      errorMessage = <p>{this.props.error.message}</p>;
    }

    if (this.props.adding == true) {
      this.props.props.history.push("/delivery");
    }

    return (
      <>
        {this.props.loading ? (
          <LoadingSpinner />
        ) : (
          <Card>
            <Typography
              variant="h6"
              sx={{ position: "relative", padding: "16px 0 20px 16px" }}
            >
              <span
                style={{
                  content: "",
                  backgroundColor: "#111e6c",
                  position: "absolute",
                  left: "0",
                  top: "16px",
                  width: "4px",
                  height: "25px",
                }}
              ></span>
              Formularz edycji dostawy
            </Typography>

            <Divider />
            <form onSubmit={this.addEquipmentForm}>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      type="text"
                      label="Odczynnik"
                      value={this.state.DeliveryReagentName}
                      variant="outlined"
                      disabled={true}
                    />
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Ilość"
                      name="DeliveryAmount"
                      value={this.state.DeliveryAmount}
                      onChange={this.onChange}
                      variant="outlined"
                      helperText={
                        errors.DeliveryAmount.length > 0
                          ? errors.DeliveryAmount
                          : ""
                      }
                      error={errors.DeliveryAmount.length ? true : false}
                    />
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      type="text"
                      label="Jednostka"
                      value={this.state.DeliverUnit}
                      variant="outlined"
                      disabled={true}
                    />
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      type="text"
                      label="Dodatkowe informacje"
                      name="DeliverInfo"
                      value={this.state.DeliverInfo}
                      onChange={this.onChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        fullWidth
                        label="Wybierz date ważności"
                        inputFormat="MM/DD/YYYY"
                        value={this.state.DeliveryCalendar}
                        onChange={this.calendarChange}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  p: 2,
                }}
              >
                <Button type="submit" variant="contained">
                  Edytuj dostawe
                </Button>
              </Box>
            </form>
          </Card>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error,
    adding: state.adding,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (formData, id) =>
      dispatch(
        actions.requestApi(formData, api(id).REAGENT_DELIVERY_EDIT_PUT, "put")
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryFormEdit);
