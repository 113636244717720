import { Box, Container, Grid, Typography } from "@mui/material";
import AccountDetailForm from "../Form/AccountDetailForm";
import AccountProfile from "../AccountProfile";
import AccountFilterForm from "../Form/AccountFilterForm";
import AccountNotificationForm from "../Form/AccountNotificationForm";
import AccountImageForm from "../Form/AccountImageForm";

function AccountTabsData(props, dashboardFilter, userNotification) {
  const tabsData = [
    {
      id: 0,
      label: "Główne informacje",
      content: (
        <Grid container spacing={3}>
          <Grid item lg={4} md={6} xs={12}>
            <AccountProfile />
          </Grid>
          <Grid item lg={8} md={6} xs={12}>
            <AccountDetailForm props={props} />
          </Grid>
          <Grid item md={12} xs={12}>
            <AccountImageForm props={props} data={userNotification} />
          </Grid>
        </Grid>
      ),
    },
    {
      id: 1,
      label: "Notyfikacja",
      content: (
        <Grid container spacing={3}>
          <Grid item md={4} xs={12}>
            <AccountFilterForm props={props} data={dashboardFilter} />
          </Grid>
          <Grid item md={8} xs={12}>
            <AccountNotificationForm props={props} data={userNotification} />
          </Grid>
        </Grid>
      ),
    },
  ];
  return tabsData;
}

export default AccountTabsData;
