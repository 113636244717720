import { styled } from "@mui/material/styles";
import background from "../assets/images/background.png";

const AuthWrapper = styled("div")(({ theme }) => ({
  minHeight: "100vh",
  backgroundImage: `url(${background})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center center",

}));

export default AuthWrapper;
