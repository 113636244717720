import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Container, Grid, Typography } from '@material-ui/core';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import NavbarHomePage from '../../components/NavbarHomePage';
import NavbarRoutes from '../../components/navbar-routes';
import Footer from "../../components/footer";
import footerRoutes from "../../config/footer-routes";
import Hand from '../../components/cooperation/Hand';
import ContactFormPopup from "../../components/form/ContactFormPopup";
import CardActions from "@mui/material/CardActions";

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontSize: "2rem",
    fontWeight: "500",
    lineHeight: "1.375",
    display: "block",
    opacity: "1",
    textTransform: "none",
    textAlign: "center",
    verticalAlign: "unset",
    textDecoration: "none",
    color: "black !important",
    letterSpacing: "-0.125px",
  },

  

  container: {
    marginTop: theme.spacing(15), // Zmieniona wartość marginesu górnego
    paddingTop: theme.spacing(2), // Dodatkowy margines górny dla odstępu od nawigacji
  },
}));


const textStyles = {
    fontSize: "1.25rem",
    fontWeight: "400",
    lineHeight: "1.625",
    opacity: "0.8",
    textTransform: "none",
    verticalAlign: "unset",
    textDecoration: "none",
    color: "rgb(255, 255, 255)",
    letterSpacing: "-0.125px",
  };

const CooperationPage = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
  useEffect(() => {
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Box component="section" sx={{ flex: '1 0 auto' }}>
        <NavbarHomePage
          routes={NavbarRoutes}
          action={{
            type: 'external',
            route: 'https://www.creative-tim.com/product/material-kit-react',
            label: 'free download',
            color: 'info',
          }}
          sticky
        />
      </Box>

      <Container className={classes.container}>
        <Grid item xs={12} md={12} lg={12}>
          <Box className={classes.titleContainer}>
            <Typography variant="h2" className={classes.title}>
              Współpraca
            </Typography>
          </Box>
          <Box
            component="span"
            sx={{
              display: "block",
              width: "20%",
              borderBottom: "5px solid #B04C4E",
              marginTop: "0.5rem",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          ></Box>
        </Grid>

        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Hand />

        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', my: 5 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box textAlign="center">
            <Typography variant="h5" component="h5" style={{ fontFamily: 'Lucida Bright' }}>
            Instytuty badawcze i uniwersytety
            </Typography>
            <Typography variant="body1" style={{ fontFamily: 'Lato' }}>
            Aplikacja biobankowania umożliwia instytutom badawczym i uniwersytetom gromadzenie, przechowywanie i udostępnianie różnorodnych próbek biologicznych. 
            Dzięki temu mogą prowadzić zaawansowane badania naukowe, analizować dane genetyczne, poszukiwać biomarkerów, badać interakcje genetyczne i środowiskowe oraz prowadzić badania nad chorobami.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box textAlign="center">
            <Typography variant="h5" component="h5" style={{ fontFamily: 'Lucida Bright' }}>
            Firmy farmaceutyczne i biotechnologiczne
            </Typography>
            <Typography variant="body1" style={{ fontFamily: 'Lato' }}>
            Dla firm farmaceutycznych i biotechnologicznych aplikacja biobankowania jest cennym narzędziem do zdobycia dostępu do szerokiej gamy próbek biologicznych, które są niezbędne do prowadzenia badań nad nowymi lekami, terapiami i diagnostyką. 
            Biobank umożliwia firmom gromadzenie danych genetycznych i klinicznych, co pomaga w identyfikacji celów terapeutycznych i zrozumieniu odpowiedzi organizmu na leczenie.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box textAlign="center">
            <Typography variant="h5" component="h5" style={{ fontFamily: 'Lucida Bright' }}>
            Organizacje non-profit
            </Typography>
            <Typography variant="body1" style={{ fontFamily: 'Lato' }}>
            Dla organizacji non-profit aplikacja biobankowania jest nieocenionym narzędziem w badaniach nad przyczynami, 
            leczeniem i diagnozowaniem konkretnych chorób. Biobank umożliwia im dostęp do próbek biologicznych od pacjentów, 
            co umożliwia prowadzenie badań nad genetycznymi i środowiskowymi czynnikami ryzyka, 
            identyfikację biomarkerów oraz rozwój lepszych metod diagnozowania i leczenia.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box textAlign="center">
            <Typography variant="h5" component="h5" style={{ fontFamily: 'Lucida Bright' }}>
            Centra medyczne i szpitale
            </Typography>
            <Typography variant="body1" style={{ fontFamily: 'Lato' }}>
            Aplikacja biobankowania dla centrów medycznych i szpitali umożliwia gromadzenie i zarządzanie próbkami biologicznymi pacjentów w sposób scentralizowany i bezpieczny. 
            Biobankowanie pomaga w badaniu epidemiologii chorób, analizie danych klinicznych i genetycznych, 
            identyfikacji czynników ryzyka oraz wspieraniu badań translacyjnych, które mają na celu wprowadzenie wyników badań do praktyki klinicznej.
            </Typography>
          </Box>
        </Grid>
      </Grid>



    </Box>
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', my: 15 }}>


    
    <Grid item xs={12} md={12} lg={12}>
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', }}>
        <Typography variant="body1" className={classes.text}>
          Jesteśmy otwarci na nawiązanie współpracy z nowymi partnerami. Niezależnie od branży czy rozmiaru Twojej firmy,
          jesteśmy gotowi do podjęcia nowych wyzwań. Nasza wizja to tworzenie innowacyjnych rozwiązań i osiąganie sukcesów
          poprzez synergiczne połączenie naszych zasobów i umiejętności. Jeśli jesteś zainteresowany/a współpracą z nami,
          skontaktuj się i omówmy możliwości wspólnego działania.
        </Typography>
      </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        <CardActions sx={{ justifyContent: 'center' }}>
          {/* Tutaj możesz dodać dowolne elementy, takie jak przyciski lub inne interaktywne komponenty */}
        </CardActions>
        <ContactFormPopup open={open} handleClose={handleClose} />
      </Box>
        </Grid>
        </Box>

      </Container>          

      <Box sx={{ pt: 6, px: 1 }}>
        <Footer content={footerRoutes} />
      </Box>
    </Box>
  );
};

export default CooperationPage;
