import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Box, Typography } from "@mui/material";
import ImageSlider from "./ImageSlider";
import GreycolabLogo from "../../assets/images/greycolab_logo-01.png";
import OfficePng from "../../assets/images/high-angle-view-software-developer-writing-code-while-using-computer-data-systems-office.png";

function AboutUs() {
  const titleStyles = {
    fontSize: "1.5rem",
    fontWeight: "700",
    lineHeight: "1.375",
    display: "block",
    opacity: "1",
    textAlign: "center",
    textTransform: "none",
    verticalAlign: "unset",
    textDecoration: "none",
    letterSpacing: "-0.125px",
  };

  const textStyles = {
    fontSize: "1.25rem",
    fontWeight: "400",
    lineHeight: "1.625",
    opacity: "0.8",
    textTransform: "none",
    verticalAlign: "unset",
    textDecoration: "none",
    color: "rgb(255, 255, 255)",
    letterSpacing: "-0.125px",
  };

  const bgImage =
    "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/desktop.jpg";

  return (
    <Box
      display="flex"
      alignItems="center"
      my={2}
      py={6}

    >
      <Container>
        <Grid container mt={7} mb={7}>
          <Grid item xs={12} lg={4}>
            <Box
              sx={{ pr: 5, pl: 5 }}
              component="img"
              src={GreycolabLogo}
              borderRadius="lg"
              width="100%"
              position="relative"
              zIndex={1}
            />
          </Grid>
          <Grid item xs={12} lg={8}>
            <Typography
              variant="h2"
              color="white"
              fontWeight="bold"
              sx={titleStyles}
              mb={2}
            >
              Kim jesteśmy?
            </Typography>
            <Typography color="white" opacity={0.8} mb={2} sx={{ textStyles }}>
              Obecnie skupiamy się na nowoczesnych rozwiązaniach ułatwiających
              automatyzację procesową w firmach. Specjalizujemy się w
              kompleksowych usługach dla branż medycznych. Dodatkowo wykonujemy
              automatyzację procesów sieciowych polegającą na przekierowaniu
              ruchu w sytuacjach kryzysowych, w oparciu o autorskie algorytmy.
            </Typography>
          </Grid>

          <Grid item xs={12} lg={5} sx={{mt:5}}> 
            <Typography
              variant="h2"
              color="white"
              fontWeight="bold"
              sx={titleStyles}
              mb={2}
            >
              Informatyzacja sektora medycznego
            </Typography>
            <Typography color="white" opacity={0.8} mb={2} sx={{ textStyles }}>
              Obecnie skupiamy się na nowoczesnych rozwiązaniach ułatwiających
              automatyzację procesową w firmach. Specjalizujemy się w
              kompleksowych usługach dla branż medycznych. Dodatkowo wykonujemy
              automatyzację procesów sieciowych polegającą na przekierowaniu
              ruchu w sytuacjach kryzysowych, w oparciu o autorskie algorytmy.
            </Typography>
          </Grid>
          <Grid item xs={12} lg={7}>
            <Box
              sx={{ pr: 5, pl: 5 }}
              component="img"
              src={OfficePng}
              borderRadius="lg"
              width="100%"
              position="relative"
              zIndex={1}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default AboutUs;
